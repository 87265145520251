<script>
import { mapGetters } from 'vuex'
import { VStepper } from 'vue-stepper-component'
import GoodWizard from '../../utility/vueGoodWizard'
import Axios from 'axios'
import _ from 'lodash'
import ImageSelector from '../../utility/image-selector'
import { appConfig } from '../../../config'
import Multiselect from 'vue-multiselect'

export default {
  name: 'estimate-options-send-to-partssearch',
  data: function () {
    return {
      parts: [],
      partssearchDefaultLocation: null,
      isCanSend: true,
      isCanSendPS: true,
      isCanSendSupp: true,
      images: [],
      estimate: {
        vehicleRego: '',
        vehicleOwner: '',
        estimate_number: '',
        supplement_number: '',
        partsSearchRfq: {
          id: null,
          isCanUpdate: false,
          status: '',
          partIds: [],
        },
      },
      actionType: 'New',
      selectedImages: [],
      suppliers: [],
      allPartsSelected: false,
      isSendToPS: true,
      isSendToSupplier: false,
      supplierEmail: [],
      steps: [
        {
          label: 'Select Parts',
          slot: 'estimate-options-send-to-partssearch-step-1',
        },
        {
          label: 'Select Images',
          slot: 'estimate-options-send-to-partssearch-step-2',
          options: {
            nextDisabled: true,
          },
        }
      ],
      step: 1,
    }
  },
  methods: {
    getParts(v) {
      if (this.isNewAdditionals && this.actionType == 'New' && this.isSendToPS) {
        return _.filter(v, (p) => {
          return !_.find(this.estimate.partsSearchRfq.partIds, (id) => {
            return Number(p.id) == Number(id)
          })
        })
      }
      return v
    },
    _isEmpty(v) {
      return _.isEmpty(v)
    },
    onActionTypeChange() {
      if (this.actionType == 'Alternate' && this.isCanNotSendAlternate) {
        this.isSendToPS = false;
        this.isSendToSupplier = true
      }
    },
    loadSuppliers: function (make) {
      make = make + '/'
      NProgress.start()
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/supplier/make/active`,
        validateStatus: function (status) {
          return status < 500
        },
        data: {
          make: make,
          pageNum: 1,
          sort_by: 'supplier_id',
          sort_type: 'DESC'
        }
      })
          .then(response => {
            this.suppliers = response.data.data
          })
    },
    toggleSelectAllParts () {
      if (this.allPartsSelected) {
        this.parts.forEach(part => part.selected = false)
      } else {
        this.parts.forEach(part => part.selected = true)
      }
    },
    loadPartsToPartsSearch: function (estimate_id) {
      NProgress.start()
      Axios.get(`/fe/estimate/to/partssearch/${estimate_id}`)
          .then(response => {
            this.parts = response.data.parts

            this.estimate = response.data.estimate

            this.loadSuppliers(this.estimate.vehicleMake)

            this.partssearchDefaultLocation = response.data.partssearchDefaultLocation

            /* TODO: review */
            this.parts = this.parts.map(part => {
              this.$set(part, 'selected', false)
              return part
            })
            /* end review */

            this.images = response.data.images

            if (this.estimate?.partsSearchRfq?.id) {
              this.actionType = 'Alternate'
            } else {
              this.actionType = 'New'
            }

            if (this.isCanNotSendAlternate) {
              this.isSendToPS = false;
              this.isSendToSupplier = true
            }

            NProgress.done()
          })
          .catch(error => {
            toastr.error('Error occurred while loading estimate info')
            console.log(error)
            NProgress.done()
          })
    },
    goToEstimate () {
      this.$router.push({
        name: 'EstimatesEdit',
        params: {estimate_id: this.computedEstimateId, tab: this.$route.params.tab, subTab: this.$route.params.subTab, itemsListId: this.$route.params.estimate_id}
      })
    },
    clickCancel: function () {
      this.$router.push({
        name: 'EstimatesEdit',
        params: {estimate_id: this.computedEstimateId, tab: this.$route.params.tab, subTab: this.$route.params.subTab, itemsListId: this.$route.params.estimate_id}
      })
      return
    },
    clickSendMain() {
      if (this.isSendToSupplier && this.isSendToPS) {
        this.isCanSend = this.isCanSendPS && this.isCanSendSupp
      } else if (this.isSendToPS) {
        this.isCanSend = this.isCanSendPS
      } else if (this.isSendToSupplier) {
        this.isCanSend = this.isCanSendSupp
      }
      if (!this.isCanSend) {
        return
      }

      this.isCanSend = false;
      this.isCanSendSupp = false;
      this.isCanSendPS = false

      let subTab = _.cloneDeep(this.$route.params.subTab)

      let interval = setInterval(() => {
        let b = false;
        if (this.isSendToSupplier && this.isSendToPS) {
          b = this.isCanSendPS && this.isCanSendSupp
        } else if (this.isSendToPS) {
          b = this.isCanSendPS
        } else if (this.isSendToSupplier) {
          b = this.isCanSendSupp
        }
        if (b) {
          this.$router.push({
            name: 'EstimatesEdit',
            params: {estimate_id: this.computedEstimateId, tab: this.$route.params.tab, subTab: subTab, itemsListId: this.$route.params.estimate_id}
          })
          clearInterval(interval)
        }
      }, 100)
      if (this.isSendToSupplier) {
        this.clickSentToSupp()
      }
      if (this.isSendToPS) {
        this.clickSend()
      }
    },
    clickSentToSupp() {


      let suppliersList = []
      _.forEach(this.supplierEmail, (s) => {
        suppliersList.push({
          supplierId: s.id,
          partIds: this.selectedPartIds,
        })
      })

      let estimate_id = this.$route.params.estimate_id

      NProgress.start()
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/email/supplier/${estimate_id}`,
        validateStatus: function (status) {
          return status < 500
        },
        data: JSON.stringify({
          suppliers: suppliersList,
          images: this.selectedImages,
        })
      })
          .then(response => {
            if (response.status == 200) {
              toastr.success(response.data.msg)
              // this.$router.push({
              //   name: 'EstimatesEdit',
              //   params: {estimate_id: this.computedEstimateId, tab: this.$route.params.tab, subTab: this.$route.params.subTab, itemsListId: this.$route.params.estimate_id}
              // })
              // this.$refs.supplierModal.hide();
            } else {
              toastr.error(response.data.msg)
            }
            NProgress.done()
          })
          .catch(error => {
            NProgress.done()
            toastr.error(error)
          })
          .finally(() => {
            this.isCanSendSupp = true
          })

    },
    clickSend: function () {

      let estimate_id = this.$route.params.estimate_id

      NProgress.start()
      let actionType= 'new'
      if (this.actionType == 'Alternate'){
        actionType = 'alternate'
      }
      if (this.actionType == 'New' && this.isNewAdditionals && this.isSendToPS){
        actionType = 'new additional'
      }
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/estimate/partssearch/${estimate_id}`,
        validateStatus: function (status) {
          return status < 500
        },
        data: JSON.stringify({
          images: this.selectedImages,
          parts: this.selectedPartIds,
          sendType: actionType,
          partssearchLocationId: this.partssearchDefaultLocation,
        })
      })
          .then(response => {
            if (response.status == 200) {
              if (response.data.status === false) {
                toastr.error(response.data.errorMsg || response.data.msg, 'Error')
              } else {
                toastr.success('Your request for Quote has been sent to Partssearch')

                let url = appConfig.appPartsSearchURL
                if (this.actionType === 'New' || this.actionType === 'Alternate')
                {
                  if (response.data.rfqId > 0) {
                    url += '/r/rfq/add/received/' + response.data.rfqId
                  }else{
                    toastr.error('Your request for Quote has not been sent to Partssearch. Please try again');
                    return ;
                  }
                }
                else if (this.estimate?.partsSearchRfq?.status === 'Received' && this.estimate?.partsSearchRfq?.id)
                {
                  url +=   '/r/rfq/add/received/' + this.estimate?.partsSearchRfq?.id
                }
                else if (this.estimate?.partsSearchRfq?.id){
                  url +=   '/r/rfq/' + this.estimate?.partsSearchRfq?.id
                }
                 else{
                  url +=   '/r/rfqs'
                }
                window.open(url, '_blank').focus()
                // this.$router.push({
                //   name: 'EstimatesEdit',
                //   params: {estimate_id: this.computedEstimateId, tab: this.$route.params.tab, subTab: this.$route.params.subTab, itemsListId: this.$route.params.estimate_id}
                // })
              }
              // this.$refs.supplierModal.hide();
            } else {
              toastr.error(response.data.msg)
            }
            NProgress.done()
          })
          .catch(error => {
            NProgress.done()
            toastr.error(error)
          })
          .finally(() => {
            this.isCanSendPS = true
          })
    },
    onImageSelect: function (imgs) {
      // console.log(imgs)
      this.selectedImages = imgs
    },
    onPartCheck () {
      if (this.selectedPartIds.length > 0 && this.selectedPartIds.length === this.parts.length) {
        this.allPartsSelected = true
      } else {
        this.allPartsSelected = false
      }
    },
  },
  computed: {
    ...mapGetters({
      getterBusinessName: 'getBusinessName',
      isShowBusinessName: 'isShowBusinessName',
      getterPartsSearchLocations: 'getPartsSearchLocations',
    }),
    isNewAdditionals() {
      return !_.isEmpty(this.estimate?.partsSearchRfq?.partIds)
    },
    isCanNotSend () {
      return !!(this.actionType === 'Alternate' && this.estimate?.partsSearchRfq?.hasAlternate) && !this.isSendToSupplier && _.isEmpty(this.supplierEmail)
    },
    isCanNotSendAlternate () {
      return !!(this.actionType === 'Alternate' && this.estimate?.partsSearchRfq?.hasAlternate)
    },
    computedLocation: {
      get: function () {
        let vm = this
        try {
          let item = _.find(this.locationsOptions, function (itm) {
            return Number(itm.id) === Number(vm.partssearchDefaultLocation)
          })
          return item
        } catch (e) {
          return {}
        }
      },
      set: function (item) {
        if (item) {
          this.partssearchDefaultLocation = item.id
        }
      }
    },
    locationsOptions () {
      return this.getterPartsSearchLocations
    },
    isActionTypeSelectAvailable () {
      return !!(this.estimate?.partsSearchRfq?.id)
    },
    computedEstimateId () {
      if (this.estimate.supplement_number > 0) {
        return this.estimate.parent_estimate_id
      }
      return this.$route.params.estimate_id
    },
    selectedPartIds () {
      try {
        let arr = _.filter(this.parts, { 'selected': true })
        let res = []
        _.forEach(arr, function (i) {
          res.push(i.id)
        })
        return res
      } catch (e) {
        return []
      }
    },
  },
  mounted: function () {
    this.loadPartsToPartsSearch(this.$route.params.estimate_id)
  },
  components: {
    VStepper,
    GoodWizard,
    Multiselect,
    ImageSelector,
  },
}

</script>

<template>
  <div class="estimate-options-send-to-partssearch">
    <div class="page-header">
      <h4>Edit Estimate - {{ estimate.estimate_number }} <span
          v-if="estimate.supplement_number > 0">- {{ estimate.supplement_number }} </span><span
          v-if="estimate.vehicleOwner">| {{ estimate.vehicleOwner }} </span><span
          v-if="estimate.vehicleRego">| {{ estimate.vehicleRego }}</span></h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link :to="'/'">Home</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="'/estimates'">View Estimates</router-link>
        </li>
        <li class="breadcrumb-item"><a href="" @click.prevent="goToEstimate">Edit Estimate</a></li>
        <li class="breadcrumb-item">Options</li>
        <li class="breadcrumb-item active">Send To PartsSearch</li>
      </ol>
      <h4 v-if="isShowBusinessName" class="header-display-name">{{ getterBusinessName }}</h4>
    </div>
    <div class="">

      <good-wizard
          :steps="steps"
          :currentStep="step"
          ref="estimateOptionsSendToPartsSearch">
        <div slot="estimate-options-send-to-partssearch-step-1" class="estimate-options-send-to-partssearch-step-1">
          <div class="header">
            Parts
          </div>
          <div class="subheader">
            Choose the Part(s) to send to your suppliers to quote in <strong>Partssearch</strong>.
          </div>
          <div class="mb-2">
            <div class="partssearch-vechicle-blocks mb-2">
              <div class="form-group row no-gutter d-flex partssearch-vechicle-right-block">
                <label class="col-form-label mr-3" style="min-width: 230px">Request for Quote Action Type
                  <div style="color: #a6a6a6; min-width: 230px">
                    Sets the action type for your Request for <br/>Quote in Partssearch
                  </div>
                </label>
              </div>
              <div class="partssearch-input-width">
                <multiselect
                    :options="['New', 'Alternate']"
                    v-model="actionType"
                    :showLabels="false"
                    :option-height="29"
                    :max-height="203"
                    :close-on-select="true"
                    @input="onActionTypeChange"
                    style="width: 147px;"
                    placeholder="Action Type"
                    class="partssearch-vechicle-left-block"
                    :disabled="!isActionTypeSelectAvailable"
                >
                  <template #option="{ option }">
                    {{ option == 'New' && isNewAdditionals && isSendToPS ? 'New Additionals' : option}}
                  </template>
                  <template #singleLabel="{ option }">
                    {{ option == 'New' && isNewAdditionals && isSendToPS ? 'New Additionals' : option}}
                  </template>
                </multiselect>
                <span class="error-text" v-if="isCanNotSendAlternate">
                            You have already sent one Alternate quote request. Partssearch does not allow more than 1
                          </span>
              </div>
            </div>

            <div class="estimate-addition-info">
              <div class="estimate-addition-info-column">
                <div class="form-group row no-gutter d-flex estimate-addition-info-column-item">
                  <label class="col-form-label partssearch-vechicle-title">Rego</label>
                  <div class="partssearch-input-width">
                    <input
                        class="form-control partssearch-input-width"
                        placeholder="Rego"
                        :disabled="isCanNotSend"
                        v-model="estimate.vehicleRego">
                  </div>
                </div>
                <div class="form-group row no-gutter d-flex estimate-addition-info-column-item">
                  <label class="col-form-label partssearch-vechicle-title">Build date</label>
                  <div class="partssearch-input-width">
                    <input
                        class="form-control partssearch-input-width"
                        placeholder="Build date"
                        :disabled="isCanNotSend"
                        v-model="estimate.vehicleDom">
                  </div>
                </div>
              </div>
              <div class="estimate-addition-info-column">
                <div class="form-group row no-gutter d-flex estimate-addition-info-column-item">
                  <label class="col-form-label partssearch-vechicle-title">Make</label>
                  <div class="partssearch-input-width">
                    <input
                        class="form-control partssearch-input-width"
                        placeholder="Make"
                        :disabled="isCanNotSend"
                        v-model="estimate.vehicleMake">
                  </div>
                </div>
                <div class="form-group row no-gutter d-flex estimate-addition-info-column-item">
                  <label class="col-form-label partssearch-vechicle-title">Model</label>
                  <div class="partssearch-input-width">
                    <input
                        class="form-control partssearch-input-width"
                        placeholder="Model"
                        :disabled="isCanNotSend"
                        v-model="estimate.vehicleModel">
                  </div>
                </div>
              </div>

              <div class="estimate-addition-info-column">
                <div class="form-group row no-gutter d-flex estimate-addition-info-column-item">
                  <label class="col-form-label partssearch-vechicle-title">Shop<br>Location</label>
                  <div class="partssearch-input-width">
                    <multiselect
                        v-model="computedLocation"
                        :options="locationsOptions"
                        :showLabels="false"
                        :disabled="isCanNotSend"
                        :option-height="29"
                        :max-height="203"
                        placeholder="Locations"
                        :close-on-select="true"
                        track-by="id"
                        label="name"
                    ></multiselect>
                  </div>

                </div>

                <div v-if="actionType == 'New' || actionType == 'Alternate'" class="form-group row no-gutter d-flex estimate-addition-info-column-item" style="flex-direction: column">
                  <div class="d-flex">
                    <label class="col-form-label partssearch-vechicle-title">Send To</label>
                    <div class="partssearch-input-width">
                      <label class="form-check-inline checkbox">
                        <input :disabled="isCanNotSendAlternate" class="form-check-input" v-model="isSendToPS" @change="isSendToPS ? isSendToSupplier = false : isSendToSupplier = isSendToSupplier"
                               type="checkbox">
                        <span class="icon" :class="{'disabled-checkbox-item': isCanNotSendAlternate}"><i
                            class='bx bx-check'></i></span><span class="text" style="width: 122px;">Partssearch</span>
                      </label>
                    </div>
                  </div>

                  <div class="d-flex" style="margin-left: 100px;">
                    <div class="partssearch-input-width">
                      <label class="form-check-inline checkbox">
                        <input class="form-check-input" v-model="isSendToSupplier" @change="isSendToSupplier ? isSendToPS = false : isSendToPS = isSendToPS"
                               type="checkbox">
                        <span class="icon"><i
                            class='bx bx-check'></i></span><span class="text" style="width: 122px;">Supplier Email</span>
                      </label>
                    </div>

                    <multiselect
                        :options="suppliers"
                        track-by="id"
                        label="business_name"
                        v-model="supplierEmail"
                        :showLabels="false"
                        :option-height="29"
                        :multiple="true"
                        :max-height="203"
                        :close-on-select="true"
                        style="width: 185px; margin-left: 15px; height: 32px"
                        placeholder="Email"
                        class="partssearch-vechicle-left-block supp-email-multiselect"
                        :disabled="!isSendToSupplier"
                    >
                    </multiselect>
                  </div>

                </div>
              </div>
            </div>

            <div class="partssearch-vechicle-blocks">

            </div>
            <div class="partssearch-vechicle-blocks">
            </div>

          </div>
          <div>
            <table class="table b-table select-parts">
              <thead class="bg-navyblue">
              <tr>
                <th class="header-check">
                  <label class="form-check-inline checkbox">
                    <input :disabled="isCanNotSend" @click="toggleSelectAllParts" class="form-check-input"
                           type="checkbox" v-model="allPartsSelected">
                    <span class="icon" :class="{'disabled-checkbox-item': isCanNotSend}"><i
                        class='bx bx-check'></i></span>
                  </label>
                </th>
                <th class="header-name" style="text-align: left;">Part Name</th>
                <th class="header-name" style="text-align: left; padding-left: 9px">Part Number</th>
                <th class="header-name">Qty</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(partItem, index) in getParts(parts)" :key="partItem.id">
                <td>
                  <label class="form-check-inline checkbox">
                    <input :disabled="isCanNotSend" class="form-check-input" type="checkbox"
                           @change="onPartCheck"
                           v-model="partItem.selected">
                    <span class="icon" :class="{'disabled-checkbox-item': isCanNotSend}"><i
                        class='bx bx-check'></i></span>
                  </label>
                </td>
                <td>{{ partItem.name }}</td>
                <td>{{ partItem.number }}</td>
                <td class="text-center">{{ partItem.qty }}</td>
              </tr>
              </tbody>
            </table>
<!--            <div v-if="actionType == 'New'" class="send-email-type">-->
<!--              <label class="form-check-inline checkbox">-->
<!--                <input class="form-check-input"-->
<!--                       type="checkbox"-->
<!--                       checked-->
<!--                       disabled>-->
<!--                <span class="icon"><i class='bx bx-check'></i></span><span class="text">Send to PartsSearch</span>-->
<!--              </label>-->
<!--            </div>-->
          </div>
        </div>
        <div slot="estimate-options-send-to-partssearch-step-2" class="estimate-options-send-to-partssearch-step-2">
          <div class="header">
            Select Images
          </div>
          <div class="subheader">
            Choose the images to attach by ticking the box next to the image
          </div>

          <div class="wizard__buttons">
            <div class=" pull-right">
              <button
                  class="btn btn-outline-primary pull-left button-cancel mr-0"
                  type="button"
                  @click="clickCancel"
              >
                Cancel
              </button>
              <button
                  v-if="$refs.estimateOptionsSendToPartsSearch && $refs.estimateOptionsSendToPartsSearch.currentStep != 0"
                  class="btn btn-outline-primary-light pull-left button-back mr-0 ml-1"
                  type="button"
                  @click="$refs.estimateOptionsSendToPartsSearch.goBack()"
              >
                Back
              </button>
              <button
                  v-if="$refs.estimateOptionsSendToPartsSearch && $refs.estimateOptionsSendToPartsSearch.currentStep != 1"
                  class="btn btn-primary pull-right button-next"
                  type="button"
                  :disabled="isCanNotSend || (isSendToSupplier && _isEmpty(supplierEmail)) || !actionType || !selectedPartIds.length || (!isSendToSupplier && !isSendToPS)"
                  @click="$refs.estimateOptionsSendToPartsSearch.goNext()"
                  :tabindex="4"
              >
                Next
              </button>
              <button
                  v-if="$refs.estimateOptionsSendToPartsSearch && $refs.estimateOptionsSendToPartsSearch.currentStep == 1"
                  class="btn btn-primary pull-right button-card-estimate"
                  type="button"
                  @click="clickSendMain"
                  :disabled="!isCanSend"
                  style=""
              >
                Send
              </button>
            </div>
          </div>

          <div class="box box-block">
            <div class="row send-to-additional-position-image-wizard">
              <image-selector :images="images" :path="''" @onChange="onImageSelect"></image-selector>
            </div>
          </div>
        </div>
      </good-wizard>

      <div class="wizard__buttons">
        <div class=" pull-right">
          <button
              class="btn btn-outline-primary pull-left button-cancel mr-0"
              type="button"
              @click="clickCancel"
          >
            Cancel
          </button>
          <button
              v-if="$refs.estimateOptionsSendToPartsSearch && $refs.estimateOptionsSendToPartsSearch.currentStep != 0"
              class="btn btn-outline-primary-light pull-left button-back mr-0 ml-1"
              type="button"
              @click="$refs.estimateOptionsSendToPartsSearch.goBack()"
          >
            Back
          </button>
          <button
              v-if="$refs.estimateOptionsSendToPartsSearch && $refs.estimateOptionsSendToPartsSearch.currentStep != 1"
              class="btn btn-primary pull-right button-next"
              type="button"
              :disabled="isCanNotSend || (isSendToSupplier && _isEmpty(supplierEmail)) || !actionType || !selectedPartIds.length || (!isSendToSupplier && !isSendToPS)"
              @click="$refs.estimateOptionsSendToPartsSearch.goNext()"
              :tabindex="4"
          >
            Next
          </button>
          <button
              v-if="$refs.estimateOptionsSendToPartsSearch && $refs.estimateOptionsSendToPartsSearch.currentStep == 1"
              class="btn btn-primary pull-right button-card-estimate"
              type="button"
              @click="clickSendMain"
              :disabled="!isCanSend"
              style=""
          >
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.V3 .supp-email-multiselect .multiselect__input {
  min-width: 0px !important;
}
.V3 .supp-email-multiselect .multiselect__tags {
  max-height: 65px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.V3 .estimate-options-send-to-partssearch .disabled-checkbox-item {
  cursor: not-allowed !important
}

.V3 .estimate-options-send-to-partssearch label.checkbox > input[type="checkbox"]:checked:disabled + span.icon {
  background-color: #5E79FF !important;
}

.V3 .estimate-options-send-to-partssearch label.checkbox > input[type="checkbox"]:checked:disabled + span.icon i {
  color: white !important;
}

.V3 .estimate-options-send-to-partssearch .estimate-addition-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 1518px) {
  .V3 .estimate-options-send-to-partssearch .estimate-addition-info .estimate-addition-info-column {
    margin-right: 2% !important;
  }
}

@media (min-width: 1518px) {
  .V3 .estimate-options-send-to-partssearch .estimate-addition-info {
    justify-content: flex-start;
  }

  .estimate-addition-info-column {
    margin-right: 10%;
  }
}

.V3 .estimate-options-send-to-partssearch .wizard__body {
  padding: 15px;
  width: 100%;
}

.V3 .estimate-options-send-to-partssearch .wizard__body .estimate-options-send-to-partssearch-step-1 {
  width: 75%;
  margin: 0 auto;
}

.V3 .estimate-options-send-to-partssearch .wizard__body .estimate-options-send-to-partssearch-step-1 .table th {
  color: #1C1F39;
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  /*   vertical-align: top; */
}

.V3 .estimate-options-send-to-partssearch .wizard__body .estimate-options-send-to-partssearch-step-1 .table td {
  color: #1C1F39;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
}

.V3 .estimate-options-send-to-partssearch .wizard__body .estimate-options-send-to-partssearch-step-2 {
  width: 100%;
}

.V3 .estimate-options-send-to-partssearch .wizard__buttons {
  width: 75%;
  margin: 0 0 70px 5px;
  padding-right: 15px;
}

.V3 .estimate-options-send-to-partssearch .multiselect__tags {
  border-radius: 3px;
}
</style>
<style scoped>
.V3 .select-suplier-show-more {
  display: inline-table;
  position: relative;
  transform: translateY(10px);
  margin-bottom: -25px;
  margin-left: 9px;
}

.V3 .select-suplier-show-more a {
  display: inline-block;
  color: #1C1F39;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  margin-bottom: 3px;
}

.V3 .select-suplier-show-more a i {
  color: #1C1F39;
  font-size: 11px;
  margin-left: 3px;
}

.V3 .select-suplier-show-more p {
  color: #1C1F39;
  font-size: 12px;
  font-weight: 600;
}

.V3 .send-email-type {
  display: inline-table;
  position: relative;
  transform: translateY(10px);
  margin-bottom: -25px;
  margin-left: 9px;
}

.V3 .send-email-type p {
  margin-bottom: 0.7rem;
}

.V3 .send-email-type label.checkbox > span.text {
  display: inline-block;
  margin-top: 5px;
  padding-right: 15px;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
}

.V3 .estimate-options-send-to-partssearch >>> .card-block {
  padding: 0px;
}

.V3 .estimate-options-send-to-partssearch .card-block {
  padding: 0px;
}


.V3 .wizard__buttons .pull-left {
  float: left !important;
  margin-right: 10px;
}

.V3 .wizard__buttons .pull-right {
  float: right !important;
  margin-left: 10px;
}

.V3 .wizard__buttons .btn {
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  height: 38px;
}

.V3 .wizard__buttons .button-cancel {
  padding: 10px 21px;
  background: #FFFFFF;
  border: 1px solid rgba(28, 31, 57, 0.25);
  color: #1C1F39;
}

.V3 .wizard__buttons .button-card {
  padding: 10px 15px;
  background: #FFFFFF;
  color: #5E79FF;
}

.V3 .wizard__buttons .button-card-estimate {
  padding: 10px 15px;
}

.V3 .wizard__buttons .button-next {
  padding: 10px 15px;
  width: 83px;
}

.V3 .wizard__buttons .button-back {
  padding: 10px 26px;
  background: #FFFFFF;
  border-color: #5E79FF;
  width: 83px;
}

@media (max-width: 823px) AND (min-width: 604px) {
  .V3 .card-new .wizard__buttons {
    padding-left: 15px;
    padding-right: 15px;
    width: auto;
    min-width: auto;
  }

  .V3 .compact-sidebar .card-new .wizard__buttons {
    width: 573px;
    margin: 0 auto;
    min-width: 573px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 603px) {
  .V3 .card-new .wizard__buttons {
    padding-left: 15px;
    padding-right: 15px;
    width: auto;
    min-width: auto;
  }
}

@media (max-width: 512px) {
  .V3 .card-new .wizard__buttons button {
    margin-bottom: 10px;
    float: none !important;
    margin-left: 0px;
    margin-right: 0px;
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .V3 .estimate-options-send-to-partssearch .wizard__body .estimate-options-send-to-partssearch-step-1,
  .V3 .estimate-options-send-to-partssearch .wizard__body .estimate-options-send-to-partssearch-step-2 {
    width: 100%;
  }

  .V3 .estimate-options-send-to-partssearch .wizard__buttons {
    width: 100% !important;
  }

  .V3 .estimate-options-send-to-partssearch .wizard__buttons {
    width: 100% !important;
  }
}
</style>

<style scoped>
.V3 .estimate-options-send-to-partssearch .estimate-options-send-to-partssearch-step-1 .supplier-default-icon {
  font-size: 18px;
}

.V3 .estimate-options-send-to-partssearch .estimate-options-send-to-partssearch-step-1 .supplier-name {
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 5px;
}

.V3 .estimate-options-send-to-partssearch .estimate-options-send-to-partssearch-step-1 .supplier-type {
  font-size: 12px;
  font-style: italic;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
}

.V3 .estimate-options-send-to-partssearch .estimate-options-send-to-partssearch-step-1 .header-name {
  vertical-align: top;
  padding-top: 35px;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
}

.V3 .estimate-options-send-to-partssearch .estimate-options-send-to-partssearch-step-1 .header-check {
  padding: 0.75rem;
  width: 5%;
  min-width: 45px;
}

/* The original select box is having transparent bg, this is an adjustment when placed on table header */
.V3 .estimate-options-send-to-partssearch .estimate-options-send-to-partssearch-step-1 .header-check .form-check-inline {
  background-color: #ffffff;
  border-radius: 3px;
}

.V3 .estimate-options-send-to-partssearch .estimate-options-send-to-partssearch-step-1 .send-email-type {
  margin-left: 0.75rem;

}

.V3 .estimate-options-send-to-partssearch .estimate-options-send-to-partssearch-step-1 .header-supplier {
  width: 140px;
}

.V3 .estimate-options-send-to-partssearch .estimate-options-send-to-partssearch-step-1 th.header-supplier {
  padding-left: 0px;
  padding-right: 0px;
}

.V3 .estimate-options-send-to-partssearch .estimate-options-send-to-partssearch-step-1 .supplier-due-by-time {
  width: 166px;
  margin: 0;
  padding-left: 15px;
  padding-right: 15px;
  border-left: 1px solid rgba(27, 30, 56, 0.25);
}

.V3 .estimate-options-send-to-partssearch .estimate-options-send-to-partssearch-step-1 .header-supplier.supplier-first .supplier-due-by-time {
  border-left: none;
}

.V3 .estimate-options-send-to-partssearch .estimate-options-send-to-partssearch-step-1 .supplier-due-by-time select.form-control {
  padding-right: 20px !important;
  color: #1C1F39;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
}

.V3 .estimate-options-send-to-partssearch .estimate-options-send-to-partssearch-step-1 .supplier-due-by {
  width: 100px;
  margin: 15px auto 0 auto;
  text-align: left;
  color: rgba(28, 31, 57, 0.5);
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
}

.V3 .estimate-options-send-to-partssearch .estimate-options-send-to-partssearch-step-1 .select-parts .select-parts-check-all span.icon,
.V3 .estimate-options-send-to-partssearch .estimate-options-send-to-partssearch-step-1 .select-parts span.icon {
  margin-right: 0px;
}

@media screen and (max-width: 500px) {
  .V3 .page-header {
    min-height: 100px !important;
  }

  .page-header h4 {
    height: auto;
  }
}

.partssearch-input-width {
  min-width: 50px;
}

.partssearch-vechicle-title {
  min-width: 70px;
  width: 100px;
}

.partssearch-vechicle-right-block {
  margin-right: 100px !important;
}

@media screen and (max-width: 576px) {
  .partssearch-input-width {
    width: 100%;
  }

  .partssearch-vechicle-left-block,
  .partssearch-vechicle-right-block {
    flex-direction: column;
    width: 100%;
    /*margin-right: 0 !important;*/
  }

  .estimate-addition-info-column-item {
    flex-direction: column;
  }
}

.partssearch-vechicle-blocks {
  display: flex;
  flex-wrap: wrap;
  align-items: center !important;
}

@media screen and (min-width: 1200px) {
  .partssearch-vechicle-blocks {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 1010px) {
  .V3 .estimate-options-send-to-partssearch .estimate-addition-info {
    flex-direction: column;
  }

  .partssearch-input-width {
    width: 157px;
  }

  .partssearch-vechicle-right-block {
    margin-right: 1050px !important;
  }
}

.error-text {
  width: 558px !important;
}

@media screen and (max-width: 869px) {
  .error-text {
    width: 450px !important;
  }
}

@media screen and (max-width: 779px) {
  .error-text {
    width: 390px !important;
  }
}

@media screen and (max-width: 767px) {
  .error-text {
    width: 531px !important;
  }
}

@media screen and (max-width: 572px) {
  .error-text {
    width: 450px !important;
  }
}

@media screen and (max-width: 487px) {
  .error-text {
    width: 350px !important;
  }
}

@media screen and (max-width: 319px) {
  .error-text {
    width: 250px !important;
  }
}
</style>

