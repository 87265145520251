<script>
import {VStepper} from 'vue-stepper-component';
import GoodWizard from '../../utility/vueGoodWizard';
import Axios from 'axios';
import _ from 'lodash';
import {mapGetters} from "vuex";
import Email from '../../utility/email-validator';
import Multiselect from 'vue-multiselect';

export default {
  name: 'invoice-options-send-to-orm',
  data: function () {
    return {
      steps: [
        {
          label: 'Confirm Estimate',
          slot: 'invoice-options-send-to-insurer-step-1',
        },
        {
          label: 'Invoice Preview',
          slot: 'invoice-options-send-to-insurer-step-2',
        },
      ],
      isCanSend: true,
      summary: {},
      countSubtotal: 0,
      new_insurer_email: '',
      new_insurer_name: '',
      step: 1,
      pcs: [],
      currentPc: null,
      programRunning: false,
      invoiceToInsurer: {},
      insurerContacts: [],
      cmsCheck: true,
      isCheckInsurerEmail: true,
      isCheckEmail: true,
    };
  },
  methods: {
    goNext() {
      if (this.isCheckEmail && this.isCheckInsurerEmail) {
        this.$refs.invoiceOptionsSendToInsurer.goNext()
      } else {
        toastr.error('Please check the form fields')
      }
    },
    addNewInsurerContact: function () {
      if (!this.isCheckEmail) {
        toastr.error('Your changes could not be saved, please check the form fields again');
        return;
      }
      this.insurerContacts.push({
        name: this.new_insurer_name,
        email: this.new_insurer_email
      });
      this.new_insurer_name = '';
      this.new_insurer_email = '';
    },
    isExpandLabour: function (id = null) {
      if (id) {
        if (this.computedExpand && this.computedExpand[id] && this.computedExpand[id].labour) {
          return true;
        }
        return false;
      } else {
        if (this.invoiceToInsurer.invoice.is_expand_labour) {
          return true;
        }
        return false;
      }
    },
    isExpandPart: function (id = null) {
      if (id) {
        if (this.computedExpand && this.computedExpand[id] && this.computedExpand[id].part) {
          return true;
        }
        return false;
      } else {

        if (this.invoiceToInsurer.invoice.is_expand_part) {
          return true;
        }
        return false;
      }
    },
    isExpandOther: function (id = null) {
      if (id) {
        if (this.computedExpand && this.computedExpand[id] && this.computedExpand[id].other) {
          return true;
        }
        return false;
      } else {
        if (this.invoiceToInsurer.invoice.is_expand_other) {
          return true;
        }
        return false;
      }
    },
    isExpandPDR: function (id = null) {
      if (id) {
        if (this.computedExpand && this.computedExpand[id] && this.computedExpand[id].pdr) {
          return true;
        }
        return false;
      } else {
        if (this.invoiceToInsurer.invoice.is_expand_pdr) {
          return true;
        }
        return false;
      }
    },
    getPcs() {
      Axios.get('http://localhost:8899', {timeout: 2000})
          .then(res => {
            if (res.data && res.data.Status) {
              this.programRunning = !!res.data.ApiKey;
              this.currentPc = res.data;
            }
          })
          .catch(error => {

          })
          .finally(() => {

            Axios.post('/test/getConnectedPcs', {
              currentApiKey: this.currentPc ? this.currentPc.ApiKey : null
            })
                .then(res => {
                  if (res.data && res.data.status) {
                    console.log("PCs %o", res.data.pcs);
                    this.pcs = res.data.pcs;
                    this.pcs.forEach(pc => {
                      if (pc.current) {
                        this.selectedPc = pc.id;
                      }
                    });
                  }
                }).catch(er => {
              console.log(er);
            });

          });
    },
    loadData() {
      let invoice_id = this.$route.params.invoice_id;
      let self = this;

      NProgress.start();
      this.getPcs();
      Axios.get(`/fe/invoice/${invoice_id}`)
          .then(response => {
            self.invoiceToInsurer = response.data;
            self.insurerContacts = [];
            let insurerData = {
              email: response.data.card.insurance.insurer_email,
              name: response.data.card.insurance.insurer_name,
            }


            if (response.data.card.insurance.insurer_payment_email_default) {
              insurerData.email = response.data.card.insurance.insurer_payment_email;
            }

            self.insurerContacts.push(insurerData);

            this.invoiceToInsurer.all_line_items = _.map(this.invoiceToInsurer.all_line_items, lines => {
              lines.line_items.lines = _.mapValues(lines.line_items.lines, items => {
                return _.filter(items, item => item.total != 0);
              });
              return lines;
            });

            let countSubtotal = 0;
            _.forEach(response.data.all_line_items, function (lines) {
              _.forEach(lines.line_items.lines, function (item) {
                if (item && item.length > 0) {
                  countSubtotal++;
                }
              });
            });
            this.countSubtotal = countSubtotal;
            this.summary = response.data.summary;

            if (this.vendorInfo?.features?.integrations?.estimage) {
              this.cmsCheck = false
            } else {
              this.cmsCheck = response.data.card.insurance.claim_management == 'Estimage';
            }

          })
          .catch(error => {
            toastr.error("Error occurred while loading info");
          })
          .finally(() => {
            NProgress.done();
          });
    },
    goToInvoice() {
      this.$router.push({name: 'InvoiceView', params: {invoice_id: this.$route.params.invoice_id, currentTab: this.$route.params.currentTab}});
    },
    clickCancel() {
      this.$router.push({name: 'InvoiceView', params: {invoice_id: this.$route.params.invoice_id, currentTab: this.$route.params.currentTab}});
    },
    clickSend() {
      if (!this.isCanSend) {
        return;
      }

      this.isCanSend = false;

      if (this.cmsCheck) {
        NProgress.start();
        Axios({
          method: 'post',
          responseType: 'json',
          url: `/fe/invoice/to/iag-estimage/${this.invoiceToInsurer.card.estimate_id}`,
          validateStatus: function (status) {
            return status < 500;
          },
          data: JSON.stringify({
            selectedPc: this.selectedPc,
          })
        })
            .then(response => {
              if (response.status == 200 && response.data.status) {
                toastr.success("Sent to Estimage");
                this.goToInvoice();
              } else {
                toastr.error(response.data.msg);
              }
              NProgress.done();
            })
            .catch(error => {
              NProgress.done();
              console.log(error);
              toastr.error('error');
            })
            .finally(() => {
              this.isCanSend = true;
            });

        return;
      }

      if (this.new_insurer_email) {
        this.addNewInsurerContact();
      }

      let invoice_id = this.$route.params.invoice_id;
      let q = JSON.stringify(this.computedExpand);
      var expand_url = '?e=' + encodeURIComponent(q);
      let url = '/fe/email/invoice/insurer/' + invoice_id + expand_url;
      NProgress.start();
      Axios({
        method: 'post',
        responseType: 'json',
        url: url,
        validateStatus: function (status) {
          return status < 500;
        },
        data: JSON.stringify({
          insurers: this.insurerContacts,
        })
      })
          .then(response => {
            if (response.status == 200) {
              toastr.success(response.data.msg);
              this.goToInvoice();
            } else {
              toastr.error(response.data.msg);
            }
          })
          .catch(error => {
            toastr.error(error);
          })
          .finally(() => {
            this.isCanSend = true;
          });
    },

  },
  computed: {
    ...mapGetters({
      vendorInfo: 'vendorInfo',
      getterBusinessName : 'getBusinessName',
      isShowBusinessName: 'isShowBusinessName',
    }),
    computedExpand() {
      if (this.$route.params.expand) {
        return this.$route.params.expand;
      } else {
        return this.invoiceToInsurer.invoice.expand;
      }
    },
    computedSelectedPc: {
      get: function () {
        let vm = this;
        try {
          let item = _.find(this.computedOptionsForPc, function (itm) {
            return itm.key == vm.selectedPc;
          });
          return item;
        } catch (e) {
          return {};
        }
      },
      set: function (item) {
        if (item) {
          this.selectedPc = item.key;
        }
      }
    },
    countOfEstimateAndSupps() {
      if (this.invoiceToInsurer && this.invoiceToInsurer.all_line_items) {
        return this.invoiceToInsurer.all_line_items.length;
      }
      return 0
    },
    computedOptionsForPc() {
      let opts = [];
      _.forEach(this.pcs, function (pc) {
        opts.push({key: pc.id, value: pc.pc_name, active: pc.active, current: pc.current})
      })
      return opts;
    },
    isEmptyInsurerContacts() {
      return _.isEmpty(this.insurerContacts);
    },
    isEmptyInvoiceToInsurer() {
      return _.isEmpty(this.invoiceToInsurer);
    }
  },
  mounted: function () {
    this.loadData();
  },
  components: {
    VStepper,
    GoodWizard,
    Multiselect,
    Email,
  }
};

</script>

<template>
  <div class="invoice-options-send-to-insurer">
    <div class="page-header">
      <h4>View Invoice<span v-if="invoiceToInsurer && invoiceToInsurer.invoice"> - {{invoiceToInsurer.invoice.number}}</span><span v-if="invoiceToInsurer && invoiceToInsurer.invoice && invoiceToInsurer.invoice.customer && invoiceToInsurer.invoice.customer.name"> | {{invoiceToInsurer.invoice.customer.name}}</span><span v-if="invoiceToInsurer && invoiceToInsurer.card && invoiceToInsurer.card.vehicle && invoiceToInsurer.card.vehicle.rego_number"> | {{invoiceToInsurer.card.vehicle.rego_number}}</span></h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link :to="'/'">Home</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="'/invoices'">View Invoices</router-link>
        </li>
        <li class="breadcrumb-item">
          <a href="" @click.prevent="goToInvoice">
            View Invoice
          </a>
        </li>
        <li class="breadcrumb-item">Options</li>
        <li class="breadcrumb-item active">Send To ORM</li>
      </ol>
      <h4 v-if="isShowBusinessName" class="header-display-name">{{ getterBusinessName }}</h4>
    </div>
    <div>

      <good-wizard
          :steps="steps"
          :currentStep="step"
          ref="invoiceOptionsSendToInsurer"
      >
        <div slot="invoice-options-send-to-insurer-step-1" class="invoice-options-send-to-insurer-step-1">
          <div class="header">
            Confirm Estimate
          </div>
          <div class="subheader">
            Review the estimate information you are about to send.
          </div>
          <div>
            <template>
              <div class="card">
                <div class="card-block bg-white">
                  <div class="form-group row">
                    <label class="col-sm-5 col-5 col-form-label" :class="{'grey-color': true}">Estimate Action Type</label>
                    <div class="col-sm-7 col-7">
                      <input  class="form-control" :disabled="true">
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-block bg-white">
                  <div class="form-group row">
                    <label class="col-sm-5 col-5 col-form-label" :class="{'grey-color':true}">Vehicle Owner Name</label>
                    <div class="col-sm-7 col-7">
                      <input type="text" class="form-control" :disabled="true">
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-block bg-white">
                  <div class="form-group row">
                    <label class="col-sm-5 col-5 col-form-label" :class="{'grey-color':true}">Rego</label>
                    <div class="col-sm-7 col-7">
                      <input type="text" class="form-control" :disabled="true">
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-block bg-white">
                  <div class="form-group row">
                    <label class="col-sm-5 col-5 col-form-label" :class="{'grey-color':true}">Make</label>
                    <div class="col-sm-7 col-7">
                      <input type="text" class="form-control" :disabled="true">
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-block bg-white">
                  <div class="form-group row">
                    <label class="col-sm-5 col-5 col-form-label" :class="{'grey-color':true}">Model</label>
                    <div class="col-sm-7 col-7">
                      <input type="text" class="form-control" :disabled="true">
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-block bg-white">
                  <div class="form-group row">
                    <label class="col-sm-5 col-5 col-form-label" :class="{'grey-color':true}">Insurer Name</label>
                    <div class="col-sm-7 col-7">
                      <input type="text" class="form-control" :disabled="true">
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-block bg-white">
                  <div class="form-group row">
                    <label class="col-sm-5 col-5 col-form-label" :class="{'grey-color':true}">Claim Number</label>
                    <div class="col-sm-7 col-7">
                      <input type="text" class="form-control" :disabled="true">
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-block bg-white">
                  <div class="form-group row">
                    <label class="col-sm-5 col-5 col-form-label" :class="{'grey-color':true}">
                      <span v-if="true">Estimate</span><span v-else>Supplement</span> Number</label>
                    <div class="col-sm-7 col-7">
                      <input type="text" class="form-control" :disabled="!true">
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div slot="invoice-options-send-to-insurer-step-2" class="invoice-options-send-to-insurer-step-2 rs-scroll rs-scroll--y">
          <div class="header">
            Invoice Preview
          </div>
          <div class="subheader">
            Confirm the details in the invoice to be sent
          </div>
          <div class="invoice" v-if="!isEmptyInvoiceToInsurer && invoiceToInsurer">
            <div class="invoice">
              <header class="invoice-header">
                <div class="invoice-logo">
                  <img :src="vendorInfo.logo" style="height: 150px; width: 300px;" alt="">
                </div>
                <div class="invoice-info">
                  <h1 class="invoice-title">TAX INVOICE</h1>
                  <table class="header-table">
                    <tr>
                      <td class="font-14 font-bold">{{invoiceToInsurer.vendor.business_name}}</td>
                    </tr>
                    <tr>
                      <td><span class="font-bold">ABN</span>: {{invoiceToInsurer.vendor.abn}}</td>
                    </tr>
                    <tr>
                      <td>License : {{invoiceToInsurer.vendor.license_number}}</td>
                    </tr>
                    <tr v-if="invoiceToInsurer.vendor.postal_address">
                      <td>{{invoiceToInsurer.vendor.postal_address}}</td>
                    </tr>
                    <tr v-if="!invoiceToInsurer.vendor.postal_address">
                      <td>{{invoiceToInsurer.vendor.postal_street}} {{invoiceToInsurer.vendor.postal_suburb}}</td>
                    </tr>
                    <tr v-if="!invoiceToInsurer.vendor.postal_address">
                      <td>{{invoiceToInsurer.vendor.postal_state}} {{invoiceToInsurer.vendor.postal_country}}</td>
                    </tr>
                    <tr>
                      <td>Email : {{invoiceToInsurer.vendor.email}}</td>
                    </tr>
                    <tr>
                      <td>Phone : {{invoiceToInsurer.vendor.phone_area_code}} {{invoiceToInsurer.vendor.phone}}</td>
                    </tr>
                  </table>
                  <table v-if="invoiceToInsurer.invoice.insurer" class="header-table">
                    <tr>
                      <td><strong>Invoice To :</strong></td>
                    </tr>
                    <tr>
                      <td>{{invoiceToInsurer.invoice.insurer.name}}</td>
                    </tr>
                    <tr>
                      <td>{{invoiceToInsurer.invoice.insurer.address}}</td>
                    </tr>
                    <tr>
                      <td>ABN : {{invoiceToInsurer.invoice.insurer.abn}}</td>
                    </tr>
                  </table>
                  <table class="header-table">
                    <tr>
                      <td><strong>Invoice Number:</strong> <span>{{invoiceToInsurer.invoice.number}}</span></td>
                    </tr>
                    <tr>
                      <td><strong>Estimator:</strong> <span>{{invoiceToInsurer.invoice.estimator}}</span></td>
                    </tr>
                    <tr>
                      <td><strong>Invoice Date:</strong> <span>{{invoiceToInsurer.invoice.created_date}}</span></td>
                    </tr>
                    <tr>
                      <td><strong>Due Date:</strong> <span>{{invoiceToInsurer.invoice.due_date}}</span></td>
                    </tr>
                  </table>
                </div>
              </header>

              <main>
                <table class="main-table m0">
                  <thead>
                  <tr>
                    <th colspan="2">Vehicle &amp; Owner</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <th>Owner Details</th>
                    <th>Vehicle Details</th>
                  </tr>
                  <tr>
                    <td class="w50 vat">
                      <table class="main-inner owner-details">
                        <tr>
                          <td>Owner Name</td>
                          <td>{{invoiceToInsurer.card.customer.name}}</td>
                        </tr>
                        <tr>
                          <td>Contact Number:</td>
                          <td>{{invoiceToInsurer.card.customer.mobile}}</td>
                        </tr>
                        <tr>
                          <td>Email:</td>
                          <td>{{invoiceToInsurer.card.customer.email}}</td>
                        </tr>
                        <tr v-if="invoiceToInsurer.card.customer.customer_type == 'B'">
                          <td>ABN</td>
                          <td>{{invoiceToInsurer.card.customer.abn}}</td>
                        </tr>
                        <tr>
                          <td>Insurer Name:</td>
                          <td>{{invoiceToInsurer.card.insurance.insurer_name}}</td>
                        </tr>
                        <tr>
                          <td>Claim Number:</td>
                          <td>{{invoiceToInsurer.card.insurance.claim_number}}</td>
                        </tr>

                      </table>
                    </td>
                    <td class="w50 vat">
                      <table class="vehicle-details">
                        <tr>
                          <td class="w50 vat">
                            <table class="main-inner">
                              <tr>
                                <td>Make:</td>
                                <td>{{invoiceToInsurer.card.vehicle.make}}</td>
                              </tr>
                              <tr>
                                <td>Model:</td>
                                <td>{{invoiceToInsurer.card.vehicle.model}}</td>
                              </tr>
                              <tr>
                                <td>Series:</td>
                                <td v-if="!invoiceToInsurer.properties.isHideSeries">{{invoiceToInsurer.card.vehicle.series}}</td>
                              </tr>
                              <tr>
                                <td>Transmission:</td>
                                <td>{{invoiceToInsurer.card.vehicle.transmission}}</td>
                              </tr>
                              <tr>
                                <td>Paint Code:</td>
                                <td>{{invoiceToInsurer.card.vehicle.colour}}</td>
                              </tr>
                              <tr>
                                <td>Date In:</td>
                                <td>{{invoiceToInsurer.card.booking_date}}</td>
                              </tr>
                              <tr>
                                <td>Date Out:</td>
                                <td>{{invoiceToInsurer.card.completion_date}}</td>
                              </tr>
                            </table>
                          </td>
                          <td class="w50 vat">
                            <table class="main-inner" align="right">
                              <tr>
                                <td>Rego:</td>
                                <td>{{invoiceToInsurer.card.vehicle.rego_number}}</td>
                              </tr>
                              <tr>
                                <td>VIN Number:</td>
                                <td>{{invoiceToInsurer.card.vehicle.vin}}</td>
                              </tr>
                              <tr>
                                <td>Cylinders:</td>
                                <td>{{invoiceToInsurer.card.vehicle.cylinders}}</td>
                              </tr>
                              <tr>
                                <td>Build Date:</td>
                                <td>{{invoiceToInsurer.card.vehicle.build_date}}</td>
                              </tr>
                              <tr>
                                <td>Odometer:</td>
                                <td>{{invoiceToInsurer.card.vehicle.odometer}}</td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <template v-for="supplement in invoiceToInsurer.all_line_items">
                  <table class="main-table m0" v-if="invoiceToInsurer.all_line_items.length > 0 && supplement.isEstimate && isExpandLabour(supplement.id) ">
                    <thead>
                    <tr>
                      <th>Repairs</th>
                    </tr>
                    </thead>
                  </table>
                  <table class="main-table m0" v-if="invoiceToInsurer.all_line_items.length > 1 && !supplement.isEstimate ">
                    <thead>
                    <tr>
                      <th>Supplement - {{supplement.number}}</th>
                    </tr>
                    </thead>
                  </table>
                  <table class="main-table estimate-repairs m0" v-if="isExpandLabour(supplement.id) && !!supplement.line_items.lines.RR.length">
                    <thead class="bg-white th-fz12">
                    <tr>
                      <th>R&amp;R</th>
                      <th class="text-center">Hrs</th>
                      <th class="text-center">Rate</th>

                      <th class="text-center">Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="line in supplement.line_items.lines.RR">
                      <td class="font-bold">{{line.name}}</td>
                      <td class="text-center">{{line.hrs_str}}</td>
                      <td class="text-right">{{line.rate_str}}</td>

                      <td class="text-right">{{line.total_str}}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr class="rr-subtotal-hrs">
                      <td class="font-bold text-align-right">Subtotal Hrs</td>
                      <td class="text-center">{{supplement.line_items.summary["RR-Hrs-SubTotal-Str"]}}</td>
                      <td></td>

                      <td></td>
                    </tr>
                    <tr class="rr-subtotal-amount">
                      <td></td>

                      <td></td>
                      <td class="font-bold text-right">Subtotal Amount</td>
                      <td class="text-right font-bold">{{supplement.line_items.summary["RR-Total-SubTotal-Str"]}}</td>
                    </tr>
                    </tfoot>
                  </table>
                  <table class="main-table estimate-repairs m0" v-if="isExpandLabour(supplement.id) && !!supplement.line_items.lines.Repair.length">
                    <thead class="bg-white th-fz12">
                    <tr>
                      <th>Repair</th>
                      <th class="text-center">Hrs</th>
                      <th class="text-center">Rate</th>

                      <th class="text-center">Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="line in supplement.line_items.lines.Repair">
                      <td class="font-bold">{{line.name}}</td>
                      <td class="text-center">{{line.hrs_str}}</td>
                      <td class="text-right">{{line.rate_str}}</td>

                      <td class="text-right">{{line.total_str}}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr class="rr-subtotal-hrs">
                      <td class="font-bold text-align-right">Subtotal Hrs</td>
                      <td class="text-center">{{supplement.line_items.summary["Repair-Hrs-SubTotal-Str"]}}</td>
                      <td></td>

                      <td></td>
                    </tr>
                    <tr class="rr-subtotal-amount">
                      <td></td>

                      <td></td>
                      <td class="font-bold text-right">Subtotal Amount</td>
                      <td class="text-right font-bold">{{supplement.line_items.summary["Repair-Total-SubTotal-Str"]}}</td>
                    </tr>
                    </tfoot>
                  </table>

                  <table class="main-table estimate-repairs m0" v-if="isExpandLabour(supplement.id) && !!supplement.line_items.lines.Paint.length">
                    <thead class="bg-white th-fz12">
                    <tr>
                      <th>Paint</th>
                      <th class="text-center">Paint Material</th>
                      <th class="text-center">Hrs</th>
                      <th class="text-center">Rate</th>

                      <th class="text-center">Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="line in supplement.line_items.lines.Paint">
                      <td class="font-bold">{{line.name}}</td>
                      <td class="text-center">{{line.paint_type_str}}</td>
                      <td class="text-center">{{line.hrs_str}}</td>
                      <td class="text-right">{{line.rate_str}}</td>

                      <td class="text-right">{{line.total_str}}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr class="paint-subtotal-hrs">
                      <td colspan="2" class=" font-bold text-right">Subtotal Hrs</td>
                      <td class="text-center">{{supplement.line_items.summary["Paint-Hrs-SubTotal-Str"]}}</td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="paint-subtotal-amount">
                      <td></td>

                      <td></td>
                      <td colspan="2" class="text-right font-bold">Subtotal Amount</td>
                      <td class="text-center">{{supplement.line_items.summary["Paint-Total-SubTotal-Str"]}}</td>
                    </tr>
                    </tfoot>
                  </table>

                  <table class="repairs-total main-table main-total-table"
                         v-if="isExpandLabour(supplement.id) &&  (!!supplement.line_items.lines.RR.length || !!supplement.line_items.lines.Repair.length || !!supplement.line_items.lines.Paint.length)">
                    <tbody>
                    <tr>
                      <td class="font-bold text-right">Total Labour Hours</td>
                      <td class="text-right">{{supplement.line_items.summary["Labour-Hrs-Str"]}}</td>
                    </tr>
                    <tr>
                      <td class="font-bold text-right">Subtotal Amount</td>
                      <td class="text-right font-bold">{{supplement.line_items.summary["Labour-Cost-Str"]}}</td>
                    </tr>
                    </tbody>
                  </table>
                  <table class="main-table estimate-parts border-t" v-if="isExpandLabour(supplement.id) && !!supplement.line_items.lines.Materials.length">
                    <thead class="th-fz12">
                    <tr>
                      <th>Materials</th>
                      <th class="text-center">Hrs</th>
                      <th class="text-center">Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="line in supplement.line_items.lines.Materials">
                      <td class="font-bold">{{line.name}}</td>
                      <td class="text-center">{{line.hrs_str}}</td>
                      <td class="text-right">{{line.total_str}}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr class="parts-subtotal-hrs">
                      <td></td>
                      <td class="font-bold text-right">Subtotal Amount</td>
                      <td class="text-right font-bold">{{supplement.line_items.summary["Materials-Total-Str"]}}</td>
                    </tr>
                    </tfoot>
                  </table>
                  <table class="main-table estimate-repairs border-t" v-if="!isExpandLabour(supplement.id) && supplement.line_items.summary['Labour-Cost-With-Materials'] > 0">
                    <thead class="th-fz12">
                    <tr>
                      <th class="text-left">Repairs</th>
                      <th class="text-center"></th>
                      <th class="text-center"></th>
                      <th class="text-center">Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td>Labour As Per Authority</td>
                      <td></td>
                      <td></td>
                      <td>{{supplement.line_items.summary['Labour-Cost-With-Materials-Str']}}</td>
                    </tr>
                    </tbody>
                  </table>

                  <table class="main-table estimate-parts border-t" v-if="isExpandPart(supplement.id) && !!supplement.line_items.lines.Part.length">
                    <thead class="th-fz12">
                    <tr>
                      <th>Parts</th>
                      <th>Part No</th>
                      <th class="text-center">Qty</th>
                      <th class="text-center">Unit Price</th>

                      <th class="text-center">Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="line in supplement.line_items.lines.Part">
                      <td class="font-bold">{{line.name}}</td>
                      <td class="font-bold">{{line.part_num}}</td>
                      <td class="text-center">{{line.qty}}</td>
                      <td class="text-right">{{line.rate_str}}</td>

                      <td class="text-right">{{line.total_str}}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr class="parts-subtotal-hrs">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td class="font-bold text-right">Subtotal Amount</td>
                      <td class="text-right font-bold">{{supplement.line_items.summary["Part-Total-SubTotal-Str"]}}</td>
                    </tr>
                    </tfoot>
                  </table>
                  <table class="table main-table tab-special mb-0" v-if="!isExpandPart(supplement.id) && supplement.line_items.summary['Part-Cost'] > 0">
                    <thead>
                    <tr>
                      <th class="text-left">Parts</th>
                      <th class="text-center"></th>
                      <th class="text-center"></th>
                      <th class="text-center"></th>
                      <th class="text-center">Amount</th>
                    </tr>
                    </thead>
                    <tr class="qtit">
                      <td>Parts</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{{supplement.line_items.summary['Part-Cost-Str']}}</td>
                    </tr>
                  </table>
                  <!--                                Other-->
                  <table class="main-table estimate-other border-t" v-if="isExpandOther(supplement.id) && !!supplement.line_items.lines.Other.length">
                    <thead class="th-fz12">
                    <tr>
                      <th>Other</th>
                      <th class="text-right">Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="line in supplement.line_items.lines.Other">
                      <td class="font-bold">{{line.name}}</td>
                      <td class="text-right">{{line.total_str}}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr class="paint-subtotal-amount">
                      <td class="font-bold text-right">Subtotal Amount</td>
                      <td class="text-right font-bold">{{supplement.line_items.summary["Other-Total-SubTotal-Str"]}}</td>
                    </tr>
                    </tfoot>
                  </table>

                  <table class="main-table estimate-sublet m0 border-t" v-if="isExpandOther(supplement.id) && !!supplement.line_items.lines.Sublet.length">
                    <thead class="th-fz12">
                    <tr>
                      <th>Sublet</th>
                      <th class="text-center">Repairer</th>
                      <th class="text-center">Cost</th>
                      <th class="text-center">Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="line in supplement.line_items.lines.Sublet">
                      <td class="font-bold">{{line.name}}</td>
                      <td class="text-center">{{line.repairer}}</td>
                      <td class="text-right">{{line.rate_str}}</td>
                      <td class="text-right">{{line.total_str}}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr class="sublet-subtotal-amount">
                      <td class="text-right font-bold" colspan="3">Subtotal Amount</td>
                      <td class="text-center">{{supplement.line_items.summary["Sublet-Total-SubTotal-Str"]}}</td>
                    </tr>
                    </tfoot>
                  </table>
                  <table class="table main-table tab-special mb-0" v-if="!isExpandOther(supplement.id) && supplement.line_items.summary['Other-Cost'] > 0">
                    <thead>
                    <tr>
                      <th class="text-left">Sublet & Other</th>
                      <th class="text-center"></th>
                      <th class="text-center"></th>
                      <th class="text-center">Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="qtit">
                      <td>Sublet & Other</td>
                      <td></td>
                      <td></td>
                      <td class="text-right">{{supplement.line_items.summary['Other-Cost-Str']}}</td>
                    </tr>
                    </tbody>
                  </table>
                  <!--                                PDR-->
                  <table class="main-table estimate-other border-t" v-if="isExpandPDR(supplement.id) && !!supplement.line_items.lines.PDR.length">
                    <thead class="th-fz12">
                    <tr>
                      <th>PDR</th>
                      <th class="text-center">Material</th>
                      <th class="text-center">Action</th>
                      <th class="text-center">Qty</th>
                      <th class="text-center">Price</th>
                      <th class="text-center">Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(line, index) in supplement.line_items.lines.PDR" :key="index">
                      <td>
                        <div style="white-space: pre-line;">{{line.name}}</div>
                        <div v-if="line.comment">Comment : {{line.comment}}</div>
                      </td>
                      <td class="text-center align-text-top">
                        <template v-if="line.type === 'Panel'">{{ line.material|valuePDRMaterial }}</template>
                      </td>
                      <td class="text-center  align-text-top">
                        <template v-if="line.type === 'Panel'">{{ line.action|valuePDRAction }}</template>
                      </td>
                      <td class="text-center  align-text-top">{{ line.qty }}</td>
                      <td class="text-right  align-text-top">{{ line.price_str }}</td>
                      <td class="text-right  align-text-top">{{ line.total_str }}</td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr class="paint-subtotal-amount">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td class="font-bold text-right">Subtotal Amount</td>
                      <td class="text-right font-bold">{{supplement.line_items.summary["PDR-Total-SubTotal-Str"]}}</td>
                    </tr>
                    </tfoot>
                  </table>
                  <table class="table main-table tab-special mb-0" v-if="!isExpandPDR(supplement.id) && supplement.line_items.summary['PDR-Cost'] > 0">
                    <thead>
                    <tr>
                      <th class="text-left">PDR</th>
                      <th class="text-center"></th>
                      <th class="text-center"></th>
                      <th class="text-center">Amount</th>
                    </tr>
                    </thead>
                    <tr class="qtit">
                      <td>PDR</td>
                      <td></td>
                      <td></td>
                      <td>{{supplement.line_items.summary['PDR-Cost-Str']}}</td>
                    </tr>
                  </table>
                </template>
                <table class="main-table  m0 divider-total">
                  <thead>
                  </thead>
                  <tbody>
                  <tr>
                    <td></td>
                  </tr>
                  </tbody>
                </table>
                <table class="parts-total main-table main-total-table">
                  <tbody>
                  <tr v-if="countSubtotal > 1">
                    <td class="font-bold text-right">Total</td>
                    <td class="text-right">{{invoiceToInsurer.summary.Total_str}}</td>
                  </tr>
                  <tr>
                    <td class="font-bold text-right border-bottom">GST</td>
                    <td class="text-right border-bottom">{{invoiceToInsurer.summary.GST_str}}</td>
                  </tr>
                  <tr v-if="countOfEstimateAndSupps > 1 && summary.isInsurance && !!summary.Excess">
                    <td class="font-bold text-right">Combined Total (Inc GST)</td>
                    <td class="text-right"><strong>{{invoiceToInsurer.summary.GrandTotal_str}}</strong></td>
                  </tr>
                  <tr v-else="countOfEstimateAndSupps > 1 && !summary.Excess">
                    <td class="font-bold text-right">GRAND TOTAL (Inc GST)</td>
                    <td class="text-right"><strong>{{invoiceToInsurer.summary.GrandTotal_str}}</strong></td>
                  </tr>
                  <tr v-if="summary.isInsurance && !!summary.Excess">
                    <td class="font-bold text-right">Less Excess</td>
                    <td class="text-right">{{summary.Excess_str}}</td>
                  </tr>
                  <tr v-if="summary.isInsurance && !!summary.Discount">
                    <td class="font-bold text-right">Discount Amount</td>
                    <td class="text-right">{{summary.Discount_str}}</td>
                  </tr>
                  <tr v-if="summary.isInsurance && (!!summary.Discount || !!summary.Excess)">
                    <td class="font-bold text-right"><strong>GRAND TOTAL Invoice</strong></td>
                    <td class="text-right"><strong>{{summary.TotalInvoice_str}}</strong></td>
                  </tr>
                  </tbody>
                </table>

              </main>
              <footer>
                <table class="footer-table">
                  <thead>
                  <tr>
                    <th>Terms</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td v-html="invoiceToInsurer.terms"></td>
                  </tr>
                  </tbody>
                </table>
              </footer>
            </div>
          </div>

        </div>
      </good-wizard>

      <div class="wizard__buttons">
        <div class=" pull-right">
          <button
              v-if="$refs.invoiceOptionsSendToInsurer"
              class="btn btn-outline-primary pull-left button-cancel"
              type="button"
              @click="clickCancel"
          >
            Cancel
          </button>
          <button
              v-if="$refs.invoiceOptionsSendToInsurer && $refs.invoiceOptionsSendToInsurer.currentStep != 0"
              class="btn btn-outline-primary-light button-back ml-0"
              type="button"
              @click="$refs.invoiceOptionsSendToInsurer.goBack()"
          >
            Back
          </button>
          <a
              v-if="$refs.invoiceOptionsSendToInsurer && $refs.invoiceOptionsSendToInsurer.currentStep != 1"
              class="btn btn-primary pull-right button-next"
              type="button"
              @click="goNext"
              :tabindex="4"
          >
            Next
          </a>
          <button
              v-if="$refs.invoiceOptionsSendToInsurer && $refs.invoiceOptionsSendToInsurer.currentStep == 1"
              class="btn btn-primary pull-right button-card-estimate"
              type="button"
              :disabled="!isCanSend"
              @click="clickSend"
              style=""
          >
            Send
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.V3 .form-control:disabled {
  opacity: 1;
}

.card-block {
  padding: 0px !important;
  margin: 0px !important;
}
.grey-color {
  opacity: 0.6;
}

.comment-limit-text {
  color: #A2ABAE;
  padding: 5px;
}

.error-text {
  color: red !important;
}
</style>
<style>
.V3 .new-card {
  max-width: 1028px;
  margin: 0 auto;
}

.V3 .invoice-options-send-to-insurer .wizard__body {
  padding: 15px;
  width: 100%;
  margin: 0 auto;
}

.V3 .invoice-options-send-to-insurer .wizard__body .invoice-options-send-to-insurer-step-1 {
  width: 60%;
  margin: 0 auto;
}

.V3 .invoice-options-send-to-insurer .wizard__body .invoice-options-send-to-insurer-step-1 table.customer-table .customer-sms-checkbox,
.V3 .invoice-options-send-to-insurer .wizard__body .invoice-options-send-to-insurer-step-1 table.customer-table .customer-email-checkbox {
  width: 25px;
  padding-left: 15px;
}

.V3 .invoice-options-send-to-insurer .wizard__body .invoice-options-send-to-insurer-step-1 table.customer-table .customer-sms-checkbox label.checkbox > span.icon,
.V3 .invoice-options-send-to-insurer .wizard__body .invoice-options-send-to-insurer-step-1 table.customer-table .customer-email-checkbox label.checkbox > span.icon {
  margin-right: 0px;

}

.V3 .invoice-options-send-to-insurer .wizard__body .invoice-options-send-to-insurer-step-2 {
  width: 100%;
  padding: 0 5px;
  overflow: auto;
}


@media screen and (max-width: 991px) {
  .V3 .invoice-options-send-to-insurer .wizard__body .invoice-options-send-to-insurer-step-1 {
    width: 100%;
  }
  .V3 .wizard-header .wizard-header-name {
    margin-right: 150px;
  }
  .V3 .wizard-header .wizard-subheader-name-text {
    margin-right: 150px;
  }
  .V3 .invoice-options-send-to-insurer .wizard__buttons {
    width: 100% !important;
  }
}

@media screen and (max-width: 460px) {
  .V3 .page-header {
    padding: 5px 0 5px 15px !important;
  }
}

.V3 .invoice-options-send-to-insurer .wizard__body .invoice-options-send-to-insurer-step-1 .table th {
  color: #1C1F39;
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
}

.V3 .invoice-options-send-to-insurer .wizard__body .invoice-options-send-to-insurer-step-2 .table th {
  color: #1C1F39;
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  vertical-align: top;
}

.V3 .invoice-options-send-to-insurer .wizard__body .invoice-options-send-to-insurer-step-1 .table td,
.V3 .invoice-options-send-to-insurer .wizard__body .invoice-options-send-to-insurer-step-2 .table td {
  color: #1C1F39;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
}

.V3 .invoice-options-send-to-insurer .wizard__body .invoice-options-send-to-insurer-step-3 {
  width: 100%;
}

.V3 .invoice-options-send-to-insurer .wizard__buttons {
  width: 60%;
  margin: 0 auto;
  padding-right: 15px;
}

</style>
<style scoped>
.V3 .card-new-body >>> .card-block {
  padding: 0px;
}

.V3 .card-new-body .card-block {
  padding: 0px;
}

.V3 .wizard__body {
  padding: 15px 30px 0px 30px;
}


.V3 .wizard__buttons .pull-left {
  float: left !important;
  margin-right: 10px;
}

.V3 .wizard__buttons .pull-right {
  float: right !important;
  margin-left: 10px;
}

.V3 .wizard__buttons .btn {
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  height: 38px;
}

.V3 .wizard__buttons .button-cancel {
  padding: 10px 21px;
  background: #FFFFFF;
  border: 1px solid rgba(28, 31, 57, 0.25);
  color: #1C1F39;
}

.V3 .wizard__buttons .button-card {
  padding: 10px 15px;
  background: #FFFFFF;
  color: #5E79FF;
}

.V3 .wizard__buttons .button-card-estimate {
  padding: 10px 15px;
}

.V3 .wizard__buttons .button-next {
  padding: 10px 15px;
  width: 83px;
}

.V3 .wizard__buttons .button-back {
  padding: 10px 26px;
  background: #FFFFFF;
  border-color: #5E79FF;
  width: 83px;
}

.disabled-checkbox-sms {
  cursor: not-allowed;
  background-color: rgba(27, 30, 56, 0.12) !important;
  border: 1px solid rgba(27, 30, 56, 0.12) !important;
}

</style>

