<script>
    import Axios from 'axios';
    import {mapGetters} from 'vuex';
    import {appConfig} from '../../config.js';
    import {VStepper} from 'vue-stepper-component';
    import AdvancedSearch from '../utility/advanced-search';
    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import NumberFormatter from '../utility/number-formatter';
    import GoogleLocation from '../utility/google-location';
    import Multiselect from 'vue-multiselect';
    import {email} from 'vuelidate/lib/validators';
    import DatePicker from '../utility/date-picker';
    import VehicleBlock from "../utility/vehicle.block";
    import InsuranceBlock from "../utility/insurance.block";
    import GoodWizard from '../utility/vueGoodWizard';
    import {isMobile} from '../../deviceDetect/index';
    import _ from 'lodash';

    export default {
        name: 'cards-new',
        data: function () {
            return {
                properties:{

                },
                vehiclePaintCodes: [],
                isNewVehicleInfo: false,
                isMobile,
                isCheckedEmail: true,
                imageUploadDropzoneOptions: {
                  capture: 'camera',
                    resizeWidth: 1500,
                    resizeQuality: 0.7,
                    url: appConfig.baseAPIURL+'/fe/card/uploadNewImage',
                    headers: { "Authorization": localStorage.getItem('token') },
                    method: 'post',
                    maxFilesize: 50,
                    clickable: ".drop-clickable",
                    thumbnailWidth: 200,
                    timeout: 300000,
                    addRemoveLinks: true,
                    acceptedFiles: 'image/jpeg, image/jpg, image/png',
                    dictFileTooBig: "Your file has exceeded the maximum limit of 5MB per file. Please reduce the file size and upload again"
                },
              insurerUploadDropzoneOptions: {
                url: appConfig.baseAPIURL+'/fe/card/scrape-pdf',
                headers: { "Authorization": localStorage.getItem('token') },
                method: 'post',
                maxFilesize: 50,
                thumbnailWidth: 200,
                addRemoveLinks: true,
                acceptedFiles: 'application/pdf',
                dictFileTooBig: "Your file has exceeded the maximum limit of 5MB per file. Please reduce the file size and upload again"
              },
                images: [],
                steps: [
                    {
                        label: 'Vehicle Owner',
                        slot: 'new-card-step-1',
                    },
                    {
                        label: 'Vehicle Information',
                        slot: 'new-card-step-2',
                        options: {
                            nextDisabled: true // control whether next is disabled or not
                        },
                    },
                    {
                        label: 'Insurance',
                        slot: 'new-card-step-3'
                    }
                ],
                step: 1,
                fileId: null,
                card: {
                    customer: {
                        abn: '',
                        address: '',
                        country: '',
                        customer_id: null,
                        name: '',
                        customer_type: 'I',
                        email: '',
                        fax: '',
                        level_unit_lot: '',
                        mobile: '',
                        phone: '',
                        postcode: '',
                        state: '',
                        street_name: '',
                        suburb: '',
                        vehicles: [],
                    },
                    vehicle: {
                        customer_vehicle_id: null,
                        dom: '',
                        complianceDate: '',
                        rego_number: '',
                        make: '',
                        model: '',
                        series: '',
                        series_shedule_number: '',
                        colour: '',
                        colour_variant: '',
                        badge: '',
                        body: '',
                        vin: '',
                        odometer: 0,
                        transmission: '',
                    },
                    insurance: {
                        id: null,
                        insurance_claim_type: null,
                        insurer_id: null,
                        insurer_name: '',
                        insurer_location: '',
                        insurer_assessor_id: null,
                        assessor_name: '',
                        assessor_email: '',
                        assessor_phone: '',
                        policy_number: '',
                        claim_number: '',
                        assessment_date: '',
                        excess: 0,
                        contribution: '',
                        discount: '',
                        market_value: '',
                        agreed_value: '',
                        accident_description: '',
                        rates: {
                            estimate_methodology: 'custom',
                            estimate_rates_rr: null,
                            estimate_rates_repair: null,
                            estimate_rates_paint: null
                        }
                    },
                    insurance_repair: '',
                    card_id: 0,
                    isTotalLoss: null,
                    vehicleDriverName:'',
                    customer_contact_2:'',
                  notes: '',
                },
                insurers: [],
                assessors: [],
                dpOptions: {
                    autoclose: true,
                    todayHighlight: true,
                    format: 'dd/mm/yyyy'
                },
                drImageOptions: {
                    messages: {
                        'default': 'Drag and drop an image here or click to select it from your computer',
                        'replace': 'Drag and drop or click to add new image',
                        'remove': 'Remove',
                        'error': 'Ooops, something went wrong.'
                    }
                },
            };
        },
        validations: {
            newCustomerInfo: {
                email: {
                    email,
                }
            },
            card: {
                customer: {
                    email: {
                        email,
                    }
                },
                insurance: {
                    assessor_email: {
                        email,
                    }
                },
            },

        },
        methods: {
            checkEmail() {
              if (this.card.customer.email.includes('@')) {
                let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                this.isCheckedEmail = re.test(this.card.customer.email);
              } else {
                this.isCheckedEmail = true
              }
            },
            checkEmail2() {
              if (this.card.customer.email === '' || !(this.card.customer.email && this.card.customer.email.trim())) {
                return;
              }
              let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              this.isCheckedEmail = re.test(this.card.customer.email);
            },
            clickCancel: function () {
                this.$router.push('/cards');
            },
            loadPaintCodes: function () {
                if (this.card.insurance.rates.estimate_methodology == 'custom') {
                    console.log('not paint code');
                    return false;
                }
                let make = this.card.vehicle.make;
                if (make != '') {
                    Axios({
                        method: 'get',
                        responseType: 'json',
                        url: `/fe/vehicle/paint-code/${make}`,
                        validateStatus: function (status) {
                            return status < 500;
                        },
                    })
                        .then(response => {
                            if (response.status === 200) {
                                this.vehiclePaintCodes = response.data;
                            }
                        })
                        .catch(error => {
                            console.log('loadPaintCodes');
                            console.log(error);
                        });
                }
            },
            sendImgUpload($event) {
                $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = "none";
            },
          removeImgUpload ($event) {
            let index = _.findIndex(this.images, (f) => {
                return f.id == $event.upload.uuid
            })
            if (index !== -1) {
              this.images.splice(index, 1)
            }
          },
            addImgUpload($event) {
                $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = "none";
            },
            clickBrowseDropzoneImages: function (event) {
              if ( this.$refs?.imgUpDrop?.dropzone?.hiddenFileInput) {
                this.$refs.imgUpDrop.dropzone.hiddenFileInput.removeAttribute("capture")
              }
            },
            openCapture() {
              if (this.$refs?.imgUpDrop?.dropzone?.hiddenFileInput) {
                this.$refs.imgUpDrop.dropzone.hiddenFileInput.setAttribute("capture", 'camera')
              }
            },
            clickCancelDropzoneImages: function () {
                this.$refs.imgUpDrop.removeAllFiles(true);
                this.images = [];
            },
            completeImgUpload($event) {
                $event.previewElement.children[($event.previewElement.children.length - 1)].removeAttribute("style");
            },
            successImgUpload(file, response) {
                if (response.status) {
                    this.images.push({id: file.upload.uuid, file_id: response.file_id});
                }
            },
            successInsurerUpload(file, response) {
              console.log(response)
                if (response._status) {
                  this.$refs.importInsurerPDF.hide()
                  if (response.fileId) {
                    this.fileId = response.fileId
                  }
                  if (response.data) {
                    let data = response.data
                    let name = data.thirdPartysName
                    if (data.insuredsName) {
                      name = data.insuredsName
                    }
                    if (name) {
                      let ins = _.find(this.customerVehicles, (c) => {
                        return c.name == name
                      })
                      if (ins) {
                        this.onCustomerSelect(ins)
                      } else {
                        this.card.customer.name = name
                      }
                    }
                    if (data.contactNumbers) {
                      this.card.customer.mobile =  data.contactNumbers
                    }
                    if (data.registration) {
                      this.card.vehicle.rego_number =  data.registration
                    }
                    if (data.make) {
                      this.card.vehicle.make =  data.make
                    }
                    if (data.model) {
                      this.card.vehicle.model =  data.model
                    }
                    if (data.claimNumber) {
                      this.card.insurance.claim_number =  data.claimNumber
                    }
                    if (data.descriptionOfLoss) {
                      this.card.insurance.accidentDescription =  data.descriptionOfLoss
                    }
                    if (data.excessApplicable) {
                      this.card.insurance.excess =  data.excessApplicable
                    }
                    if (data.insuranceCompany) {
                      this.card.insurance_repair = 'true'

                      let ins = _.find(this.insurers, (i) => {
                        return i.insurer_name == data.insuranceCompany
                      })
                      if (ins) {
                        this.card.insurance.insurer_name = ins.insurer_name
                        this.card.insurance.insurer_id = ins.insurer_id
                        this.card.insurance.insurer_location = ins.insurer_location
                        this.card.insurance.rates = ins.rates
                        this.card.insurance.rates.estimate_methodology = _.toLower(ins.rates.estimate_methodology)
                        this.card.insurance.custom_paint_times = ins.custom_paint_times
                        this.selectedInsurerModel = ins
                      } else {
                        this.card.insurance.insurer_name =  data.insuranceCompany
                      }
                    }
                  }
                }
            },
            changedCardCustomerAddress: function (addr) {
                if (addr["levelUnitLot"] != '' && this.card.customer.level_unit_lot == '')
                {
                    this.card.customer.level_unit_lot = addr["levelUnitLot"];
                }
                this.card.customer.address = addr["address"];
                this.card.customer.street_name = addr["street_name"];
                this.card.customer.suburb = addr["suburb"];
                this.card.customer.state = addr["state"];
                this.card.customer.postcode = addr["postcode"];
                this.card.customer.country = addr["country"];
            },
            onCustomerSelect: function (c) {
                if (c === null) {
                    this.isNewCustomerInfo = true;
                    if (this.isNewVehicleInfo) {
                        this.card.vehicle = this.newVehicleInfo;
                    } else {
                        this.card.vehicle = {
                            customer_vehicle_id: null,
                            dom: '',
                            complianceDate: '',
                            rego_number: '',
                            make: '',
                            model: '',
                            series: '',
                            series_shedule_number: '',
                            colour: '',
                            colour_variant: '',
                            badge: '',
                            body: '',
                            vin: '',
                            odometer: 0,
                            transmission: '',
                        };
                    }
                    let name = this.card.customer.name;
                    if (name && name != '') {
                        this.newCustomerInfo.name = name;
                    }
                    this.card.customer = this.newCustomerInfo;
                } else {
                    let customer = _.filter(this.getterAllCustomers, function(customer) {
                      return customer.customer_id == c.customer_id;
                    })[0];
                    this.isSelectedExistingCustomer = true;
                    this.isNewCustomerInfo = false;
                    this.isNewVehicleInfo = false;
                    this.card.customer.abn = customer.abn;
                    this.card.customer.address = customer.address;
                    this.card.customer.country = customer.country;
                    this.card.customer.customer_id = customer.customer_id;
                    this.card.customer.name = customer.customer_name;
                    this.card.customer.customer_type = customer.customer_type;
                    this.card.customer.email = customer.email;
                    this.card.customer.fax = customer.fax;
                    this.card.customer.level_unit_lot = customer.level_unit_lot;
                    this.card.customer.mobile = customer.mobile;
                    this.card.customer.phone = customer.phone;
                    this.card.customer.postcode = customer.postcode;
                    this.card.customer.state = customer.state;
                    this.card.customer.street_name = customer.street_name;
                    this.card.customer.suburb = customer.suburb;
                    this.card.customer.vehicles = [];

                    this.selectedCustomerVehicles = customer.vehicles;
                    this.card.vehicle = {
                        customer_vehicle_id: null,
                        dom: '',
                        complianceDate: '',
                        rego_number: '',
                        make: '',
                        model: '',
                        series: '',
                        series_shedule_number: '',
                        colour: '',
                        colour_variant: '',
                        badge: '',
                        body: '',
                        vin: '',
                        odometer: 0,
                        transmission: '',
                    };
                }
                this.checkEmail();
            },
            clickCreateCard: function () {
                let data = {
                    card: this.card,
                    images: _.map(this.images, (img) => {return img.file_id}),
                    type: 'card',
                };
                this.create(data);
            },
            clickCreateCardAndEstimate: function () {
                let data = {
                    card: this.card,
                    images: _.map(this.images, (img) => {return img.file_id}),
                    type: 'estimate',
                };
                this.create(data);
            },
            setUpdateData: function (v, estimateId = null) {
              let uid = Date.now().toString() + Math.random().toString(36).substr(2, 4)
              let r = {}
              r[uid] = v

              Axios({
                method: 'post',
                responseType: 'json',
                headers: { 'Autosave': true },
                url: 'fe/estimate/' + estimateId,
                validateStatus: function (status) {
                  return status < 500
                },
                data: r
              })
            },
            create: function (data) {
                if (data.card.insurance_repair === '') {
                  data.card.insurance_repair = 'false';
                }
                NProgress.start();
                Axios.post(`/fe/card/new`, data)
                    .then(response => {
                        NProgress.done();
                        if (response.data.status && response.data.id > 0){
                            if (this.isNewVehicleInfo) {
                              this.$store.dispatch('loadCustomerVehicles');
                            }
                            if (data.type == "estimate"){
                              if (this.fileId) {
                                this.setUpdateData({file_ids: [this.fileId]}, response.data.id)
                              }
                                this.$router.push({ name: 'EstimatesEdit', params: { estimate_id: response.data.id }})
                                return;
                            }else{
                                this.$router.push({ name: 'CardsEdit', params: { card_id: response.data.id }})
                                return;
                            }
                        }
                    })
                    .catch(error => {
                        toastr.error("Error");
                        NProgress.done();
                    });
            },
            newCard: function () {
                NProgress.start();
                Axios.get(`/fe/card/new`)
                    .then(response => {
                        NProgress.done();
                        this.insurers = response.data.insurers;
                        this.assessors = response.data.assessors;
                        this.properties = response.data.properties;
                    })
                    .catch(error => {
                        toastr.error("Error");
                        NProgress.done();
                        this.$router.push('/cards');
                    });
            },
        },
        computed: {
            ...mapGetters({
                addressStates: 'addressStates',
                addressCountries: 'addressCountries',
                isShopManagerUser: 'isShopManagerUser',
                isProductionManagerUser: 'isProductionManagerUser',
                isEstimatorUser: 'isEstimatorUser',
                isPDRTechnicianUser: 'isPDRTechnicianUser',
                isCustomerUser: 'isCustomerUser',
                getterAllCustomers: 'getAllCustomers',
                getterBusinessName : 'getBusinessName',
              isShowBusinessName: 'isShowBusinessName',
              }),

            computedCustomerState: {
                get: function () {
                    let vm = this;
                    try {
                        let item = _.find(this.addressStates, function (itm) {
                            return itm.key === vm.card.customer.state;
                        });
                        return item;
                    } catch (e) {
                        return {};
                    }
                },
                set: function (item) {
                    this.card.customer.state = item.key;
                },
            },
            nextAllowed(){
                return !! (
                    this.card.customer.name
                );
            },
            computedCustomerCountry: {
                get: function () {
                    let vm = this;
                    try {
                        let item = _.find(this.addressCountries, function (itm) {
                            return itm.key === vm.card.customer.country;
                        });
                        return item;
                    } catch (e) {
                        return [];
                    }
                },
                set: function (item) {
                    this.card.customer.country = item.key;
                },
            },
            isInsuranceRepairYes: function () {
                if (this.card.insurance_repair == 'true') {
                    return true;
                }
                return false;
            },
            isInsuranceRepairNo: function () {
                if (this.card.insurance_repair == 'false') {
                    return true;
                }
                return false;
            },
            isInsuranceRepairOther: function () {
                if (this.card.insurance_repair == 'Other' || this.card.insurance_repair == '2') {
                    return true;
                }
                return false;
            },
            isNoNeedVehicleRego: function () {
                if (this.isInsuranceRepairOther) {
                    return true;
                }
                return false;
            },
            isEstimateMethodologyCustom: function () {
                if (this.card.insurance.rates.estimate_methodology == 'custom') {
                    return true;
                }
                return false;
            },
            insurances: function () {
                return this.$store.state.insurances;
            },
            insurerAssessors: function () {
                if (this.card.insurance.insurer_id) {
                    return this.assessors[this.card.insurance.insurer_id];
                }

                return [];
            },
            customerVehicles: function () {
                let customersVehicles = [];
                _.forEach(this.getterAllCustomers, function(customer) {
                  customersVehicles.push({
                    name: customer.customer_name,
                    customer_id: customer.customer_id,
                  })
                })
                return customersVehicles;
            },
            isTechnicalUser: function () {
                let userInfo = this.$store.state.userInfo;
                if (userInfo && userInfo.group_id == 4) {
                    return true;
                }
                return false;
            },
        },
        mounted: function () {
            if (!this.isShopManagerUser && !this.isProductionManagerUser && !this.isEstimatorUser && !this.isPDRTechnicianUser && !this.isCustomerUser){
               return this.$router.push('/cards');
            }
            this.newCard();
            this.$store.dispatch('loadInsurances');
            this.$store.dispatch('loadVehiclesForCustom');
        },
        components: {
            InsuranceBlock,
            VehicleBlock,
            VStepper,
            AdvancedSearch,
            vueDropzone: vue2Dropzone,
            NumberFormatter,
            GoogleLocation,
            Multiselect,
            DatePicker,
            GoodWizard,
        }
    };

</script>

<template>
    <div class="card-new">
        <div class="page-header">
            <h4>New Card</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item home"><router-link to="/">Home</router-link></li>
                <li class="breadcrumb-item active">New Card</li>
            </ol>
            <h4 v-if="isShowBusinessName" class="header-display-name">{{ getterBusinessName }}</h4>
        </div>
        <div class="card-new-body">
            <good-wizard
                    :steps="steps"
                    :currentStep="step"
                    ref="newCardStepper"
            >
                <div slot="new-card-step-1">
                    <div class="header">
                        Vehicle Owner
                    </div>
                    <div class="subheader">
                        Add in the vehicle owner’s detail to create a new card in the system
                        <span class="indicate">
                           * Indicates a mandatory field
                        </span>
                    </div>
                    <div class="card new-card-step-vehicle-owner">
                        <div class="card-block bg-white">
                            <div class="form-group row">
                                <label for="customer_name" class="col-sm-5 col-5 col-form-label">Vehicle Owner <span class="indicate">*</span></label>
                                <div class="col-sm-7 col-7">
                                    <advanced-search ref="customerName"
                                                     :searchLabel="'Customer'"
                                                     v-model="card.customer.name"
                                                     :searchData="customerVehicles"
                                                     :searchKey="'name'"
                                                     @onSelect="onCustomerSelect"
                                                     :placeholderText="'Customer Name'"
                                                     :sortByName="'name'"
                                    >
                                    </advanced-search>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label  class="col-sm-5 col-5 col-form-label">
                                    Owner Type <span class="indicate">*</span>
                                </label>
                                <div class="col-sm-7 col-7 ex-customer-type-container">
                                    <div class="checkbox-group">
                                        <div class="checkbox-p">
                                            <label class="form-check-inline radio">
                                                <input v-model="card.customer.customer_type" class="form-check-input" type="radio" name="customer_type" id="individual_customer_type" value="I"
                                                >
                                                <span class="icon"><i class='bx bx-check'></i></span><span class="text">Individual</span>
                                            </label>
                                        </div>
                                        <div class="checkbox-p">
                                            <label class="form-check-inline radio">
                                                <input v-model="card.customer.customer_type" class="form-check-input" type="radio" name="customer_type" id="business_customer_type" value="B"
                                                >
                                                <span class="icon"><i class='bx bx-check'></i></span><span class="text">Business</span>
                                            </label>
                                        </div>
                                        <div>
                                            <label class="form-check-inline radio">
                                                <input v-model="card.customer.customer_type"  class="form-check-input" type="radio" name="customer_type" id="business_fleet_customer_type" value="F"
                                                >
                                                <span class="icon"><i class='bx bx-check'></i></span><span style = 'min-width: 121px' class="text">Business Fleet</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="card.customer.customer_type === 'B' || card.customer.customer_type === 'F'" class="form-group row">
                                <label for="abn" class="col-sm-5 col-5 col-form-label">Business ABN</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="card.customer.abn" class="form-control" id="abn" placeholder="Business ABN">
                                </div>
                            </div>
                            <div v-if="card.customer.customer_type === 'F'" class="form-group row">
                              <label for="driver" class="col-sm-5 col-5 col-form-label">Vehicle Driver</label>
                              <div class="col-sm-7 col-7">
                                <input type="text" v-model="card.vehicleDriverName" id="driver" class="form-control"  placeholder="Vehicle Driver">
                              </div>
                            </div>
                            <div class="form-group row" v-if="!isTechnicalUser">
                                <label class="col-sm-5 col-5 col-form-label">Contact Number 1 (Mobile Nbr)</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="card.customer.mobile" class="form-control" placeholder="Contact Number 1">
                                </div>
                            </div>
                            <div class="form-group row" v-if="!isTechnicalUser">
                                <label for="phone" class="col-sm-5 col-5 col-form-label">Contact Number 2</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" id="phone" v-model="card.customer_contact_2" class="form-control" placeholder="Contact Number 2">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="fax" class="col-sm-5 col-5 col-form-label">Fax</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="card.customer.fax" class="form-control" id="fax" placeholder="Fax">
                                </div>
                            </div>
                            <div class="form-group row" v-if="!isTechnicalUser">
                                <label class="col-sm-5 col-5 col-form-label">Email Address</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="card.customer.email" class="form-control" placeholder="Email Address" @input="checkEmail" @blur="checkEmail2"
                                    >
                                    <small class="form-text show-help-for-body" v-if="!isCheckedEmail">
                                        Please enter a valid email address
                                    </small>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="level_unit_lot" class="col-sm-5 col-5 col-form-label">Level/Unit/Lot Nbr</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="card.customer.level_unit_lot" class="form-control" id="level_unit_lot" placeholder="Lot, Unit, or Level number"
                                    >
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="address" class="col-sm-5 col-5 col-form-label">Search Address</label>
                                <div class="col-sm-7 col-7">
                                    <div class="search-location-block">
                                        <google-location type="text" v-model="card.customer.address" id="address" placeholder="Search for an Address"
                                                        @onPlaceChange="changedCardCustomerAddress"
                                        >
                                        </google-location>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="street_name" class="col-sm-5 col-5 col-form-label">Street Nbr/Name</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="card.customer.street_name" class="form-control" id="street_name" placeholder="Street Number or Name"
                                    >
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="suburb" class="col-sm-5 col-5 col-form-label">Suburb</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="card.customer.suburb" class="form-control" id="suburb" placeholder="Suburb">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="vehicleOwnerAddressState" class="col-sm-5 col-5 col-form-label">State</label>
                                <div class="col-sm-7 col-7">
                                    <multiselect
                                            ref="vehicleOwnerAddressState"
                                            v-model="computedCustomerState"
                                            :options="addressStates"
                                            :showLabels="false"
                                            :option-height="29"
                                            :max-height="203"
                                            :close-on-select="true"
                                            placeholder="Select State"
                                            track-by="key"
                                            label="value"
                                            :tabindex="2"
                                    >
                                    </multiselect>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="postcode" class="col-sm-5 col-5 col-form-label">Postcode</label>
                                <div class="col-sm-7 col-7">
                                    <input type="text" v-model="card.customer.postcode" class="form-control width25" id="postcode" placeholder="Postcode"
                                    >
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="vehicleOwnerAddressCountry" class="col-sm-5 col-5 col-form-label">Country</label>
                                <div class="col-sm-7 col-7">
                                    <multiselect
                                            ref="vehicleOwnerAddressCountry"
                                            v-model="computedCustomerCountry"
                                            :options="addressCountries"
                                            :showLabels="false"
                                            :option-height="29"
                                            :max-height="203"
                                            :close-on-select="true"
                                            placeholder="Select Country"
                                            track-by="key"
                                            label="value"
                                            :tabindex="3"
                                    >
                                    </multiselect>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div slot="new-card-step-2">
                    <div class="header">
                        Vehicle Information
                    </div>
                    <div class="subheader">
                        Add the vehicle details
                    </div>
                    <div class="wrapper-vehicle-block">
                        <vehicle-block
                                ref="vehicle-block"
                                :isNewCard="true"
                                :card="card"
                                :vehiclePaintCodes="vehiclePaintCodes"
                                @loadPaintCodes="loadPaintCodes"
                                class="pl-1"
                                :properties="properties"
                                @onCustomerVehicleSelect="value => isNewVehicleInfo = value"
                        ></vehicle-block>
                    </div>


                </div>

                <div slot="new-card-step-3">
                    <div class="header">
                        Insurance Details
                    </div>
                    <div class="subheader">
                        Confirm if the repair is to be done under insurance.If so,select the insurer so that your rates are applied
                        automatically and other insurance details.
                        <span class="indicate">* Indicates a mandatory field</span>
                    </div>
                    <insurance-block
                        ref="insurance-block"
                        :isNewCard="true"
                        :card="card"
                        :assessors="assessors"
                        :insurers="insurers"
                        :hiddenElements="{market_value:true, agreed_value:true, pav: true, sum_insured: true}"
                        :isShowExcessSetToNil="false"
                    ></insurance-block>
                </div>

            </good-wizard>

          <div style="width: 100%; display: flex; justify-content: center" :class="{'hide-dropzone': $refs.newCardStepper && $refs.newCardStepper.currentStep != 1}">
            <div class="additional-dropzone-block" style="width: 640px">
              <vue-dropzone
                  v-on:vdropzone-file-added="addImgUpload"
                  v-on:vdropzone-success="successImgUpload"
                  v-on:vdropzone-complete="completeImgUpload"
                  v-on:vdropzone-sending="sendImgUpload"
                  v-on:vdropzone-removed-file="removeImgUpload"
                  ref="imgUpDrop"
                  id="imgUploadDrop"
                  class="dropify-wrapper dropzoneContainer"
                  style="height: auto; margin-bottom: 15px;"
                  :class="{'dropzoneForDesktop': !isMobile}"
                  :options="imageUploadDropzoneOptions"
                  :useCustomSlot="true">
                <div class="dropify-message " style="transform: translateY(0%);">
                  <template v-if="isMobile">
                    <i @click="openCapture" class='bx bx-camera drop-clickable file-icon'></i>
                    <p @click="openCapture" class="drop-down-text drop-clickable">Take a Photo</p>
                  </template>
                  <template v-else>
                    <i class='bx bx-cloud-upload file-icon' ></i>
                    <p class="drop-down-text">Drag and Drop Images Here</p>
                  </template>
                  <p class="text-or mb-0-5">Or</p>
                  <div class="text-sm-center mb-1 ex-image-upload-buttons">
                    <button @click.prevent="clickBrowseDropzoneImages($event)" type="button" class="drop-clickable btn btn-primary waves-effect waves-light browse-btn browse-btn-images">Browse Images</button>
                    <button @click.prevent="clickCancelDropzoneImages($event)" type="button" class="btn btn-secondary waves-effect waves-light cancel-btn">Cancel</button>
                  </div>
                </div>
              </vue-dropzone>
            </div>
          </div>


            <div class="wizard__buttons">
            <button v-if="$refs.newCardStepper && $refs.newCardStepper.currentStep == 2"
                        class="btn btn-outline-primary button-cancel"
                        type="button"
                        @click="clickCancel">
                    Cancel
                </button>

                <button v-if="$refs.newCardStepper && $refs.newCardStepper.currentStep != 0" class="btn btn-outline-primary button-back"
                        type="button"
                        @click="$refs.newCardStepper.goBack()">
                    Back
                </button>
                <button v-if="$refs.newCardStepper && $refs.newCardStepper.currentStep == 0"
                        class="btn btn-outline-primary button-cancel"
                        type="button"
                        @click="clickCancel">
                    Cancel
                </button>

              <button
                  v-if="$refs.newCardStepper && $refs.newCardStepper.currentStep == 0 && !isMobile"
                  class="btn btn-primary button-next"
                  type="button"
                  style="width: 137px; min-width: auto; margin-right: 65px"
                  @click="$refs.importInsurerPDF.show()">
                Import Insurer PDF
              </button>

                <button v-if="$refs.newCardStepper && $refs.newCardStepper.currentStep != 2"
                        class="btn btn-primary button-next"
                        type="button"
                        :disabled="!nextAllowed"
                        @click="$refs.newCardStepper.goNext()"
                        :tabindex="4">
                    Next
                </button>

                <button v-if="$refs.newCardStepper && $refs.newCardStepper.currentStep == 2"
                        class="btn btn-outline-primary button-card"
                        type="button"
                        @click="clickCreateCard">
                    Create Card
                </button>
                <button v-if="$refs.newCardStepper && $refs.newCardStepper.currentStep == 2"
                        class="btn btn-primary button-card-estimate"
                        type="button"
                        @click="clickCreateCardAndEstimate">
                    Create Card &amp; Estimate
                </button>
            </div>
        </div>

        <modal-wizard ref="importInsurerPDF" :hideFinishButton="true" >
          <modal-wizard-tab title="Upload Insurer PDF" description="" title-icon-class="ti-align-right">
            <template>
              <div class="insurer-modal-subtitle">Upload a PDF copy of the Insurer for import processing</div>
              <div class="additional-dropzone-block">
                <vue-dropzone v-on:vdropzone-file-added="addImgUpload"
                              v-on:vdropzone-success="successInsurerUpload"
                              v-on:vdropzone-complete="completeImgUpload"
                              v-on:vdropzone-sending="sendImgUpload"
                              ref="insurerUpDrop"
                              id="insurerUploadDrop"
                              class="dropify-wrapper dropzoneContainer"
                              style="height: auto; margin-bottom: 15px;"
                              :class="{'dropzoneForDesktop': !isMobile}"
                              :options="insurerUploadDropzoneOptions"
                              :useCustomSlot="true">
                  <div class="dropify-message " style="transform: translateY(0%);">
                    <i class='bx bx-cloud-upload file-icon' ></i>
                    <p class="drop-down-text">Drag and Drop Files Here</p>
                    <p class="text-or mb-0-5">Or</p>
                    <div class="text-sm-center mb-1 ex-image-upload-buttons">
                      <button type="button" class="btn btn-primary waves-effect waves-light browse-btn browse-btn-images drop-clickable">Browse Files</button>
                      <button @click.prevent="clickCancelDropzoneImages($event)" type="button" class="btn btn-secondary waves-effect waves-light cancel-btn">Cancel</button>
                    </div>
                  </div>
                </vue-dropzone>
              </div>
            </template>
          </modal-wizard-tab>
        </modal-wizard>
    </div>
</template>
<style>
    .hide-dropzone {
      position: absolute;
      visibility: hidden;
      top: -1000px;
      z-index: -10;
    }
    .V3 .new-card {
        max-width: 1028px;
        margin: 0 auto;
    }

    .wrapper-vehicle-block .wrapper-advenced-search-for-lookup .lookup-modal .modal-content {
        width: 370px;
    }

    .wrapper-vehicle-block .wrapper-advenced-search-for-lookup .lookup-modal .modal.in .modal-dialog {
        transform: none !important;
        right: 43px;
    }

    .wrapper-vehicle-block .wrapper-advenced-search-for-lookup .lookup-modal .modal {
        position: absolute;
        overflow: visible;
    }

    @media screen and (max-width: 420px) {
        .wrapper-vehicle-block .wrapper-advenced-search-for-lookup .lookup-modal .modal.in .modal-dialog {
            right: 0px !important;
        }

        .wrapper-vehicle-block .wrapper-advenced-search-for-lookup .lookup-modal .modal-content {
            width: 300px;
        }

        .wrapper-vehicle-block .wrapper-advenced-search-for-lookup .additional-input-rego-new-card-mobile {
            width: 150px;
            float: right;
        }
    }

    @media screen and (max-width: 575px) {
        .wrapper-vehicle-block .wrapper-advenced-search-for-lookup .lookup-modal .modal.in .modal-dialog {
            right: -5px;
            top: 20px;
        }
    }

    @media screen and (max-width: 768px) {
        .wrapper-vehicle-block .wrapper-advenced-search-for-lookup .lookup-modal .modal.in .modal-dialog {
            right: -15px;
        }
    }

    .wrapper-vehicle-block .wrapper-advenced-search-for-lookup .modal-wizard-footer {
        display: flex;
        flex-direction: row-reverse;
    }

    .wrapper-vehicle-block .wrapper-advenced-search-for-lookup .modal-wizard-footer .btn-secondary {
        margin-right: 5px;
    }
</style>
<style scoped>
    .insurer-modal-subtitle{
      font-size: 13px;
      margin-bottom: 10px;
    }
    .V3 .dropzoneForDesktop{
      margin-right: 15px !important;
    }

    .V3 .dropify-wrapper .dropify-message .drop-down-text {
        color: #1C1F39;
        font-size: 13px;
        font-weight: bold;
    }
    .V3 .dropify-wrapper .file-icon {
        font-size: 37px;
        color: #5E79FF;
    }

    .new-card-step-2 .dropify-wrapper {
        width: auto;
        height: auto;
        border-radius: 5px;
        border-width: 0;
        background-color: #31344B !important;
        margin-bottom: 25px;
    }

    .V3 .dropzoneContainer {
        margin: 5px 15px;
        width: auto;
        border: 1px dashed rgba(27, 30, 56, 0.25);
        border-radius: 3px;
        background: white !important;
    }

    .V3 .browse-btn {
        height: 38px;
        width: 126px;
        border-radius: 3px;
        background-color: #5E79FF;
        color: white;
        font-size: 13px;
        font-weight: bold;
    }

    .V3 .cancel-btn {
        box-sizing: border-box;
        height: 39px;
        width: 127px;
        border: 1px solid rgba(28, 31, 57, 0.25);
        border-radius: 3px;
        font-size: 13px;
        font-weight: bold;
    }
    .V3 .text-or {
        color: #1C1F39;
        font-family: "Nunito Sans";
        font-weight: 700;
        opacity: 0.25;
        font-size: 13px;
  }

    .V3 .card-new-body > .card-block {
        padding: 0px;
    }

    .V3 .card-new-body .card-block {
        padding: 0px;
    }

    .V3 .wizard__buttons {
        display: flex;
        position: relative;
        flex-flow: row wrap;
        justify-content: flex-end;
        max-width: 543px;
        margin: 0 auto;
    }
    .V3 .wizard__buttons .btn {
        flex: 0 1 92px;
        margin-left: 15px;
        margin-bottom: 15px;
        min-width: 82px;
        padding: 10px;
    }
    .V3 .wizard__buttons .btn:first-child {
        margin-right: auto;
        margin-left: 0px;
    }
    .V3 .wizard__buttons .btn:last-child {
        margin-right: 0;
    }
    .V3 .wizard__buttons .button-cancel {
        border-color: rgba(28,31,57,0.25);
        color: #1C1F39;
    }
    .V3 .wizard__buttons .button-cancel:hover {
        background-color: rgba(28,31,57,0.62);
        color: white;
    }
    .V3 .wizard__buttons .button-card-estimate {
        flex: 0 1 170px;
    }

    @media screen and (max-width: 625px) {
        .V3 .wizard__buttons {
            padding: 0 20px;
            width: 100%;
            display: flex;
        }
        .V3 .wizard__buttons .btn {
            font-size: 13px;
            font-weight: bold;
            line-height: 18px;
            height: 39px;
        }
        .V3 .wizard__buttons .button-card-estimate {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 100%;
            margin-left: 0px;
        }
    }

    @media screen and (max-width: 543px) {
        .V3 .wizard__buttons {
            padding: 0 30px;
        }
    }

    @media screen  and (max-width: 320px) {
        .V3 .wizard__buttons .btn:nth-child(3) {
            margin-left: 0px;
        }
    }

    @media screen  and (max-width: 380px) {
        .V3 .wizard__buttons .btn:first-child {
            margin-right: 5px;
            float: left;
        }
        .V3 .wizard__buttons .btn {
            margin-left: 0;
            margin-right: 5px;
            padding: 0 !important;
        }
        .V3 .btn-outline-primary {
            flex: 0 1 85px !important;
        }
        .V3 .wizard__buttons {
            justify-content: space-between;
        }
    }

    .checkbox-group {
        display: flex;
        flex-wrap: wrap;
    }

    .checkbox-p {
        padding-bottom: 10px;
        padding-right: 10px;
    }

    .search-location-block .form-control {
        height: 32px !important;
        padding: 0;
    }
</style>

<style>
    .additional-dropzone-block .dropzone.dz-started .dz-message {
        display: block !important;
    }
</style>

