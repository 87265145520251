import { render, staticRenderFns } from "./estimates-test-4.vue?vue&type=template&id=5849b357&scoped=true"
import script from "./estimates-test-4.vue?vue&type=script&lang=js"
export * from "./estimates-test-4.vue?vue&type=script&lang=js"
import style0 from "./estimates-test-4.vue?vue&type=style&index=0&id=5849b357&prod&scoped=true&lang=css"
import style1 from "./estimates-test-4.vue?vue&type=style&index=1&id=5849b357&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5849b357",
  null
  
)

export default component.exports