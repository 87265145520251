<template>
  <div class="box-block pt-0 px-0">
    <div class="board-view mt-0">
      <div class="row no-gutters row-eq-height">
        <board-column
            v-for="(ce_status, index) in ceStatuses"
            :ref="'column-' + ce_status.ce_status_id"
            v-if="!hiddenStatuses.userHiddenStatuses.includes(ce_status.ce_status_id) && !hiddenStatuses.vendorHiddenStatuses.includes(ce_status.ce_status_id)"
            :key="'all'+ce_status.ce_status_id"
            :count="getCards(ce_status.ce_status_id) ? getCards(ce_status.ce_status_id).length : 0"
            :cards="getCardsLimited(ce_status.ce_status_id)"
            :ce_status="ce_status"
            :isShowMore="isShowMore(ce_status.ce_status_id)"
            @isDraggedAll="v => $emit('isDraggedAll', v)"
            @increaseLimit="increaseLimit(ce_status.ce_status_id)"
            @deleteCard="v => $emit('deleteCard', v)"
            @openDeclineORMJobModal="$emit('openDeclineORMJobModal')"
            @acceptORMJob="$emit('acceptORMJob')"
            @setCardPriority="(cardId, priority )=> $emit('setCardPriority', cardId, priority)"
            @addToVehicleInBoard="(cardId, number )=> $emit('addToVehicleInBoard', cardId, number)"
            @addToDepartureBoard="(cardId, number )=> $emit('addToDepartureBoard', cardId, number)"
            @assignedToPerson="(data, user )=> $emit('assignedToPerson', data, user)"
            @onRightClickByMenu="(index, value)=> $emit('onRightClickByMenu', index, value)"
            @markAsRead="(id)=> $emit('markAsRead', id)"
            @openMenuForBoardCards="(event, title, cardId, cardNumber) => {$emit('openMenuForBoardCards', event, title, cardId, cardNumber)}"
            @changedFilter="v => changedFilter(v, ce_status.ce_status_id)"
            :index="index"
        ></board-column>
      </div>
    </div>
  </div>
</template>

<script>
import BoardColumn from './cards-board-column'
import _ from 'lodash'

export default {
  name: 'cards-board',
  components: {
    BoardColumn
  },
  props: {
    ceStatuses: {
      type: Array,
      default () {
        return []
      }
    },
    cards: {
      type: Array,
      default () {
        return []
      }
    },
    mainFilterCount: {
      type: Number,
      default () {
        return 0
      }
    },
    hiddenStatuses: {
      type: Object,
      default () {
        return {}
      }
    },
  },
  data () {
    return {
      limit: {},
      limitCount: 50,
      filter: {},
      cards2: this.cards,
      countOfRequests: 0,
      isCreated: false,
      mainFilterCountLocal: 0,
    }
  },
  watch: {
    cards: {
      handler (newVal) {
        let v = _.cloneDeep(newVal)
        this.countOfRequests++
        if (!this.isCreated) {
          this.setCards(v)
        } else {
          if (this.mainFilterCountLocal !== this.mainFilterCount) {
            this.setCards(v)
            this.mainFilterCountLocal = _.cloneDeep(this.mainFilterCount)
          } else {
            this.debouncedSetCards(v)
            if (this.countOfRequests == 10 || this.countOfRequests == 5) {
              this.setCards(v)
            }
          }
        }
      },
      immediate: true
    }
  },
  created() {
    this.isCreated = true
    this.debouncedSetCards = _.debounce((v) => {
      this.setCards(v)
    }, 3000);
  },
  methods: {
    getIds(id) {
      return this.$refs?.['column-' + id]?.[0]?.getIds2()
    },
    setCards(v) {
      this.cards2.splice(0, this.cards2.length)
      this.$nextTick(() => {
        let value = _.orderBy(_.uniqBy(v, 'card_id'), [(itm) => Number(itm.boardSort), 'created_on'],['asc'])
        this.cards2.push(...value)
      })
    },
    changedFilter (v, id) {
      let filterCopy = _.cloneDeep(this.filter)
      filterCopy[id] = v
      this.$set(this, 'filter', filterCopy)
    },
    getCards (id) {
      let cards = _.filter(_.uniqBy(this.cards2, 'card_id'), (c) => {
        let filter = this.filter[id] ? this.filter[id] : ''
        return Number(id) == Number(c.card_status) && this.isCheckedByFilter(c, filter)
      })
      cards = _.uniqBy(cards, 'card_id')
      return cards
    },
    isCheckedByFilter (c, f) {
      let filter = _.trim(_.toLower(f))
      if (_.isEmpty(filter)) {
        return true
      }
      if (_.includes(_.trim(_.toLower(c.card_number)), filter)) {
        return true
      }
      if (_.includes(_.trim(_.toLower(c.rego_number)), filter)) {
        return true
      }
      if (_.includes(_.trim(_.toLower(c.make)), filter)) {
        return true
      }
      if (_.includes(_.trim(_.toLower(c.model)), filter)) {
        return true
      }
      if (_.includes(_.trim(_.toLower(c.priority)), filter)) {
        return true
      }
      if (_.includes(_.trim(_.toLower(c.insurance)), filter)) {
        return true
      }
      if (_.includes(_.trim(_.toLower(c.colour)), filter)) {
        return true
      }
      if (_.includes(_.trim(_.toLower(c.customer_name)), filter)) {
        return true
      }
      if (_.includes(_.trim(_.toLower(c.card_id)), filter)) {
        return true
      }
      if (_.includes(_.trim(_.toLower(c.partNumbers)), filter)) {
        return true
      }
      if (_.includes(_.trim(_.toLower(c.filters)), filter)) {
        return true
      }
      if (_.includes(_.trim(_.toLower(c.claim_number)), filter)) {
        return true
      }
      return false
    },
    getCardsLimited (id) {
      let limit = this.limit[id] ? this.limit[id] : this.limitCount
      return _.slice(this.getCards(id), 0, limit)
    },
    increaseLimit (id) {
      let limitCopy = _.cloneDeep(this.limit)
      if (limitCopy[id]) {
        limitCopy[id] += this.limitCount
      } else {
        limitCopy[id] = this.limitCount * 2
      }
      this.$set(this, 'limit', limitCopy)
    },
    isShowMore (id) {
      let limit = this.limit[id] ? this.limit[id] : this.limitCount
      return this.getCards(id)?.length > limit
    },
  }
}
</script>

<style scoped>
.board-view {
  overflow-x: auto;
}

.board-view {
  scrollbar-width: thin;
  scrollbar-color: #B7B8C0; /* #5e79ff; */
}

.board-view::-webkit-scrollbar-thumb {
  background-color: #B7B8C0; /* #5e79ff; */
}

.board-view::-webkit-scrollbar {
  /* width: 7px; */
  height: 7px;
}

* {
  box-sizing: border-box;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: calc(100vh - 255px);
  transition: all 0.5s;
}

.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}

.V3 .cards-page .no-gutters {
  display: flex;
  justify-content: space-between;
}

</style>

