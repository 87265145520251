<template>
    <div class="board">
        <div class="page-header">
            <h4>{{ title }}</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item active">View Boards</li>
                <li class="breadcrumb-item active">Departure Board</li>
            </ol>
            <h4 v-if="isShowBusinessName" class="header-display-name">{{ getterBusinessName }}</h4>
        </div>

        <div class="box-block bg-white cards boards">
            <tabs
                    ref="boards-departure-table"
            >
                <tab
                        name="All"
                        id="boards-departure-all"
                        :class-name="'tab-content-table'"
                >
                    <div class="pagination-block-bottom" style="overflow-x: auto;">
                        <b-table
                                responsive
                                hover
                                class="noselect rs-scroll rs-scroll--y"
                                striped
                                ref="departure"
                                :items="vehicles"
                                :fields="fields"
                                :filter="filter"
                                :filter-function="filterForTable"
                                :current-page="boardAllCurrentPage"
                                :per-page="vehiclesPerPage"
                                @filtered="onAllCardsFiltered"
                                :tbody-tr-class="rowClass"
                                @row-contextmenu="openMenuCards2">

                            <template v-slot:cell(cardNumber)="data">
                                <div v-if="data.item.isWeekSeparator" class="background-white-weekday">
                                    {{data.item.separatorName}}
                                </div>
                                <div v-else>
                                    {{data.item.cardNumber}}
                                </div>
                            </template>

                            <template v-slot:cell(rego)="data">
                                <div v-if="data.item.isWeekSeparator" class="background-white">
                                </div>
                                <div v-else>
                                    {{data.item.rego}}
                                </div>
                            </template>

                            <template v-slot:cell(make)="data">
                                <div v-if="data.item.isWeekSeparator" class="background-white">
                                </div>
                                <div v-else>
                                    {{data.item.make}}
                                </div>
                            </template>

                            <template v-slot:cell(model)="data">
                                <div v-if="data.item.isWeekSeparator" class="background-white">
                                </div>
                                <div v-else>
                                    {{data.item.model}}
                                </div>
                            </template>

                            <template v-slot:cell(color)="data">
                                <div v-if="data.item.isWeekSeparator" class="background-white">
                                </div>
                                <div v-else>
                                    {{data.item.color}}
                                </div>
                            </template>

                            <template v-slot:cell(empty)="data">
                                <div v-if="data.item.isWeekSeparator" class="background-white" style="height: 45px;">
                                </div>
                            </template>

                            <template v-slot:cell(departure_detail)="data">
                                <div v-if="data.item.isWeekSeparator" class="background-white">
                                </div>
                                <div v-else class="departure-container">
                                    <div class="departure-item">
                                        <div class="departure-item__text departure-item__text--yellow">Date</div>
                                        <div class="departure-item__text departure-item__text--bold departure-item__text--yellow">{{data.item.departureDate}}</div>
                                    </div>
                                    <div class="departure-item">
                                        <div class="departure-item__text departure-item__text--yellow">Pick/Up</div>
                                        <div v-if="data.item.pickUpTime === null || data.item.pickUpTime == ''" class="departure-item__text departure-item__text--bold departure-item__text--yellow">
                                            00:00
                                        </div>
                                        <div v-else class="departure-item__text departure-item__text--bold departure-item__text--yellow">
                                            {{data.item.pickUpTime}}
                                        </div>

                                    </div>
                                </div>
                            </template>

                            <template v-slot:cell(assignedTo)="data">
                                <div v-if="data.item.isWeekSeparator" class="background-white">
                                </div>
                                <div v-else>
                                    {{data.item.assignedTo}}
                                </div>
                            </template>

                            <template v-slot:cell(status)="data">
                                <div v-if="data.item.isWeekSeparator" class="background-white">
                                </div>
<!--                                <span v-else>{{ data.value }}</span>-->
                            </template>

                            <template v-slot:cell(images)="data">
                                <div v-if="data.item.isWeekSeparator" class="background-white">
                                </div>
                                <div v-else class="vehicle-image-container" :class="{'background-white': data.item.images == 'separator'}">
                                    <div class="vehicle-image border-image" v-if="data.item.images.length != 0" :key="'departure-board-image-'+data.item.images[0].id" :class="{'border-image':true}">
                                        <img
                                                :src="data.item.images[0].src"
                                                @contextmenu.prevent="openMenuForImgs($event, data.item.id, data.item.images[0].id)"
                                                alt=""/>
                                    </div>

                                </div>
                            </template>
                        </b-table>
                    </div>
                    <block-pagination
                      :role="'cards'"
                      :arrayOfSomeone="vehiclesWithoutDateLine"
                      :arrayOfSomeoneFiltered="vehicleDepartureBoardsFiltered2"
                      :currentPage="boardAllCurrentPage"
                      @changedCurrentPage="value => boardAllCurrentPage = value"
                      :perPage="vehiclesPerPage"
                      @changedPerPage="value => vehiclesPerPage = value"
                    >
                    </block-pagination>
                </tab>
                <template slot="nav-item-right">
                    <div class="tabs-nav-right-search">
                        <SearchFilter ref="search-filter" placeholder="Search or Filter results" v-model="filter"/>
                    </div>

                    <div class="tabs-nav-right-options">
                        <div class="navbar-nav nav dropdown dropdown-options  show inline">
                            <button type="button"
                                    class="btn waves-effect waves-light ex-options-button"
                                    id="dropdownMenuLink"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="true">
                                Options
                                <span class="dropdown-arrow pull-right" style="box-sizing: border-box; width: 32px; border-left: 1px solid rgba(27,30,56,0.25);">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                </span>
                            </button>
                            <div class="dropdown-menu dropdown-menu-right animated fadeInUp"
                                 aria-labelledby="dropdownMenuLink">
                                <div @click.prevent class="dropdown-item text-primary p-1">Zoom: {{computedZoomScale}}</div>
                                <a @click.prevent="zoom((zoomScale += 0.1).toFixed(1))" class="dropdown-item text-primary p-1 clickable">Zoom +</a>
                                <a @click.prevent="zoom((zoomScale -= 0.1).toFixed(1))" class="dropdown-item text-primary p-1 clickable">Zoom -</a>
                                <a @click.prevent="setDefaultZoomScale" class="dropdown-item text-primary p-1 clickable">Set As Default</a>
                                <a @click.prevent="zoomReset(1.4)" class="dropdown-item text-primary p-1 clickable">Reset</a>
                            </div>
                        </div>
                    </div>
                </template>
            </tabs>
        </div>
        <vue-context ref="rightClickMenuForDeparture" @open="contextOpen" @close='contextClose'>
            <ul slot-scope="scope">
                <li v-if="scope && scope.data && scope.data.item && scope.data.item.id" @click="removeVehicle(scope.data.item.id, scope.data.item.cardNumber, scope.data.index)">
                    Remove Vehicle
                </li>
                <li class="hasSubContext" v-if="scope && scope.data && scope.data.item && scope.data.item.id && !isCustomerUser">
                    Add to
                    <i class="icon-right bx bx-chevron-right"></i>
                    <ul class="context-level subContext">
                        <li @click.prevent="addedToVehicleInBoard(scope.data.item.id, scope.data.item.cardNumber)">Vehicle In Board</li>
                    </ul>
                </li>
            </ul>
        </vue-context>
        <vue-context ref="rightClickMenuForImgOfBoards">
            <ul slot-scope="scope">
                <li v-if="scope && scope.data && scope.data.imageId" @click="removeImg(scope.data.boardId, scope.data.imageId)">
                    Remove Image
                </li>
                <li class="hasSubContext" v-if="scope && scope.data && scope.data.item && scope.data.item.id && !isCustomerUser">
                    Add to
                    <i class="icon-right bx bx-chevron-right"></i>
                    <ul class="context-level subContext">
                        <li @click.prevent="addedToVehicleInBoard(scope.data.item.id, scope.data.item.cardNumber)">Vehicle In Board</li>
                    </ul>
                </li>
            </ul>
        </vue-context>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import SearchFilter from '../search-filter/search-filter';
    import _ from "lodash";
    import Axios from "axios";
    import VueContext from "../../extends/vue-context";
    import BlockPagination from '../utility/block-pagination';
    import {appConfig} from "../../config";

    const tableMixin = {
        data() {
            return {
                currentDay: null,
                clientWidth: 0,
                filter: '',
                boardDepartureFiltered: [],
                contextOpenEvent: null,
                boardAllCurrentPage: 1,
                vehiclesPerPage: 35,
                fields: [
                    {
                        label: "Card Nbr",
                        key: "cardNumber",
                        sortable: true,
                        tdClass: "text-center cards-table-number",
                        thClass: "text-center bg-navyblue  cards-table-number",
                    },
                    {
                        label: "Rego",
                        key: "rego",
                        sortable: true,
                        tdClass: "text-center cards-table-rego",
                        thClass: "text-center bg-navyblue cards-table-rego",
                    },
                    {
                        label: "Make",
                        key: "make",
                        sortable: true,
                        tdClass: "text-center cards-table-make",
                        thClass: "text-center bg-navyblue cards-table-make",
                    },
                    {
                        label: "Model",
                        key: "model",
                        sortable: true,
                        tdClass: "text-center cards-table-model",
                        thClass: "text-center bg-navyblue cards-table-model",
                    },
                    {
                        label: "Colour",
                        key: "color",
                        sortable: true,
                        tdClass: "text-center cards-table-colour",
                        thClass: "text-center bg-navyblue cards-table-colour",
                    },
                    {
                        label: "Departure Detail",
                        key: "departure_detail",
                        sortable: true,
                        tdClass: "text-center  cards-table-departure-detail",
                        thClass: "text-center bg-navyblue  cards-table-departure-detail",
                    },
                    {
                        label: "Assigned",
                        key: "assignedTo",
                        sortable: true,
                        tdClass: "text-center  cards-table-assigned-to",
                        thClass: "text-center bg-navyblue  cards-table-assigned-to",
                    },
                    {
                        label: "Status",
                        key: "status",
                        sortable: true,
                        tdClass: "text-center  cards-table-status",
                        thClass: "text-center bg-navyblue cards-table-status",
                    },
                    {
                        label: "Vehicle Images",
                        key: "images",
                        sortable: true,
                        tdClass: "text-center  cards-table-image",
                        thClass: "text-center bg-navyblue cards-table-image",
                    },
                ],
            };
        },
        mounted() {
            this.setCompactSidebar();
            console.log('board departure', this.vehicles);
            let today = new Date();
            this.currentDay = today.getDay();
            let self = this;
            setInterval(() => {
                let today = new Date();
                if (self.currentDay != today.getDay()) {
                    self.currentDay = today.getDay();
                    console.log('NEW DAY');
                    self.getBoardDepartureByWeekday;
                }
            }, 60000);
        },
        computed: {
            ...mapGetters({
                getterBoardDeparture: 'board/getBoardDeparture',
                getterZoomScale: 'board/getZoomScaleDeparture',
                isDisplayBoardUser: 'isDisplayBoardUser',
                isCustomerUser: 'isCustomerUser',
                isStatusActive: 'isStatusActive',
                getterBusinessName : 'getBusinessName',
              isShowBusinessName: 'isShowBusinessName',
            }),
            vehiclesWithoutDateLine() {
              return this.getterBoardDeparture;
            },
            vehicleDepartureBoardsFiltered2() {
              return this.boardDepartureFiltered;
            },
            getBoardDepartureByWeekday() {
                let data = this.weekSeparatorData;
                if (data && data.orderBoards && data.orderBoards.length > 0) {
                    return data.orderBoards;
                }
                return [];
            },
            weekSeparatorData() {
                this.currentDay;
                let arr = {
                    date: {},
                    boards: {},
                    order: [],
                    orderBoards: [],
                    currentDay: this.currentDay,
                };
                let order = [];
                order.push('wp');

                let today = new Date();
                today.setHours(0, 0, 0, 0);
                order.push('w' + today.getDay());

                let tomorrow = new Date(today);
                tomorrow.setDate(tomorrow.getDate() + 1);
                order.push('w' + tomorrow.getDay());

                let tomorrow2 = new Date(today);
                tomorrow2.setDate(tomorrow2.getDate() + 2);
                order.push('w' + tomorrow2.getDay());

                let tomorrow3 = new Date(today);
                tomorrow3.setDate(tomorrow3.getDate() + 3);
                order.push('w' + tomorrow3.getDay());

                let tomorrow4 = new Date(today);
                tomorrow4.setDate(tomorrow4.getDate() + 4);
                order.push('w' + tomorrow4.getDay());

                let tomorrow5 = new Date(today);
                tomorrow5.setDate(tomorrow5.getDate() + 5);
                order.push('w' + tomorrow5.getDay());

                let tomorrow6 = new Date(today);
                tomorrow6.setDate(tomorrow6.getDate() + 6);
                order.push('w' + tomorrow6.getDay());

                let tomorrow7 = new Date(today);
                tomorrow7.setDate(tomorrow7.getDate() + 7);

                order.push('wn');

                let boards = {
                    w1: [],
                    w2: [],
                    w3: [],
                    w4: [],
                    w5: [],
                    w6: [],
                    w0: [],
                    wp: [],
                    wn: [],
                };

                let names = {
                    w1: 'Monday',
                    w2: 'Tuesday',
                    w3: 'Wednesday',
                    w4: 'Thursday',
                    w5: 'Friday',
                    w6: 'Saturday',
                    w0: 'Sunday',
                    wp: 'Yesterday',
                    wn: 'Next Week',
                };

                _.forEach(this.getterBoardDeparture,
                    function (item) {
                        if (!item.sortDate || item.sortDate == '' || item.sortDate == null) {
                            item.weekday = 'wn';
                        } else {
                            let cdate = new Date(item.sortDate);
                            item.cdate = cdate;
                            //item.weekday = cdate.getDay();
                            console.log('item', item);
                            if (cdate > tomorrow7) {
                                item.weekday = 'wn';
                            } else if (cdate > today) {
                                item.weekday = 'w' + cdate.getDay();
                            } else if (cdate) {
                                item.weekday = 'wp';
                            } else {
                                item.weekday = 'wn';
                            }
                        }
                        boards[item.weekday].push(item);
                    }
                );
                console.log('boards', boards);

                _.forEach(order, function (ord) {
                    if (boards && boards[ord] && boards[ord].length > 0) {
                        arr.orderBoards.push({isWeekSeparator: true, separatorName: names[ord]});
                        arr.orderBoards = _.concat(arr.orderBoards, boards[ord]);
                    }
                });

                arr.date.today = today;
                arr.date.tomorrow = tomorrow;
                arr.date.tomorrow2 = tomorrow2;
                arr.date.tomorrow3 = tomorrow3;
                arr.date.tomorrow4 = tomorrow4;
                arr.date.tomorrow5 = tomorrow5;
                arr.date.tomorrow6 = tomorrow6;
                arr.date.d = today.getDay();
                arr.boards = boards;
                arr.order = order;

                this.boardDepartureFiltered = this.getterBoardDeparture;

                return arr;
            },
            computedZoomScale() {
                return this.zoomScale.toFixed(1);
            },
            computedDefaultCardTableTabHash: function () {
                if (this.$storage.get("cards.tabs.defaultCardsTable")) {
                    //  console.log('ret', this.$storage.get('cards.tabs.defaultCardsTable'));
                    return this.$storage.get("cards.tabs.defaultCardsTable");
                }
                return "cards-all";
            },
            vehicles() {
                return this.getBoardDepartureByWeekday;
            },
        },
        watch: {
            getterBoardDeparture() {
                this.setCompactSidebar();
            },
            getterZoomScale(value) {
                this.zoomScale = value;
                this.zoom(value);
            }
        },
        methods: {
            rowClass(item, type) {
                if (!item || type !== 'row') return;
                if (item.isWeekSeparator) return 'background-white';
            },
            setDefaultZoomScale() {
                NProgress.start();
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: '/fe/board/set-default-zoom',
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: {
                        'zoomBoardDeparture': this.computedZoomScale
                    },
                }).then(response => {
                    if (response.status === 200 && response.data._status) {
                        this.$store.commit('board/setZoomScaleDeparture', this.computedZoomScale);
                        toastr.success('Zoom scale has been saved');
                    }
                })
                    .catch(error => {
                        toastr.error(error);
                    })
                    .finally(() => {
                        NProgress.done();
                    });
            },
            addedToVehicleInBoard(boardId, number) {
                NProgress.start();
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: `/fe/board/in/add-card`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: {
                        boardId: boardId,
                    },
                }).then(response => {
                    if (response.status === 200 && response.data._status) {
                        toastr.success('Card ' + number + ' added successfully to the Vehicle In Board');
                    } else {
                        toastr.error('Error!');
                    }
                })
                    .catch(error => {
                        toastr.error(error);
                    })
                    .finally(() => {
                        NProgress.done();
                    });
            },
            updateWidth() {
                this.clientWidth = window.innerWidth;
            },
            filterForTable(row, filter) {
                let formattedFilter = filter.toLowerCase();
                if (row.cardNumber && row.cardNumber.toLowerCase().includes(formattedFilter)) {
                    return true;
                } else if (row.rego && row.rego.toLowerCase().includes(formattedFilter)) {
                    return true;
                } else if (row.make && row.make.toLowerCase().includes(formattedFilter)) {
                    return true;
                } else if (row.model && row.model.toLowerCase().includes(formattedFilter)) {
                    return true;
                } else if (row.color && row.color.toLowerCase().includes(formattedFilter)) {
                    return true;
                } else if (row.assignedTo && row.assignedTo.toLowerCase().includes(formattedFilter)) {
                    return true;
                } else if (row.status && row.status.toLowerCase().includes(formattedFilter)) {
                    return true;
                }
            },
            onAllCardsFiltered: function (fi) {
                this.boardDepartureFiltered = fi;
                this.boardAllCurrentPage = 1;
                this.filteredVehiclesRowsCountValue = fi.length;
            },
            onRowClick: function (item, index, event) {
                this.$router.push("/boards/view/" + item.card_id);
            },
            contextOpen: function (event, w, e) {
                this.contextOpenEvent = event;
            },
            contextClose: function () {
                let event = this.contextOpenEvent;
                if (event) {
                    let row = event.target.parentElement;
                    let rows = row.parentElement.rows;
                    for (let i = 0; i < rows.length; i++) {
                        let elem = rows[i];
                        elem.style.backgroundColor = "";
                    }
                }
            },
            openMenuForImgs(event, boardId, imageId) {
                if (this.isDisplayBoardUser || !this.isStatusActive) {
                    return;
                }
                this.$refs.rightClickMenuForDeparture.close();
                this.$refs.rightClickMenuForImgOfBoards.open(event, {imageId: imageId, boardId: boardId});
            },
            openMenuCards2: function (item, index, event) {
                event.preventDefault();
                if (this.isDisplayBoardUser) {
                    return;
                }
                if (item.isWeekSeparator) {
                    return;
                }
                if (event.target.localName === 'img') {
                    return;
                }
                //let row = event.target.parentElement;
                //let rows = row.parentElement.rows;
                //for (let i = 0; i < rows.length; i++) {
                //    let elem = rows[i];
                //    elem.style.backgroundColor = "";
                //}
                //row.style.backgroundColor = "";
                this.openMenuCards(event, index, item);
            },
            openMenuCards(e, index, item) {
                if (!this.isStatusActive) {
                  return
                }
                this.$refs.rightClickMenuForImgOfBoards.close();
                this.$refs.rightClickMenuForDeparture.open(e, {item: item, index: index});
            },
            removeImg(boardId, imageId) {
                NProgress.start();
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: `/fe/board/remove-image`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: {
                        boardId: boardId,
                        imageId: imageId,
                    },
                }).then(response => {
                    if (response.status === 200 && response.data._status) {
                        let boardItem = _.filter(this.vehicles, function (item) {
                            Number(item.id) === Number(boardId);
                        });
                        let index = this.vehicles.indexOf(boardItem[0]);
                        let indexOfImg = _.filter(this.vehicles[index].images, function (img) {
                            Number(img.id) === Number(imageId);
                        });
                        this.vehicles[index].images.splice(indexOfImg, 1);
                        this.$refs.boards.refresh();
                    }
                })
                    .catch(error => {
                        // toastr.error(error);
                    })
                    .finally(() => {
                        NProgress.done();
                    });
            },
            removeVehicle(id, number, index) {
                NProgress.start();
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: `/fe/board/remove`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: {
                        boardId: id,
                    },
                }).then(response => {
                    if (response.status === 200 && response.data._status) {
                        //toastr.success('Card ' + number + ' removed successfully');
                        this.vehicles.splice(index, 1);
                        this.$refs.departure.refresh();
                        this.contextClose();
                    } else {
                        toastr.error(response.data.msg);
                    }
                })
                    .catch(error => {
                        //toastr.error(error);
                    })
                    .finally(() => {
                        NProgress.done();
                    });
            },

            onAllCardsPageChange: function () {
            },
            setCompactSidebar() {
                this.$nextTick(() => {
                    document.body.classList.add('compact-sidebar');
                });
            }
        }
    };

    export default {
        name: 'DepartureBoard',
        components: {
            SearchFilter,
            VueContext,
            BlockPagination,
        },
        mixins: [
            tableMixin
        ],
        data() {
            return {
                title: 'Departure Board',
                zoomScale: 1.4,
                cardsAllFiltered: [],
            };
        },
        mounted() {
            this.boardDepartureFiltered = this.getterBoardDeparture;
            this.zoomScale = this.getterZoomScale;
            this.zoom(this.zoomScale);
            this.cardsAllFiltered =  this.getBoardDepartureByWeekday;
            //document.addEventListener('keydown', function (event) {
            //    console.log(event);
            //});
          this.setTitle()

        },
      beforeDestroy() {
        this.setTitle(true)
      },
      beforeRouteLeave(to, from, next) {
            document.body.classList.remove('compact-sidebar');
            this.zoom(null);
            next();
            //console.log('beforeRouteLeave', to, from, next)
        },

        methods: {
          setTitle (isNeedToClear = false) {
            let text = !isNeedToClear ? 'Departures' : appConfig.appName
            let title = document.getElementsByTagName('title')
            if (title?.[0]) {
              title[0].innerText = text
            }
          },
            zoomReset(val) {
                this.zoomScale = val;
                document.body.style.zoom = val;
                // document.body.style.cssText = 'zoom: '+val+'; -moz-transform: scale(150%);';
            },
            zoom(val) {
                +
                    console.log('zoom', val);
                document.body.style.zoom = val;
                //document.body.style.cssText = 'zoom: '+val+'; -moz-transform: scale('+val+'); -moz-transform-origin: top left;';
                //document.body.style.cssText = 'zoom: ' + val + ';';
                //document.documentElement.style.cssText = 'zoom: '+val+'; -moz-transform: scale(1.1,1.2); ';
                //var evt = new KeyboardEvent('keydown', {'keyCode': 61, 'ctrlKey': true, "code": "Equal", which: 61, key: "="});
                //document.dispatchEvent(evt);
            }
        }
    };
</script>

<style>
    /**
     * initial board styles
     * this can be erased after merged with #12
     **/

    .V3 .boards table.table.b-table > thead > tr > th {
        background-color: var(--board-header) !important;
    }

    .V3 .boards .tab-content-table table .cards-table-departure-detail {
        width: 30%;
    }

    .V3 .boards .table tbody tr:nth-of-type(odd) {
        background-color: #2B2B2D;
        color: #FFFFFF;
    }

    .V3 .boards .table tbody tr:nth-of-type(even) {
        background-color: #1F1F21;
        color: #FFFFFF;
    }

    .V3 .boards .item-progress-container {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 20px;
    }

    .V3 .boards .item-progress {
        display: grid;
        grid-template-rows: auto auto;
        gap: 5px;
        width: 50px;
    }

    .V3 .boards .item-progress__text {
        font-size: 11px;
        line-height: 15px;
        letter-spacing: normal;
        color: #FFFFFF;
    }

    .V3 .boards .item-progress__text--blue {
        color: var(--blue);
    }

    .V3 .boards .item-progress__text--yellow {
        color: var(--yellow);
    }

    .V3 .boards .item-progress__text--grey {
        color: var(--grey);
    }

    .V3 .boards .item-progress__text--green {
        color: var(--green);
    }
</style>

<style scoped>
    /*
     * Styling for departure
     * needs initial board style on file vehicle-in.vue
     */
    .V3 .boards {
        --board-header: var(--green);
    }

    .V3 .boards table.table.b-table > thead > tr > th {
        color: #FFFFFF !important;
    }

    .V3 .boards .tab-content-table table .cards-table-departure-detail {
        color: var(--assignee-color);
        min-width: 150px;
    }

    .V3 .boards .departure-container {
        display: grid;
        justify-content: space-around;
        grid-template-columns: repeat(2, auto);
        gap: 20px;
    }

    .V3 .boards .departure-item {
        display: grid;
        grid-template-rows: 1fr 1fr;
        gap: 5px;
        min-width: 100px;
    }

    .V3 .boards .departure-item__text {
        font-size: 11px;
        line-height: 15px;
        font-weight: normal;
        letter-spacing: normal;
        color: #FFFFFF;
        text-align: left;
    }

    .V3 .boards .departure-item__text--blue {
        color: var(--blue);
        font-weight: 600;
    }

    .V3 .boards .departure-item__text--yellow {
        color: var(--yellow);
    }

    .V3 .boards .departure-item__text--grey {
        color: var(--grey);
    }

    .V3 .boards .departure-item__text--green {
        color: var(--green);
    }

    .V3 .boards .departure-item__text--bold {
        font-weight: bold;
    }

    .v-context .context-level {
        width: 125px;
        left: 139px;
        top: -1px;
    }

    .tuesday-block-table {
        font-size: 24px;
        padding-left: 20px;
        font-weight: bold;
    }
</style>
