<script>
    import {appConfig} from '../../config.js';
    import {mapGetters} from 'vuex';
    import Axios from 'axios';
    import Summernote from '../utility/summernote';
    import DatePicker from '../utility/date-picker';
    import NumberFormatter from '../utility/number-formatter';
    import GoogleLocation from '../utility/google-location';
    import AdvancedSearch from '../utility/advanced-search';
    import Multiselect from 'vue-multiselect';
    import CardNotes from "../utility/card-notes";
    import SmallFormWizard from '../small-form-wizard.vue';
    import _ from "lodash";
    import InvoiceNotes from "@/components/utility/invoice-notes";

    export default {
        name: 'invoice-view',
        data: function () {
            return {
                infoWindow: false,
                isAutoSave: false,
                autoSaveMessage: '',
                classTabContent: 'tab-content',
                isHiddenOptions: false,
                isHiddenNewNote: true,
                isHiddenSave: false,
                notes: [],
                heightOptions: '',
                currentTab: 'details',
                new_excess_amount: false,
                readOnlyCustomerName: true,
                readOnlyCustomerAddress: true,
                is_change_customer_name: false,
                is_change_customer_address: false,
                is_set_other_insurer_name: false,
                is_set_other_insurer_address: false,
                is_set_other_insurer_abn: false,
                insurers: [],
                configTerms: {
                    toolbarLocation: 'bottom',
                    toolbar: [
                        ['Bold', 'Italic', 'Underline']
                    ],
                    height: 300
                },
                configNotes: {
                    toolbarLocation: 'bottom',
                    toolbar: [
                        ['Bold', 'Italic', 'Underline']
                    ],
                    height: 300
                },
                is_sent_el: false,
                list_detail_el: false,
                switch: {size: 'small', color: '#3E70C9'},
                paid: {
                    amount: 0,
                    due: 0,
                    date: '',
                    payment_type: '',
                },
                previewInvoice: {},
                previewReceipt: {
                    html: ''
                },
                invoiceToCustomer: {},
                line_items: {},
                all_line_items: {},
                countSubtotal: 0,
                summary: {},
                estimate: {
                    parts: {
                        RR: [],
                        Repair: [],
                        Paint: [],
                        Part: [],
                        Materials: [],
                        Other: [],
                        Sublet: [],
                        PDR: [],
                    }
                },
                supplements: [],
              estimateMethodology: '',
                invoice: {
                    id: '',
                    number: '',
                    card_id: '',
                    created_date: '',
                    due_date: '',
                    due: '',
                    due_str: '',
                    all_numbers: '',
                    is_sent: null,
                    list_detail: false,
                    manual_customer_name: null,
                    manual_customer_address: null,
                    customer: {
                        name: '',
                        address: '',
                    },
                    insurer: {
                        id: null,
                        name: '',
                        address: '',
                        abn: '',
                    },
                    is_expand_labour: false,
                    is_expand_part: true,
                    is_expand_other: true,
                    is_expand_pdr: true,
                    is_insurer: true,
                    expand: [],
                    transactions: {},
                    status: null,
                    isAutoSave: false,
                },
                card: {
                    customer: {
                        name: '',
                        mobile: '',
                        email: '',
                        customer_type: '',
                        phone: '',
                    },
                    vehicle: {
                        customer_vehicle_id: null,
                        dom: '',
                        rego_number: '',
                        make: '',
                        model: '',
                        series: '',
                        colour: '',
                        vin: '',
                        odometer: 0,
                        transmission: '',
                        build_date: '',
                        cylinders: '',
                    },
                    insurance: {},
                    insurance_repair: true,
                    booking_date: '',
                    completion_date: '',
                    card_number: '',
                    card_status: '',
                    statuses: [],
                    card_id: '',
                    all_estimate_numbers: '',
                    estimate_id: 0,
                },
                terms: '',
                invoiceClasses: {
                    labourInvoiceClass: 'labour_invoice',
                    partsInvoiceClass: 'parts_invoice',
                    otherInvoiceClass: 'other_invoice',
                    pdrInvoiceClass: 'pdr_invoice',
                },
                isInvoice: {
                    parts_invoice: true,
                    labour_invoice: false,
                    other_invoice: true,
                    pdr_invoice: true,
                },
                isExpand: [],
                cardProgress: {
                    startIndex: 0,
                    color: '#142742',
                    stepSize: 'xs',
                    width: "",
                    widthActiveProgress: "",
                },
                ceStatuses: [],
                progressStatus: [],
                originalData: '',
                updateData: {},
                properties: {
                    isAutoSave: false,
                    isFromIag: false,
                    isHideSeries: false,
                  isFromEio:false,
                  isForEstimage:false,
                  isGSTCorrected:false,
                },
            };
        },
        filters: {
            valuePDRMaterial: function (value) {
                //if (value === 'None') {
                //    return '';
                //}
                return value;
            },
            valuePDRAction: function (value) {
                if (value === 'PushToPaint') {
                    return 'Push To Paint';
                }
                //if (value === 'None') {
                //    return '';
                //}
                return value;
            },
        },
        computed: {
            ...mapGetters({
                'vendorInfo': 'vendorInfo',
                'isCustomerUser': 'isCustomerUser',
                'isStatusActive': 'isStatusActive',
                getterBusinessName : 'getBusinessName',
              isShowBusinessName: 'isShowBusinessName',
            }),
          isEstimateMethodologyDollars(){
            return  (this.estimateMethodology === 'dollars')
          },
          filteredStatusList() {
            let currentIndex = this.card?.statuses?.findIndex(
                (status) => Number(status.id) === Number(this.card.card_status)
            );
            if (this.card?.statuses?.length <= 4) return this.card?.statuses;

            const statusCount = this.card?.statuses?.length;
            const index = currentIndex;

            if (index < 3) {
              // on minimal
              return this.card?.statuses?.slice(0, 4);
            } else if ((statusCount - index + 1) < 5) {
              // on maximum
              return this.card?.statuses?.slice(-4);

              // everything in between
            } else if (index === 3 || index === 4) {
              return this.card?.statuses?.slice(2, 6);
            } else if (index === 5 || index === 6) {
              return this.card?.statuses?.slice(4, 8);
            } else if (index === 7 || index === 8) {
              return this.card?.statuses?.slice(6, 10);
            } else if (index === 9 || index === 10) {
              return this.card?.statuses?.slice(8, 12);
            }
          },
            userInfo: function () {
                return this.$store.state.userInfo;
            },
            countOfEstimateAndSupps() {
                return this.all_line_items.length;
            },
            isAutoSaveEnable() {
                return !!(this.invoice.isAutoSave);
            },
            isStatusAwaitingPayment() {
                return (this.invoice.status === 'awaiting');
            },
            isStatusPaid() {
                return (this.invoice.status === 'paid');
            },
            totalNotes: function () {
                return this.notes.length;
            },
            totalTransactions() {
              return this.invoice.transactions.length;
            },
            isReadOnlyCustomerAddress: function () {
                if (this.readOnlyCustomerAddress) {
                    return true;
                }
                return false;
            },
            isReadOnlyCustomerName: function () {
                if (this.readOnlyCustomerName) {
                    return true;
                }
                return false;
            },

            isFullListDetail: function () {
                if (this.invoice.list_detail) {
                    return true;
                }
                return false;
            },
            isShortListDetail: function () {
                return !this.isFullListDetail;
            },
            isInvoiceToInsurer: function () {
                if (this.card.insurance_repair && this.card.insurance_repair !== "false" && this.card.insurance && this.card.insurance.insurer_id > 0) {
                    return true;
                }
                return false;
            },
            isInvoiceToCustomer: function () {
                return !this.isInvoiceToInsurer;
            },
            isPaid() {
                return this.invoice.status == "paid";
            },
            cardProgressBarStatuses() {
                let statuses = [];
                let currentStatusIndex = 0;
                this.ceStatuses.forEach((ce_status, i) => {
                    if (Number(ce_status.ce_status_id) === Number(this.card.card_status)) {
                        currentStatusIndex = i;
                    }
                });
                // let count = this.ceStatuses.length;
                // if (count > 0) {
                // if (count > 0) {
                //     this.cardProgress.width = 'width: ' + (count * 89.3) + 'px';
                //     this.cardProgress.widthActivePercent = 'width: ' + (89.3 * (currentStatusIndex + 1) - 25) + 'px';
                // } else {
                //     this.cardProgress.width = 'width: 0px';
                //     this.cardProgress.widthActivePercent = 'width: 0px';
                // }

                this.cardProgress.startIndex = currentStatusIndex;
                this.ceStatuses.forEach((ce_status, i) => {
                    let status = {
                        id: ce_status.ce_status_id,
                        title: ce_status.name,
                        active: false,
                    };
                    if (currentStatusIndex >= i) {
                        status.active = true;
                        status.icon = 'ti-check';
                    } else {
                        status.active = false;
                        status.icon = ' ';
                    }
                    statuses.push(status);
                });

                return statuses;
            },
        },
        methods: {
          isShowHrsMaterialsPreview(line) {
            let r = null
            if (line.adj_rate && line.original_total && line.adj_rate == line.original_total) {
              r = null
            } else if (line.adj_rate) {
              r = line.adj_rate
            } else if (line.original_total) {
              r = line.original_total
            }
            return !(line.isCalculation && this.properties.isFromEio && r)
          },
          updateCardProgressBar () {
            //this.showUnsavedChanges();
            this.card.statuses = []
            let currentStatusIndex = 0
            this.ceStatuses.forEach((ce_status, i) => {
              if (ce_status.ce_status_id.toString() === this.card.card_status) {
                currentStatusIndex = i
              }
            })

            this.cardProgress.startIndex = currentStatusIndex
            this.ceStatuses.forEach((ce_status, i) => {
              let status = {
                id: ce_status.ce_status_id,
                title: ce_status.name,
                active: false,
              }
              if (currentStatusIndex >= i) {
                status.active = true
                status.icon = 'ti-check'
              } else {
                status.active = false
                status.icon = ' '
              }
              this.card.statuses.push(status)
            })
            this.updateCardProgressBarSmall()
          },
          updateCardProgressBarSmall() {
            let currentStatusIndexSmall = 0
            this.filteredStatusList.forEach((ce_status, i) => {
              if (ce_status.id.toString() === this.card.card_status) {
                currentStatusIndexSmall = i
              }
            })
            let countSmall = this.filteredStatusList.length

            let el = document.querySelector("#invoice-details > div.row.block-mobile-column > div.col-md-12.col-lg-4 > div > div.card.ex-card.wizard-bottom > div > div.wizard-navigation > ul")

            let width = 134
            if (el && el.clientWidth) {
              width = el.clientWidth / 4
              if (el.clientWidth > 365 && el.clientWidth <= 460) {
                width += 5
              }
              if (el.clientWidth > 460) {
                width += 15
              }
            }
            if (countSmall > 0) {
              this.cardProgress.width = 'width: ' + (countSmall * 89.3) + 'px'
              if (currentStatusIndexSmall == 0) {
                this.$set(this.cardProgress, 'widthActivePercent', 'width: 25px')
              } else {
                this.$set(this.cardProgress, 'widthActivePercent', 'width: ' + (width * (currentStatusIndexSmall)) + 'px')
              }
            } else {
              this.cardProgress.width = 'width: 0px'
              this.$set(this.cardProgress, 'widthActivePercent', 'width: 0px')
            }
          },
            markAsUnpaid() {
                let data = {'unpaid': true};
                this.saveInvoice(data);
                this.autoUpdate();
            },
            sendToORM() {
                if (!this.properties.isFromIag) {
                    return;
                }
                NProgress.start();
                Axios.post(`/fe/invoice/${this.invoice.id}/send-to-iag`)
                    .then(response => {
                        if (response.data && response.data._status) {
                            toastr.success('Successfully sent to IAG');
                        } else {
                            toastr.error('Error!');
                        }
                    })
                    .catch(e => console.error(e))
                    .finally(() => NProgress.done());
            },
            sendToXero() {
                NProgress.start();
                Axios.post(`/fe/invoice/${this.invoice.id}/send-to-xero`)
                    .then(response => {
                        if (response.data && response.data._status) {
                            toastr.success('Successfully sent to Xero');
                            this.loadInvoice(this.invoice.id);
                        } else {
                            toastr.error(response.data._error);
                        }
                    })
                    .catch(e => console.error(e))
                    .finally(() => NProgress.done());
            },
            viewOnXero() {
                NProgress.start();
                Axios.post(`/fe/invoice/${this.invoice.id}/view-on-xero`)
                    .then(response => {
                        if (response.data && response.data._status) {
                            window.open(response.data.url, '__blank');
                        }
                    })
                    .catch(e => console.error(e))
                    .finally(() => NProgress.done());
            },
            loadInvoice(invoice_id) {
                NProgress.start();
                Axios.get(`/fe/invoice/${invoice_id}`)
                    .then(response => {
                        this.card = response.data.card;
                        this.all_line_items = response.data.all_line_items;
                        let countSubtotal = 0;
                        _.forEach(response.data.all_line_items, function (lines) {
                            _.forEach(lines.line_items.lines, function (item) {
                                if (item && item.length > 0) {
                                    countSubtotal++;
                                }
                            });
                        });
                        this.countSubtotal = countSubtotal;
                        this.summary = response.data.summary;
                        this.estimateMethodology = response.data.estimateMethodology;
                        this.invoice = response.data.invoice;
                        this.properties = response.data.properties;

                        // set PDR expand
                        this.all_line_items.forEach(lineItem => {
                            this.$set(this.invoice.expand[lineItem.id], 'pdr', true);
                        });

                        if (response.data.insurers) {
                            this.insurers = response.data.insurers;
                        }
                        this.terms = response.data.terms;
                        this.loadNotes();
                        this.loadCEStatuses();
                        this.$nextTick(() => {
                            this.isAutoSave = true;
                        });

                    })
                    .catch(error => {
                        toastr.error("Invoice not found");
                        this.$router.push('/invoices');
                    })
                    .finally(() => {
                        this.$nextTick(() => {
                            NProgress.done();
                        });
                    });
            },
            beforeDebouncedAutoSave: function () {
                if (!this.isAutoSaveEnable) {
                    return;
                }
                this.debouncedAutoSave();
            },
            autoUpdate() {
                if (!this.isAutoSaveEnable || !this.isAutoSave) {
                    return;
                }
                this.beforeDebouncedUnsavedChanges();
                this.beforeDebouncedAutoSave();
            },
            beforeDebouncedUnsavedChanges: function () {
                if (!this.isAutoSaveEnable || !this.isAutoSave) {
                    return;
                }
                this.debouncedUnsavedChanges();
            },
            showUnsavedChanges: function () {
                this.autoSaveMessage = 'Unsaved Changes';
            },
            autoSave: function () {
                this.saveAsActive();
            },
            loadCEStatuses() {
                this.$store.dispatch('loadCEStatuses').then((res) => {
                    if (res.status === 200) {
                        this.ceStatuses = res.data;
                        this.updateCardProgressBar();
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
            isExpandLabour: function (id = null) {
              if (id) {
                if (this.invoice.expand && this.invoice.expand[id] && this.invoice.expand[id].labour) {
                  return true;
                }
                return false;
              } else {
                if (this.invoice.is_expand_labour) {
                  return true;
                }
                return false;
              }
            },
            isExpandPart: function (id = null) {
              if (id) {
                if (this.invoice.expand && this.invoice.expand[id] && this.invoice.expand[id].part) {
                  return true;
                }
                return false;
              } else {

                if (this.invoice.is_expand_part) {
                  return true;
                }
                return false;
              }
            },
            isExpandOther: function (id = null) {
              if (id) {
                if (this.invoice.expand && this.invoice.expand[id] && this.invoice.expand[id].other) {
                  return true;
                }
                return false;
              } else {
                if (this.invoice.is_expand_other) {
                  return true;
                }
                return false;
              }
            },
            isExpandPDR: function (id = null) {
              if (id) {
                if (this.invoice.expand && this.invoice.expand[id] && this.invoice.expand[id].pdr) {
                  return true;
                }
                return false;
              } else {
                if (this.invoice.is_expand_pdr) {
                  return true;
                }
                return false;
              }
            },
            onChangeCardProgress: function (id) {
                if (!this.isStatusActive) {
                  return;
                }
                this.updateCardProgressBarSmall()
                this.card.card_status = id;
                let data = {
                    card: {
                        card_status: id,
                    }
                };
                let invoice_id = this.$route.params.invoice_id;
                NProgress.start();
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: `/fe/invoice/${invoice_id}`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: JSON.stringify({data})
                })
                    .then(response => {
                        if (response.status == 200) {
                            toastr.success('Progress has been updated');
                            this.updateCardProgressBarSmall()
                        } else {
                            toastr.error(response.data.msg);
                        }

                        NProgress.done();
                    })
                    .catch(error => {
                        NProgress.done();
                        toastr.error(error);
                    });
            },
            addNote: function () {
                this.$refs['cardNotes'].addNote();
            },
            detailsTabChange: function () {
                this.currentTab = 'details';
                this.classTabContent = 'tab-content';
                this.isHiddenNewNote = true;
                this.isHiddenSave = false;
                this.$storage.set("views.invoiceView." + this.$route.params.invoice_id + ".defaultView", "details");
            },
            transactionTabChange: function () {
                this.currentTab = 'transaction';
                this.classTabContent = 'tab-content';
                this.isHiddenNewNote = true;
                this.isHiddenSave = true;
                this.$storage.set("views.invoiceView." + this.$route.params.invoice_id + ".defaultView", "transaction");
            },
            notesTabChange: function () {
                this.currentTab = 'notes';
                this.classTabContent = 'tab-content-table';
                this.isHiddenNewNote = false;
                this.isHiddenSave = true;
                this.$storage.set("views.invoiceView." + this.$route.params.invoice_id + ".defaultView", "notes");
            },
            loadNotes: function () {
                let invoice_id = this.invoice.id;
                Axios.get(`/fe/invoice/${invoice_id}/notes`)
                    .then(response => {
                      if(response.data._status) {
                        this.notes = response.data.notes;
                      }
                    })
                    .catch(error => {
                        NProgress.done();
                    });
            },
            setOtherInsurerName: function () {
                this.is_set_other_insurer_name = true;
                this.autoUpdate();
            },
            setOtherInsurerAddress: function () {
                this.is_set_other_insurer_address = true;
                console.log('change');
                this.autoUpdate();
            },
            setOtherInsurerAbn: function () {
                this.is_set_other_insurer_abn = true;
                this.autoUpdate();
            },
            onInsurerSelect: function (i) {
                if (i === null) {
                    this.invoice.insurer.address = '';
                    this.invoice.insurer.abn = '';
                    this.invoice.insurer.id = null;

                } else {
                    this.invoice.insurer.name = i.insurer_name;
                    this.invoice.insurer.address = i.insurer_location;
                    this.invoice.insurer.abn = i.insurer_abn;
                    this.invoice.insurer.id = i.insurer_id;
                    //this.selectedInsurerModel = i;
                }
                this.setOtherInsurerName();
                this.autoUpdate();
            },
            changeCustomerAddress: function () {
                this.is_change_customer_address = true;
                this.autoUpdate();
            },
            editCustomerAddress: function () {
                this.readOnlyCustomerAddress = false;
            },
            blurCustomerAddress: function () {
                this.readOnlyCustomerAddress = true;
            },
            changeCustomerName: function () {
                this.is_change_customer_name = true;
                this.autoUpdate();
            },
            editCustomerName: function () {
                this.readOnlyCustomerName = false;
            },
            noEditExcessAmount: function () {
                toastr.error('Void this Excess invoice first before changing');
            },
            updateExcess: function () {
                this.new_excess_amount = this.summary.Excess;
                this.autoUpdate();
            },
            blurCustomerName: function () {
                this.readOnlyCustomerName = true;
            },
            expandForAllItems(queryElement, elementExpand, mainParentRef, id, type) {
                let parentElement = document.querySelectorAll('.' + mainParentRef)[0];
                if (parentElement.querySelector('.' + queryElement + '_expand').style.display === 'none') {
                    this.isInvoice[queryElement] = true;
                    this.invoice.expand[id][type] = true;
                    parentElement.querySelector('.' + queryElement).style.display = 'none';
                    parentElement.querySelector('.' + queryElement + '_expand').setAttribute('data-show-invoice', true);
                    parentElement.querySelector('.' + queryElement + '_expand').style.display = 'block';
                    if (this.$refs[elementExpand][0].querySelector('strong')) {
                        this.$refs[elementExpand][0].querySelector('strong').style.visibility = 'hidden';
                    }
                    if (this.$refs[elementExpand][0].querySelector('i.fa')) {
                        this.$refs[elementExpand][0].querySelector('i.fa').classList.remove('fa-angel-down');
                        this.$refs[elementExpand][0].querySelector('i.fa').classList.add('fa-angel-up');
                    }
                } else {
                    this.isInvoice[queryElement] = false;
                    this.invoice.expand[id][type] = false;
                    if (this.$refs[elementExpand][0].querySelector('i.fa')) {
                        this.$refs[elementExpand][0].querySelector('i.fa').classList.remove('fa-angel-up');
                        this.$refs[elementExpand][0].querySelector('i.fa').classList.add('fa-angel-down');
                    }
                    if (this.$refs[elementExpand][0].querySelector('strong')) {
                        this.$refs[elementExpand][0].querySelector('strong').style.visibility = 'visible';
                    }
                    parentElement.querySelector('.' + queryElement).style.display = 'block';
                    parentElement.querySelector('.' + queryElement + '_expand').setAttribute('data-show-invoice', false);
                    parentElement.querySelector('.' + queryElement + '_expand').style.display = 'none';
                }
                //console.log('expand');
                //console.log(id);
                //console.log(type);
                //console.log(this.invoice.expand[id][type]);
            },
            showPaidModal: function () {
                const today = new Date();
                let month = ('0' + (today.getMonth() + 1)).slice(-2);
                let date = ('0' + today.getDate()).slice(-2);
                let year = today.getFullYear();
                this.paid.amount = this.invoice.due;
                this.paid.due = this.invoice.due;
                this.paid.date = date + '/' + month + '/' + year;
                this.paid.payment_type = 'EFT';
                this.$router.push({ name: 'invoiceMarkAsPaid',
                  params: {
                    invoice_id: this.$route.params.invoice_id,
                    currentTab: this.currentTab,
                }})
            },
            saveAsDraft: function () {
                let data = {
                    status: 'draft',
                    new_excess_amount: this.new_excess_amount,
                    invoice: this.invoice,
                    terms: this.terms,
                    vehicle: this.card.vehicle,
                    carddatein: this.card.booking_date,
                    carddateout: this.card.completion_date,
                    is_change_customer_address: this.is_change_customer_address,
                    is_change_customer_name: this.is_change_customer_name,
                    is_set_other_insurer_name: this.is_set_other_insurer_name,
                    is_set_other_insurer_address: this.is_set_other_insurer_address,
                    is_set_other_insurer_abn: this.is_set_other_insurer_abn,
                };
                this.saveInvoice(data);
                this.new_excess_amount = false;
            },
            saveAsActive: function () {
                let data = {
                    status: 'active',
                    invoice: this.invoice,
                    new_excess_amount: this.new_excess_amount,
                    terms: this.terms,
                    vehicle: this.card.vehicle,
                    carddatein: this.card.booking_date,
                    carddateout: this.card.completion_date,
                    is_change_customer_address: this.is_change_customer_address,
                    is_change_customer_name: this.is_change_customer_name,
                    is_set_other_insurer_name: this.is_set_other_insurer_name,
                    is_set_other_insurer_address: this.is_set_other_insurer_address,
                    is_set_other_insurer_abn: this.is_set_other_insurer_abn,
                };
                this.saveInvoice(data);
                this.new_excess_amount = false;
            },
            completeVoidInvoice: function () {
                this.$refs.voidInvoiceModal.hide();
                let invoice_id = this.$route.params.invoice_id;
                let self = this;
                NProgress.start();
                Axios.post(`/fe/invoice/${invoice_id}/void`)
                    .then(response => {
                        toastr.success("Invoice has been deleted");
                        NProgress.done();
                        this.$router.push('/invoices');
                    })
                    .catch(error => {
                        toastr.error("Error");
                        NProgress.done();
                    });
            },
            markAsVoidInvoice: function () {
                this.$refs.voidInvoiceModal.show();
            },
            markAsAwaitingPayment: function () {
                let data = {status: 'awaiting'};
                this.saveInvoice(data);
            },
            onChangeCardDetailProgress: function (id, index) {
                this.card.card_status = id;
                this.clickedIndexInDetailTab = index;
                this.isActivityChangeInDetailTab = true;
                this.autoUpdateCardStatus();
            },
            showCustomerModal: function () {
                this.$router.push({ name: 'invoiceSendToCustomer', params: { invoice_id: this.$route.params.invoice_id, isExcessInvoice: false, currentTab: this.currentTab, expand: this.invoice.expand }})
            },
            showInsurerModal: function () {
            if (this.properties?.isFromEio)
            {
              this.$router.push({ name: 'invoiceSendToEstimageOnline', params: { invoice_id: this.$route.params.invoice_id, currentTab: this.currentTab, expand: this.invoice.expand}})
            }else{
              this.$router.push({ name: 'invoiceSendToInsurer', params: { invoice_id: this.$route.params.invoice_id, currentTab: this.currentTab, expand: this.invoice.expand}})
            }
                // this.$router.push({ name: 'invoiceSendToORM', params: { invoice_id: this.$route.params.invoice_id, currentTab: this.currentTab, expand: this.invoice.expand}})


            },
            downloadInvoice: function () {
                let invoice_id = this.$route.params.invoice_id;
                let q = JSON.stringify(this.invoice.expand);
                let token = localStorage.getItem('token');
                var expand_url = '?e=' + encodeURIComponent(q) + '&at=' + token;

                let url = appConfig.baseAPIURL + '/fe/pdf/invoice/' + invoice_id + expand_url;
                window.open(url);
                return;
            },
            downloadExcessInvoice: function () {
                let invoice_id = this.$route.params.invoice_id;
                let token = localStorage.getItem('token');
                window.open(appConfig.baseAPIURL + `/fe/pdf/invoice/${invoice_id}/excess?at=${token}`);
                return;
            },
            showPreviewReceipt: function () {
                let invoice_id = this.$route.params.invoice_id;
                var self = this;
                NProgress.start();
                Axios.get(`/fe/invoice/${invoice_id}/receipt`)
                    .then(response => {
                        if (response.data && response.data.status) {
                            self.previewReceipt = response.data;
                            self.$refs.previewReceiptModal.show();
                        }
                        NProgress.done();
                    })
                    .catch(error => {
                        toastr.error("Error occurred while loading invoice info");
                        NProgress.done();
                    });
            },
            downloadReceipt: function () {
                let invoice_id = this.$route.params.invoice_id;
                let token = localStorage.getItem('token');
                window.open(appConfig.baseAPIURL + `/fe/pdf/invoice/${invoice_id}/receipt?at=${token}`);
                return;
            },
            sctollToTop: function () {
                setTimeout(() => {
                    document.getElementsByClassName('modal-dialog')[0].scrollIntoView();
                    return true;
                }, 100);
            },
            showPreviewExcessInvoiceModal: function () {
                let invoice_id = this.$route.params.invoice_id;
                var self = this;
                NProgress.start();
                Axios.get(`/fe/invoice/${invoice_id}/excess`)
                    .then(response => {
                        self.previewInvoice = response.data;
                        self.previewInvoice.invoice = response.data.excess_invoice;
                        NProgress.done();
                        self.$refs.previewExcessInvoiceModal.show();
                    })
                    .catch(error => {
                        toastr.error("Error occurred while loading invoice info");
                        NProgress.done();
                    });
            },
            showPreviewInvoiceModal: function () {
                let invoice_id = this.$route.params.invoice_id;
                var self = this;
                NProgress.start();
                Axios.get(`/fe/invoice/${invoice_id}`)
                    .then(response => {
                        self.previewInvoice = response.data;

                        self.previewInvoice.all_line_items = _.map(self.previewInvoice.all_line_items, lines => {
                            lines.line_items.lines = _.mapValues(lines.line_items.lines, items => {
                                return _.filter(items, item => item.total != 0);
                            });
                            return lines;
                        });

                        let countSubtotal = 0;
                        _.forEach(self.previewInvoice.all_line_items, function (lines) {
                            _.forEach(lines.line_items.lines, function (item) {
                                if (item && item.length > 0) {
                                    countSubtotal++;
                                }
                            });
                        });
                        self.countSubtotal = countSubtotal;
                        NProgress.done();
                        self.$refs.previewInvoiceModal.show();

                        [].slice.call(Array.from(document.querySelectorAll('[data-class-modal-invoice]'))).map(elem => {
                            Object.keys(this.invoiceClasses).forEach(classes => {
                                if (document.querySelector('.' + elem.getAttribute('data-class-modal-invoice'))
                                    .querySelector('.' + this.invoiceClasses[classes] + '_expand')
                                    .getAttribute('data-show-invoice') === 'true') {
                                    elem.querySelector('.' + this.invoiceClasses[classes] + '_expand').style.display = 'block';
                                    elem.querySelector('.' + this.invoiceClasses[classes]).style.display = 'none';
                                } else {
                                    elem.querySelector('.' + this.invoiceClasses[classes] + '_expand').style.display = 'none';
                                    elem.querySelector('.' + this.invoiceClasses[classes]).style.display = 'block';
                                }
                            });
                        });

                    })
                    .catch(error => {
                        toastr.error("Error occurred while loading invoice info");
                        NProgress.done();
                    });

            },
            saveInvoice: function (data) {
                this.autoSaveMessage = 'Saving...';
                let invoice_id = this.$route.params.invoice_id;
                NProgress.start();
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: `/fe/invoice/${invoice_id}`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: JSON.stringify({data})
                })
                    .then(response => {
                        if (response.status == 200) {
                            if (response.data && response.data.result) {
                                if (response.data.result.paid) {
                                    this.invoice.due = response.data.result.paid.due;
                                    this.invoice.due_str = response.data.result.paid.due_str;
                                }
                                if (response.data.result.status) {
                                    this.invoice.status = response.data.result.status;
                                }
                                if (response.data.result.transactions) {
                                    this.invoice.transactions = response.data.result.transactions;
                                }
                            }
                            if (!this.isAutoSaveEnable) {
                                toastr.success('Invoice updated');
                            }
                            this.autoSaveMessage = 'Saved';
                        } else {
                            if (!this.isAutoSaveEnable) {
                                toastr.error(response.data.msg);
                            }
                            this.autoSaveMessage = 'Unsaved Changes';
                        }
                    })
                    .catch(error => {

                        if (!this.isAutoSaveEnable) {
                            toastr.error(error);
                        }
                        this.autoSaveMessage = 'Unsaved Changes';
                    })
                    .finally(() => {
                        NProgress.done();
                    });
            },
            updateHeightOptionsMenu(event) {
                if (document.body.offsetHeight < 450) {
                    this.heightOptions = document.body.offsetHeight - event.clientY - 30;
                }
            },
        },
        beforeRouteLeave(to, from, next) {
            if (!_.isEmpty(this.updateData2)) {
                if (!confirm('Do you really want to lose the page? Estimate has unsaved data')) {
                    return;
                }
            }
            this.$storage.remove("views.invoiceView." + this.$route.params.invoice_id + ".defaultView");
            next();
        },
        mounted: function () {
            if (this.$route.params.currentTab) {
              this.currentTab = this.$route.params.currentTab;
            } else if (!_.isEmpty(this.$storage.get("views.invoiceView." + this.$route.params.invoice_id + ".defaultView"))) {
                this.currentTab = this.$storage.get("views.invoiceView." + this.$route.params.invoice_id + ".defaultView");
            }

            if (this.currentTab === 'notes') {
                this.isHiddenNewNote = false;
                this.isHiddenSave = true;
            }
            let invoice_id = this.$route.params.invoice_id;
            this.loadInvoice(invoice_id);
          window.addEventListener('resize', this.updateCardProgressBarSmall)
        },
        beforeDestroy() {
          window.removeEventListener('resize', this.updateCardProgressBarSmall)
        },
        created() {
            this.debouncedAutoSave = _.debounce(this.autoSave, 500);
            this.debouncedUnsavedChanges = _.debounce(this.showUnsavedChanges, 100);
        },
        components: {
            Multiselect,
            Summernote,
            DatePicker,
            NumberFormatter,
            GoogleLocation,
            AdvancedSearch,
            CardNotes,
            SmallFormWizard,
            InvoiceNotes,
        }
    };
</script>

<template>
    <div class="ex-invoice-view invoice-view-page">
        <div class="page-header">
            <h4>Invoice - {{invoice.number}} <span class="text-muted">|</span> {{invoice.customer.name}} <span class="text-muted">|</span> {{card.vehicle.rego_number}}
                <span v-if="isAutoSaveEnable" class="autosave">{{autoSaveMessage}}</span>
            </h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item home">
                    <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link to="/invoices">Invoices</router-link>
                </li>
                <li class="breadcrumb-item active">View Invoice</li>
            </ol>
            <h4 v-if="isShowBusinessName" class="header-display-name">{{ getterBusinessName }}</h4>
            <div v-if="!isHiddenOptions" class="page-header-nav-options">
                <div class="navbar-nav nav dropdown dropdown-options  show inline">
                    <button type="button"
                            class="btn waves-effect waves-light ex-options-button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                            @click="updateHeightOptionsMenu($event)">
                        Options <span class="dropdown-arrow  pull-right" style="
                                                  box-sizing: border-box;

                                                  width: 32px;
                                                  border-left: 1px solid rgba(27,30,56,0.25);
                                                  ">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                </span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right animated fadeInUp" aria-labelledby="dropdownMenuLink" :style="{'max-height': heightOptions + 'px'}" :class="{'options-menu-height': heightOptions != ''}">
                        <a v-if="!isAutoSaveEnable && !isCustomerUser && isStatusActive" @click="saveAsActive" class="dropdown-item"> Save</a>
                        <a v-if="!isCustomerUser" v-show="isPaid" @click="showPreviewReceipt" class="dropdown-item"> Print Receipt</a>
                        <a @click="showPreviewInvoiceModal" class="dropdown-item"> Preview Invoice</a>
                        <a v-if="summary.isInsurance && summary.Excess !='0' && summary.Excess !='' && summary.Excess != null " @click="showPreviewExcessInvoiceModal" class="dropdown-item">
                            Print Excess Invoice
                        </a>
                        <a v-if="!isCustomerUser && properties.isFromIag && vendorInfo && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.orm" @click="sendToORM" class="dropdown-item">Send To ORM</a>
                        <a v-if="isStatusActive" @click="showCustomerModal" class="dropdown-item"> Send To Customer</a>
                        <a v-if="!isCustomerUser && isStatusActive" @click="showInsurerModal" class="dropdown-item"> Send To Insurer</a>
                        <a v-if="vendorInfo.activeXeroAuth && !invoice.xeroInvoiceId && !isCustomerUser && isStatusActive && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.xero" @click="sendToXero" class="dropdown-item">Send to Xero</a>
                        <a v-if="vendorInfo.activeXeroAuth &&  invoice.xeroInvoiceId && !isCustomerUser && isStatusActive && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.xero" @click="sendToXero" class="dropdown-item">Update on Xero</a>
                        <a v-if="vendorInfo.activeXeroAuth &&  invoice.xeroInvoiceId && !isCustomerUser && isStatusActive && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.xero" @click="viewOnXero" class="dropdown-item">View on Xero</a>
                        <!--                        <a @click="markAsAwaitingPayment" class="dropdown-item"> Mark As Awaiting Payment</a>-->
                        <a v-if="isStatusAwaitingPayment && isStatusActive" @click="markAsVoidInvoice" class="dropdown-item"> Mark As Void</a>
                        <a v-if="(isStatusAwaitingPayment || invoice.due != 0) && isStatusActive" @click="showPaidModal" class="dropdown-item"> Mark As Paid</a>
                        <a v-if="(isStatusPaid || +invoice.amount !== invoice.due) && isStatusActive" @click="markAsUnpaid" class="dropdown-item"> Mark As Unpaid</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="box-block bg-white ex-main-box">
            <div class="ex-main-card">
                <div class="tabs-component">
                    <ul class="tab-header nav nav-tabs nav-tabs-2 float-sm-left">
                        <li class="tabs-component-tab nav-item">
                            <a href="#invoice-details" @click="detailsTabChange()" data-toggle="tab" :class="{'active': currentTab === 'details'}" class="tabs-component-tab-a nav-link ">Details</a>
                        </li>
                        <li class="nav-item">
                            <a href="#activity" @click="transactionTabChange()" data-toggle="tab" :class="{'active': currentTab === 'transaction'}" class="tabs-component-tab-a nav-link ">
                              Transaction
                                History
                              <span v-if="totalTransactions" class="tag tag-info">{{ totalTransactions }}</span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#card-notes" @click="notesTabChange()" data-toggle="tab" :class="{'active': currentTab === 'notes'}" class="tabs-component-tab-a nav-link ">
                              Notes
                              <span v-if="totalNotes" class="tag tag-info">{{ totalNotes }}</span>
                            </a>
                        </li>
                    </ul>
                    <div class="tab-header rs-btn-group float-sm-right mt-1-sm">
                        <div v-if="!isAutoSaveEnable &&  !isHiddenSave && !isCustomerUser && isStatusActive" class="tabs-nav-right-btn">
                            <button @click="saveAsActive" type="button" class="btn btn-primary w-min-sm waves-effect waves-light"
                                    style="margin-right:5px">Save
                            </button>
                        </div>
                        <div v-if="!isHiddenNewNote && !isCustomerUser && isStatusActive" class="tabs-nav-right-btn tabs-nav-right-btn-mobile">
                            <button @click="addNote"
                                    data-toggle="modal" data-target="#noteBoxModal" type="button"
                                    class="btn btn-primary w-min-sm waves-effect waves-light"
                                    style="margin-right:5px"><i class="ti-plus"></i> New Note
                            </button>
                        </div>
                        <div v-if="!isHiddenOptions" class="tabs-nav-right-options">
                            <div class="navbar-nav nav dropdown dropdown-options  show inline">
                                <button type="button"
                                        class="btn waves-effect waves-light ex-options-button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="true"
                                        @click="updateHeightOptionsMenu($event)">
                                    Options <span class="dropdown-arrow  pull-right" style="
                                                  box-sizing: border-box;

                                                  width: 32px;
                                                  border-left: 1px solid rgba(27,30,56,0.25);
                                                  ">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                </span>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right animated fadeInUp" aria-labelledby="dropdownMenuLink" :style="{'max-height': heightOptions + 'px'}" :class="{'options-menu-height': heightOptions != ''}">
                                    <a v-show="isPaid" @click="showPreviewReceipt" class="dropdown-item"> Print Receipt</a>
                                    <a @click="showPreviewInvoiceModal" class="dropdown-item"> Preview Invoice</a>
                                    <a v-if="summary.isInsurance && summary.Excess !='0' && summary.Excess !=''  && summary.Excess != null" @click="showPreviewExcessInvoiceModal"
                                       class="dropdown-item">
                                        Print Excess Invoice
                                    </a>
                                    <a v-if="!isCustomerUser  && properties.isFromIag && vendorInfo && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.orm" @click="sendToORM" class="dropdown-item">Send To ORM</a>
                                    <a v-if="isStatusActive" @click="showCustomerModal" class="dropdown-item"> Send To Customer</a>
                                    <a v-if="!isCustomerUser && isStatusActive" @click="showInsurerModal" class="dropdown-item"> Send To Insurer</a>
                                    <a v-if="vendorInfo.activeXeroAuth && !invoice.xeroInvoiceId && !isCustomerUser && isStatusActive && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.xero" @click="sendToXero" class="dropdown-item">Send to Xero</a>
                                    <a v-if="vendorInfo.activeXeroAuth &&  invoice.xeroInvoiceId && !isCustomerUser && isStatusActive && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.xero" @click="sendToXero" class="dropdown-item">Update on Xero</a>
                                    <a v-if="vendorInfo.activeXeroAuth &&  invoice.xeroInvoiceId && !isCustomerUser && isStatusActive && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.xero" @click="viewOnXero" class="dropdown-item">View on Xero</a>
                                    <!--                                    <a @click="markAsAwaitingPayment" class="dropdown-item"> Mark As Awaiting Payment</a>-->
                                    <a v-if="isStatusAwaitingPayment && isStatusActive" @click="markAsVoidInvoice" class="dropdown-item"> Mark As Void</a>
                                    <a v-if="(isStatusAwaitingPayment || invoice.due!=0) && isStatusActive" @click="showPaidModal" class="dropdown-item"> Mark As Paid</a>
                                    <a v-if="(isStatusPaid || +invoice.amount !== invoice.due) && isStatusActive" @click="markAsUnpaid" class="dropdown-item"> Mark As Unpaid</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div :class="classTabContent">
                    <div v-if="currentTab === 'details'" id="invoice-details">
                        <div class="top_section_invoice_vehicle_row">
                            <div class="col_invoice">
                                <div class="card ex-card ex-invoice-information">
                                    <div class="card-header">
                                        <strong>&nbsp;Invoice To</strong>
                                    </div>
                                    <div class="card-block bg-white">
                                        <div class="form-group row">
                                            <label class="col-lg-3 col-form-label">Invoice Number</label>
                                            <div class="col-lg-9">
                                                <input :readonly="!isStatusActive" type="text" class="form-control" v-model="invoice.number" readonly>
                                            </div>
                                        </div>

                                        <div class="form-group row" v-if="card.insurance_repair == 'true'">
                                            <label class="col-lg-3 col-form-label">Insurer</label>
                                            <div class="col-lg-9">
                                                <advanced-search
                                                        v-model="invoice.insurer.name"
                                                        :searchData="insurers"
                                                        :searchKey="'insurer_name'"
                                                        :sortByName="'insurer_name'"
                                                        @onSelect="onInsurerSelect"
                                                        :is-disabled="!isStatusActive"
                                                        :searchLabel="'Insurer'"
                                                        placeholder="Insurer Name">
                                                </advanced-search>
                                            </div>
                                        </div>
                                        <div class="form-group row" v-if="card.insurance_repair  == 'true'">
                                            <label class="col-lg-3 col-form-label">Insurer Address</label>
                                            <div class="col-lg-9">
                                                <google-location type="text"
                                                                 v-model="invoice.insurer.address"
                                                                 @onPlaceChange="setOtherInsurerAddress"
                                                                 @onChange="setOtherInsurerAddress"
                                                                 :is-disabled="!isStatusActive"
                                                                 placeholder="Search for an Address">
                                                </google-location>
                                            </div>
                                        </div>
                                        <div class="form-group row" v-if="card.insurance_repair  == 'true'">
                                            <label class="col-lg-3 col-form-label">Insurer ABN</label>
                                            <div class="col-lg-9">
                                                <input type="text" class="form-control" v-model="invoice.insurer.abn" @change="setOtherInsurerAbn" :readonly="isCustomerUser || !isStatusActive">
                                            </div>
                                        </div>
                                        <div class="form-group row" v-if="card.insurance_repair  == 'false'">
                                            <label class="col-lg-3 col-form-label">Customer</label>
                                            <div class="col-lg-9">
                                                <input type="text" class="form-control" v-model="invoice.customer.name"
                                                       @blur="blurCustomerName" @click="editCustomerName" @change="changeCustomerName" :readonly="isReadOnlyCustomerName || isCustomerUser || !isStatusActive">
                                            </div>
                                        </div>
                                        <div class="form-group row" v-if="card.insurance_repair  == 'false'">
                                            <label class="col-lg-3 col-form-label">Customer Address</label>
                                            <div class="col-lg-9">
                                                <input type="text" class="form-control" v-model="invoice.customer.address"
                                                       @blur="blurCustomerAddress" @click="editCustomerAddress" @change="changeCustomerAddress" :readonly="isReadOnlyCustomerAddress || isCustomerUser || !isStatusActive">
                                            </div>
                                        </div>
                                        <div class="form-group row" v-if="card.insurance_repair  == 'false' && card.customer.customer_type =='B'">
                                            <label class="col-lg-3 col-form-label">Customer ABN</label>
                                            <div class="col-lg-9">
                                                <span class="form-control">{{card.customer.abn}} </span>
                                            </div>
                                        </div>

                                        <div class="form-group row">
                                            <label class="col-lg-3 col-form-label">Invoice Date</label>
                                            <div class="col-lg-9">
                                                <date-picker v-model="invoice.created_date" :isDisabled="isCustomerUser || !isStatusActive" @onChange="autoUpdate"></date-picker>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-lg-3 col-form-label">Due Date</label>
                                            <div class="col-lg-9">
                                                <date-picker v-model="invoice.due_date" :isDisabled="isCustomerUser || !isStatusActive" @onChange="autoUpdate"></date-picker>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-lg-3 col-form-label">Estimate Reference</label>
                                            <div class="col-lg-9">
                                                <span class="form-control">
                                                    <router-link v-for="item in card.estimates" :to="'/estimates/view/' + item.id" :key="'routerLink'+item.id" style="padding-right:10px;">{{item.number}}
                                                    </router-link>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group row" v-if="invoice.is_insurer &&  card.insurance.excess === null">
                                            <label class="col-lg-3 col-form-label">Excess</label>
                                            <div class="col-lg-9">
                                                <span class="form-control"> SET TO NIL
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group row" v-else-if="invoice.is_insurer && invoice.excess_invoice && summary.Excess">
                                            <label class="col-lg-3 col-form-label">Excess</label>
                                            <div class="col-lg-9">
                                                <span class="form-control"><span @click="noEditExcessAmount">{{summary.Excess_str}}</span>
                                                    <span v-if="invoice.excess_invoice"> -
                                                    <router-link :to="'/invoice/view/' + invoice.excess_invoice.id + '/excess'">{{invoice.excess_invoice.number}}</router-link>
                                                        </span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="form-group row" v-else-if="invoice.is_insurer">
                                            <label class="col-lg-3 col-form-label">Excess</label>
                                            <div class="col-lg-9">
                                                <number-formatter ref="invoice.manuual_excess" v-model="summary.Excess" type="text" class="form-control" placeholder="Customer Excess"
                                                                  format="$0,0.00"
                                                                  @onChange="updateExcess"
                                                                  :isDisabled="!isStatusActive || isCustomerUser"></number-formatter>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-lg-3 col-form-label">Sent</label>
                                            <div class="col-sm-7 col-7 d-flex">
                                                <label class="form-check-inline radio" style="width:70px;">
                                                    <input class="form-check-input" v-model="invoice.is_sent" type="radio" name="invoice_is_sent"
                                                           :value="true" :disabled="isCustomerUser || !isStatusActive" @change="autoUpdate">
                                                    <span class="icon"><i class='bx bx-check'></i></span><span class="text">Yes</span>
                                                </label>
                                                <label class="form-check-inline radio">
                                                    <input class="form-check-input" v-model="invoice.is_sent" type="radio" name="invoice_is_sent"
                                                           :value="false" :disabled="isCustomerUser || !isStatusActive" @change="autoUpdate">
                                                    <span class="icon"><i class='bx bx-check'></i></span><span class="text">No</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col_vehicle">
                                <div class="card ex-card ex-card-details">
                                    <div class="card-header vehicle-block-margin">
                                        <strong>&nbsp;Vehicle Owner</strong>
                                    </div>
                                    <div class="own_vehicle_section">
                                        <div class="card_owner_details">
                                            <div class="card card-in-card">
                                                <div class="card-header">Owner Details</div>
                                                <div class="card-block bg-white">
                                                    <div class="form-group row">
                                                        <label class="col-lg-4 col-form-label">Name</label>
                                                        <div class="col-lg-8">
                                                            <input type="text" v-model="card.customer.name" class="form-control" readonly>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row">
                                                        <label class="col-lg-4 col-form-label">Owner Type</label>
                                                        <div class="col-lg-8">
                                                            <input type="text" v-model="card.customer.customer_type == 'I'?'Individual':'Business' " class="form-control" readonly>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-lg-4 col-form-label">Contact Number</label>
                                                        <div class="col-lg-8">
                                                            <input type="text" v-model="card.customer.mobile" class="form-control" readonly>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row">
                                                        <label class="col-lg-4 col-form-label">Email Address</label>
                                                        <div class="col-lg-8">
                                                            <input type="text" v-model="card.customer.email" class="form-control" readonly>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row" v-if="!!card.insurance_repair">
                                                        <label class="col-lg-4 col-form-label">Claim Number</label>
                                                        <div class="col-lg-8">
                                                            <input type="text" v-model="card.insurance.claim_number" class="form-control" readonly>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row" v-if="!!card.insurance_repair">
                                                        <label class="col-lg-4 col-form-label">Insurer Name</label>
                                                        <div class="col-lg-8">
                                                            <input type="text" v-model="card.insurance.insurer_name" class="form-control" readonly>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row" v-if="!!card.insurance_repair">
                                                        <label class="col-lg-4 col-form-label">Insurer Address</label>
                                                        <div class="col-lg-8">
                                                            <input type="text" v-model="card.insurance.insurer_location" class="form-control" readonly>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card_vehicle_details">
                                            <div class="card card-in-card">
                                                <div class="card-header">Vehicle Details</div>
                                                <div class="card-block bg-white">
                                                    <div class="row">
                                                        <div class="col-md-6 vehicle-details-block-padding">
                                                            <div class="form-group row">
                                                                <label class="col-lg-4 col-form-label">Make</label>
                                                                <div class="col-lg-8">
                                                                    <input type="text" v-model="card.vehicle.make" class="form-control" :readonly="isCustomerUser || !isStatusActive">
                                                                </div>
                                                            </div>
                                                            <div class="form-group row">
                                                                <label class="col-lg-4 col-form-label">Model</label>
                                                                <div class="col-lg-8">
                                                                    <input type="text" v-model="card.vehicle.model" class="form-control" :readonly="isCustomerUser || !isStatusActive">
                                                                </div>
                                                            </div>
                                                            <div class="form-group row">
                                                                <label class="col-lg-4 col-form-label">Series</label>
                                                                <div class="col-lg-8">
                                                                    <input type="text" v-model="card.vehicle.series" class="form-control" :readonly="isCustomerUser || !isStatusActive">
                                                                </div>
                                                            </div>
                                                            <div class="form-group row">
                                                                <label class="col-lg-4 col-form-label">Transmission</label>
                                                                <div class="col-lg-8">

                                                                    <multiselect
                                                                        :disabled="isCustomerUser || !isStatusActive"
                                                                        id="transmission"
                                                                        v-model="card.vehicle.transmission"
                                                                        :options="['Automatic', 'Manual']"
                                                                        :showLabels="false"
                                                                        :option-height="29"
                                                                        :max-height="203"
                                                                        :close-on-select="true"
                                                                        style="opacity: 1"
                                                                        placeholder="Select transmission"
                                                                        >
                                                                    </multiselect>
                                                                </div>
                                                            </div>
                                                            <div class="form-group row">
                                                                <label class="col-lg-4 col-form-label">Paint Code</label>
                                                                <div class="col-lg-8">
                                                                    <input type="text" v-model="card.vehicle.colour" class="form-control" :readonly="isCustomerUser || !isStatusActive">
                                                                </div>
                                                            </div>
                                                            <div class="form-group row">
                                                                <label class="col-lg-4 col-form-label">Date In</label>
                                                                <div class="col-lg-8">
                                                                    <date-picker v-model="card.booking_date" :is-disabled="isCustomerUser || !isStatusActive" @onChange="autoUpdate"></date-picker>
                                                                </div>
                                                            </div>
                                                            <div class="form-group row">
                                                                <label class="col-lg-4 col-form-label">Date Out</label>
                                                                <div class="col-lg-8">
                                                                    <date-picker v-model="card.completion_date" :is-disabled="isCustomerUser || !isStatusActive" @onChange="autoUpdate"></date-picker>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 vehicle-details-block-padding">

                                                            <div class="form-group row">
                                                                <label class="col-lg-4 col-form-label">Rego</label>
                                                                <div class="col-lg-8">
                                                                    <input type="text" v-model="card.vehicle.rego_number" class="form-control" :readonly="isCustomerUser || !isStatusActive">
                                                                </div>
                                                            </div>
                                                            <div class="form-group row">
                                                                <label class="col-lg-4 col-form-label">VIN Number</label>
                                                                <div class="col-lg-8">
                                                                    <input type="text" v-model="card.vehicle.vin" class="form-control" :readonly="isCustomerUser || !isStatusActive">
                                                                </div>
                                                            </div>
                                                            <div class="form-group row">
                                                                <label class="col-lg-4 col-form-label">Cylinders</label>
                                                                <div class="col-lg-8">
                                                                    <input type="text" v-model="card.vehicle.cylinders" class="form-control" :readonly="isCustomerUser || !isStatusActive">
                                                                </div>
                                                            </div>
                                                            <div class="form-group row">
                                                                <label class="col-lg-4 col-form-label">Build Date</label>
                                                                <div class="col-lg-8">
                                                                    <date-picker v-model="card.vehicle.build_date" @onChange="autoUpdate" :is-disabled="isCustomerUser || !isStatusActive"></date-picker>
                                                                </div>
                                                            </div>
                                                            <div class="form-group row">
                                                                <label class="col-lg-4 col-form-label">Odometer</label>
                                                                <div class="col-lg-8">
                                                                    <number-formatter ref="vehicle.odometer" v-model="card.vehicle.odometer" type="text" class="form-control" placeholder="Odometer"
                                                                                      format="" :disabled="isCustomerUser || !isStatusActive"></number-formatter>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row block-mobile-column">

                            <template v-for="(item,index) in all_line_items">
                                <div class="estimates-number pl-0" v-if="item.isEstimate">
                                    <div class="line-invoice-reference"></div>
                                    <strong class="position-text-reference">Estimate Reference: {{item.number}}</strong>
                                </div>
                            </template>
                            <div class="col-md-12 col-lg-8 padding-additional-class">
                                <template v-for="(supplement, index) in all_line_items">
                                    <div class="supplement-number" v-if="!supplement.isEstimate">
                                        <div class="line-invoice-reference-suplement"></div>
                                        <strong class="text-suplement-ref">Supplement Reference: {{supplement.number}}</strong>
                                    </div>
                                    <div class="card invoice-view ex-card ex-quote-information" :class="'invoice_parent_item' + supplement.id">
                                        <div class="card-header font-13">
                                            Repairs
                                        </div>
                                        <div class="card-header-2"
                                             v-if="!!supplement.line_items.lines.RR.length || !!supplement.line_items.lines.Repair.length || !!supplement.line_items.lines.Paint.length || !!!!supplement.line_items.lines.Materials.length">
                                            <div class="top_header_invoice">
                                                <div class="left_complate_invoice">
                                                    <span>RR,</span>
                                                    <span>Repair,</span>
                                                    <span>Paint & Materials</span>
                                                </div>
                                                <div class="expand_amount" :ref="'expand_'+supplement.id+'_labour'">
                                                    <span class="expand_button"
                                                          @click="expandForAllItems(invoiceClasses.labourInvoiceClass, 'expand_'+supplement.id+'_labour', 'invoice_parent_item' + supplement.id, supplement.id, 'labour')">Expand <i
                                                            class="fa" :class="[!isExpandLabour(supplement.id) ? 'fa-angel-down' : 'fa-angel-up']"></i></span>
                                                    <span :style="{'visibility':!isExpandLabour(supplement.id) ? 'visible' : 'hidden'}">Amount</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div :class="invoiceClasses.labourInvoiceClass+ '_expand'"
                                             :data-show-invoice="isExpandLabour(supplement.id) ? 'true' : 'false'"
                                             :style="{'display':isExpandLabour(supplement.id) ? 'block' : 'none'}">
                                            <div class="bg-white spacing-of-sections">
                                                <table class="table main-table tab-special mb-0" v-if="!!supplement.line_items.lines.RR.length">
                                                    <thead>
                                                    <tr>
                                                        <th class="text-left">R&R</th>
                                                        <th class="text-center">{{ isEstimateMethodologyDollars ? '' : 'Hrs' }}</th>
                                                        <th class="text-center">{{ isEstimateMethodologyDollars ? '' : 'Rate' }}</th>
                                                        <th class="text-center">Amount</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr class="qtit" v-for="part in supplement.line_items.lines.RR">
                                                        <td>{{part.name}}</td>
                                                        <td>{{isEstimateMethodologyDollars ? '' : part.hrs_str}}</td>
                                                        <td class="rate">{{isEstimateMethodologyDollars ? '' : part.rate_str}}</td>
                                                        <td class="amount">{{part.total_str}}</td>
                                                    </tr>
                                                    </tbody>
                                                    <tfoot>
                                                    <tr v-if="!isEstimateMethodologyDollars">
                                                        <td class="subtotal-right">Subtotal Hrs</td>
                                                        <td>{{supplement.line_items.summary['RR-Hrs-SubTotal-Str']}}</td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="font-bold text-right" colspan="3">Subtotal Amount</td>
                                                        <td>{{supplement.line_items.summary['RR-Total-SubTotal-Str']}}</td>
                                                    </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div class="bg-white spacing-of-sections">
                                                <table class="table main-table tab-special mb-0" v-if="!!supplement.line_items.lines.Repair.length">
                                                    <thead>
                                                    <tr>
                                                        <th class="text-left">Repair</th>
                                                        <th class="text-center">{{ isEstimateMethodologyDollars ? '' : 'Hrs' }}</th>
                                                        <th class="text-center">{{ isEstimateMethodologyDollars ? '' : 'Rate' }}</th>
                                                        <th class="text-center">Amount</th>
                                                    </tr>
                                                    </thead>
                                                    <tr class="qtit" v-for="part in supplement.line_items.lines.Repair">
                                                        <td>{{part.name}}</td>
                                                        <td>{{isEstimateMethodologyDollars ? '' : part.hrs_str}}</td>
                                                        <td>{{isEstimateMethodologyDollars ? '' : part.rate_str}}</td>
                                                        <td class="amount">{{part.total_str}}</td>
                                                    </tr>
                                                    <tfoot>
                                                    <tr v-if="!isEstimateMethodologyDollars">
                                                        <td class="subtotal-right">Subtotal Hrs</td>
                                                        <td>{{supplement.line_items.summary['Repair-Hrs-SubTotal-Str']}}</td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td class="font-bold text-right" colspan="2">Subtotal Amount</td>
                                                        <td class="font-bold">{{supplement.line_items.summary['Repair-Total-SubTotal-Str']}}</td>
                                                    </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div class="bg-white spacing-of-sections">
                                                <table class="table main-table tab-special mb-0" v-if="!!supplement.line_items.lines.Paint.length">
                                                    <thead>
                                                    <tr>
                                                        <th class="text-left">Paint</th>
                                                        <th class="text-center">Paint Material</th>
                                                        <th class="text-center">{{ isEstimateMethodologyDollars ? '' : 'Hrs' }}</th>
                                                        <th class="text-center">{{ isEstimateMethodologyDollars ? '' : 'Rate' }}</th>
                                                        <th class="text-center">Amount</th>
                                                    </tr>
                                                    </thead>
                                                    <tr class="qtit" v-for="part in supplement.line_items.lines.Paint">
                                                        <td>{{part.name}}</td>
                                                        <td>{{part.paint_type}}</td>
                                                        <td>{{isEstimateMethodologyDollars ? '' : part.hrs_str}}</td>
                                                        <td>{{isEstimateMethodologyDollars ? '' : part.rate_str}}</td>
                                                        <td class="amount">{{part.total_str}}</td>
                                                    </tr>
                                                    <tfoot>
                                                    <tr v-if="!isEstimateMethodologyDollars">
                                                        <td class="subtotal-right">Subtotal Hrs</td>
                                                        <td></td>
                                                        <td>{{supplement.line_items.summary['Paint-Hrs-SubTotal-Str']}}</td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td class="subtotal-right font-bold text-right">Subtotal Amount</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>{{supplement.line_items.summary['Paint-Total-SubTotal-Str']}}</td>
                                                    </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div class="bg-white spacing-of-sections">
                                                <table class="table  main-table tab-special mb-0" v-if="!!supplement.line_items.lines.Materials.length">
                                                    <thead>
                                                    <tr>
                                                        <th class="text-left">Materials</th>
                                                        <th class="text-center">Hrs</th>
                                                        <th class="text-center">Amount</th>
                                                    </tr>
                                                    </thead>
                                                    <tr class="qtit" v-for="part in supplement.line_items.lines.Materials">
                                                        <td>{{part.name}}</td>
                                                        <td><span v-if="isShowHrsMaterialsPreview(part)">{{ part.hrs_str }}</span></td>
                                                        <td class="amount text-right">{{part.total_str}}</td>
                                                    </tr>
                                                    <tfoot>
                                                    <tr>
                                                        <td class="subtotal-right font-bold text-right">Subtotal Amount</td>
                                                        <td></td>
                                                        <td>{{supplement.line_items.summary['Materials-Total-SubTotal-Str']}}</td>
                                                    </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="bg-white spacing-of-sections" :class="invoiceClasses.labourInvoiceClass" :style="{'display':!isExpandLabour(supplement.id) ? 'block' : 'none'}">
                                            <table class="table main-table tab-special mb-0" v-if="supplement.line_items.summary['Labour-Cost-With-Materials'] > 0">
                                                <tr class="qtit">
                                                    <td>Labour As Per Authority</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>{{supplement.line_items.summary['Labour-Cost-With-Materials-Str']}}</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="card-header-2" v-if="!!supplement.line_items.lines.Part.length">
                                            <div class="top_header_invoice">
                                                <div class="left_complate_invoice">
                                                    <span>Parts</span>
                                                </div>
                                                <div class="expand_amount" :ref="'expand_'+supplement.id+'_part'">
                                                    <span class="expand_button"
                                                          @click="expandForAllItems(invoiceClasses.partsInvoiceClass, 'expand_'+supplement.id+'_part', 'invoice_parent_item' + supplement.id, supplement.id, 'part')">Expand <i
                                                            class="fa" :class="[!invoice.is_expand_part ? 'fa-angel-down' : 'fa-angel-up']"></i></span>
                                                    <span :style="{'visibility':!invoice.is_expand_part ? 'visible' : 'hidden'}">Amount</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bg-white spacing-of-sections" :class="invoiceClasses.partsInvoiceClass + '_expand'"
                                             :data-show-invoice="isExpandLabour(supplement.id) ? 'true' : 'false'"
                                             :style="{'display':invoice.is_expand_part ? 'block' : 'none'}">
                                            <table class="table main-table tab-special mb-0 parts-block-size" v-if="!!supplement.line_items.lines.Part.length">
                                                <thead>
                                                <tr>
                                                    <th class="text-left"></th>
                                                    <th class="text-center">Part Numbers</th>
                                                    <th class="text-center">Qty</th>
                                                    <th class="text-center">Unit Price</th>
                                                    <th class="text-center">Amount</th>
                                                </tr>
                                                </thead>
                                                <tr class="qtit" v-for="part in supplement.line_items.lines.Part">
                                                    <td>{{part.name}}</td>
                                                    <td>{{part.part_num}}</td>
                                                    <td>{{part.qty}}</td>
                                                    <td>{{part.rate_str}}</td>
                                                    <td class="amount">{{part.total_str}}</td>
                                                </tr>
                                                <tfoot>

                                                <tr>
                                                    <td></td>
                                                    <td class="font-bold text-right" colspan="3">Subtotal Amount</td>
                                                    <td class="font-bold">{{supplement.line_items.summary['Part-Total-SubTotal-Str']}}</td>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div class="bg-white spacing-of-sections" :class="invoiceClasses.partsInvoiceClass" :style="{'display':!invoice.is_expand_part ? 'block' : 'none'}">
                                            <table class="table main-table tab-special mb-0" v-if="supplement.line_items.summary['Part-Cost'] > 0">
                                                <tr class="qtit">
                                                    <td>Parts Summary</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>{{supplement.line_items.summary['Part-Cost-Str']}}</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <!--                                        Other-->
                                        <div class="card-header-2 bg-white" v-if="!!supplement.line_items.lines.Other.length || !!supplement.line_items.lines.Sublet.length">
                                            <div class="top_header_invoice">
                                                <div class="left_complate_invoice">
                                                    <span>Sublet & Other</span>
                                                </div>
                                                <div class="expand_amount" :ref="'expand_'+supplement.id+'_other'">
                                                    <span class="expand_button"
                                                          @click="expandForAllItems(invoiceClasses.otherInvoiceClass, 'expand_'+supplement.id+'_other', 'invoice_parent_item' + supplement.id, supplement.id, 'other')">Expand <i
                                                            class="fa" :class="[!invoice.is_expand_other ? 'fa-angel-down' : 'fa-angel-up']"></i></span>
                                                    <span :style="{'visibility':!invoice.is_expand_other ? 'visible' : 'hidden'}">Amount</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div :class="invoiceClasses.otherInvoiceClass + '_expand'"
                                             :data-show-invoice="isExpandLabour(supplement.id) ? 'true' : 'false'"
                                             :style="{'display':invoice.is_expand_other ? 'block' : 'none'}">
                                            <div class="bg-white spacing-of-sections">
                                                <table class="table main-table tab-special mb-0" v-if="!!supplement.line_items.lines.Other.length">
                                                    <thead>
                                                    <tr>
                                                        <th class="text-left">Other</th>
                                                        <th class="text-center">Amount</th>
                                                    </tr>
                                                    </thead>
                                                    <tr class="qtit" v-for="part in supplement.line_items.lines.Other">
                                                        <td>{{part.name}}</td>
                                                        <td class="amount">{{part.total_str}}</td>
                                                    </tr>
                                                    <tfoot>
                                                    <tr>
                                                        <td class="font-bold text-right">Subtotal Amount</td>
                                                        <td>{{supplement.line_items.summary['Other-Total-SubTotal-Str']}}</td>
                                                    </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                            <div class="bg-white spacing-of-sections">
                                                <table class="table main-table tab-special mb-0" v-if="!!supplement.line_items.lines.Sublet.length">
                                                    <thead>
                                                    <tr>
                                                        <th class="text-left">Sublet</th>
                                                        <th class="text-center">Repairer</th>
                                                        <th class="text-center">Rate</th>
                                                        <th class="text-center">Amount</th>
                                                    </tr>
                                                    </thead>
                                                    <tr class="qtit" v-for="part in supplement.line_items.lines.Sublet">
                                                        <td>{{part.name}}</td>
                                                        <td>{{part.repairer}}</td>
                                                        <td>{{part.rate_str}}</td>
                                                        <td class="amount">{{part.total_str}}</td>
                                                    </tr>
                                                    <tfoot>
                                                    <tr>
                                                        <td class="font-bold text-right" colspan="3">Subtotal Amount</td>
                                                        <td>{{supplement.line_items.summary['Sublet-Total-SubTotal-Str']}}</td>
                                                    </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="bg-white spacing-of-sections" :class="invoiceClasses.otherInvoiceClass" :style="{'display':!invoice.is_expand_other ? 'block' : 'none'}">
                                            <table class="table main-table tab-special mb-0" v-if="supplement.line_items.summary['Other-Cost'] > 0">
                                                <tr class="qtit">
                                                    <td>Sublet & Other</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>{{supplement.line_items.summary['Other-Cost-Str']}}</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <!--                                        PDR-->
                                        <div class="card-header-2 bg-white" v-if="!!supplement.line_items.lines.PDR.length || !!supplement.line_items.lines.PDR.length">
                                            <div class="top_header_invoice">
                                                <div class="left_complate_invoice">
                                                    <span>PDR</span>
                                                </div>
                                                <div class="expand_amount" :ref="'expand_'+supplement.id+'_pdr'">
                                                    <span class="expand_button"
                                                          @click="expandForAllItems(invoiceClasses.pdrInvoiceClass, 'expand_'+supplement.id+'_pdr', 'invoice_parent_item' + supplement.id, supplement.id, 'pdr')">Expand <i
                                                            class="fa" :class="[!invoice.is_expand_pdr ? 'fa-angel-down' : 'fa-angel-up']"></i></span>
                                                    <span :style="{'visibility':!invoice.is_expand_pdr ? 'visible' : 'hidden'}">Amount</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div :class="invoiceClasses.pdrInvoiceClass + '_expand'"
                                             :data-show-invoice="isExpandLabour(supplement.id) ? 'true' : 'false'"
                                             :style="{'display':invoice.is_expand_pdr ? 'block' : 'none'}">
                                            <div class="bg-white spacing-of-sections">
                                                <table class="table main-table tab-special mb-0" v-if="!!supplement.line_items.lines.PDR.length">
                                                    <thead>
                                                    <tr>
                                                        <th class="text-left"></th>
                                                        <th class="text-center">Material</th>
                                                        <th class="text-center">Action</th>
                                                        <th class="text-center">Qty</th>
                                                        <th class="text-center">Price</th>
                                                        <th class="text-center">Amount</th>
                                                    </tr>
                                                    </thead>
                                                    <tr class="qtit" v-for="part in supplement.line_items.lines.PDR">
                                                        <td>{{part.name}}</td>
                                                        <td v-if="part.type=='Panel'">{{part.material|valuePDRMaterial}}</td>
                                                        <td v-else></td>
                                                        <td v-if="part.type=='Panel'">{{part.action|valuePDRAction}}</td>
                                                        <td v-else></td>
                                                        <td>{{part.qty}}</td>
                                                        <td>{{part.price_str}}</td>
                                                        <td class="amount">{{part.total_str}}</td>
                                                    </tr>
                                                    <tfoot>
                                                    <tr>
                                                        <td class="subtotal-right font-bold text-right">Subtotal Amount</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>{{supplement.line_items.summary['PDR-Total-SubTotal-Str']}}</td>
                                                    </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="bg-white spacing-of-sections" :class="invoiceClasses.pdrInvoiceClass" :style="{'display':!invoice.is_expand_pdr ? 'block' : 'none'}">
                                            <table class="table main-table tab-special mb-0" v-if="supplement.line_items.summary['PDR-Cost'] > 0">
                                                <tr class="qtit">
                                                    <td>PDR</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>{{supplement.line_items.summary['PDR-Cost-Str']}}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="card invoice-view ex-card ex-quote-information no-border" :class="{'spacing-of-est-and-supp': index + 1 != all_line_items.length}">
                                        <div class="card-block bg-white result_of_total_amount">
                                            <table class="parts-total main-table main-total-table latest_amount_table">
                                                <tbody>
                                                <tr style="font-weight: bold; font-size: 13px;">
                                                    <td class="font-bold text-right">Total</td>
                                                    <td class="text-right">{{supplement.line_items.summary.Total_str}}</td>
                                                </tr>
                                                <tr style="font-weight: bold; font-size: 13px;">
                                                    <td class="font-bold text-right border-bottom">GST</td>
                                                    <td class="text-right border-bottom">{{supplement.line_items.summary.GST_str}}</td>
                                                </tr>
                                                <tr class="latest_amount" style="font-weight: bold; font-size: 13px;">
                                                    <td class="font-bold text-right">Total (Inc GST)</td>
                                                    <td class="text-right">{{supplement.line_items.summary.GrandTotal_str}}</td>
                                                </tr>
                                                <!--                                                <tr v-if="countOfEstimateAndSupps == 1 && summary.isInsurance && !!summary.Excess">-->
                                                <!--                                                    <td class="font-bold text-right">Less Excess</td>-->
                                                <!--                                                    <td class="text-right">{{summary.Excess_str}}</td>-->
                                                <!--                                                </tr>-->
                                                <!--                                                <tr v-if="countOfEstimateAndSupps == 1 && summary.isInsurance && !!summary.Discount">-->
                                                <!--                                                    <td class="font-bold text-right">Discount Amount</td>-->
                                                <!--                                                    <td class="text-right">{{summary.Discount_str}}</td>-->
                                                <!--                                                </tr>-->
                                                <!--                                                <tr v-if="countOfEstimateAndSupps == 1 && summary.isInsurance && (!!summary.Discount || !!summary.Excess)" class="latest_amount">-->
                                                <!--                                                    <td class="font-bold text-right">Total Invoice</td>-->
                                                <!--                                                    <td class="text-right">{{summary.TotalInvoice_str}}</td>-->
                                                <!--                                                </tr>-->
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </template>
                                <div class="total">
                                </div>
                                <div class="card invoice-view ex-card ex-quote-information no-border">
                                    <div class="line-invoice-reference-grand-total"></div>
                                    <div class="card-block bg-white result_of_total_amount" style="flex-direction: column; align-items: flex-end;">
                                        <table class="parts-total main-table main-total-table latest_amount_table">
                                            <tbody>
                                            <tr v-if="countSubtotal > 1 && countOfEstimateAndSupps > 1" style="font-weight: bold; font-size: 13px;">
                                                <td class="font-bold text-right">Combined Sub-total</td>
                                                <td class="text-right">{{summary.Total_str}}</td>
                                            </tr>
                                            <tr v-if="countSubtotal > 1 && countOfEstimateAndSupps > 1" style="font-weight: bold; font-size: 13px;">
                                                <td class="font-bold text-right border-bottom">GST</td>
                                                <td class="text-right border-bottom">{{summary.GST_str}}
                                                  <i v-if="(properties?.isGSTCorrected && properties?.isForEstimage)" data-tooltip="the GST was corrected by matching on the Estimage side" class='bx bx-error-circle gst-icon-exclamation'></i>
                                                </td>
                                            </tr>
                                            <template>
                                                <tr v-if="countOfEstimateAndSupps > 1 && summary.isInsurance && !!summary.Excess" class="latest_amount" style="font-weight: bold; font-size: 13px;">
                                                    <td class="font-bold text-right d-flex" style="white-space: nowrap; justify-content: end">
                                                        Combined Total (Inc GST)
                                                    </td>
                                                    <td class="text-right">{{summary.GrandTotal_str}}</td>
                                                </tr>
                                                <tr v-else-if="countOfEstimateAndSupps > 1 && !summary.Excess" class="latest_amount" style="font-weight: bold; font-size: 13px;">
                                                    <td class="font-bold text-right d-flex" style="white-space: nowrap;">
                                                        <span class="grand-total-text">GRAND TOTAL </span>&ensp;(Inc GST)
                                                    </td>
                                                    <td class="text-right">{{summary.GrandTotal_str}}</td>
                                                </tr>
                                            </template>
                                            <template v-if="(summary.isInsurance && !!summary.Discount) || (summary.isInsurance && !!summary.Excess)">
                                                <tr v-if=" summary.isInsurance && !!summary.Excess">
                                                    <td class="font-bold text-right">Less Excess</td>
                                                    <td class="text-right">{{summary.Excess_str}}</td>
                                                </tr>
                                                <tr v-if="summary.isInsurance && !!summary.Discount">
                                                    <td class="font-bold text-right">Discount Amount</td>
                                                    <td class="text-right">{{summary.Discount_str}}</td>
                                                </tr>
                                                <tr v-if="summary.isInsurance && (!!summary.Discount || !!summary.Excess)" class="latest_amount">
                                                    <td class="font-bold text-right" style="white-space: nowrap">
                                                        <span class="grand-total-text">GRAND TOTAL </span>&ensp;Invoice
                                                    </td>
                                                    <td class="text-right">{{summary.TotalInvoice_str}}</td>
                                                </tr>
                                            </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-12 col-lg-4">
                                <div class="card card-section">
                                    <div class="card-header">
                                        <strong>Card</strong>
                                    </div>
                                    <div class="card-block bg-white">
                                        <div class="form-group row">
                                            <label class="col-lg-4 col-form-label">Card Number</label>
                                            <div class="col-lg-8">
                                                <span class="form-control"><router-link :to="'/cards/view/' + card.card_id">{{card.card_number}}</router-link></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card ex-card wizard-bottom">
                                        <small-form-wizard
                                                title="Progress"
                                                :current-status-id="card.card_status"
                                                :statuses="cardProgressBarStatuses"
                                                :progress="cardProgress.widthActivePercent"
                                                :isShowFullActivity="false"
                                                @change="onChangeCardProgress"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-lg-8 padding-additional-class">
                                <div class="card ex-card ex-card-details">
                                    <div class="card-header">
                                        <span>&nbsp;Payment Terms</span>
                                    </div>
                                    <div>
                                        <div v-html="terms" disabled class="invoice-terms"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-12 col-lg-8"></div>
                        </div>
                    </div>

                    <!-- transaction history / activity -->
                    <div v-if="currentTab === 'transaction'" id="activity">
                        <div class="row no-gutter">
                            <div class="col-md-6">
                                <div class="row activity-list-header px-0 no-gutter">
                                    <div class="col-md-6 col-xs-6">
                                        <h4 class="tab-pane__title">Transaction History</h4>
                                    </div>
                                    <div class="col-md-6 col-xs-6 text-right">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row no-gutter">
                            <div class="col-md-6">
                                <div class="timeline">
                                    <div v-for="(a, index) in invoice.transactions" class="tl-item tl-activity-item">
                                        <div class="tl-wrap b-a-primary"
                                             v-if="a.transaction_type == 'Info' || a.transaction_type == 'Paid' || a.transaction_type == 'EIC' || a.transaction_type == 'EIP' || a.transaction_type == 'CIC' || a.transaction_type == 'CIP' || a.transaction_type == 'Correction' || a.transaction_type == 'SentToOrm' || a.transaction_type == 'Info'|| a.transaction_type == 'OrmPaymentAuthorised'">
                                            <i class="fa fa-usd left" aria-hidden="true"></i>
                                            <div class="tl-content box box-block display-block">
                                                <span class="arrow left" style="border-color: #FAFAFA;"></span>
                                              <table v-if="a.transaction_type == 'OrmPaymentAuthorised'">
                                                <tr style="padding-right:25px;  width:160px;">
                                                  <td>Invoice ORM Payment</td>
                                                </tr>

                                                <tr style="padding-right:25px;  width:160px;">
                                                  <td>Insurance Claim <br/>Number:</td>
                                                  <td>{{a.payment_message}}</td>
                                                </tr>

                                                <tr style="padding-right:25px;  width:160px;">
                                                  <td>Tax Invoice:</td>
                                                  <td>{{ invoice.number }}</td>
                                                </tr>
                                                <tr style="padding-right:25px;  width:160px;">
                                                  <td>Payment Authorised:</td>
                                                  <td>{{ a.date }}</td>
                                                </tr>
                                                <tr style="padding-right:25px;  width:160px;">
                                                  <td>Payment Amount:</td>
                                                  <td> {{ a.amount_str }} </td>
                                                </tr>

                                                <tr style="padding-right:25px;  width:160px;">
                                                  <td>Received:</td>
                                                  <td>Success</td>
                                                </tr>
                                                <tr style="padding-right:25px;  width:160px;">
                                                  <td>Sent By:</td>
                                                  <td>ORM</td>
                                                </tr>
                                                <tr>
                                                  <td style="padding-right:25px;  width:160px;">Date</td>
                                                  <td>{{a.date}}</td>
                                                </tr>
                                              </table>
                                              <table v-if="a.transaction_type == 'Info'">
                                                <tr>
                                                  <td style="padding-right:25px;  width:160px;">Transaction:</td>
                                                  <td>{{a.payment_message}}</td>
                                                </tr>
                                                <tr>
                                                  <td style="padding-right:25px;  width:160px;">Date:</td>
                                                  <td>{{ a.date }}</td>
                                                </tr>
                                                <tr>
                                                  <td style="padding-right:25px;  width:160px;">Processed by:</td>
                                                  <td>{{ a.processedBy }}</td>
                                                </tr>
                                              </table>
                                              <table v-if="a.transaction_type == 'SentToOrm'">
                                                <tr>
                                                  <td style="padding-right:25px;  width:160px;">Invoice Sent To</td>
                                                  <td>ORM</td>
                                                </tr>
                                                <tr>
                                                  <td style="padding-right:25px;  width:160px;">Amount Due</td>
                                                  <td>{{a.amount_due_str}}</td>
                                                </tr>
                                                <tr>
                                                  <td style="padding-right:25px;  width:160px;">Sent Status</td>
                                                  <td>Success</td>
                                                </tr>
                                                <tr>
                                                  <td style="padding-right:25px;  width:160px;">Sent by</td>
                                                  <td>{{a.processedBy}}</td>
                                                </tr>
                                                <tr>
                                                  <td style="padding-right:25px;  width:160px;">Date</td>
                                                  <td>{{a.date}}</td>
                                                </tr>

                                              </table>
                                                <table v-if="a.transaction_type == 'Info'">
                                                    <tr>
                                                        <td style="padding-right:25px;   width:160px;">Information</td>
                                                        <td>{{a.payment_message}}</td>
                                                    </tr>
                                                    <tr v-if="a.processedBy">
                                                        <td style="padding-right:25px;   width:160px;">Processed by:</td>
                                                        <td>{{a.processedBy}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-right:25px;   width:160px;">Date</td>
                                                        <td>{{a.date}}</td>
                                                    </tr>
                                                </table>
                                                <table v-if="a.transaction_type == 'Paid' || a.transaction_type == 'Correction'">
                                                    <tr>
                                                        <td style="padding-right:25px;   width:160px;">Payment Type</td>
                                                        <td>{{a.payment_type}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-right:25px;   width:160px;">Amount Due</td>
                                                        <td>{{a.amount_due_str}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-right:25px;   width:160px;">Amount Paid</td>
                                                        <td>{{a.amount_str}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-right:25px;   width:160px;">Date Paid</td>
                                                        <td>{{a.date}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-right:25px;  width:160px;">Amount Outstanding</td>
                                                        <td>{{a.amount_outstanding_str}}</td>
                                                    </tr>
                                                </table>
                                                <table v-if="a.transaction_type == 'EIC'">
                                                    <tr>
                                                        <td style="padding-right:25px;  width:160px;">Transaction</td>
                                                        <td>Excess Invoice Created</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-right:25px;  width:160px;">Amount Due</td>
                                                        <td>{{a.amount_due_str}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-right:25px;  width:160px;">Date</td>
                                                        <td>{{a.date}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-right:25px;  width:160px;">Processed by:</td>
                                                        <td>{{a.processedBy}}</td>
                                                    </tr>
                                                </table>
                                                <table v-if="a.transaction_type == 'CIC'">
                                                    <tr>
                                                        <td style="padding-right:25px;  width:160px;">Transaction</td>
                                                        <td>Complete Invoice Created</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-right:25px;  width:160px;">Amount Due</td>
                                                        <td>{{a.amount_due_str}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-right:25px;  width:160px;">Date</td>
                                                        <td>{{a.date}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-right:25px;  width:160px;">Processed by</td>
                                                        <td>{{a.processedBy}}</td>
                                                    </tr>
                                                </table>
                                                <table v-if="a.transaction_type == 'EIP'">
                                                    <tr>
                                                        <td style="padding-right:25px;  width:160px;">Transaction</td>
                                                        <td>Excess Invoice Payment Received</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-right:25px;  width:160px;">Payment Type</td>
                                                        <td>{{a.payment_type}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-right:25px;  width:160px;">Amount Due</td>
                                                        <td>{{a.amount_due_str}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-right:25px;  width:160px;">Amount Paid</td>
                                                        <td>{{a.amount_str}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-right:25px;  width:160px;">Date</td>
                                                        <td>{{a.date}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-right:25px;  width:160px;">Amount Outstanding</td>
                                                        <td>{{a.amount_outstanding_str}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-right:25px;  width:160px;">Processed by</td>
                                                        <td>{{a.processedBy}}</td>
                                                    </tr>
                                                </table>

                                                <table v-if="a.transaction_type == 'CIP'">
                                                    <tr>
                                                        <td style="padding-right:25px;  width:160px;">Transaction</td>
                                                        <td>Complete Invoice Payment Received</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-right:25px;  width:160px;">Payment Type</td>
                                                        <td>{{a.payment_type}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-right:25px;  width:160px;">Amount Due</td>
                                                        <td>{{a.amount_due_str}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-right:25px;  width:160px;">Amount Paid</td>
                                                        <td>{{a.amount_str}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-right:25px;  width:160px;">Date</td>
                                                        <td>{{a.date}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-right:25px;  width:160px;">Amount Outstanding</td>
                                                        <td>{{a.amount_outstanding_str}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style="padding-right:25px;  width:160px;">Processed by</td>
                                                        <td>{{a.processedBy}}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">

                            </div>
                        </div>

                    </div>
                    <div v-if="currentTab === 'notes'" id="card-notes">
                        <invoice-notes
                                ref="cardNotes"
                                :notes="notes"
                                :invoice_id="invoice.id"
                                @loadNotes="loadNotes">
                        </invoice-notes>
                    </div>
                </div>
            </div>
        </div>

        <modal-wizard ref="previewExcessInvoiceModal" cancelButtonText="Close" finishButtonText="Print PDF" @on-complete="downloadExcessInvoice">
            <modal-wizard-tab title="Preview Invoice" description="" title-icon-class="ti-align-right" :tabData="previewInvoice">
                <template slot-scope="props">
                    <div style="display: none">{{ sctollToTop() }}</div>

                    <div class="invoice">
                        <header class="invoice-header">
                            <div class="invoice-logo">
                                <img :src="vendorInfo.logo" style="height: 150px; width: 300px;" alt="">
                            </div>
                            <div class="invoice-info">
                                <h1 class="invoice-title">TAX INVOICE</h1>
                                <table class="header-table">
                                    <tr>
                                        <td class="font-14 font-bold">{{props.vendor.business_name}}</td>
                                    </tr>
                                    <tr>
                                        <td><span class="font-bold">ABN</span> : {{props.vendor.abn}}</td>
                                    </tr>
                                    <tr>
                                        <td>License : {{props.vendor.license_number}}</td>
                                    </tr>
                                    <tr v-if="props.vendor.postal_address">
                                        <td>{{props.vendor.postal_address}}</td>
                                    </tr>
                                    <tr v-if="!props.vendor.postal_address">
                                        <td>{{props.vendor.postal_street}} {{props.vendor.postal_suburb}}</td>
                                    </tr>
                                    <tr v-if="!props.vendor.postal_address">
                                        <td>{{props.vendor.postal_state}} {{props.vendor.postal_country}}</td>
                                    </tr>
                                    <tr>
                                        <td>Email : {{props.vendor.email}}</td>
                                    </tr>
                                    <tr>
                                        <td>Phone : {{props.vendor.phone_area_code}} {{props.vendor.phone}}</td>
                                    </tr>
                                </table>
                                <table class="header-table">
                                    <tr>
                                        <td><strong>Invoice To :</strong></td>
                                    </tr>
                                    <tr>
                                        <td>{{props.invoice.customer.name}}</td>
                                    </tr>
                                    <tr v-if="props.invoice.customer.address">
                                        <td>{{props.invoice.customer.address}}</td>
                                    </tr>
                                    <tr v-if="props.invoice.customer.address === null">
                                        <td>{{props.card.customer.street_name}} {{props.card.customer.suburb}}</td>
                                    </tr>
                                    <tr v-if="props.invoice.customer.address === null">
                                        <td>{{props.card.customer.state}} {{props.card.customer.country}}</td>
                                    </tr>
                                </table>

                                <table class="header-table">
                                    <tr>
                                        <td><strong>Invoice Number :</strong> <span>{{props.invoice.number}}</span></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Invoice Date :</strong> <span>{{props.invoice.created_date}}</span></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Due Date :</strong> <span>{{props.invoice.due_date}}</span></td>
                                    </tr>
                                </table>
                            </div>
                        </header>

                        <main class="main-flex">
                            <table class="main-table m0">
                                <thead>
                                <tr>
                                    <th colspan="2">Vehicle &amp; Owner</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th>Owner Details</th>
                                    <th>Vehicle Details</th>
                                </tr>
                                <tr>
                                    <td class="w50 vat">
                                        <table class="main-inner owner-details">
                                            <tr>
                                                <td>Owner Name</td>
                                                <td>{{props.card.customer.name}}</td>
                                            </tr>
                                            <tr>
                                                <td>Home Phone</td>
                                                <td>{{props.card.customer.phone}}</td>
                                            </tr>
                                            <tr>
                                                <td>Mobile Phone</td>
                                                <td>{{props.card.customer.mobile}}</td>
                                            </tr>
                                            <tr>
                                                <td>Email</td>
                                                <td>{{props.card.customer.email}}</td>
                                            </tr>
                                            <tr v-if="props.card.customer.customer_type == 'B'">
                                                <td><span class="font-bold">ABN</span></td>
                                                <td>{{props.card.customer.abn}}</td>
                                            </tr>
                                            <tr>
                                                <td>Insurer Name</td>
                                                <td>{{props.card.insurance.insurer_name}}</td>
                                            </tr>
                                            <tr>
                                                <td>Claim Number</td>
                                                <td>{{props.card.insurance.claim_number}}</td>
                                            </tr>

                                        </table>
                                    </td>
                                    <td class="w50 vat">
                                        <table class="vehicle-details">
                                            <tr>
                                                <td class="w50 vat">
                                                    <table class="main-inner">
                                                        <tr>
                                                            <td>Make</td>
                                                            <td>{{props.card.vehicle.make}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Model</td>
                                                            <td>{{props.card.vehicle.model}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Series</td>
                                                            <td v-if="!props.properties.isHideSeries">{{props.card.vehicle.series}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Transmission</td>
                                                            <td>{{props.card.vehicle.transmission}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Paint Code</td>
                                                            <td>{{props.card.vehicle.colour}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Date In</td>
                                                            <td>{{props.card.booking_date}}</td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td class="w50 vat">
                                                    <table class="main-inner" align="right">
                                                        <tr>
                                                            <td>Date Out</td>
                                                            <td>{{props.card.completion_date}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Rego</td>
                                                            <td>{{props.card.vehicle.rego_number}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>VIN Number</td>
                                                            <td>{{props.card.vehicle.vin}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Cylinders</td>
                                                            <td>{{props.card.vehicle.cylinders}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Build Date</td>
                                                            <td>{{props.card.vehicle.build_date}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Odometer</td>
                                                            <td>{{props.card.vehicle.odometer}}</td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <div>
                                <table class="main-table m0">
                                    <thead>
                                    <tr>
                                        <th>Charges</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                            <div>
                                <table class="main-table estimate-repairs m0">
                                    <thead class="bg-white th-fz12">
                                    <tr>
                                        <th>Description</th>
                                        <th class="text-center"></th>
                                        <th class="text-center"></th>

                                        <th class="text-center">Amount</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    <tr>
                                        <td>Excess</td>
                                        <td></td>
                                        <td></td>
                                        <td class="text-center" style="padding-left:15px">{{props.summary.Excess_str}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <table class="main-table  m0 divider-total">
                                <thead>
                                </thead>
                                <tbody>
                                <tr>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                            <table class="parts-total main-table main-total-table">
                                <tbody>

                                <tr>
                                    <td class="color-dark-grey"><strong>Total</strong></td>
                                    <td class="color-dark-grey"><strong>{{props.summary.Excess_str}}</strong></td>
                                </tr>
                                </tbody>
                            </table>
                        </main>
                        <footer>
                            <table class="footer-table">
                                <thead>
                                <tr>
                                    <th>Terms</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td v-html="props.terms"></td>
                                </tr>
                                </tbody>
                            </table>
                            <!-- <table class="footer-table">
                                <thead>
                                <tr>
                                    <th>Payment Options</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table> -->
                        </footer>
                    </div>
                </template>
            </modal-wizard-tab>
        </modal-wizard>

        <div class="fixed-size">
            <modal-wizard ref="previewInvoiceModal" cancelButtonText="Close" finishButtonText="Print PDF" @on-complete="downloadInvoice">
                <modal-wizard-tab title="Preview Invoice" description="" title-icon-class="ti-align-right" :tabData="previewInvoice">
                    <template slot-scope="props">
                        <div style="display: none">{{ sctollToTop() }}</div>

                        <div class="invoice">
                            <header class="invoice-header">
                                <div class="invoice-logo">
                                    <img :src="vendorInfo.logo" style="height: 150px; width: 300px;" alt="">
                                </div>
                                <div class="invoice-info">
                                    <h1 class="invoice-title">TAX INVOICE</h1>
                                    <table class="header-table">
                                        <tr>
                                            <td class="font-14 font-bold">{{props.vendor.business_name}}</td>
                                        </tr>
                                        <tr>
                                            <td><span class="font-bold">ABN</span> : {{props.vendor.abn}}</td>
                                        </tr>
                                        <tr>
                                            <td>License : {{props.vendor.license_number}}</td>
                                        </tr>
                                        <tr v-if="props.vendor.postal_address">
                                            <td>{{props.vendor.postal_address}}</td>
                                        </tr>
                                        <tr v-if="!props.vendor.postal_address">
                                            <td>{{props.vendor.postal_street}} {{props.vendor.postal_suburb}}</td>
                                        </tr>
                                        <tr v-if="!props.vendor.postal_address">
                                            <td>{{props.vendor.postal_state}} {{props.vendor.postal_country}}</td>
                                        </tr>
                                        <tr>
                                            <td>Email : {{props.vendor.email}}</td>
                                        </tr>
                                        <tr>
                                            <td>Phone : {{props.vendor.phone_area_code}} {{props.vendor.phone}}</td>
                                        </tr>
                                    </table>
                                    <table class="header-table">
                                        <tr>
                                            <td><strong>Invoice To:</strong></td>
                                        </tr>
                                        <tr v-if="isInvoiceToCustomer">
                                            <td>{{props.invoice.customer.name}}</td>
                                        </tr>
                                        <tr v-if="isInvoiceToCustomer && props.invoice.customer.address">
                                            <td>{{props.invoice.customer.address}}</td>
                                        </tr>
                                        <tr v-if="isInvoiceToCustomer && props.invoice.customer.address  === null">
                                            <td>{{props.card.customer.street_name}} {{props.card.customer.suburb}}</td>
                                        </tr>
                                        <tr v-if="isInvoiceToCustomer && props.invoice.customer.address  === null">
                                            <td>{{props.card.customer.state}} {{props.card.customer.country}}</td>
                                        </tr>
                                        <tr v-if="isInvoiceToInsurer">
                                            <td>{{props.invoice.insurer.name}}</td>
                                        </tr>
                                        <tr v-if="isInvoiceToInsurer">
                                            <td>{{props.invoice.insurer.address}}</td>
                                        </tr>
                                        <tr v-if="isInvoiceToInsurer">
                                            <td>ABN : {{props.invoice.insurer.abn}}</td>
                                        </tr>
                                    </table>

                                    <table class="header-table">
                                        <tr>
                                            <td><strong>Invoice Number :</strong> <span>{{props.invoice.number}}</span></td>
                                        </tr>
                                        <tr>
                                            <td><strong>Invoice Date :</strong> <span>{{props.invoice.created_date}}</span></td>
                                        </tr>
                                        <tr>
                                            <td><strong>Due Date :</strong> <span>{{props.invoice.due_date}}</span></td>
                                        </tr>
                                    </table>
                                </div>
                            </header>

                            <main>
                                <table class="main-table m0">
                                    <thead>
                                    <tr>
                                        <th colspan="2">Vehicle &amp; Owner</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr class="mobile-disabled">
                                        <th>Owner Details</th>
                                        <th>Vehicle Details</th>
                                    </tr>
                                    <tr>
                                        <td class="w50 vat">
                                            <table class="main-inner owner-details">
                                                <tr>
                                                    <td>Owner Name</td>
                                                    <td>{{props.card.customer.name}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Home Phone</td>
                                                    <td>{{props.card.customer.phone}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Mobile Phone</td>
                                                    <td>{{props.card.customer.mobile}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email</td>
                                                    <td>{{props.card.customer.email}}</td>
                                                </tr>
                                                <tr v-if="props.card.customer.customer_type == 'B'">
                                                    <td>ABN</td>
                                                    <td>{{props.card.customer.abn}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Insurer Name</td>
                                                    <td>{{props.card.insurance.insurer_name}}</td>
                                                </tr>
                                                <tr>
                                                    <td>Claim Number</td>
                                                    <td>{{props.card.insurance.claim_number}}</td>
                                                </tr>

                                            </table>
                                        </td>
                                        <td class="w50 vat">
                                            <table class="vehicle-details">
                                                <tr>
                                                    <td class="w50 vat">
                                                        <table class="main-inner">
                                                            <tr>
                                                                <td>Make</td>
                                                                <td>{{props.card.vehicle.make}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Model</td>
                                                                <td>{{props.card.vehicle.model}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Series</td>
                                                                <td v-if="!props.properties.isHideSeries">{{props.card.vehicle.series}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Transmission</td>
                                                                <td>{{props.card.vehicle.transmission}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Paint Code</td>
                                                                <td>{{props.card.vehicle.colour}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Date In</td>
                                                                <td>{{props.card.booking_date}}</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                    <td class="w50 vat">
                                                        <table class="main-inner">
                                                            <tr>
                                                                <td>Date Out</td>
                                                                <td>{{props.card.completion_date}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Rego</td>
                                                                <td>{{props.card.vehicle.rego_number}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>VIN Number</td>
                                                                <td>{{props.card.vehicle.vin}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Cylinders</td>
                                                                <td>{{props.card.vehicle.cylinders}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Build Date</td>
                                                                <td>{{props.card.vehicle.build_date}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Odometer</td>
                                                                <td>{{props.card.vehicle.odometer}}</td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                                <template v-for="item in props.all_line_items">
                                    <table class="main-table m0" v-if="item.isEstimate">
                                        <thead>
                                        <tr>
                                            <th>Estimate Reference: {{item.number}}</th>
                                        </tr>
                                        </thead>
                                    </table>
                                </template>

                                <template v-for="(supplement, index) in props.all_line_items">
                                    <div :data-class-modal-invoice="'invoice_parent_item' + supplement.id">
                                        <table class="main-table m0" v-if="props.all_line_items.length > 1 && !supplement.isEstimate">
                                            <thead>
                                            <tr>
                                                <th>Supplement Reference: {{supplement.number}}</th>
                                            </tr>
                                            </thead>
                                        </table>
                                        <div v-if="isExpandLabour(supplement.id)">
                                            <table class="main-table estimate-repairs m0" v-if="!!supplement.line_items.lines.RR.length">
                                                <thead class="bg-white th-fz12">
                                                <tr>
                                                    <th>R&amp;R</th>
                                                    <th class="text-center">{{ isEstimateMethodologyDollars ? '' : 'Hrs' }}</th>
                                                    <th class="text-center">{{ isEstimateMethodologyDollars ? '' : 'Rate' }}</th>

                                                    <th class="text-center">Amount</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="line in supplement.line_items.lines.RR">
                                                    <td class="font-bold">{{line.name}}</td>
                                                    <td class="text-center">{{isEstimateMethodologyDollars ? '' : line.hrs_str}}</td>
                                                    <td class="text-right">{{isEstimateMethodologyDollars ? '' : line.rate_str}}</td>

                                                    <td class="text-right">{{line.total_str}}</td>
                                                </tr>
                                                </tbody>
                                                <tfoot>
                                                <tr v-if="!isEstimateMethodologyDollars" class="rr-subtotal-hrs">
                                                    <td class="font-bold text-align-right">Subtotal Hrs</td>
                                                    <td class="text-center">{{supplement.line_items.summary["RR-Hrs-SubTotal-Str"]}}</td>
                                                    <td></td>

                                                    <td></td>
                                                </tr>
                                                <tr class="rr-subtotal-amount">
                                                    <td></td>

                                                    <td></td>
                                                    <td class="font-bold text-right">Subtotal Amount</td>
                                                    <td class="text-right  font-bold">{{supplement.line_items.summary["RR-Total-SubTotal-Str"]}}</td>
                                                </tr>
                                                </tfoot>
                                            </table>
                                            <table class="main-table estimate-repairs m0" v-if="!!supplement.line_items.lines.Repair.length">
                                                <thead class="bg-white th-fz12">
                                                <tr>
                                                    <th>Repair</th>
                                                    <th class="text-center">{{ isEstimateMethodologyDollars ? '' : 'Hrs' }}</th>
                                                    <th class="text-center">{{ isEstimateMethodologyDollars ? '' : 'Rate' }}</th>

                                                    <th class="text-center">Amount</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="line in supplement.line_items.lines.Repair">
                                                    <td class="font-bold">{{line.name}}</td>
                                                    <td class="text-center">{{isEstimateMethodologyDollars ? '' : line.hrs_str}}</td>
                                                    <td class="text-right">{{isEstimateMethodologyDollars ? '' : line.rate_str}}</td>

                                                    <td class="text-right">{{line.total_str}}</td>
                                                </tr>
                                                </tbody>
                                                <tfoot>
                                                <tr v-if="!isEstimateMethodologyDollars" class="rr-subtotal-hrs">
                                                    <td class="font-bold text-align-right">Subtotal Hrs</td>
                                                    <td class="text-center">{{supplement.line_items.summary["Repair-Hrs-SubTotal-Str"]}}</td>
                                                    <td></td>

                                                    <td></td>
                                                </tr>
                                                <tr class="rr-subtotal-amount">
                                                    <td></td>

                                                    <td></td>
                                                    <td class="font-bold text-right">Subtotal Amount</td>
                                                    <td class="text-right  font-bold">{{supplement.line_items.summary["Repair-Total-SubTotal-Str"]}}</td>
                                                </tr>
                                                </tfoot>
                                            </table>
                                            <table class="main-table estimate-repairs m0" v-if="!!supplement.line_items.lines.Paint.length">
                                                <thead class="bg-white th-fz12">
                                                <tr>
                                                    <th>Paint</th>
                                                    <th class="text-center">Paint Material</th>
                                                    <th class="text-center">{{ isEstimateMethodologyDollars ? '' : 'Hrs' }}</th>
                                                    <th class="text-center">{{ isEstimateMethodologyDollars ? '' : 'Rate' }}</th>

                                                    <th class="text-center">Amount</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="line in supplement.line_items.lines.Paint">
                                                    <td class="font-bold">{{line.name}}</td>
                                                    <td class="text-center">{{line.paint_type_str}}</td>
                                                    <td class="text-center">{{isEstimateMethodologyDollars ? '' : line.hrs_str}}</td>
                                                    <td class="text-right">{{isEstimateMethodologyDollars ? '' : line.rate_str}}</td>

                                                    <td class="text-right">{{line.total_str}}</td>
                                                </tr>
                                                </tbody>
                                                <tfoot>
                                                <tr v-if="!isEstimateMethodologyDollars" class="paint-subtotal-hrs">
                                                    <td colspan="2" class="font-bold text-right">Subtotal Hrs</td>
                                                    <td class="text-center">{{supplement.line_items.summary["Paint-Hrs-SubTotal-Str"]}}</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr class="paint-subtotal-amount">
                                                    <td></td>

                                                    <td></td>
                                                    <td colspan="2" class="text-right font-bold">Subtotal Amount</td>
                                                    <td class="text-center">{{supplement.line_items.summary["Paint-Total-SubTotal-Str"]}}</td>
                                                </tr>
                                                </tfoot>
                                            </table>
                                            <table class="repairs-total main-table main-total-table"
                                                   v-if="(!!supplement.line_items.lines.RR.length || !!supplement.line_items.lines.Repair.length || !!supplement.line_items.lines.Paint.length)">
                                                <tbody>
                                                <tr v-if="!isEstimateMethodologyDollars">
                                                    <td class="font-bold text-right">Total Labour Hours</td>
                                                    <td class="text-right">{{supplement.line_items.summary["Labour-Hrs-Str"]}}</td>
                                                </tr>
                                                <tr>
                                                    <td class="font-bold text-right">Subtotal Amount</td>
                                                    <td class="text-right font-bold">{{supplement.line_items.summary["Labour-Cost-Str"]}}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <table class="main-table estimate-parts border-t" v-if="!!supplement.line_items.lines.Materials.length">
                                                <thead class="th-fz12">
                                                <tr>
                                                    <th>Materials</th>
                                                    <th></th>
                                                    <th></th>
                                                    <th class="text-center">Hrs</th>
                                                    <th class="text-center">Amount</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="line in supplement.line_items.lines.Materials">
                                                    <td class="font-bold">{{line.name}}</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td class="text-center">{{line.hrs_str}}</td>
                                                    <td class="text-right">{{line.total_str}}</td>
                                                </tr>
                                                </tbody>
                                                <tfoot>
                                                <tr class="parts-subtotal-hrs">
                                                    <td></td>
                                                    <td></td>
                                                    <td class="font-bold text-right" colspan="2">Subtotal Amount</td>
                                                    <td class="text-right font-bold">{{supplement.line_items.summary["Materials-Total-Str"]}}</td>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div v-if="!isExpandLabour(supplement.id)">
                                            <table class="main-table estimate-parts border-t" v-if="supplement.line_items.summary['Labour-Cost-With-Materials'] > 0">
                                                <thead class="th-fz12">
                                                <tr>
                                                    <th class="text-left">Repairs</th>
                                                    <th class="text-align-center"></th>
                                                    <th class="text-align-center"></th>
                                                    <th class="text-align-center"></th>
                                                    <th class="text-align-center">Amount</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td class="font-bold">Labour As Per Authority</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td class="text-align-right">{{supplement.line_items.summary['Labour-Cost-With-Materials-Str']}}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div v-if="isExpandPart(supplement.id)">
                                            <table class="main-table estimate-parts border-t" v-if="!!supplement.line_items.lines.Part.length">
                                                <thead class="th-fz12">
                                                <tr>
                                                    <th>Parts</th>
                                                    <th>Part No</th>
                                                    <th class="text-align-center">Qty</th>
                                                    <th class="text-align-center">Unit Price</th>
                                                    <th class="text-align-center">Amount</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(line, index) in supplement.line_items.lines.Part" :key="`${line.part_num}-${index}`">
                                                    <td class="font-bold">{{line.name}}</td>
                                                    <td class="font-bold">{{line.part_num}}</td>
                                                    <td class="font-bold text-align-center">{{line.qty}}</td>
                                                    <td class="text-align-right">{{line.rate_str}}</td>
                                                    <td class="text-align-right">{{line.total_str}}</td>
                                                </tr>
                                                </tbody>
                                                <tfoot>
                                                <tr class="parts-subtotal-hrs">
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td class="font-bold text-align-right">Subtotal Amount</td>
                                                    <td class="text-align-right">{{supplement.line_items.summary["Part-Total-SubTotal-Str"]}}</td>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div v-if="!isExpandPart(supplement.id)">
                                            <table class="table main-table tab-special mb-0" v-if="supplement.line_items.summary['Part-Cost'] > 0">
                                                <thead>
                                                <tr>
                                                    <th class="text-left">Parts</th>
                                                    <th class="text-center"></th>
                                                    <th class="text-center"></th>
                                                    <th class="text-center"></th>
                                                    <th class="text-center">Amount</th>
                                                </tr>
                                                </thead>
                                                <tr class="qtit">
                                                    <td>Parts</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>{{supplement.line_items.summary['Part-Cost-Str']}}</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <!--Other-->
                                        <div v-if="isExpandOther(supplement.id)">
                                            <table class="main-table estimate-other border-t" v-if="!!supplement.line_items.lines.Other.length">
                                                <thead class="th-fz12">
                                                <tr>
                                                    <th>Other</th>
                                                    <th class="text-right">Amount</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(line, index) in supplement.line_items.lines.Other" :key="index">
                                                    <td class="font-bold">{{line.name}}</td>
                                                    <td class="text-align-right">{{line.total_str}}</td>
                                                </tr>
                                                </tbody>
                                                <tfoot>
                                                <tr class="paint-subtotal-amount">
                                                    <td class="font-bold text-align-right" style="width: 750px">Subtotal Amount</td>
                                                    <td class="text-align-right">{{supplement.line_items.summary["Other-Total-SubTotal-Str"]}}</td>
                                                </tr>
                                                </tfoot>
                                            </table>
                                            <table class="main-table estimate-sublet m0 border-t" v-if="!!supplement.line_items.lines.Sublet.length">
                                                <thead class="th-fz12">
                                                <tr>
                                                    <th>Sublet</th>
                                                    <th class="text-center">Repairer</th>
                                                    <th class="text-center">Cost</th>
                                                    <th class="text-center">Amount</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(line, index) in supplement.line_items.lines.Sublet" :key="index">
                                                    <td class="font-bold">{{line.name}}</td>
                                                    <td class="text-center">{{line.repairer}}</td>
                                                    <td class="text-right">{{line.rate_str}}</td>
                                                    <td class="text-right">{{line.total_str}}</td>
                                                </tr>
                                                </tbody>
                                                <tfoot>
                                                <tr class="sublet-subtotal-amount">
                                                    <td class="text-right font-bold" colspan="3">Subtotal Amount</td>
                                                    <td class="text-center">{{supplement.line_items.summary["Sublet-Total-SubTotal-Str"]}}</td>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div v-if="!isExpandOther(supplement.id)">
                                            <table class="table main-table tab-special mb-0" v-if="supplement.line_items.summary['Other-Cost'] > 0">
                                                <thead>
                                                <tr>
                                                    <th class="text-left">Sublet & Other</th>
                                                    <th class="text-center"></th>
                                                    <th class="text-center"></th>
                                                    <th class="text-center">Amount</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr class="qtit">
                                                    <td>Sublet & Other</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td class="text-right">{{supplement.line_items.summary['Other-Cost-Str']}}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!--                                    PDR-->
                                        <div v-if="isExpandPDR(supplement.id)">
                                            <table class="main-table estimate-sublet m0 border-t"
                                                   v-if="supplement.line_items.lines.PDR && supplement.line_items.lines.PDR.length>0">
                                                <thead class="th-fz12">
                                                <tr>
                                                    <th>PDR</th>
                                                    <th class="text-center">Material</th>
                                                    <th class="text-center">Action</th>
                                                    <th class="text-center">Qty</th>
                                                    <th class="text-center">Price</th>
                                                    <th class="text-center">Amount</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(line, index) in supplement.line_items.lines.PDR" :key="index">
                                                    <td>
                                                        <div style="white-space: pre-line;">{{line.name}}</div>
                                                        <div v-if="line.comment">Comment : {{line.comment}}</div>
                                                    </td>
                                                    <td class="text-center align-text-top">
                                                        <template v-if="line.type === 'Panel'">{{ line.material|valuePDRMaterial }}</template>
                                                    </td>
                                                    <td class="text-center  align-text-top">
                                                        <template v-if="line.type === 'Panel'">{{ line.action|valuePDRAction }}</template>
                                                    </td>
                                                    <td class="text-center  align-text-top">{{ line.qty }}</td>
                                                    <td class="text-right  align-text-top">{{ line.price_str }}</td>
                                                    <td class="text-right  align-text-top">{{ line.total_str }}</td>
                                                </tr>
                                                </tbody>
                                                <tfoot>
                                                <tr class="paint-subtotal-amount">
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td class="font-bold text-right">Subtotal Amount</td>
                                                    <td class="text-right font-bold">{{supplement.line_items.summary["PDR-Total-SubTotal-Str"]}}</td>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div v-if="!isExpandPDR(supplement.id)">
                                            <table class="table main-table tab-special mb-0" v-if="supplement.line_items.summary['PDR-Cost'] > 0">
                                                <thead>
                                                <tr>
                                                    <th class="text-left">PDR</th>
                                                    <th class="text-center"></th>
                                                    <th class="text-center"></th>
                                                    <th class="text-center">Amount</th>
                                                </tr>
                                                </thead>
                                                <tr class="qtit">
                                                    <td>PDR</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>{{supplement.line_items.summary['PDR-Cost-Str']}}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="card invoice-view ex-card ex-quote-information no-border" :class="{'spacing-of-est-and-supp': index + 1 != all_line_items.length}">
                                        <div class="card-block bg-white result_of_total_amount">
                                            <table class="parts-total main-table main-total-table latest_amount_table">
                                                <tbody>
                                                <tr style="font-weight: bold; font-size: 13px;">
                                                    <td class="font-bold text-right">Total</td>
                                                    <td class="text-right">{{supplement.line_items.summary.Total_str}}</td>
                                                </tr>
                                                <tr style="font-weight: bold; font-size: 13px;">
                                                    <td class="font-bold text-right border-bottom">GST</td>
                                                    <td class="text-right border-bottom">{{supplement.line_items.summary.GST_str}}</td>
                                                </tr>
                                                <tr style="font-weight: bold; font-size: 13px;">
                                                    <td class="font-bold text-right">Total (Inc GST)</td>
                                                    <td class="text-right">{{supplement.line_items.summary.GrandTotal_str}}</td>
                                                </tr>
                                                <!--                                                <tr v-if="countOfEstimateAndSupps == 1 && summary.isInsurance && !!summary.Excess">-->
                                                <!--                                                    <td class="font-bold text-right">Less Excess</td>-->
                                                <!--                                                    <td class="text-right">{{summary.Excess_str}}</td>-->
                                                <!--                                                </tr>-->
                                                <!--                                                <tr v-if="countOfEstimateAndSupps == 1 && summary.isInsurance && !!summary.Discount">-->
                                                <!--                                                    <td class="font-bold text-right">Discount Amount</td>-->
                                                <!--                                                    <td class="text-right">{{summary.Discount_str}}</td>-->
                                                <!--                                                </tr>-->
                                                <!--                                                <tr v-if="countOfEstimateAndSupps == 1 && summary.isInsurance && (!!summary.Discount || !!summary.Excess)" class="latest_amount">-->
                                                <!--                                                    <td class="font-bold text-right">Total Invoice</td>-->
                                                <!--                                                    <td class="text-right">{{summary.TotalInvoice_str}}</td>-->
                                                <!--                                                </tr>-->
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </template>
                                <table class="main-table  m0 divider-total">
                                    <thead>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td></td>
                                    </tr>
                                    </tbody>
                                </table>
                                <table class="parts-total main-table main-total-table">
                                    <tbody>
                                    <tr v-if="countSubtotal > 1  && countOfEstimateAndSupps > 1">
                                        <td class="font-bold text-right">Combined Sub-total</td>
                                        <td class="text-right">{{props.summary.Total_str}}</td>
                                    </tr>
                                    <tr v-if="countSubtotal > 1  && countOfEstimateAndSupps > 1">
                                        <td class="font-bold text-right border-bottom">GST</td>
                                        <td class="text-right border-bottom">{{props.summary.GST_str}}<i v-if="(properties?.isGSTCorrected && properties?.isForEstimage)" data-tooltip="the GST was corrected by matching on the Estimage side" style="font-size: 11px" class='bx bx-error-circle gst-icon-exclamation'></i></td>
                                    </tr>
                                    <template>
                                        <tr v-if="countOfEstimateAndSupps > 1 && summary.isInsurance && !!summary.Excess">
                                            <td class="font-bold text-right">Combined Total (Inc GST)</td>
                                            <td class="text-right"><strong>{{props.summary.GrandTotal_str}}</strong></td>
                                        </tr>
                                        <tr v-else-if="countOfEstimateAndSupps > 1 && !summary.Excess">
                                            <td class="font-bold text-right">GRAND TOTAL (Inc GST)</td>
                                            <td class="text-right"><strong>{{props.summary.GrandTotal_str}}</strong></td>
                                        </tr>
                                    </template>
                                    <template v-if="(summary.isInsurance && !!summary.Discount) || (summary.isInsurance && !!summary.Excess)">
                                        <tr v-if="summary.isInsurance && !!summary.Excess">
                                            <td class="font-bold text-right">Less Excess</td>
                                            <td class="text-right">{{summary.Excess_str}}</td>
                                        </tr>
                                        <tr v-if="summary.isInsurance && !!summary.Discount">
                                            <td class="font-bold text-right">Discount Amount</td>
                                            <td class="text-right">{{summary.Discount_str}}</td>
                                        </tr>
                                        <tr v-if="summary.isInsurance && (!!summary.Discount || !!summary.Excess)">
                                            <td class="font-bold text-right"><strong>GRAND TOTAL Invoice</strong></td>
                                            <td class="text-right"><strong>{{summary.TotalInvoice_str}}</strong></td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>

                            </main>
                            <footer>
                                <table class="footer-table">
                                    <thead>
                                    <tr>
                                        <th>Terms</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td v-html="props.terms"></td>
                                    </tr>
                                    </tbody>
                                </table>
                                <!-- <table class="footer-table">
                                    <thead>
                                    <tr>
                                        <th>Payment Options</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td></td>
                                    </tr>
                                    </tbody>
                                </table> -->
                            </footer>
                        </div>
                    </template>
                </modal-wizard-tab>
            </modal-wizard>
        </div>
        <div class="receipt-additional">
            <modal-wizard ref="previewReceiptModal" cancelButtonText="Close" finishButtonText="Print PDF" @on-complete="downloadReceipt">
                <modal-wizard-tab title="Preview Receipt" description="" title-icon-class="ti-align-right" :tabData="previewReceipt">
                    <template slot-scope="props">
                        <div style="display: none">{{ sctollToTop() }}</div>

                        <div class="invoice" v-html="props.html">

                        </div>

                    </template>
                </modal-wizard-tab>
            </modal-wizard>
        </div>

        <modal-wizard id="voidInvoiceModal" ref="voidInvoiceModal" cancelButtonText="Close" finishButtonText="Void Invoice" @on-complete="completeVoidInvoice">
            <modal-wizard-tab title="Void Invoice" description="" title-icon-class="ti-align-right" :tabData="card">
                <template slot-scope="props">
                    <div>Are you sure you want to delete this Invoice?</div>
                </template>
            </modal-wizard-tab>
        </modal-wizard>
    </div>
</template>
<style scoped>
    .V3 label.radio > input[type="radio"]:checked:disabled + span.icon i {
      color: white;
    }

    .V3 label.radio > input[type="radio"]:checked + span.icon {
      background-color: #5E79FF;
}
</style>

<style scoped>
    [data-tooltip] {
      position: relative; /* Относительное позиционирование */
    }
    [data-tooltip]::after {
      content: attr(data-tooltip);
      position: absolute;
      left: 10px; top: 20px;
      width: 200px;
      line-height: 1.5;
      background: #fdfdfd;
      color: #000000;
      padding: 10px 5px;
      border-radius: 0 5px 5px 5px;
      font-weight: bold;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
      pointer-events: none;
      opacity: 0;
      transition: 1s;
      text-align: center;
      z-index: 10;
      font-size: 13px;
      font-family: "Nunito Sans", sans-serif;
    }
    [data-tooltip]:hover::after {
      opacity: 1; /* Показываем подсказку */
      top: 1em; /* Положение подсказки */
    }
    .gst-icon-exclamation{
      font-size: 15px;
      position: absolute;
      bottom: 17px;
      right: -5px;
    }
    .V3 label.radio > input[type="radio"]:checked:disabled + span.icon i {
      color: white;
    }

    .V3 label.radio > input[type="radio"]:checked + span.icon {
      background-color: #5E79FF;
    }
    .spacing-of-sections {
        margin-bottom: 20px;
    }

    .spacing-of-est-and-supp {
        margin-bottom: 40px !important;
    }

    .grand-total-text {
        width: 100%;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        white-space: nowrap;
    }

    .vue-form-wizard.xs {
        padding-bottom: 0px !important;
        overflow: auto;
    }

    .wizard-nav {
        overflow: hidden;
    }

    .vue-form-wizard .wizard-nav-pills a, .vue-form-wizard .wizard-nav-pills li {
        max-width: 89px;
    }

    .vue-form-wizard.xs .wizard-icon-circle {
        width: 30px !important;
        height: 30px !important;
    }

    #activity .vue-form-wizard .wizard-tab-content {
        min-height: 1px;
        padding-bottom: 0;
        border-top: 1px solid transparent;
        margin-top: 0px;
        padding: 5px;
    }

    .wizard-icon.ti-check {
        font-weight: bold;
    }

    .vue-form-wizard.xs .wizard-navigation .wizard-progress-with-circle {
        height: 2px !important;
        top: 19px !important;
    }

    .vue-form-wizard.xs .wizard-nav-pills > li.active > a .wizard-icon {
        font-size: 12px !important;
        color: #5E79FF;
    }

    .wizard-navigation {
        overflow-x: auto;
    }

    .vue-form-wizard .wizard-icon-circle .wizard-icon-container {
        border-radius: 50%;
    }

    .revert-all-children * {
        all: revert;
    }

    .no-border {
        border: none;
    }

    .top_section_invoice_vehicle_row
    .col_invoice
    .ex-card,
    .top_section_invoice_vehicle_row
    .col_vehicle
    .ex-card {
        height: 100%;
    }

    .top_section_invoice_vehicle_row .col_invoice {
        width: calc(25% - 10px);
    }

    .top_section_invoice_vehicle_row .col_vehicle {
        width: calc(75% - 10px);
    }

    .top_section_invoice_vehicle_row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    @media screen and (max-width: 1200px) {
        .top_section_invoice_vehicle_row {
            flex-direction: column;
        }

        .top_section_invoice_vehicle_row .col_invoice,
        .top_section_invoice_vehicle_row .col_vehicle {
            width: 100%;
        }
    }

    .result_of_total_amount > table {
        width: 70vw;
        max-width: 280px;
    }

    .result_of_total_amount {
        text-align: -webkit-right;
    }

    .latest_amount_table tbody > tr {
        margin-bottom: 10px;
        text-align: right;
        /* display: flex;
        align-items: center;
        justify-content: space-between; */
    }

    .latest_amount_table .latest_amount > td:last-child {
        font-size: 23px;
        font-weight: 600;
        color: #000000;
    }

    .latest_amount_table .latest_amount > td:first-child {
        color: #000000;
    }

    .latest_amount_table .latest_amount {
        border-top-width: 1px;
        border-top-style: solid;
        position: relative;
        border-top-color: rgba(28, 31, 57, 0.25);
        margin-top: 19px;
        padding-top: 10px;
    }

    .latest_amount_table .latest_amount {
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgba(28, 31, 57, 0.25);
    }
</style>

<style>
    @media(max-width: 427px) {
      #voidInvoiceModal .modal-footer {
        padding-left: 10px !important;
        justify-content: center;
        display: flex;
        padding-right: 10px;
      }
    }

    @media(max-width: 361px) {
      #voidInvoiceModal .modal-footer {
        flex-direction: column;
        padding-right: 15px;
        padding-left: 15px !important;
      }

      #voidInvoiceModal .modal-footer #finishModalWizardButton {
        margin-bottom: 10px;
        margin-right: 0px !important;
      }
    }

    .main-table.divider-total {
        border-top: 1px solid rgba(27, 30, 56, 0.25);
    }

    .main-table.divider-total td {
        padding-top: 0px;
        margin-top: 0px;
        padding-bottom: 0px;
        height: 1px;
    }

    #voidInvoiceModal .modal-footer {
        margin-top: 25px;
    }

    #voidInvoiceModal .modal-content {
        margin-left: 20%;
        width: 60%;
    }

    .act-dd.act-dd-right {
        right: -9px !important;
    }

    .srt-dd-right {
        right: 25px !important;
        max-width: 130px;
        width: 130px;
    }

    .activity-list-header {
        padding: 15px;
    }

    .srt-dd-right.dropdown-menu i {
        position: relative;
        top: 0px;
        display: inline-block;
        padding-right: 5px;
    }

    .btn-outline-primary-light {
        color: #5883CC !important;
        border-color: #5883CC !important;
    }

    .btn-outline-primary-light:hover {
        color: #FFFFFF !important;
        background: #5883CC !important;
        border-color: #5883CC !important;
    }

    .border-top {
        border: 0.5px solid #DCDCDC;
    }

    .dropzoneContainer {
        margin-bottom: 15px;
    }

    .btnNotBorder {
        border-color: #FAFAFA !important;
    }

    .btnNotBorder:hover {
        background-color: #FAFAFA !important;
        color: #142742 !important;
    }

    .btnNotBorder .fa-sort-down {
        left: unset !important;
        margin: 0 !important;
        top: 5px !important;
        font-size: 14px !important;
        width: 20px !important;
        height: auto !important;
    }

    .dropdownLinks-container {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
        position: absolute !important;
        border: none !important;
        background-color: #FAFAFA !important;
        right: 20px !important;
        top: 28px !important;
        height: auto !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    .dropdownLinks-links {
        position: relative;
        background: white;
        border: 1px solid rgba(0, 0, 0, .25) !important;
    }

    .dropdownLinks-links:after,
    .dropdownLinks-links:before {
        bottom: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .dropdownLinks-links:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #FFFFFF;
        border-width: 5px;
        right: 12px;
        background-clip: padding-box;
    }

    .dropdownLinks-links:before {
        border-color: rgba(184, 184, 184, 0);
        border-bottom-color: #B8B8B8;
        border-width: 6px;
        right: 11px;
        background-clip: padding-box;
    }

    .activtyPopupIcon {
        position: unset !important;
        margin: 0 !important;
        font-size: 10px !important;
    }

    .autosave {
        color: #142742;
        font-size: 13px;
        margin-left: 100px;
    }

    .invoice {
        width: 950px;
        margin: 25px auto
    }

    .invoice-header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .invoice .main-table {
        margin: 0 0 20px;
        width: 100%;
        border-collapse: collapse
    }

    .invoice .main-table tr.empty-row td, .invoice .main-table tr.empty-row th {
        height: 20px
    }

    .invoice .main-table .vehicle-details {
        width: 100%
    }

    .invoice .parts-required {
        margin-bottom: 0
    }

    .invoice .parts-required td:nth-child(1), .invoice .parts-required th:nth-child(1) {
        width: 355px
    }

    .invoice .parts-required td:last-child, .invoice .parts-required th:last-child {
        width: 70px
    }

    .invoice .estimate-repairs td:first-child, .invoice .estimate-repairs th:first-child {
        width: 550px
    }

    .invoice .estimate-repairs td:nth-child(2), .invoice .estimate-repairs th:nth-child(2) {
        width: 55px
    }

    .invoice .estimate-repairs td:last-child, .invoice .estimate-repairs th:last-child {
        width: 70px
    }

    .invoice .estimate-other td:first-child, .invoice .estimate-other th:first-child {
        width: 550px
    }

    .invoice .estimate-other td:nth-child(2), .invoice .estimate-other th:nth-child(2) {
        width: 55px
    }

    .invoice .estimate-other td:last-child, .invoice .estimate-other th:last-child {
        width: 70px
    }

    .invoice .estimate-sublet td:first-child, .invoice .estimate-sublet th:first-child {
        width: 605px
    }

    .invoice .estimate-sublet td:last-child, .invoice .estimate-sublet th:last-child {
        width: 70px
    }

    .invoice .estimate-parts td:first-child, .invoice .estimate-parts th:first-child {
        width: 400px
    }

    .invoice .estimate-parts td:nth-child(2), .invoice .estimate-parts th:nth-child(2) {
        width: 150px
    }

    .invoice .estimate-parts td:nth-child(3), .invoice .estimate-parts th:nth-child(3) {
        width: 55px
    }

    .invoice .estimate-parts td:last-child, .invoice .estimate-parts th:last-child {
        width: 70px
    }

    .invoice .parts-total td:last-child {
        width: 70px;
        padding-left: 15px
    }

    .invoice .tax-subtotal-hrs td:first-child {
        width: 560px
    }

    footer {
        clear: both
    }

    .footer-table {
        width: 100%;
        border-top: 1px solid silver;
        border-bottom: 1px solid silver
    }

    .footer-table td, .footer-table th {
        text-align: left
    }

    .footer-table th {
        padding: 16px 8px 10px;
        font-size: 12px
    }

    .footer-table td {
        padding: 0 8px 16px;
        font-size: 11px;
        color: #595959
    }

    .invoice-view .main-table thead {
        background: #F2F2F2
    }

    .V3 .invoice-view .main-table thead th {
        padding: .75rem 10px;
    }

    .V3 .invoice-view-page .receipt-additional .modal .invoice {
        margin: 25px -15px;
    }

    @media screen and (min-width: 991px) {
        .V3 .invoice-view-page .fixed-size .modal {
            left: 18% !important;
        }

        .V3 .invoice-view-page .fixed-size .modal {
            left: 18% !important;
        }
    }

    @media screen and (max-width: 991px) {
        .V3 .invoice-view-page .receipt-additional .modal .invoice {
            width: 540px;
            margin: 25px -15px;
        }

        .V3 .invoice-view-page .receipt-additional .modal .invoice div {
            padding-left: 0;
        }

        .V3 .invoice-view-page .receipt-additional .modal .invoice .main-table:first-child tbody tr {
            display: flex;
            flex-direction: column;
        }

        .V3 .invoice-view-page .receipt-additional .modal .invoice-header table tbody tr {
            display: flex;
            flex-direction: column;
            word-break: break-all;
        }

        .V3 .invoice-view-page .receipt-additional .modal .invoice .main-table:first-child tbody {
            display: flex;
            flex-direction: column;
        }

        .V3 .invoice-view-page .receipt-additional .modal .invoice .main-inner tbody tr {
            flex-direction: row !important;
        }

        .V3 .invoice-view-page .receipt-additional .modal .invoice .vehicle-details .w50:last-child {
            width: 56%;
        }
    }

    .invoice-view .main-table td {
        padding: .75rem 15px;
    }


    .invoice-view .main-table tfoot td {
        text-align: right;
    }

    #card-details .total {
        padding: 1rem 0;
        font-size: 1.3rem;
        font-weight: bold;
    }

    .card-in-card {
        margin-bottom: 0;
    }

    .card-in-card .card-header {
        font-weight: bold;
    }

    .top_header_invoice .expand_button .fa-angel-down:before {
        content: "\f107";
    }

    .top_header_invoice .expand_button .fa-angel-up:before {
        content: "\f106";
    }

    .top_header_invoice .expand_amount strong {
        margin-left: 10px;
    }

    .top_header_invoice .expand_amount {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .top_header_invoice .expand_button {
        cursor: pointer;
        padding-right: 25px;
    }

    .top_header_invoice {
        /*    background-color: #F2F2F2;*/
        /*     padding: 10px 10px;*/
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }

    .top_section_invoice_vehicle_row
    .col_invoice
    .ex-card,
    .top_section_invoice_vehicle_row
    .col_vehicle
    .ex-card {
        height: 100%;
    }

    .top_section_invoice_vehicle_row
    .col_invoice .ex-invoice-information
    .card-block {
        margin-top: -1px !important;
        height: calc(100% - 50px);
    }

    .top_section_invoice_vehicle_row
    .col_vehicle
    .own_vehicle_section
    .card_owner_details {
        width: 30%;
    }

    .top_section_invoice_vehicle_row
    .col_vehicle
    .own_vehicle_section
    .card_vehicle_details {
        width: 70%;
    }

    .top_section_invoice_vehicle_row
    .col_vehicle .card,
    .top_section_invoice_vehicle_row
    .col_vehicle
    .own_vehicle_section
    .card_vehicle_details .card,
    .top_section_invoice_vehicle_row
    .col_invoice .card {
        border: 0px !important;
    }

    .top_section_invoice_vehicle_row .col_vehicle .own_vehicle_section {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        height: calc(100% - 50px);
        margin-top: -1px;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    @media screen and (max-width: 576px) {
        .top_section_invoice_vehicle_row
        .col_vehicle
        .own_vehicle_section
        .card_owner_details,
        .top_section_invoice_vehicle_row
        .col_vehicle
        .own_vehicle_section
        .card_vehicle_details {
            width: 100%;
            border: 0;
        }

        .V3 .invoice-view-page .receipt-additional .modal-body {
            padding: 0;
        }

        .V3 .invoice-view-page .receipt-additional .modal-body .tab-content {
            padding: 0;
        }

        .V3 .invoice-view-page .receipt-additional .modal .invoice {
            width: 100%;
            padding: 0;
            margin: 0;
        }

        .V3 .invoice-view-page .receipt-additional .modal .invoice main {
            width: 100%;
        }

        .V3 .invoice-view-page .receipt-additional .modal .invoice .invoice-logo img {
            width: 80% !important;
            object-fit: cover;
        }

        .V3 .invoice-view-page .receipt-additional .modal .invoice .vehicle-details .w50:last-child {
            width: 138px;
        }
    }

    .top_section_invoice_vehicle_row .col_invoice {
        width: calc(25% - 10px);
    }

    .top_section_invoice_vehicle_row .col_vehicle {
        width: calc(75% - 10px);
    }

    .top_section_invoice_vehicle_row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-bottom: 15px;
    }

    @media screen and (max-width: 1200px) {
        .top_section_invoice_vehicle_row {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
        }

        .top_section_invoice_vehicle_row .col_invoice,
        .top_section_invoice_vehicle_row .col_vehicle {
            width: 100%;
        }
    }

    .result_of_total_amount > table {
        width: 70vw;
        max-width: 280px;
    }

    .result_of_total_amount {
        display: flex;
        display: -webkit-box;
        display: -ms-flexbox;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: flex-end;
    }
</style>
<style scoped>
    .tab-content {
        padding-top: 10px;
        padding-left: 15px;
    }

    .no-border {
        border: none;
    }

    .top_section_invoice_vehicle_row
    .col_invoice
    .ex-card,
    .top_section_invoice_vehicle_row
    .col_vehicle
    .ex-card {
        height: 100%;
    }

    .top_section_invoice_vehicle_row .col_invoice {
        width: calc(25% - 10px);
    }

    .top_section_invoice_vehicle_row .col_vehicle {
        width: calc(75% - 10px);
    }

    .top_section_invoice_vehicle_row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    @media screen and (max-width: 1200px) {
        .top_section_invoice_vehicle_row {
            flex-direction: column;
        }

        .top_section_invoice_vehicle_row .col_invoice,
        .top_section_invoice_vehicle_row .col_vehicle {
            width: 100%;
        }
    }

    .result_of_total_amount > table {
        width: 70vw;
        max-width: 280px;
    }

    .result_of_total_amount {
        text-align: -webkit-right;
        border-top: 1px solid rgba(27, 30, 56, 0.25);
    }

    .latest_amount_table tbody > tr {
        margin-bottom: 10px;
        text-align: right;
        /* display: flex;
        align-items: center;
        justify-content: space-between; */
    }

    .latest_amount_table .latest_amount > td:last-child {
        font-size: 23px;
        font-weight: 600;
        color: #000000;
    }

    .latest_amount_table .latest_amount > td:first-child {
        color: #000000;
    }

    .latest_amount_table .latest_amount {
        border-top-width: 1px;
        border-top-style: solid;
        position: relative;
        border-top-color: rgba(28, 31, 57, 0.25);
        margin-top: 19px;
        padding-top: 10px;
    }

    .latest_amount_table .latest_amount {
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgba(28, 31, 57, 0.25);
    }

    .no-border {
        border: none;
    }

    .latest_amount_table tbody > tr {
        margin-bottom: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        text-align: right;
        /* display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between; */
    }

    .latest_amount_table .latest_amount > td:last-child {
        font-size: 23px;
        font-weight: 600;
        color: #000000;
    }

    .latest_amount_table .latest_amount > td:first-child {
        color: #000000;
    }

    .latest_amount_table .latest_amount {
        border-top-width: 1px;
        border-top-style: solid;
        position: relative;
        border-top-color: rgba(28, 31, 57, 0.25);
        margin-top: 19px;
        padding-top: 10px;
    }

    .latest_amount_table .latest_amount {
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgba(28, 31, 57, 0.25);
    }

</style>

<style scoped>
    .tabs-component::after {
        content: '';
        width: 100%;
        height: 10px;
        display: block;
        background: #e3e4e7;
    }

    .ex-invoice-view .tabs-component {
        border-bottom: none;
    }

    .ex-invoice-view .tabs-component ul li:hover {
        border-bottom: 1px solid #DCDCDC;
    }
</style>

<style scoped>
    .V3 .card-block {
        padding-left: 0px;
        padding-right: 0px;
    }

    .V3 .invoice-terms {
        border-radius: 3px;
        background-color: rgba(27, 30, 56, 0.03);
        margin: 5px 7px;
        padding: 9px 13px;
    }

    .V3 .invoice-view {
        color: #1C1F39;
        font-family: "Nunito Sans";
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
    }

    .V3 .invoice-view .amount {
        text-align: right;
    }

    .V3 .invoice-view .rate {
        text-align: right;
    }
</style>

<style scoped>
    .modal-styled-block {
        background-color: #EFEFF1 !important;
        color: black !important;;
    }

    .btn-styled {
        height: 37px;
        width: 77px;
        border-radius: 3px;
    }

    .th-width-30 {
        width: 35%;
    }

    .th-width-50 {
        width: 56%;
    }

    .th-width-20 {
        width: 9%;
    }

    .autosave {
        color: #142742;
        font-size: 13px;
        margin-left: 100px;
    }

    .invoice .main-table tbody td, .invoice .main-table tfoot td {
        padding: 5px 5px;
    }


    @media screen and (max-width: 991px) {
        .invoice-header {
            flex-direction: column;
        }

        .invoice .invoice-header table tbody tr {
            display: flex;
            flex-direction: column;
        }

        .table-fixed {
            table-layout: fixed;
        }

        .vat {
            display: block;
        }

        .owner-details tr {
            padding-left: 5px;
        }

        .invoice {
            width: 540px;
        }

        .main-flex {
            display: flex;
            flex-direction: column;
        }

        .mobile-disabled {
            display: none;
        }

        .parts-block-size tr th,
        .parts-block-size tr td {
            padding: 0.75rem 5px;
        }
    }

    @media screen and (max-width: 767px) {
        .invoice-header {
            flex-direction: column;
        }

        .invoice {
            width: 95%;
        }

        .V3 .col_vehicle {
            padding-right: 0;
        }
    }

    @media screen and (max-width: 576px) {
        .invoice {
            width: 100%;
        }

        .invoice .main-table tbody td, .invoice .main-table tfoot td {
            padding: 5px 0;
        }

        .invoice .main-table thead th {
            padding: 8px 5px;
        }

        .invoice-view .main-table td {
            padding-left: 5px;
            padding-right: 5px;
        }

        .vehicle-block-margin {
            margin-bottom: 15px;
        }

        .vehicle-details-block-padding {
            padding: 0;
        }

        .V3 .invoice-view .main-table thead th,
        .invoice-view .main-table th {
            padding-right: 5px;
        }
    }

    @media screen and (min-width: 991px) {
        .padding-additional-class {
            padding-right: 15px !important;
        }
    }

    @media screen and (max-width: 380px) {
        .invoice-view .main-table td {
            padding-left: 2px;
            padding-right: 2px;
        }

        .qtit td:first-child {
            word-break: break-all;
        }
    }

    .line-invoice-reference,
    .line-invoice-reference-suplement,
    .line-invoice-reference-grand-total {
        width: 100%;
        height: 10px;
        right: 0;
        display: block;
        position: absolute;
        background: #e3e4e7;
    }

    .line-invoice-reference-suplement {
        right: 15px;
    }

    .line-invoice-reference-grand-total {
        right: -15px;
        width: 110%;
    }

    .supplement-number {
        padding: 2rem 0 1rem 0;
        font-size: 14px;
        font-weight: bold;
    }

    .estimates-number {
        padding: 2rem 0 1rem 15px;
        font-size: 14px;
        font-weight: bold;
    }

    .text-suplement-ref {
        height: 50px;
        top: 0px;
        display: block;
        position: relative;
        top: 25px;
    }

    .position-text-reference {
        height: 50px;
        display: block;
        top: 25px;
        position: relative;
    }

    @media screen and (max-width: 768px) {
        .header-display-name {
            display: none;
        }
    }

    @media screen and (max-width: 767px) {
        .V3 .tabs-component .tab-header.float-sm-right div.tabs-nav-right-btn {
            display: flex !important;
            justify-content: flex-end;
            margin-right: 0 !important;
        }

        .V3 .invoice-view-page .tabs-component .tab-header.float-sm-left {
            float: left !important;
            margin-right: 115px !important;
        }
    }
</style>
