<script>
import DatePicker from './date-picker'
import AdvancedSearch from './advanced-search'
import NumberFormatter from './number-formatter'
import Multiselect from 'vue-multiselect'
import GoogleLocation from './google-location'
import { mapGetters } from 'vuex'
import Email from './email-validator'
import _ from 'lodash'

export default {
  name: 'insurance-block',
  props: {
    card: {},
    insurers: {},
    assessors: {},
    hiddenElements: {
      agreed_value: false,
      market_value: false,
      sumInsured: false,
      pav: false,
    },
    isNewCard: {
      type: Boolean,
      default: false,
    },
    isShowExcessSetToNil: {
      type: Boolean,
      default: true,
    },
    isDisabledIsurerName: {
      type: Boolean,
      default: false,
    },

  },
  data: function () {
    return {
      selectedInsurerModel: [],
      checkHiddenElements: this.hiddenElements,
      excessPlaceholder: '',
    }
  },
  methods: {
    changedEmail (email) {
      this.card.insurance.assessor_email = email
      this.autoUpdateInsurance()
    },
    changedInsuranceRepairJob () {
      if (this.card.insurance_repair == 'false') {
        this.$emit('setDefaultValues', false)
      } else {
        if (this.card.insurance.insurer_id) {
          let insurer = _.find(this.computedInsurers, (i) => {
            return i.insurer_id == this.card.insurance.insurer_id
          })
          if (insurer) {
            this.$emit('setDefaultValues', insurer.rates)
          }
        }
      }
      this.autoUpdateInsurance()
    },
    autoUpdateInsurance: function () {
      if (this.isNewCard) {
        return
      }
      let update = {}
      // if (this.card.insurance_repair == 'true') {
      update.insurance = this.card.insurance
      // }
      update.insurance_repair = this.card.insurance_repair
      this.$emit('updateInsurance', update)
    },
    autoUpdateVehicleTotalLoss: function () {
      if (this.card.isTotalLoss == 'true') {
        this.card.isTotalLoss = true
      }
      if (this.card.isTotalLoss == 'false') {
        this.card.isTotalLoss = false
      }
      if (this.isNewCard) {
        return
      }
      let update = {}
      update.isTotalLoss = this.card.isTotalLoss
      this.$emit('updateTotalLoss', update)
    },
    onInsurerSelect: function (i) {
      console.log('iiiii', i, this.card.insurance)
      if (i === null) {
        this.card.insurance.insurer_id = null
        this.card.insurance.insurer_location = ''
        this.card.insurance.custom_paint_times = {}
        this.selectedInsurerModel = []
      } else if (i && i.id === null) {
        this.card.insurance.insurer_id = null
        this.card.insurance.insurer_name = null
        this.card.insurance.insurer_location = ''
        this.card.insurance.custom_paint_times = {}
        this.selectedInsurerModel = []
      } else {
        if (this.card.insurance.insurer_id == i.insurer_id) {
          return
        }
        this.card.insurance.insurer_name = i.insurer_name
        this.card.insurance.insurer_id = i.insurer_id
        this.card.insurance.insurer_location = i.insurer_location
        this.card.insurance.rates = i.rates
        this.card.insurance.rates.estimate_methodology = _.toLower(i.rates.estimate_methodology)
        this.card.insurance.custom_paint_times = i.custom_paint_times
        this.selectedInsurerModel = i

        let data = _.cloneDeep(i)
        data.rates.estimate_methodology = _.toLower(data.rates.estimate_methodology)
        this.$emit('setRates', data)
      }
      this.card.insurance.insurer_assessor_id = null
      this.card.insurance.assessor_name = ''
      this.card.insurance.assessor_email = ''
      this.card.insurance.assessor_mobile_phone = ''
      this.card.insurance.assessor_work_phone = ''
      this.autoUpdateInsurance()
    },
    onAssessorSelect: function (a) {
      if (a === null) {
        this.card.insurance.insurer_assessor_id = null
        this.card.insurance.assessor_email = ''
        this.card.insurance.assessor_mobile_phone = ''
        this.card.insurance.assessor_work_phone = ''
      } else {
        this.card.insurance.insurer_assessor_id = a.insurer_assessor_id
        this.card.insurance.assessor_name = a.assessor_name
        this.card.insurance.assessor_email = a.assessor_email
        this.card.insurance.assessor_mobile_phone = a.assessor_mobile_phone
        this.card.insurance.assessor_work_phone = a.assessor_work_phone
      }
    },
  },
  created () {
    this.debouncedAutoUpdateInsurance = _.debounce(this.autoUpdateInsurance, 500)
  },
  mounted () {
    // this.card.insurance.assessor_work_phone = ''
    // console.log(this.card.insurance, 'card')
  },
  computed: {
    ...mapGetters({
      isCustomerUser: 'isCustomerUser',
      isShopManagerUser: 'isShopManagerUser',
      isProductionManagerUser: 'isProductionManagerUser',
      isEstimatorUser: 'isEstimatorUser',
      isStatusActive: 'isStatusActive',
      isTechnicalUser: 'isTechnicalUser',
      isPDRTechnicianUser: 'isPDRTechnicianUser',
      isPanelTechnicianUser: 'isPanelTechnicianUser',
      isPaintTechnicianUser: 'isPaintTechnicianUser',
      isStripperFitterUser: 'isStripperFitterUser',
      isDetailerUser: 'isDetailerUser',
    }),
    isDisabledFieldsByUserRole () {
      return this.isPanelTechnicianUser || this.isPaintTechnicianUser || this.isStripperFitterUser || this.isDetailerUser || this.isTechnicalUser || this.isPDRTechnicianUser
    },
    computedInsurers () {
      let insurers = this.insurers
      insurers = _.forEach(insurers, function (i) {
        if (i.insurer_name) {
          i.insurer_name = i.insurer_name.trim()
        }
        return i
      })
      insurers = _.sortBy(insurers, [function (insurer) {
        if (insurer.insurer_name) {
          return insurer.insurer_name.trim().toLowerCase()
        }
      }])

      insurers.push({ 'insurer_name': null, id: null })
      return insurers
    },
    computedExcessToNil: {
      get () {
        if (this.card.insurance.excess === null || this.card.insurance.excess === '') {
          this.excessPlaceholder = `$0.00`
          this.card.insurance.excess = null
        }
        return !!(this.card.insurance.excess === null || this.card.insurance.excess == '')
      },
      set (v) {
        if (v === true) {
          this.excessPlaceholder = `$0.00`
          this.card.insurance.excess = null
          return false
        } else {
          this.excessPlaceholder = ''
          this.card.insurance.excess = 0
          return true
        }
      },
    },
    computedInsuranceClaimType: {
      get: function () {
        if (!this.isInsuranceRepairYes) {
          return ''
        }
        return this.card.insurance.insurance_claim_type
      },
      set: function (v) {
        this.card.insurance.insurance_claim_type = v
      }
    },
    isTechnicalUser: function () {
      let userInfo = this.$store.state.userInfo
      if (userInfo && userInfo.group_id == 4) {
        return true
      }
      return false
    },
    isInsuranceRepairYes: function () {
      if (this.card.insurance_repair == 'true') {
        return true
      }
      return false
    },
    isInsuranceRepairNo: function () {
      if (this.card.insurance_repair == 'false') {
        return true
      }
      return false
    },
    isInsuranceRepairOther: function () {
      if (this.card.insurance_repair == 'other' || this.card.insurance_repair == '2') {
        return true
      }
      return false
    },
    insurerAssessors: function () {
      if (this.card.insurance.insurer_id) {
        return _.sortBy(this.assessors[this.card.insurance.insurer_id], [function (assessor) {
          return assessor.assessor_name.trim().toLowerCase()
        }])
      }

      return []
    },
  },
  watch: {},
  components: {
    DatePicker,
    AdvancedSearch,
    NumberFormatter,
    Multiselect,
    GoogleLocation,
    Email,
  }
}
</script>

<template>
  <div class="card">
    <div class="card-header bg-navyblue" v-if="!isNewCard">
      <strong>Insurance</strong>
    </div>
    <div class="card-block bg-white row">
      <div :class="{'col-sm-6': !isNewCard}">
        <div class="form-group row">
          <label class="col-sm-4 col-4 col-form-label">Insurance Repair Job <span class="indicate"
                                                                                  v-if="!isNewCard"></span></label>
          <div class="col-sm-8 col-8 d-flex insurer-block-mobile">
            <div class="checkbox-p">
              <label for="insurance_repair_y" class="form-check-inline radio">
                <input class="form-check-input" v-model="card.insurance_repair" type="radio" name="insurance_repair"
                       id="insurance_repair_y"
                       value="true" @change="changedInsuranceRepairJob"
                       :disabled=" !isStatusActive || isDisabledFieldsByUserRole">
                <span class="icon"><i class='bx bx-check'></i></span><span class="text">Yes</span>
              </label>
            </div>
            <div class="checkbox-p">
              <label for="insurance_repair_n" class="form-check-inline radio">
                <input class="form-check-input" v-model="card.insurance_repair" type="radio" name="insurance_repair"
                       id="insurance_repair_n"
                       value="false" @change="changedInsuranceRepairJob" hidden
                       :disabled="!isStatusActive || isDisabledFieldsByUserRole">
                <span class="icon"><i class='bx bx-check'></i></span><span class="text">No</span>
              </label>
            </div>
            <div class="checkbox-p">
              <label for="insurance_repair_o" class="form-check-inline radio insurance-repair-other checkbox-p">
                <input class="form-check-input" v-model="card.insurance_repair" type="radio" name="insurance_repair"
                       id="insurance_repair_o"
                       value="2" @change="autoUpdateInsurance" hidden
                       :disabled="!isStatusActive || isDisabledFieldsByUserRole">
                <span class="icon"><i class='bx bx-check'></i></span><span class="text">Other</span>
              </label>
            </div>
          </div>
        </div>
        <div class="form-group row" v-if="!isTechnicalUser">
          <label for="mobile" class="col-sm-4 col-4 col-form-label">Insurance Claim Type</label>
          <div class="col-sm-8 col-8 d-flex insurer-block-mobile">
            <div class="checkbox-p">
              <label class="form-check-inline radio">
                <input class="form-check-input" v-model="computedInsuranceClaimType" type="radio"
                       name="insurance_claim_type"
                       id="insurance_claim_type_insured" value="insured"
                       :disabled="!isInsuranceRepairYes || !isStatusActive || isDisabledFieldsByUserRole"
                       @change="autoUpdateInsurance">
                <span class="icon"><i class='bx bx-check'></i></span><span class="text">Insured</span>
              </label>
            </div>
            <div class="checkbox-p">
              <label class="form-check-inline radio">
                <input class="form-check-input" v-model="computedInsuranceClaimType" type="radio"
                       name="insurance_claim_type"
                       id="insurance_claim_type_other_party" value="other_party"
                       :disabled="!isInsuranceRepairYes || !isStatusActive || isDisabledFieldsByUserRole"
                       @change="autoUpdateInsurance">
                <span class="icon"><i class='bx bx-check'></i></span><span class="text">Other Party</span>
              </label>
            </div>
          </div>
        </div>
        <div class="form-group row" v-if="!isTechnicalUser">
          <label for="is_total_loss" class="col-sm-4 col-4 col-form-label">Total Loss</label>
          <div class="col-sm-8 col-8 d-flex insurer-block-mobile">
            <div class="checkbox-p">
              <label class="form-check-inline radio">
                <input class="form-check-input"
                       v-model="card.isTotalLoss"
                       type="radio"
                       name="card_is_total_loss"
                       value="true"
                       :disabled="!isStatusActive || isDisabledFieldsByUserRole"
                       @click="(!!card.isTotalLoss)?card.isTotalLoss=null:null"
                       @change="autoUpdateVehicleTotalLoss">
                <span class="icon"><i class='bx bx-check'></i></span><span class="text">Yes</span>
              </label>
            </div>
            <div class="checkbox-p">
              <label class="form-check-inline radio">
                <input class="form-check-input"
                       v-model="card.isTotalLoss"
                       type="radio"
                       name="card_is_total_loss"
                       value="false"
                       :disabled="!isStatusActive || isDisabledFieldsByUserRole"
                       @click="(!card.isTotalLoss)?card.isTotalLoss=null:null"
                       @change="autoUpdateVehicleTotalLoss">
                <span class="icon"><i class='bx bx-check'></i></span><span class="text">No</span>
              </label>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label for="insurance_name" class="col-sm-4 col-4 col-form-label">Insurer Name</label>
          <div class="col-sm-8 col-8 insurer-block-mobile">
            <!--<input type="text" v-model="card.insurance.name" class="form-control" id="insurance_name" placeholder="Insurance company name">-->
            <advanced-search ref="test"
                             v-model="card.insurance.insurer_name"
                             :searchData="computedInsurers"
                             :searchKey="'insurer_name'"
                             @onSelect="onInsurerSelect"
                             :searchLabel="'Insurer'"
                             placeholder="Insurer Name"
                             :isDisabled="!isInsuranceRepairYes || !isStatusActive || isDisabledFieldsByUserRole || isDisabledIsurerName"
                             :isCanBeEmpty="true"
                             :sortByName="'insurer_name'"
                             :maxRecords="computedInsurers.length"
                             :class="{'disabled-cards-edit-is-user': isDisabledFieldsByUserRole}"
            />
          </div>
        </div>
        <div class="form-group row" v-if="!isTechnicalUser && !(isNewCard && card.insurance.insurer_id)">
          <label for="location" class="col-sm-4 col-4 col-form-label">Insurance Location</label>
          <div class="col-sm-8 col-8 insurer-block-mobile">
            <google-location type="text" v-model="card.insurance.insurer_location" id="location"
                             @onPlaceChange="autoUpdateInsurance"
                             :isDisabled="((!isInsuranceRepairYes) || (!card.insurance.insurer_id && card.insurance.insurer_name == '')  || !isStatusActive || isDisabledFieldsByUserRole)">
            </google-location>
            <!--                <input type="text" v-model="card.insurance.insurer_location" class="form-control" id="location"-->
            <!--                       placeholder="Insurance company location" :disabled="(!isInsuranceRepairYes) || (card.insurance.insurer_id)  || isTechnicalUser"-->
            <!--                       @change="autoUpdateInsurance">-->
          </div>
        </div>
        <div class="form-group row" v-if="!isTechnicalUser">
          <label for="claim_number" class="col-sm-4 col-4 col-form-label">Claim No</label>
          <div class="col-sm-8 col-8 insurer-block-mobile">
            <input type="text" v-model="card.insurance.claim_number" class="form-control" id="claim_number"
                   :readonly="!isStatusActive"
                   placeholder="Insurance claim number" :disabled="!isInsuranceRepairYes || isDisabledFieldsByUserRole"
                   @change="autoUpdateInsurance">
          </div>
        </div>
        <div class="form-group row" v-if="!isTechnicalUser">
          <label for="policy_number" class="col-sm-4 col-4 col-form-label">Policy No</label>
          <div class="col-sm-8 col-8 insurer-block-mobile">
            <input type="text" v-model="card.insurance.policy_number" class="form-control" id="policy_number"
                   :readonly="!isStatusActive"
                   placeholder="Insurance policy number" :disabled="!isInsuranceRepairYes || isDisabledFieldsByUserRole"
                   @change="autoUpdateInsurance">
          </div>
        </div>
        <div
            v-if=" (isCustomerUser || isEstimatorUser || isProductionManagerUser || isShopManagerUser) && !isNewCard"
            class="form-group row">
          <label class="col-sm-4 col-4 col-form-label">SUM Insured</label>
          <div class="col-sm-8 col-8 insurer-block-mobile">
            <number-formatter ref="sumInsured" v-model="card.insurance.sumInsured" type="text"
                              class="form-control width50" :class="{'o50':!isInsuranceRepairYes}"
                              maxlength="8"
                              placeholder="SUM Insured" format="$0,0.00"
                              :disabled="!isInsuranceRepairYes || !isStatusActive || isDisabledFieldsByUserRole"
                              @onChange="autoUpdateInsurance"></number-formatter>
          </div>
        </div>

        <div
            v-if="(isCustomerUser || isEstimatorUser || isProductionManagerUser || isShopManagerUser) && !isNewCard"
            class="form-group row">
          <label class="col-sm-4 col-4 col-form-label">PAV</label>
          <div class="col-sm-8 col-8 insurer-block-mobile">
            <number-formatter ref="PAV"
                              v-model="card.insurance.pav"
                              type="text"
                              class="form-control"
                              placeholder="PAV"
                              format="$0,0.00"
                              :disabled="!isInsuranceRepairYes || !isStatusActive || isDisabledFieldsByUserRole"
                              @onChange="autoUpdateInsurance">
            </number-formatter>
          </div>
        </div>

        <div
            v-if="(isCustomerUser || isEstimatorUser || isProductionManagerUser || isShopManagerUser) && !isNewCard"
            class="form-group row">
          <label class="col-sm-4 col-4 col-form-label">Accident Description</label>
          <div class="col-sm-8 col-8 insurer-block-mobile">
                        <textarea style="height: 150px !important;" class="form-control" :readonly="!isStatusActive"
                                  v-model="card.insurance.accidentDescription" name="" cols="30" rows="10"
                                  @change="debouncedAutoUpdateInsurance"
                                  :disabled="isDisabledFieldsByUserRole">
                        </textarea>
          </div>
        </div>
      </div>

      <!--            <div class="form-group row" v-if="!(checkHiddenElements && checkHiddenElements['agreed_value'] && isTechnicalUser)">-->
      <!--                <label class="col-sm-4 col-4 col-form-label">Agreed Value</label>-->
      <!--                <div class="col-sm-8 col-8">-->
      <!--                    <number-formatter-->
      <!--                            ref="agreed_value"-->
      <!--                            v-model="card.insurance.agreed_value"-->
      <!--                            type="text"-->
      <!--                            class="form-control"-->
      <!--                            placeholder="Agreed Value"-->
      <!--                            format="$0,00.00"-->
      <!--                            :disabled="!isInsuranceRepairYes || isTechnicalUser"-->
      <!--                            @onChange="autoUpdateInsurance"-->
      <!--                    ></number-formatter>-->
      <!--                </div>-->
      <!--            </div>-->
      <!--            <div class="form-group row" v-if="!(checkHiddenElements && checkHiddenElements['market_value'] && isTechnicalUser)">-->
      <!--                <label class="col-sm-4 col-4 col-form-label">Market Value</label>-->
      <!--                <div class="col-sm-8 col-8">-->
      <!--                    <number-formatter-->
      <!--                            ref="market_value"-->
      <!--                            v-model="card.insurance.market_value"-->
      <!--                            type="text"-->
      <!--                            class="form-control"-->
      <!--                            placeholder="Market Value"-->
      <!--                            format="$0,00.00"-->
      <!--                            :disabled="!isInsuranceRepairYes || isTechnicalUser"-->
      <!--                            @onChange="autoUpdateInsurance"-->
      <!--                    ></number-formatter>-->
      <!--                </div>-->
      <!--            </div>-->
      <div :class="{'col-sm-6': !isNewCard}">
        <div class="form-group row" v-if="!isTechnicalUser">
          <label for="assessor" class="col-sm-4 col-4 col-form-label">Assessor Name</label>
          <div class="col-sm-8 col-8 insurer-block-mobile">
            <!--<input type="text" v-model="card.insurance.assessor" class="form-control" id="assessor" placeholder="Insurance company assessor">-->
            <advanced-search ref="assessor_name" v-model="card.insurance.assessor_name" :searchData="insurerAssessors"
                             :searchKey="'assessor_name'"
                             @onSelect="onAssessorSelect" :searchLabel="'Assessor'" placeholder="Assessor Name"
                             :isDisabled="!isInsuranceRepairYes || !isStatusActive || isDisabledFieldsByUserRole"
                             @onChange="autoUpdateInsurance"
                             :class="{'disabled-cards-edit-is-user': isDisabledFieldsByUserRole}"></advanced-search>
          </div>
        </div>
        <div class="form-group row" v-if="!isTechnicalUser">
          <label for="asessor_email" class="col-sm-4 col-4 col-form-label">Assessor Email</label>
          <div class="col-sm-8 col-8 insurer-block-mobile">
            <email
                :email="card.insurance.assessor_email"
                style="opacity: 1"
                :is-disabled="(!isInsuranceRepairYes) || !!(card.insurance.insurer_assessor_id) || !isStatusActive || isDisabledFieldsByUserRole"
                @onChange="value => changedEmail(value)"
                :placeholder-text="'Assessor Email'"
            ></email>
          </div>
        </div>
        <div class="form-group row" v-if="!isTechnicalUser">
          <label class="col-sm-4 col-4 col-form-label">Mobile Phone</label>
          <div class="col-sm-8 col-8 insurer-block-mobile">
            <input type="text"
                   v-model="card.insurance.assessor_mobile_phone"
                   class="form-control"
                   id="assessor_mobile_phone"
                   style="opacity: 1;"
                   placeholder="Mobile Phone"
                   :readonly="!isStatusActive"
                   :disabled="(!isInsuranceRepairYes) || (card.insurance.insurer_assessor_id) || isDisabledFieldsByUserRole"
                   @change="autoUpdateInsurance">
          </div>
        </div>
        <div class="form-group row" v-if="!isTechnicalUser">
          <label class="col-sm-4 col-4 col-form-label">Work Phone</label>
          <div class="col-sm-8 col-8 insurer-block-mobile">
            <input type="text"
                   v-model="card.insurance.assessor_work_phone"
                   class="form-control"
                   style="opacity: 1;"
                   id="assessor_work_phone"
                   placeholder="Work Phone"
                   :readonly="!isStatusActive"
                   :disabled="(!isInsuranceRepairYes) || (card.insurance.insurer_assessor_id) || isDisabledFieldsByUserRole"
                   @change="autoUpdateInsurance">
          </div>
        </div>
        <div class="form-group row" v-if="!isTechnicalUser">
          <label for="assessment_date" class="col-sm-4 col-4 col-form-label">Assessment Date</label>
          <div class="col-sm-8 col-8 insurer-block-mobile">
            <!--<input type="text" v-model="card.insurance.assessment_date" class="form-control" id="assessment_date" placeholder="Assessment date">-->
            <date-picker v-model="card.insurance.assessment_date" id="assessment_date"
                         :placeHolderText="'Assessment Date'"
                         :isDisabled="!isInsuranceRepairYes || !isStatusActive || isDisabledFieldsByUserRole"
                         @onChange="autoUpdateInsurance"></date-picker>
          </div>
        </div>
        <div class="form-group row" v-if="!isTechnicalUser">
          <label for="excess" class="col-sm-4 col-4 col-form-label">Excess</label>
          <div class="col-sm-8 col-8 insurer-block-mobile">
            <div :class="{'rs-input-group':isShowExcessSetToNil}">
              <number-formatter ref="card.insurance.excess"
                                v-model="card.insurance.excess"
                                type="text"
                                class="form-control"
                                :placeholder="isShowExcessSetToNil && card.insurance.excess === null ? excessPlaceholder : 'Customer Excess' "
                                format="$0,0.00"
                                :isTotalManual="isShowExcessSetToNil"
                                :disabled="!isInsuranceRepairYes || isTechnicalUser || !isStatusActive
                                            || (card.insurance.excess === null && isShowExcessSetToNil) || isDisabledFieldsByUserRole"
                                @onChange="autoUpdateInsurance"/>
              <div v-if="isShowExcessSetToNil" class="excess-checkbox font-12">
                <label class="pin-job excess-checkbox__wrapper"
                       v-b-tooltip.hover="{customClass: 'ps-tooltip', title: `This has been disabled for this Estimate`, placement: 'bottom', disabled: !isDisabledIsurerName}"
                       :class="{'disabled-additional cursor-not-allowed-additional': isDisabledIsurerName}">
                  <input type="checkbox"
                         :disabled="!isInsuranceRepairYes || !isStatusActive || isDisabledFieldsByUserRole || isDisabledIsurerName"
                         v-model="computedExcessToNil"
                         @click="autoUpdateInsurance"
                  />
                  <div :class="{
                    'disabled-excess-checkbox': !isInsuranceRepairYes || !isStatusActive || isDisabledFieldsByUserRole,
                    'cursor-not-allowed-additional not-hover-additional': isDisabledIsurerName
                  }" class="pin_header pin-checkmark">
                    <i class='bx bx-check'></i>
                  </div>
                  <div class="text-checkbox-excess"
                       :class="{'disabled-additional cursor-not-allowed-additional': isDisabledIsurerName}">
                    Set to NIL
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row" v-if="!isTechnicalUser">
          <label for="contribution" class="col-sm-4 col-4 col-form-label">Contribution</label>
          <div class="col-sm-8 col-8 insurer-block-mobile">
            <number-formatter ref="card.insurance.contribution" v-model="card.insurance.contribution" type="text"
                              class="form-control width50" id="contribution"
                              placeholder="Customer Contribution"
                              format="$0,0.00"
                              :disabled="!isInsuranceRepairYes || !isStatusActive || isDisabledFieldsByUserRole"
                              @onChange="autoUpdateInsurance"></number-formatter>
          </div>
        </div>
        <div class="form-group row" v-if="!isTechnicalUser">
          <label for="discount" class="col-sm-4 col-4 col-form-label">Discount</label>
          <div class="col-sm-8 col-8 insurer-block-mobile">
            <number-formatter ref="discount" v-model="card.insurance.discount" type="text" class="form-control width50"
                              :class="{'o50':!isInsuranceRepairYes}" id="discount" maxlength="3"
                              placeholder="Customer discount" format="0%"
                              :disabled="!isInsuranceRepairYes || !isStatusActive || isDisabledFieldsByUserRole"
                              :percentage="true"
                              @onChange="autoUpdateInsurance"></number-formatter>
          </div>
        </div>

        <div class="form-group row"
             v-if="(isCustomerUser || isEstimatorUser || isProductionManagerUser || isShopManagerUser) && isNewCard">
          <label class="col-sm-4 col-4 col-form-label">Accident Description</label>
          <div class="col-sm-8 col-8 insurer-block-mobile">
            <textarea style="height: 150px !important;" class="form-control" :readonly="!isStatusActive"
                      :disabled="isDisabledFieldsByUserRole"
                      v-model="card.insurance.accidentDescription" name="" cols="30" rows="10"></textarea>
          </div>
        </div>

        <div v-if="!isTechnicalUser && isNewCard" class="form-group row">
          <label class="col-sm-4 col-4 col-form-label">Notes</label>
          <div class="col-sm-8 col-8 insurer-block-mobile">
                          <textarea
                              v-model="card.notes"
                              :disabled="isDisabledFieldsByUserRole"
                              style="height: 150px !important;" class="form-control"
                              name="" cols="30" rows="10">
                          </textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.V3 label.radio > input[type="radio"]:checked:disabled + span.icon i {
  color: white;
}

.V3 label.radio > input[type="radio"]:checked + span.icon {
  background-color: #5E79FF;
}

.V3 .card-block {
  padding-left: 0px;
  padding-right: 0px;
}

.V3 .d-flex {
  display: flex;
}

.V3 .col-sm-6 {
  padding-left: 0;
  padding-right: 0;
}

/*
input[type="radio"]+span{
    -webkit-appearance:none;
    outline:none;
    margin:5px;
    display:inline-block;
    box-sizing: border-box;
    height: 25px;
    width: 25px;
    border: 1px solid rgba(27,30,56,0.25);
    border-radius: 3px;
    background-color: rgba(27,30,56,0.03);
}

input[type="radio"]:checked+span:before{
    content:"";
    display:block;
    background:rgb(58, 153, 45);
    width:8px;
    height:8px;
    margin:2px;
    pointer-events:none;
}
*/

.V3 .estimates-edit-page .pin-checkmark,
.V3 .cards-edit-page .pin-checkmark {
  /* top: 0; */
  position: unset;
  background-color: #FFFFFF;

  /* margin-left: 150px; */
}

.V3 #job-card .pin-job:hover input ~ .pin-checkmark {
  background-color: white;
  border-color: #CCCCCC;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.V3 #job-card .pin-job input:checked ~ .pin-checkmark {
  background-color: #5E79FF;
  border-color: #5E79FF;
}

.pin-job {
  color: #34495E;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* Hide the browser's default checkbox */
.pin-job input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* On mouse-over, add a grey background color */
.pin-job:hover input ~ .pin-checkmark {
  background-color: #F1F1F7;
  border-color: #CCCCCC;
  transition: 0.3s;
}

/* When the checkbox is checked, add a blue background */
.pin-job input:checked ~ .pin-checkmark {
  background-color: #5E79FF;
  border-color: #5E79FF;
}

/* Show the checkmark when checked */
.pin-job input:checked ~ .pin-checkmark i {
  display: block;
}

.text-checkbox-excess {
  /* margin-left: 118px; */
}

.excess-checkbox {
  position: relative;
}

.rs-input-group {
  display: grid;
  grid-template-columns: 50% auto;
  gap: 10px;
  align-items: center;
}

.rs-input-group input.form-control:disabled {
  /* background-color: #F1F1F7 !important; */
  /* opacity: unset; */
}

.disabled-excess-checkbox {
  background-color: rgba(27, 30, 56, 0.03) !important;
  border-color: rgba(27, 30, 56, 0.25) !important;
}

.disabled-excess-checkbox .bx-check {
  color: #000000 !important;
}

.excess-checkbox__wrapper {
  display: grid;
  grid-template-columns: 25px minmax(55px, auto);
  align-items: center;
  gap: 10px;

  font-weight: 600;

  /* remove the default margin */
  margin-bottom: unset;
}

.disabled-additional {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.cursor-not-allowed-additional {
  cursor: not-allowed !important;
}

.not-hover-additional:hover {
  background-color: white !important;

}

@media screen and (max-width: 1024px) {
  .rs-input-group {
    align-items: start;
    /* grid-template-columns: auto auto; */
  }

  .excess-checkbox__wrapper {
    grid-template-columns: auto;
    margin-top: 3px;
    justify-items: center;
    text-align: center;
  }
}

@media screen and (max-width: 450px) {
  .rs-input-group {
    align-items: start;
    grid-template-columns: 50% auto;
  }

  .excess-checkbox__wrapper {
    grid-template-columns: 25px auto;
    margin-top: 3px;
    justify-items: start;
  }
}

@media screen and (max-width: 575px) {
  .insurer-block-mobile {
    margin-left: 15px;
  }
}

.insurer-block-mobile {
  flex-wrap: wrap !important;
}

.checkbox-p {
  padding-bottom: 10px;
}
</style>
