<script>
    import Axios from 'axios';
    import { mapGetters } from 'vuex';
    import BlockPagination from '../utility/block-pagination';

    export default {
        name: 'invoice-create',
        data: function () {
            return {
                filter: '',
                estimates: [],

                estimatesAll: [],
                estimatesAllPerPage: 100,
                estimatesAllCurrentPage: 1,
                estimatesAllFiltered: [],

                fieldsAll: [
                    {label: "Card Number", key: "card_number", sortable: true, tdClass: 'clickable card-number', thClass: 'bg-navyblue'},
                    {label: "Estimate Number", key: "estimate_number", sortable: true, tdClass: 'clickable estimate-number', thClass: 'bg-navyblue'},
                    {label: "Rego", key: "rego_number", sortable: true, tdClass: 'clickable rego', thClass: 'bg-navyblue'},
                    {label: "Customer", key: "customer_name", sortable: true, tdClass: 'clickable customer', thClass: 'bg-navyblue'},
                ],
            };
        },
        computed: {
            ...mapGetters({
                getterBusinessName : 'getBusinessName',
              isShowBusinessName: 'isShowBusinessName',
            }),
            estimatesAllFiltered2: function () {
              return this.estimatesAllFiltered;
            },
        },
        methods: {
            filterForTable(row, filter) {
              let formattedFilter = filter.toLowerCase();
              if (row.card_number && row.card_number.toLowerCase().includes(formattedFilter)) {
                return true;
              } else if (row.customer_name && row.customer_name.toLowerCase().includes(formattedFilter)) {
                return true;
              } else if (row.estimate_number && row.estimate_number.toLowerCase().includes(formattedFilter)) {
                return true;
              } else if (row.rego_number && row.rego_number.toLowerCase().includes(formattedFilter)) {
                return true;
              }
              return false;
            },
            loadEstimates: function (pageNumber) {
                NProgress.start();
                Axios.get('/fe/invoice/create', {
                    params: {
                        pageNum: pageNumber,
                        sort_by: 'id',
                        sort_type: 'DESC'
                    }
                })
                    .then(response => {
                        this.estimates = response.data.data;
                        for (let i = 0; i < this.estimates.length; i++) {
                            const c = this.estimates[i];

                            this.estimatesAll.push(c);
                            this.estimatesAllFiltered.push(c);
                        }
                        NProgress.done();
                    })
                    .catch(error => {
                        console.log(error);
                        NProgress.done();
                    });
            },
            navigateToCardPage(card_id) {
                this.$router.push('/invoice/new/'+card_id);
            },
            onAllEstimatesFiltered: function (fi) {
                this.estimatesAllFiltered = fi;
                this.estimatesAllCurrentPage = 1;
            },
            onEstimatesRowClick: function (item, index, event) {
                this.$router.push('/invoice/new/' + item.id);
            }
        },
        mounted: function () {
            this.loadEstimates(1);
        },
        components: {
          BlockPagination,
        }
    };
</script>

<template>
    <div>
        <div class="page-header">
            <h4>Create New Invoice</h4>
            <ol class="breadcrumbs">
                <li class="breadcrumb-item home">
                    <router-link to="/">Home</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link to="/invoices">Invoices</router-link>
                </li>
                <li class="breadcrumb-item active">Create New Invoice</li>
            </ol>
            <h4 v-if="isShowBusinessName" class="header-display-name">{{ getterBusinessName }}</h4>
        </div>
        <div class="box-block bg-white invoices">
          <div class="tabs-component">
            <div class="search-block" style="overflow-x: auto;">
                <p class="font-90 text-muted mb-0">Search for a Card or Estimate to create this Invoice against. </p>
                <div class="tabs-nav-right-search">
                  <b-form-input v-model="filter" type="text" class="form-control search-input search-position"
                                placeholder="Search or Filter results"
                                debounce="100"></b-form-input>
                </div>
            </div>
          </div>
          <div class="additional-block-new-invoice">
            <div class="pagination-block-bottom">
              <b-table
                responsive
                hover
                class="tab-content-table rs-scroll rs-scroll--y"
                :items="estimatesAll"
                :fields="fieldsAll"
                :filter-function="filterForTable"
                :filter="filter"
                :current-page="estimatesAllCurrentPage"
                :per-page="estimatesAllPerPage"
                @filtered="onAllEstimatesFiltered"
                @row-clicked="onEstimatesRowClick">
              </b-table>
            </div>
            <block-pagination
              :role="'items'"
              :arrayOfSomeone="estimatesAll"
              :arrayOfSomeoneFiltered="estimatesAllFiltered2"
              :currentPage="estimatesAllCurrentPage"
              @changedCurrentPage="value => estimatesAllCurrentPage = value"
              :perPage="estimatesAllPerPage"
              @changedPerPage="value => estimatesAllPerPage = value"
            >
            </block-pagination>
          </div>
          <template slot="nav-item-right">
            <input type="text" v-model="filter" class="form-control" placeholder="Search..." style="float:left; width:auto;"/>
          </template>
        </div>
    </div>
</template>

<style scoped>
    .row.no-gutters {
        margin-right: 0;
        margin-left: 0;
    }
    .row.no-gutters > [class^="col-"],
    .row.no-gutters > [class*=" col-"] {
        padding-right: 0;
        padding-left: 0;
    }

    tbody tr {
        cursor: pointer;
    }

    .action-icon i{
        font-size: 14px;
        color: #bfbbba;
    }

    .action-icon.active i{
        color: #142742
    }


    .action-icon.active[tooltip]{
        position:relative;
        display:inline-block;
        transition: .3s;
    }
    .action-icon.active[tooltip]::before {
        content: "";
        position: absolute;
        bottom: 25px;
        left: 50%;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-top-color: #333;
        border-bottom: 0;
        margin-left: -5px;
        margin-bottom: -5px;
        opacity: 0;
        transition: .3s;
    }

    .action-icon.active[tooltip]:hover::before{
        opacity: 1;
    }

    .action-icon.active[tooltip-position='left']::before{
        left:0%;
        top:50%;
        margin-left:-12px;
        transform:translatey(-50%) rotate(-90deg)
    }
    .action-icon.active[tooltip-position='top']::before{
        left:50%;
    }
    .action-icon.active[tooltip-position='buttom']::before{
        top:100%;
        margin-top:8px;
        transform: translateX(-50%) translatey(-100%) rotate(-180deg)
    }
    .action-icon.active[tooltip-position='right']::before{
        left:100%;
        top:50%;
        margin-left:1px;
        transform:translatey(-50%) rotate(90deg)
    }

    .action-icon.active[tooltip]::after {
        content: attr(tooltip);
        position: absolute;
        left:50%;
        top:-6px;
        transform: translateX(-50%)   translateY(-100%);
        background: #333333;
        text-align: center;
        color: #fff;
        padding:4px 2px;
        font-size: 10px;
        font-weight: 600;
        min-width: 155px;
        border-radius: 0px;
        pointer-events: none;
        z-index:99;
        opacity:0;
        transition: .3s;
    }

    .action-icon.active[tooltip-position='left']::after{
        left:0%;
        top:50%;
        margin-left:-8px;
        transform: translateX(-100%)   translateY(-50%);
    }
    .action-icon.active[tooltip-position='top']::after{
        left:50%;
    }
    .action-icon.active[tooltip-position='buttom']::after{
        top:100%;
        margin-top:8px;
        transform: translateX(-50%) translateY(0%);
    }
    .action-icon.active[tooltip-position='right']::after{
        left:100%;
        top:50%;
        margin-left:8px;
        transform: translateX(0%)   translateY(-50%);
    }

    .action-icon.active[tooltip]:hover::after,[tooltip]:hover::before {
        opacity:1
    }

    .search-block {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 15px;
        margin-bottom: 10px;
    }

    .font-90 {
        margin: 0 10px 0 15px;
    }

    .table thead th {
        text-align: left;
    }

    .search-input {
        width: 191px;
    }

    .search-input:focus {
        box-shadow: 0 0 0 0.2rem transparent;
    }

</style>

<style>
    .additional-block-new-invoice .card-number, .estimate-number, .rego, .customer {
        width: 25%;
    }
</style>
