<script>
import { mapActions, mapGetters } from 'vuex'
import { appConfig } from '../../config.js'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import Axios from 'axios'
import DatePicker from '../utility/date-picker'
import FileUploader from '../utility/file-uploader'
import ImageLightbox from '../utility/image-lightbox/image-lightbox'
import AdvancedSearch from '../utility/advanced-search'
import { FormWizard, TabContent, WizardStep } from 'vue-form-wizard'
import _ from 'lodash'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import NumberFormatter from '../utility/number-formatter'
import GoogleLocation from '../utility/google-location'
import { Utils } from '../../helpers/common'
import Multiselect from 'vue-multiselect'
import { email } from 'vuelidate/lib/validators'
import AmazonAds from '../utility/amazon-ads'
import ActivityList from '../utility/activity-list/activity-list'
import CardNotes from '../utility/card-notes'
import CardFiles from '../utility/card-files'
import InsuranceBlock from '../utility/insurance.block.vue'
import VehicleBlock from '../utility/vehicle.block'
import advancedSearchWithNew from '../utility/advancedSearchWithNew'
import AddressSuggestions from '../utility/address-suggestions'
import { isMobile, isMobileOnly } from '../../deviceDetect/index'
import { VueContext } from 'vue-context'
import SmallFormWizard from '../small-form-wizard.vue'
import Vue2TouchEvents from 'vue2-touch-events'
import AdvertBanner from '../utility/advert-banner'

export default {
  name: 'cards-edit',
  props: {
    msg: String
  },
  data: function () {
    return {
      reasonForDeclineORM: '',
      locationForDecline: '',
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1', '#2196F3', '#3F51B5', '#673AB7', '#00BCD4', '#4CAF50', '#FF9800', '#757575'],
      selectedSplitAllTypeOnMobile: '',
      splitAllPercent: null,
      splitPercentForMobileContext: null,
      splitTypeForMobileContext: '',
      currentBoards: [],
      isShowGallery: false,
      plannerBoardGroupId: null,
      lastTimestampImages: 0,
      intervalUpdateImages: null,
      selectedEstimateItemData: {
        item: {},
        type: '',
        index: null,
        splitPercent: null,
        splitType: '',
        selectedSplitId: null
      },
      isOpenContextMenuForJobCardOnMobile: false,
      isShowLoader: false,
      isShowLoader2: false,
      topForLoader: 0,
      topForTouchMenu: 0,
      leftForLoader: 0,
      leftForTouchMenu: 0,
      touchStartTime: 0,
      isShowContext: false,
      isShowContext2: false,
      isShowHighlightRow: false,
      isShowAddComment: false,
      isShowEditComment: false,
      isShowAddSplit: false,
      isShowAddSplit2: false,
      isShowViewEditSplit: false,
      splitSymbol: '',
      properties: {},
      onSiteReason: null,
      onSiteAttempt: null,
      copyCard: {
        isNew: false,
        copyToCardId: '',
        isCopyImages: false,
        isCopyFiles: false,
        copyToCard: {},
      },
      isOpenContextMenuForJobCard: false,
      currentTab: 'vehicleOwner',
      prevTab: null,
      isNeedAddImgsToDeparture: false,
      isLoadedCard: false,
      currentEstimateItemComment: '',
      filterAssignTo: '',
      cardCustomerAddress: '',
      showAddedToVehicleInBoard: '',
      addressOptions: {
        type: 'ADDRESS',
        scrollOnFocus: false,
        triggerSelectOnBlur: false,
        triggerSelectOnEnter: false,
        addon: 'none',
        url: appConfig.baseAPIURL + '/fe/suggestions',
        headers: { 'Authorization': localStorage.getItem('token') },
        hint: ' ',
        noSuggestionsHint: ' ',
      },
      isMobile,
      isMobileOnly,
      classTabContent: 'tab-content',
      currentNewPaintCode: '',
      vehiclePaintCodes: [],
      steps: [
        { label: 'Received Request for Quotes' },
        { label: 'Priced' },
        { label: 'Lost' },
        { label: 'Won' },
        { label: 'Parts Ordered' }
      ],
      newTask: {
        name: '',
        date: '',
        time: '00:00',
        notes: '',
        type: { key: 'to-do', value: 'ToDo' },
        assignedTo: { id: '-1', name: 'Estimators' },
        status: { key: 'none', value: 'None' },
      },
      isActivityChangeInDetailTab: false,
      clickedIndexInDetailTab: null,
      isOpenNewTaskForm: false,
      currentNewMake: '',
      currentNewModel: '',
      currentNewSeries: '',
      currentNewScheduleNumber: '',
      heightOptions: '',
      isAutoSave: false,
      isNewCustomerInfo: false,
      isSelectedExistingCustomer: false,
      newCustomerId: null,
      isNewVehicleInfo: false,
      newVehicleId: null,
      newCustomerInfo: {
        customer_id: null,
        name: '',
        mobile: '',
        email: '',
        customer_type: 'I',
        abn: '',
        fax: '',
      },
      newVehicleInfo: {
        customer_vehicle_id: null,
        dom: '',
        complianceDate: '',
        rego_number: '',
        make: '',
        model: '',
        series: '',
        series_shedule_number: '',
        colour: '',
        colour_variant: '',
        badge: '',
        body: '',
        vin: '',
        odometer: 0,
        transmission: '',
      },
      autoSaveMessage: '',
      isCheckEmail: true,
      originalData: '',
      activitySortTypes: {
        email: 'Email',
        sms: 'SMS',
        external: 'External',
        assignment: 'Assignment',
        status_change: 'Status/Change',
        show_all: 'Show All',
      },
      cardSaveElement: 'All',
      activitySortTypeText: 'Sort By',
      imageUploadDropzoneOptions: {
        headers: { 'Authorization': localStorage.getItem('token') },
        resizeWidth: 1500,
        resizeQuality: 0.7,
        capture: 'camera',
        clickable: '.drop-clickable',
        url: appConfig.baseAPIURL + '/fe/card/uploadImagesDropzone',
        method: 'post',
        maxFilesize: 50,
        // maxFiles: 2,     //Can be used to limit the maximum number of files that will be handled by this Dropzone
        thumbnailWidth: 200,
        timeout: 300000,
        addRemoveLinks: false,
        acceptedFiles: 'image/jpeg, image/jpg, image/png',
        dictFileTooBig: 'Your file has exceeded the maximum limit of 5MB per file. Please reduce the file size and upload again'
      },
      selectedVehicleMake: {},
      selectedVehicleModel: {},
      selectedVehicleSheduleNumber: '',
      selectedCustomerVehicles: [],
      newImageAdded: false,
      isShowScanner: false,
      selectedPcName: null,
      selectedPcId: null,
      intervalUpdateScannerFiles: null,
      checkConnectedPcsIntervalId: null,
      checkConnectedPcsInterval: 10000,
      // newFileAdded: false,
      image: '',
      images: [],
      file: '',
      image_ids: [],
      image_categories: [],
      notes: [],
      insurers: [],
      assessors: [],
      estimates: [],
      ceStatuses: [],
      cardProgressSmall: {
        startIndex: 0,
        color: '#142742',
        stepSize: 'xs',
        width: '',
        widthActiveProgress: '',
      },
      cardProgress: {
        startIndex: 0,
        color: '#142742',
        stepSize: 'xs',
        width: '',
        widthActiveProgress: '',
      },
      // showCustomerSearchBox: false,
      // showVehicleSearchBox: false,
      activity: [],
      drImageOptions: {
        messages: {
          'default': 'Drag and drop an image here or click to select it from your computer',
          'replace': 'Drag and drop or click to add new image',
          'remove': 'Remove',
          'error': 'Ooops, something went wrong.'
        }
      },
      drFilesOptions: {
        messages: {
          'default': 'Drag and drop a file here or click to select it from your computer',
          'replace': 'Drag and drop or click to add new file',
          'remove': 'Remove',
          'error': 'Ooops, something went wrong.'
        }
      },
      itemsListImageUploadDropzoneOptions: {
        resizeWidth: 1500,
        resizeQuality: 0.7,
        url: appConfig.baseAPIURL + '/fe/card/uploadImagesDropzone',
        method: 'post',
        maxFilesize: 5,
        thumbnailWidth: 160,
        thumbnailHeight: 160,
        addRemoveLinks: false,
        acceptedFiles: 'image/jpeg, image/jpg, image/png',
        dictFileTooBig: 'Your file has exceeded the maximum limit of 5MB per file. Please reduce the file size and upload again',
      },
      jobCard: {
        RR: {},
        Repair: {},
        Paint: {},
        Part: {},
      },
      estimator: {
        id: null
      },
      progressStatus: [],
      settings: {},
      computedOnSiteSelect: 0,
      isAttendedBookingYes: false,
      isAttendedBookingNo: false,
      card: {
        customer_contact_2: '',
        estimator: null,
        customer: {
          customer_id: null,
          name: '',
          mobile: '',
          phone: '',
          email: '',
          customer_type: 'I',
          abn: '',
          address: '',
          level_unit_lot: '',
          street_name: '',
          suburb: '',
          state: '',
          postcode: '',
          country: '',
          fax: '',
        },
        vehicle: {
          customer_vehicle_id: null,
          dom: '',
          complianceDate: '',
          rego_number: '',
          make: '',
          model: '',
          series: '',
          series_shedule_number: '',
          colour: '',
          colour_variant: '',
          badge: '',
          body: '',
          vin: '',
          odometer: 0,
          transmission: '',
        },
        insurance: {
          id: null,
          insurance_claim_type: null,
          insurer_id: null,
          insurer_name: '',
          insurer_location: '',
          insurer_assessor_id: null,
          assessor_name: '',
          assessor_email: '',
          assessor_phone: '',
          policy_number: '',
          claim_number: '',
          assessment_date: '',
          excess: '',
          contribution: '',
          discount: '',
          market_value: '',
          agreed_value: '',
          accidentDescription: '',
          sumInsured: '',
          pav: '',
          rates: {
            estimate_methodology: 'custom',
            estimate_rates_rr: null,
            estimate_rates_repair: null,
            estimate_rates_paint: null
          }
        },
        insurance_repair: 'true',
        booking_date: '',
        completion_date: '',
        pickUpTime: '',
        vehicleInBoard: '',
        boardIn: {
          id: null,
          updatedDate: null,
          createdDate: null,
        },
        boardDeparture: {
          id: null,
          updatedDate: null,
          createdDate: null,
        },
        card_number: '',
        card_status: '',
        statuses: [],
        state: 1,
        card_id: 0,
        estimate_number: '',
        supplement_numbers: '',
        isTotalLoss: false,
        invoice: {},
        excess_invoice: {},
        estimate_methodology: null,
        vehicleDriverName: '',
        contactNumber1: '',
        contactNumber2: '',
        contactEmail: '',
        plannerBoard: {
          'vehicle': {
            groupId: null,
            id: null,
            name: '',
          },
          'onSite': {
            groupId: null,
            attempt: null,
            isAttendedBooking: false,
            onsiteEstimateBookingDate: null,
            reason: null,
            id: null,
            name: '',
          },
        }
      },
      pinImage: false,
      resultSortingImages: {},
      uploadImageCategories: ['Uncategorised', 'Existing Damage', 'Accident Damage', 'Supplementary Damage',],
      assignedToUsers: [],
      assignedToUser: '',
      assignedTo: '',
      assignedToArr: [],
      groups: [],
      assignedName: '',
      assignedToText: '',
      loaderAssignTo: false,
      isHiddenOptions: false,
      isHiddenNewNote: false,
      topSticky: 0,
    }
  },
  validations: {
    newCustomerInfo: {
      email: {
        email,
      }
    },
    card: {
      customer: {
        email: {
          email,
        }
      },
      contactEmail: {
        email,
      },
      insurance: {
        assessor_email: {
          email,
        }
      },
    },

        },
        computed: {
            ...mapGetters({
                userInfo: 'userInfo',
                vendorInfo: 'vendorInfo',
                isStatusActive: 'isStatusActive',
                file_ids: 'fileIdsInCardEdit',
                files: 'filesInCardEditGetter',
                totalFiles: 'totalFiles',
                updateData: 'updateDataGetter',
                isTechnicalUser: 'isTechnicalUser',
                isCustomerUser: 'isCustomerUser',
                isEstimatorUser: 'isEstimatorUser',
                isProductionManagerUser: 'isProductionManagerUser',
                isPDRTechnicianUser: 'isPDRTechnicianUser',
                isShopManagerUser: 'isShopManagerUser',
                isPanelTechnicianUser: 'isPanelTechnicianUser',
                isPaintTechnicianUser: 'isPaintTechnicianUser',
                isStripperFitterUser: 'isStripperFitterUser',
                isDetailerUser: 'isDetailerUser',
                addressStates: 'addressStates',
                addressCountries: 'addressCountries',
                getterActiveUsers: 'activeUsers',
                getterVisibleUsers: 'visibleUsers',
                getterAllCards: 'card/getAllCards',
                getterBoardIn: 'board/getBoardIn',
                getterBoardDeparture: 'board/getBoardDeparture',
                getterAllCustomers: 'getAllCustomers',
                getterBusinessName : 'getBusinessName',
              isShowBusinessName: 'isShowBusinessName',
              pcs: 'pcs',
            }),
          isDisabledOnSiteSelects () {
              this.computedOnSiteSelect++
            return !!(!this.isStatusActive || this.isDisabledFieldsByUserRole || this.card.plannerBoard.onSite.isAttendedBooking)
          },
          isShowBigImage() {
            return this.$route.name == 'CardsEditImage'
          },
          computedBookingBoardCategory: {
            get: function () {
              if (this.plannerBoardGroupId){
                return this.plannerBoardGroupId
              }
              let vm = this;

              try {
                let groupId= this.card?.plannerBoard?.vehicle?.groupId
                let item = _.find(vm.categoryOptions, (opt) => {
                  return opt.id === groupId
                });
                return item;
              } catch (e) {
                console.log('E.computedBookingBoardCategory', e)
                return {}
              }
            },
            set: function (item) {
              if (item && item.id) {
                this.plannerBoardGroupId = item;
                if (this.card?.plannerBoard?.vehicle) {
                  this.card.plannerBoard.vehicle.groupId = item.id;
                }
              }
              else{
                this.plannerBoardGroupId = null
                if (this.card?.plannerBoard?.vehicle) {
                  this.card.plannerBoard.vehicle.groupId = null
                }
              }
              console.log('computedBookingBoardCategory', item)
            }
          },
          categoryOptions() {
            let groups = this.groups.slice();
            let emptyCategoryIndex = _.findIndex(this.groups, (gr) => {
              return gr.id === null;
            })
            if (emptyCategoryIndex === -1) {
              groups.unshift({name: 'No Category', id: null, color: '#5f9fa0', isSetColor: false, isOpenColorPicker: false});
            }
            return groups;
          },
           isDisabledFieldsByUserRole() {
             return  this.isPanelTechnicianUser || this.isPaintTechnicianUser || this.isStripperFitterUser || this.isDetailerUser || this.isTechnicalUser || this.isPDRTechnicianUser
           },
            isDisabledModalFinishButton () {
              return !!(_.isEmpty(this.reasonForDeclineORM) || _.isEmpty(this.locationForDecline));
            },
            filteredStatusList() {
              let currentIndex = this.card?.statuses?.findIndex(
                  (status) => Number(status.id) === Number(this.card.card_status)
              );
              if (this.card?.statuses?.length <= 4) return this.card?.statuses;

              const statusCount = this.card?.statuses?.length;
              const index = currentIndex;

              if (index < 3) {
                // on minimal
                return this.card?.statuses?.slice(0, 4);
              } else if ((statusCount - index + 1) < 5) {
                // on maximum
                return this.card?.statuses?.slice(-4);

                // everything in between
              } else if (index === 3 || index === 4) {
                return this.card?.statuses?.slice(2, 6);
              } else if (index === 5 || index === 6) {
                return this.card?.statuses?.slice(4, 8);
              } else if (index === 7 || index === 8) {
                return this.card?.statuses?.slice(6, 10);
              } else if (index === 9 || index === 10) {
                return this.card?.statuses?.slice(8, 12);
              }
            },
            computedAssignedUserName() {
              if (!this.assignedToUser || this.assignedToUser === 'None') {
                return '';
              } else {
                return this.assignedToUser;
              }
            },
            isSmsAvailable(){
              return this.properties.isSmsFeatureEnabled;
            },
            boardInId() {
                if (this.card.boardIn && this.card.boardIn.id) {
                    return this.card.boardIn.id;
                }
                return '';
            },
            boardDepartureId() {
                if (this.card.boardDeparture && this.card.boardDeparture.id) {
                    return this.card.boardDeparture.id;
                }
                return '';
            },
            jobCardRR() {
                //if (this.jobCard.RR) {
                //  let filteredRR = {}
                //  for (let item in this.jobCard.RR) {
                //    if (this.jobCard.RR[item].hours != 0){
                //      filteredRR[item] = this.jobCard.RR[item]
                //    }
                //  }
                //  return filteredRR
                //}
                return this.jobCard.RR || [];
            },
            jobCardRepair() {
                //if (this.jobCard.Repair) {
                //  let filteredRepair = {}
                //  for (let item in this.jobCard.Repair) {
                //    if (this.jobCard.Repair[item].hours != 0){
                //      filteredRepair[item] = this.jobCard.Repair[item]
                //    }
                //  }
                //  return filteredRepair
                //}
                return this.jobCard.Repair || [];
            },
            jobCardPaint() {
                //if (this.jobCard.Paint) {
                //  let filteredPaint = {}
                //  for (let item in this.jobCard.Paint) {
                //    if (this.jobCard.Paint[item].hours != 0){
                //      filteredPaint[item] = this.jobCard.Paint[item]
                //    }
                //  }
                //  return filteredPaint
                //}
                return this.jobCard.Paint || [];
            },
            jobCardPart() {
                //if (this.jobCard.Part) {
                //  let filteredPart = {}
                //  for (let item in this.jobCard.Part) {
                //    if (this.jobCard.Part[item].price != 0){
                //      filteredPart[item] = this.jobCard.Part[item]
                //    }
                //  }
                //  return filteredPart
                //}
                return this.jobCard.Part || [];
            },
            jobCardSublet() {
                //if (this.jobCard.Sublet) {
                //  let filteredSublet = {}
                //  for (let item in this.jobCard.Sublet) {
                //    if (this.jobCard.Sublet[item].price != 0){
                //      filteredSublet[item] = this.jobCard.Sublet[item]
                //    }
                //  }
                //  return filteredSublet
                //}
                return this.jobCard.Sublet || [];
            },
            jobCardOther() {
                //if (this.jobCard.Other) {
                //  let filteredOther = {}
                //  for (let item in this.jobCard.Other) {
                //    if (this.jobCard.Other[item].hours != 0){
                //      filteredOther[item] = this.jobCard.Other[item]
                //    }
                //  }
                //  return filteredOther
                //}
                return this.jobCard.Other || [];
            },
            jobCardPDR() {
                //if (this.jobCard.PDR) {
                //  let filteredPDR = {}
                //  for (let item in this.jobCard.PDR) {
                //    if (this.jobCard.PDR[item].price != 0){
                //      filteredPDR[item] = this.jobCard.PDR[item]
                //    }
                //  }
                //  return filteredPDR
                //}
                return this.jobCard.PDR || [];
            },
            computedCardsForCopyCard() {
                let vm = this;
                let cards = _.filter(this.getterAllCards, function (i) {
                    return !(i.state === "3" || i.status_name == "Invoiced" || i.status_name == "Closed" || i.card_id == vm.card.card_id);
                });
                return cards;
            },
            computedActiveUsers() {
                if (!this.filterAssignTo || this.filterAssignTo == '') {
                    let users = this.getterVisibleUsers.slice();
                    users.unshift({
                      id: null,
                      name: 'None',
                      groupId: null,
                      group_id: null,
                      isActive: true,
                      isVisible: true,
                      lastName: null,
                      firstName: null,
                      role: null,
                    })
                    return users;
                }
                let self = this;
                let filterUsers = _.filter(this.getterVisibleUsers, function (user) {
                    let search = [];
                    search.push(_.toLower(user.name));
                    return !!(search.join(',').indexOf(_.toLower(self.filterAssignTo)) > -1);
                });
                return filterUsers;
            },
            computedUpdatedOnlyDate() {
                if (this.card.boardDeparture && this.card.boardDeparture.updatedDate !== null) {
                    let updatedDate = this.card.boardDeparture.updatedDate.split(' ')[0].split('-');
                    let day = updatedDate[2];
                    let month = updatedDate[1];
                    let year = updatedDate[0];
                    return day + '/' + month + '/' + year;
                }
                return '';
            },
            computedCustomerState: {
                get: function () {
                    let vm = this;
                    try {
                        let item = _.find(this.addressStates, function (itm) {
                            return itm.key === vm.card.customer.state;
                        });
                        return item;
                    } catch (e) {
                        return {};
                    }
                },
                set: function (item) {
                    this.card.customer.state = item.key;
                },
            },
            computedCustomerCountry: {
                get: function () {
                    let vm = this;
                    try {
                        let item = _.find(this.addressCountries, function (itm) {
                            return itm.key === vm.card.customer.country;
                        });
                        return item;
                    } catch (e) {
                        return [];
                    }
                },
                set: function (item) {
                    this.card.customer.country = item.key;
                },
            },
            computedEstimators() {
                return this.getterActiveUsers.filter(user => {
                    return !!(user.groupId === "-1" || user.groupId === "2" || user.groupId === "3");
                });
            },
            customerVehicles: function () {
                let customersVehicles = [];
                _.forEach(this.getterAllCustomers, function (customer) {
                    customersVehicles.push({
                        customer_name: customer.customer_name,
                        customer_id: customer.customer_id,
                    });
                });
                return customersVehicles;
                ;
            },
            isInsuranceRepairYes: function () {
                if (this.card.insurance_repair == 'true') {
                    return true;
                }
                return false;
            },
            isInsuranceRepairNo: function () {
                if (this.card.insurance_repair == 'false') {
                    return true;
                }
                return false;
            },
            isInsuranceRepairOther: function () {
                if (this.card.insurance_repair == 'other' || this.card.insurance_repair == '2') {
                    return true;
                }
                return false;
            },
            onChangeIsNewBtn: function () {
                if (this.copyCard.isNew === false) {
                    return true;
                }
                return false;
            },
            isNoNeedVehicleRego: function () {
                if (this.isInsuranceRepairOther) {
                    return true;
                }
                return false;
            },
            showUpperRegoNumber: {
                get: function () {
                    return _.toUpper(this.card.vehicle.rego_number);
                },
                set: function (rego) {
                    rego = _.trim(rego);
                    this.card.vehicle.rego_number = _.toUpper(rego);
                }
            },
            isEstimateMethodologyCustom: function () {
                if (this.card.estimate_methodology !== undefined && this.card.estimate_methodology !== null) {
                    if (this.card.estimate_methodology === 'custom') {
                        return true;
                    } else {
                        return false;
                    }
                }
                if (this.card.insurance !== undefined && this.card.insurance !== null && this.card.insurance.rates !== undefined && this.card.insurance.rates !== null) {
                    if (this.card.insurance.rates.estimate_methodology === 'custom') {
                        return true;
                    } else {
                        return false;
                    }
                }
                return false;
            },
            isCheckedAll() {
              return {
                'Remove':  this.isCheckedRemoveAllRRInJobCard,
                'Replace': this.isCheckedAllRRInJobCard,
                'Repair':  this.isCheckedAllRepairInJobCard,
                'Paint':   this.isCheckedAllPaintInJobCard,
                'Part':    this.isCheckedAllPartInJobCard,
                'Other':   this.isCheckedAllOtherInJobCard,
                'Sublet':  this.isCheckedAllSubletInJobCard,
                'PDR':     this.isCheckedAllPDRInJobCard,
              }
            },
            isCheckedAllRRInJobCard: {
                get: function () {
                    if (!this.isEmpty(this.jobCard.RR)) {
                        let st = true;
                        _.forEach(this.jobCard.RR, function (item) {
                            if (!item.is_checked_replace) {
                                st = false;
                                return;
                            }
                        });
                        return st;
                    }
                    return false;
                },
                set: function (v) {
                    let self = this;
                    if (v) {

          _.forEach(this.jobCard.RR, function (item) {
            item.is_checked_replace = true
            self.onChangeJobCardCheckedItemOfRR(item, 'Replace')
          })
        } else {
          _.forEach(this.jobCard.RR, function (item) {
            item.is_checked_replace = false;
            item.split_replace = [];
            item.percent_replace = null;
            item.job_user_fullname_replace = '';
            self.onChangeJobCardCheckedItemOfRR(item, 'Replace')
          })
        }
      }
    },
    isCheckedRemoveAllRRInJobCard: {
      get: function () {
        if (!this.isEmpty(this.jobCard.RR)) {
          let st = true
          _.forEach(this.jobCard.RR, function (item) {
            if (!item.is_checked_remove) {
              st = false
              return
            }
          })
          return st
        }
        return false
      },
      set: function (v) {
        let self = this
        if (v) {

          _.forEach(this.jobCard.RR, function (item) {
            item.is_checked_remove = true
            self.onChangeJobCardCheckedItemOfRR(item, 'Remove')
          })
        } else {
          _.forEach(this.jobCard.RR, function (item) {
            item.is_checked_remove = false;
            item.split_remove = [];
            item.percent_remove = null;
            item.job_user_fullname_remove = '';
            self.onChangeJobCardCheckedItemOfRR(item, 'Remove')
          })
        }
      }
    },
    isCheckedAllRepairInJobCard: {
      get: function () {
        if (!this.isEmpty(this.jobCard.Repair)) {
          let st = true
          _.forEach(this.jobCard.Repair, function (item) {
            if (!item.is_checked) {
              st = false
              return
            }
          })
          return st
        }
        return false
      },
      set: function (v) {
        let self = this
        if (v) {
          _.forEach(this.jobCard.Repair, function (item) {
            item.is_checked = true
            self.onChangeJobCardCheckedItem(item, 'Repair')
          })
        } else {
          _.forEach(this.jobCard.Repair, function (item) {
            item.is_checked = false;
            item.split = [];
            item.percent = null;
            item.job_user_fullname = '';
            self.onChangeJobCardCheckedItem(item, 'Repair')
          })
        }
      }
    },
    isCheckedAllPaintInJobCard: {
      get: function () {
        if (!this.isEmpty(this.jobCard.Paint)) {
          let st = true
          _.forEach(this.jobCard.Paint, function (item) {
            if (!item.is_checked) {
              st = false
              return
            }
          })
          return st
        }
        return false
      },
      set: function (v) {
        let self = this
        if (v) {
          _.forEach(this.jobCard.Paint, function (item) {
            item.is_checked = true
            self.onChangeJobCardCheckedItem(item, 'Paint')
          })
        } else {
          _.forEach(this.jobCard.Paint, function (item) {
            item.is_checked = false;
            item.split = [];
            item.percent = null;
            item.job_user_fullname = '';
            self.onChangeJobCardCheckedItem(item, 'Paint')
          })
        }
      }
    },
    isCheckedAllPartInJobCard: {
      get: function () {
        if (!this.isEmpty(this.jobCard.Part)) {
          let st = true
          _.forEach(this.jobCard.Part, function (item) {
            if (!item.is_checked) {
              st = false
              return
            }
          })
          return st
        }
        return false
      },
      set: function (v) {
        let self = this
        if (v) {
          _.forEach(this.jobCard.Part, function (item) {
            item.is_checked = true
            self.onChangeJobCardCheckedItem(item, 'Part')
          })
        } else {
          _.forEach(this.jobCard.Part, function (item) {
            item.is_checked = false;
            item.split = [];
            item.percent = null;
            item.job_user_fullname = '';
            self.onChangeJobCardCheckedItem(item, 'Part')
          })
        }
      }
    },
    isCheckedAllOtherInJobCard: {
      get: function () {
        if (!this.isEmpty(this.jobCard.Other)) {
          let st = true
          _.forEach(this.jobCard.Other, function (item) {
            if (!item.is_checked) {
              st = false
              return
            }
          })
          return st
        }
        return false
      },
      set: function (v) {
        let self = this
        if (v) {
          _.forEach(this.jobCard.Other, function (item) {
            item.is_checked = true
            self.onChangeJobCardCheckedItem(item, 'Other')
          })
        } else {
          _.forEach(this.jobCard.Other, function (item) {
            item.is_checked = false;
            item.split = [];
            item.percent = null;
            item.job_user_fullname = '';
            self.onChangeJobCardCheckedItem(item, 'Other')
          })
        }
      }
    },
    isCheckedAllSubletInJobCard: {
      get: function () {
        if (!this.isEmpty(this.jobCard.Sublet)) {
          let st = true
          _.forEach(this.jobCard.Sublet, function (item) {
            if (!item.is_checked) {
              st = false
              return
            }
          })
          return st
        }
        return false
      },
      set: function (v) {
        let self = this
        if (v) {
          _.forEach(this.jobCard.Sublet, function (item) {
            item.is_checked = true
            self.onChangeJobCardCheckedItem(item, 'Sublet')
          })
        } else {
          _.forEach(this.jobCard.Sublet, function (item) {
            item.is_checked = false;
            item.split = [];
            item.percent = null;
            item.job_user_fullname = '';
            self.onChangeJobCardCheckedItem(item, 'Sublet')
          })
        }
      }
    },
    isCheckedAllPDRInJobCard: {
      get: function () {
        if (!this.isEmpty(this.jobCard.PDR)) {
          let st = true
          _.forEach(this.jobCard.PDR, function (item) {
            if (!item.is_checked) {
              st = false
              return
            }
          })
          return st
        }
        return false
      },
      set: function (v) {
        let self = this
        if (v) {
          _.forEach(this.jobCard.PDR, function (item) {
            item.is_checked = true
            self.onChangeJobCardCheckedItem(item, 'PDR')
          })
        } else {
          _.forEach(this.jobCard.PDR, function (item) {
            item.is_checked = false;
            item.split = [];
            item.percent = null;
            item.job_user_fullname = '';
            self.onChangeJobCardCheckedItem(item, 'PDR')
          })
        }
      }
    },
    isShowMarkAsRead () {
      if (_.find(this.activity, { 'viewed': '0' })) {
        return true
      }
      return false
    },
    isShowRedMessageForBody: function () {
      if (this.computedBodies && this.computedBodies.length > 0 && this.card.vehicle.body === '') {
        return true
      }
      return false
    },
    computedActivity: {
      get: function () {
        return this.activity
      },
      set: function (data) {
        this.activity = data
      }
    },
    insurances: function () {
      return this.$store.state.insurances
    },
    insurerAssessors: function () {
      if (this.card.insurance.insurer_id) {
        return this.assessors[this.card.insurance.insurer_id]
      }

      return []
    },
    saveData: function () {
      return JSON.stringify({
        customer: this.card.customer,
        customer_contact_2: this.card.customer_contact_2,
        vehicle: this.card.vehicle,
        booking_date: this.card.booking_date,
        completion_date: this.card.completion_date,
        pickUpTime: this.card.pickUpTime,
        boardDeparture: this.card.boardDeparture,
        boardIn: this.card.boardIn,
        vehicleInBoard: this.card.vehicleInBoard,
        card_number: this.card.card_number,
        file_ids: this.file_ids,
        image_ids: this.image_ids,
        card_status: this.card.card_status
      })
    },
    imageSource: function () {
      let images = _.orderBy(this.images, ['id'],['desc'])
      let _uncategoriesArr = [],
          _dataObj = {}
      Array.from(this.uploadImageCategories).forEach(el => {
        _dataObj[this.replaceCharAtCategories(el)] = []
      });
      [].slice.call(Array.from(images)).forEach((el, ind) => {
        if (!el.hasOwnProperty('category') || !el.category || this.replaceCharAtCategories(el.category) === 'Uncategorised') {
          _uncategoriesArr.push(el)
          _dataObj.Uncategorised = _uncategoriesArr
        } else {
          let _categReplace = this.replaceCharAtCategories(el.category)
          _dataObj[_categReplace].push(el)
        }
      })
      this.resultSortingImages = _dataObj

      return this.resultSortingImages
    },
    totalImages: function () {
      return this.images.length
    },
    totalNotes: function () {
      return this.notes.length
    },
  },
  methods: {
    ...mapActions([
      'clearFileIdsInCardEdit',
      'updateFiles',
      'unshiftFileInCardEdit',
      'updateDataInCardEdit',
      'deleteUpdateDataByField',
      'clearUpdateDataByField',
      'getConnectedPcs',
    ]),
    checkIsAttendYes(v, isSave = true) {
      if (v) {
        this.isAttendedBookingYes = v
        this.isAttendedBookingNo = false
        this.card.plannerBoard.onSite.isAttendedBooking = v
      } else {
        this.isAttendedBookingYes = v
        this.isAttendedBookingNo = v
        this.card.plannerBoard.onSite.isAttendedBooking = null
      }
      this.computedOnSiteSelect++;
      if (isSave) {
        this.saveOnsiteData(false)
      }

    },
    checkIsAttendNo(v, isSave = true) {
      if (v) {
        this.isAttendedBookingYes = false
        this.isAttendedBookingNo = v
        this.card.plannerBoard.onSite.isAttendedBooking = false
      } else {
        this.isAttendedBookingYes = v
        this.isAttendedBookingNo = v
        this.card.plannerBoard.onSite.isAttendedBooking = null
      }
      this.computedOnSiteSelect++;
      if (isSave) {
        this.saveOnsiteData(false)
      }

    },
    goToEstimateBooking() {
      this.$router.push({
        name: 'planner',
        params: {
          eventId: this.card.plannerBoard.onSite.id,
          eventDate: this.card.plannerBoard.onSite.onsiteEstimateBookingDate,
        }
      })
    },
    saveOnsiteData(data) {
      console.log(data, 'data')
      let update = {}
      let isRebooking = !!(data.prev && this.card.plannerBoard.onSite.onsiteEstimateBookingDate
          && data.prev !== this.card.plannerBoard.onSite.onsiteEstimateBookingDate && this.onSiteReason &&  this.onSiteAttempt)

      if (this.card.plannerBoard.onSite.isRebooked) {
        isRebooking = true
      } else if (isRebooking) {
        this.onSiteAttempt = null;
        this.card.plannerBoard.onSite.isAttendedBooking = null
        this.onSiteReason = null
        this.isAttendedBookingYes = null;
        this.isAttendedBookingNo = null;
      }
      update.plannerBoard = {}
      update.plannerBoard.onSite = {
        attempt: this.onSiteAttempt,
        isAttendedBooking: this.card.plannerBoard.onSite.isAttendedBooking,
        onsiteEstimateBookingDate: this.card.plannerBoard.onSite.onsiteEstimateBookingDate,
        reason: this.onSiteReason,
      }
      if (!this.card.plannerBoard.onSite.isRebooked && isRebooking) {
        update.plannerBoard.onSite.rebooked = isRebooking
      }
      this.card.plannerBoard.onSite.isRebooked = isRebooking

      this.setUpdateData(update)
      this.beforeDebouncedAutoSave()
    },
    selectImageGallery(v) {
      this.prevTab = _.cloneDeep(this.currentTab)
      this.currentTab = 'images'
      this.$nextTick(() => {
        this.$refs.imageLightbox.setGallery(v)
      })
    },
    showOrHideGallery(v) {
      this.isShowGallery = v;
      if (!this.isShowGallery && this.prevTab) {
        this.currentTab = _.cloneDeep(this.prevTab)
        this.prevTab = null
      }
    },
    pinImageOnBigImageView() {
      let body = document.querySelector('body');

      if (!_.includes(body.classList, 'compact-sidebar')) {
        this.pinImage = false;
      } else {
        this.pinImage = true;
      }

      body.classList.toggle('compact-sidebar');
    },
    getActivePcs(pcs) {
      return _.filter(pcs, (pc) => {
        return pc.active
      })
    },
    selectPc(pc) {
      this.isShowScanner = false
      this.selectedPcName = pc.pc_name
      this.selectedPcId = pc.id
      if (this.intervalUpdateScannerFiles) {
        clearInterval(this.intervalUpdateScannerFiles)
      }

      let lastTimestamp = this.$store.getters['updater/lastTimestamp']
      this.getFilesScanner({
        pcId: pc.id,
        time: lastTimestamp
      })
      this.intervalUpdateScannerFiles = setInterval(() => {
        this.getFilesScanner({
          pcId: pc.id,
          time: lastTimestamp
        })
      }, 5000)
    },
    getFilesScanner(data) {
      Axios.post('/fe/scanner-service/get-files-list',data).then((r) => {
        if (r.data._status && !_.isEmpty(r.data.files)) {
          _.forEach(r.data.files, (f) => {
            if (f.status == 'found') {
              this.uploadScannerFile({
                fileId: f.id,
                cardId: this.card.card_id
              })
            }
          })
        }
      })
    },
    uploadScannerFile(data) {
      Axios.post('/fe/scanner-service/request-file-upload', data).then((r) => {
        if (r.data._status) {

        }
      })
    },
    setTitle (isNeedToClear = false) {
      let text = !isNeedToClear && (!_.isEmpty(this.card.card_number) || !_.isEmpty(this.card.customer.name)) ? '' : appConfig.appName
      if (!isNeedToClear && !_.isEmpty(this.card.card_number)) {
        text += this.card.card_number
      }
      if (!isNeedToClear && !_.isEmpty(this.card.customer.name)) {
        if (!_.isEmpty(this.card.customer.name)) {
          text += ' - '
        }
        text += this.card.customer.name
      }
      let title = document.getElementsByTagName('title')
      if (title?.[0]) {
        title[0].innerText = text
      }
    },
    loadCategories() {
      Axios.get('/fe/planner-board/groups')
          .then(response => {
            if (response.data.groups && response.data._status) {
              this.groups.splice(0, this.groups.length);
              _.forEach(response.data.groups, (gr) => {
                this.groups.push({
                  isOpenColorPicker: false,
                  isSetColor: false,
                  ...gr,
                });
              });
            }
          })
          .finally(()=> {
            // this.plannerBoardGroupId = _.find(this.categoryOptions, (opt) => {
            //   return Number(opt.id) == Number(this.card?.plannerBoard?.groupId);
            // })
          })
    },
    changedBookingCategory() {
      console.log('changedBookingCategory')
      Axios.post(
          "/fe/planner-board/update",
          {
            id: this.card?.plannerBoard?.vehicle?.id,
            groupId: this.card?.plannerBoard?.vehicle?.groupId,
            color: this.plannerBoardGroupId?.vehicle?.color,
            type: 'vehicle'
          }
      )
    },
    goToCards() {
      if (!_.isEmpty(this.$route.params.trackerFilter)) {
        this.$store.commit('setCardFilter', this.$route.params.trackerFilter);
      }
      this.$router.push({ name: 'Cards', params: {filters: this.$route.params.filters, trackerFilter: this.$route.params.trackerFilter} })
    },
    addToWebtrim() {
      NProgress.start()
      Axios.post('/fe/card/send-to-webtrim', {cardId: this.card.card_id})
          .then(response => {
            if (response.data._status) {
              toastr.success('Card ' + this.card.card_number + ' added successfully to the Webtrim')
            }
          })
          .catch(error => {
            NProgress.done()
            toastr.error(error)
          })
          .finally(() => {
            NProgress.done()
          })
    },
    getAllNamesOfItem(item, type) {
      let users = [];
      let resultText = '';
      let data = item.split;
      if (type === 'Replace') {
        data = item.split_replace;
      } else if (type === 'Remove') {
        data = item.split_remove;
      }
      if (data.length > 0) {
        if (type !== 'Replace' && type !== 'Remove') {
          resultText = 'Checked by: <br>';
        }
        _.forEach(data, item => {
          let user = _.find(users, (us) => {
            return Number(us.id) === Number(item.createdBy.id);
          });
          if (!user) {
            users.push(item.createdBy);
            if (type !== 'Replace' && type !== 'Remove') {
              resultText += item.createdBy.fullName + ' <br>';
            } else if (type === 'Replace') {
              resultText += item.createdBy.fullName + ' - REFIT <br>';
            } else if (type === 'Remove') {
              resultText += item.createdBy.fullName + ' - REMOVE <br>';
            }
          }
        })
      } else {
        if (type !== 'Replace' && type !== 'Remove') {
          resultText = 'Checked by ' + item.job_user_fullname;
        } else if (type === 'Replace') {
          resultText += item.job_user_fullname_replace + ' - REFIT <br>';
        } else if (type === 'Remove') {
          resultText += item.job_user_fullname_remove + ' - REMOVE <br>';
        }
      }
      return resultText;
    },
    acceptORMJob () {
      NProgress.start();
      Axios.post(`/fe/card/${this.card.card_id}/orm/job-accept`, {})
          .then(response => {
            if (response?.data?._status) {
              toastr.success('ORM job [ORMnumber] has been successfully Accepted.');
            }
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            NProgress.done();
          })
    },
    declineORMJob () {
      this.$refs.declineORMJob.hide();
      NProgress.start();
      Axios.post(`/fe/card/${this.card.card_id}/orm/job-decline`, {})
          .then(response => {
            if (response?.data?._status) {
              toastr.success('ORM job [TestOrmNumber] has been successfully declined.')
              if (!_.isEmpty(this.$route.params.trackerFilter)) {
                this.$store.commit('setCardFilter', this.$route.params.trackerFilter);
              }
              this.$router.push({ name: 'Cards', params: {filters: this.$route.params.filters, trackerFilter: this.$route.params.trackerFilter} })
            }
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            NProgress.done();
          })
    },
    undoAddSplitAll(type) {
      this.$refs.contextForEstimateAllItems.close();
      this.isShowContext2 = false;
      this.isShowAddSplit2 = false;
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/card/${this.card.card_id}/job/undo-split-all`,
        validateStatus: function (status) {
          return status < 500
        },
        data: {
          type: type,
        },
      }).then(response => {
        if (response.status === 200 && response.data._status) {
          NProgress.start();
          Axios.get(`/fe/card/${this.card.card_id}`)
              .then(response => {
                if(response.status === 200 && response.data){
                  this.jobCard = response.data.jobCard;
                }
              })
              .catch(e => console.error(e))
              .finally(() => {
                NProgress.done();
              });
        } else {
          toastr.error(response.data.msg)
        }
      })
    },
    checkAllEstItms(type, isCheckAll) {
      this.isShowContext2 = false;
      this.isShowAddSplit2 = false;
      if (type === 'Remove') {
        _.forEach(this.jobCard.RR, (itm) => {
          if (isCheckAll) {
            itm.is_checked_remove = true;
            itm.job_user_fullname_remove = this.userInfo.full_name;
          } else {
            itm.is_checked_remove = false;
            itm.job_user_fullname_remove = '';
          }
          this.onChangeJobCardCheckedItemOfRR(itm, 'Remove');
        });
      } else if (type === 'Replace') {
        _.forEach(this.jobCard.RR, (itm) => {
          if (isCheckAll) {
            itm.is_checked_replace = true;
            itm.job_user_fullname_replace = this.userInfo.full_name;
          } else {
            itm.is_checked_replace = false;
            itm.job_user_fullname_replace = '';
          }
          this.onChangeJobCardCheckedItemOfRR(itm, 'Replace');
        });
      } else {
        _.forEach(this.jobCard[type], (itm) => {
          if (isCheckAll) {
            itm.is_checked = true;
            itm.job_user_fullname = this.userInfo.full_name;
          } else {
            itm.is_checked = false;
            itm.job_user_fullname = '';
          }
          this.onChangeJobCardCheckedItem(itm, type);
        });
      }
    },
    showAddAllSplit() {
      this.isShowAddSplit2 = true;
      setTimeout(() => {
        this.$refs['splitAll'].focus();
      }, 300);
    },
    addAllSplit(type, percent) {
      this.$refs.contextForEstimateAllItems.close();
      this.isShowContext2 = false;
      this.isShowAddSplit2 = false;
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/card/${this.card.card_id}/job/add-split-all`,
        validateStatus: function (status) {
          return status < 500
        },
        data: {
          type: type,
          percent: Number(percent),
        },
      })
          .then(response => {
            if (response.status === 200 && response.data._status) {
              NProgress.start();
              Axios.get(`/fe/card/${this.card.card_id}`)
                  .then(response => {
                    if(response.status === 200 && response.data){
                      this.jobCard = response.data.jobCard;
                    }
                  })
                  .catch(e => console.error(e))
                  .finally(() => {
                    NProgress.done();
                  });
            }
          })
          .catch(e => console.error(e));
    },
    openAddSplitAllContext(e, data) {
     this.$refs.contextForEstimateAllItems.open(e, data)
    },
    getIsAllPartsSplitIsReady(type) {
      let jobCard = [];
      if (type === 'Replace' || type === 'Remove') {
        jobCard = this.jobCardRR.slice();
      } else {
        jobCard = this['jobCard' + type].slice();
      }
      let isAllPartsSplitIsReady = true;
      _.forEach(jobCard, (c) => {
        if (type === 'Replace' && c.split_replace.length && Number(c.percent_replace) !== 100) {
          isAllPartsSplitIsReady = false;
          return;
        } else if (type === 'Remove' && c.split_remove.length && Number(c.percent_remove) !== 100) {
          isAllPartsSplitIsReady = false;
          return;
        } else if (type !== 'Remove' && type !== 'Replace' && c.split.length && Number(c.percent) !== 100) {
          isAllPartsSplitIsReady = false;
          return;
        }
      })
      return isAllPartsSplitIsReady;
    },
    addNewSplitWithGlobalPercent(type, splitType, id, percent, allPercent) {
      this.$refs.contextForEstimateItem.close();
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/card/${this.card.card_id}/job/add-split`,
        validateStatus: function (status) {
          return status < 500
        },
        data: {
          "type": splitType,
          "id": Number(id),
          "percent": Number(percent),
          // 'Alltick': allPercent
        },
      }).then(response => {
        if (response.status === 200 && response.data._status) {
          let item = _.find(this.jobCard[type], (itm) => {
            return itm.id === id;
          })
          if (type === 'RR') {
            if (splitType === 'Remove') {
              item.split_remove.push(response.data.splitRecord);
              item.percent_remove = response.data.jobTotalPercent;
              item.is_checked_remove = true;
              item.job_user_fullname_remove = this.userInfo.full_name;
            } else {
              item.split_replace.push(response.data.splitRecord);
              item.percent_replace = response.data.jobTotalPercent;
              item.is_checked_replace = true;
              item.job_user_fullname_replace = this.userInfo.full_name;
            }

          } else {
            item.split.push(response.data.splitRecord);
            item.percent = response.data.jobTotalPercent;
            item.is_checked = true;
            item.job_user_fullname = this.userInfo.full_name;
          }
        } else {
          toastr.error(response.data.msg)
        }
      })
    },
    checkUpdate() {
      Axios.get(`/fe/updater/card/${this.card.card_id}/${this.lastTimestampImages}`)
          .then((response) => {
            this.lastTimestampImages = response.data._timestamp;
            let isHasResponse = !!(response.data._status && response.data._update);
            if (isHasResponse && (response.data._update.images > 0 || response.data._update.deletedImages > 0)) {
              this.loadCard(this.$route.params.card_id, false,data => {
                this.images = data.images;
              }, false);
            }
            if (isHasResponse && (response.data._update.files > 0 || response.data._update.deletedFiles > 0)) {
              this.loadCard(this.$route.params.card_id, false,data => {
                this.updateFiles(data.files)
              }, false);
            }
            if (isHasResponse && response.data._update.cardNeedUpdate) {
              this.getUpdatedJobCardsAndAssignedName(response.data._update.timestamp);
              this.loadActivity();
              this.loadCard(this.$route.params.card_id, false ,data => {
                this.card.card_status = data.card.card_status;
                this.updateCardProgressBar();
                this.card.insurance_repair = data.card.insurance_repair;
                this.card.isTotalLoss = data.card.isTotalLoss;
                this.card.insurance.insurance_claim_type = data.card.insurance.insurance_claim_type;
                this.card.customer.name = data.card.customer.name;
                this.card.customer.customer_type = data.card.customer.customer_type;
                this.card.booking_date = data.booking_date
                this.card.completion_date = data.completion_date
                this.$nextTick(() => {
                  this.$set(this.card, 'plannerBoard', data.plannerBoard)
                })
                if (data.plannerBoard.onSite) {
                  this.onSiteReason = data.plannerBoard.onSite.reason
                  this.onSiteAttempt = data.plannerBoard.onSite.attempt
                  this.card.plannerBoard = data.plannerBoard
                  if (this.card.plannerBoard.onSite.isAttendedBooking) {
                    this.checkIsAttendYes(true, false)
                  } else if (this.card.plannerBoard.onSite.isAttendedBooking === false) {
                    this.checkIsAttendNo(true, false)
                  }
                }

              }, false);
            }
          })
    },
    addToBookingBoard() {
      if (!this.card.booking_date || !this.card.completion_date) {
        toastr.error("'Date in' and 'Date out' must not be empty");
        setTimeout(() =>  {
          if (this.currentBoards.includes('Bookings Board')) {
            let index = _.findIndex(this.currentBoards, (itm) => {
              return itm === 'Bookings Board';
            });
            if (index !== -1) {
              this.currentBoards.splice(index, 1);
            }
          }
        }, 300);
        return;
      }
      NProgress.start();
      let [dayS, monthS, yearS] = this.card.booking_date.split('/');
      let [dayE, monthE, yearE] = this.card.completion_date.split('/');
      let title = '';
      if (this.card.card_number) {
        title += this.card.card_number
        if (this.card.vehicle.rego_number || this.card.vehicle.make  || this.card.vehicle.model ) {
          title += ' - '
        }
      }
      if (this.card.vehicle.rego_number) {
        title += this.card.vehicle.rego_number + ' ';
      }
      if (this.card.vehicle.make) {
        title += this.card.vehicle.make + ' ';
      }
      if (this.card.vehicle.model) {
        title += this.card.vehicle.model;
      }
      Axios.post(
          "/fe/planner-board/add",
          {
            type: 'vehicle',
            "cardId": this.card.card_id, // required
            "dateStart": yearS + '-' + monthS + '-' + dayS, // required
            "timeStart": "00:00:00", // required
            "dateEnd": yearE + '-' + monthE + '-' + dayE, // required
            "timeEnd": "00:15:00", // required
            "title": title, // required
            "note": "", // optional
            "color": this.colors[this.getRandomNumber(0, 13)], // optional
            // "addToBoard": [ // optional
            //   "in", // optional
            //   "departure" // optional
            // ]
          }
      ).then(response => {
        toastr.success('Added successfully');
        this.loadCard(this.$route.params.card_id, false, data => {
          this.card.plannerBoard = data.plannerBoard;
          this.currentBoards.splice(0, this.currentBoards.length);

          if (data.boardIn && data.boardIn.createdDate && data.boardIn.id) {
            this.currentBoards.push('Vehicle In Board');
          }

          if (data.boardDeparture && data.boardDeparture.createdDate && data.boardDeparture.id) {
            this.currentBoards.push('Departure Board');
          }

          if (data.plannerBoard && data.plannerBoard?.vehicle?.id) {
            this.currentBoards.push('Bookings Board');
          }
        });
      })
          .finally(()=> {
            NProgress.done();
          });
    },
    getRandomNumber(min, max) {
      let rand = min - 0.5 + Math.random() * (max - min + 1);
      return Math.round(rand);
    },
    removeFromBookingBoard() {
      NProgress.start();
      Axios.post(
          "/fe/planner-board/delete",
          {
            id: this.card.plannerBoard.vehicle.id,
          }
      ).then(response => {
        toastr.success('Removed successfully');
        this.plannerBoardGroupId = null
        this.loadCard(this.$route.params.card_id, false, data => {
          this.card.plannerBoard = data.plannerBoard;
          this.currentBoards.splice(0, this.currentBoards.length);

          if (data.boardIn && data.boardIn.createdDate && data.boardIn.id) {
            this.currentBoards.push('Vehicle In Board');
          }

          if (data.boardDeparture && data.boardDeparture.createdDate && data.boardDeparture.id) {
            this.currentBoards.push('Departure Board');
          }

          if (data.plannerBoard && data.plannerBoard?.vehicle?.id) {
            this.currentBoards.push('Bookings Board');
          }
        });
      })
          .finally(()=> {
            NProgress.done();
          });
    },
    goToUpdateSplit(data, type, splitItem) {
      data.splitType = type;
      data.splitPercent = splitItem.percent;
      data.selectedSplitId = splitItem.id;
      setTimeout(() => {
        this.$refs['split-edit-' + data.item.id].focus();
      }, 10)
    },
    goToUpdateSplitForMobile(data, type, splitItem) {
      this.splitTypeForMobileContext = type;
      this.splitPercentForMobileContext = splitItem.percent;
      data.selectedSplitId = splitItem.id;
      setTimeout(() => {
        this.$refs['split-edit-' + data.item.id].focus();
      }, 10)
    },
    addNewSplitForMobileContext(data) {
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/card/${this.card.card_id}/job/add-split`,
        validateStatus: function (status) {
          return status < 500
        },
        data: {
          "type": this.splitTypeForMobileContext === 'Refit' ? 'Replace' : this.splitTypeForMobileContext,
          "id": Number(data.item.id),
          "percent": Number(this.splitPercentForMobileContext),
        },
      }).then(response => {
        if (response.status === 200 && response.data._status) {
          if (data.type === 'RR') {
            let item = _.find(this.jobCard.RR, (i) => {
                return i.id == data.item.id;
            })
            if (this.splitTypeForMobileContext === 'Remove') {
              item.split_remove.push(response.data.splitRecord);
              this.$set(item, 'percent_remove', response.data.jobTotalPercent);
              this.$set(item, 'is_checked_remove', true);
              this.$set(item, 'job_user_fullname_remove', this.userInfo.full_name);
            } else {
              item.split_replace.push(response.data.splitRecord);
              this.$set(item, 'percent_replace', response.data.jobTotalPercent);
              this.$set(item, 'is_checked_replace', true);
              this.$set(item, 'job_user_fullname_replace', this.userInfo.full_name);
            }

          } else {
            let item = _.find(this.jobCard[data.type], (i) => {
              return i.id == data.item.id;
            })
            item.split.push(response.data.splitRecord);
            this.$set(item, 'percent', response.data.jobTotalPercent);
            this.$set(item, 'is_checked', true);
            this.$set(item, 'job_user_fullname', this.userInfo.full_name);
          }
        } else {
          toastr.error(response.data.msg)
        }
      })

      this.isShowContext = false;
    },
    updateSplitForMobileContext(data) {
      if (!data.selectedSplitId) {
        toastr.error('Please select a split');
        return;
      }
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/card/job/change-split`,
        validateStatus: function (status) {
          return status < 500
        },
        data: {
          id: data.selectedSplitId,
          percent: this.splitPercentForMobileContext,
        },
      }).then(response => {
        if (response.status === 200 && response.data._status) {
          toastr.success('Changed successfully');
          if (data.type === 'RR') {
            let item = _.find(this.jobCard.RR, (it) => {
              return it.id == data.item.id;
            });
            let index = _.findIndex(item.split_remove, (i) => {
              return i.id == data.selectedSplitId;
            });
            let index2 = _.findIndex(item.split_replace, (i) => {
              return i.id == data.selectedSplitId;
            });

            if (index !== -1) {
              this.$set(item.split_remove[index], 'percent', this.splitPercentForMobileContext);
              this.$set(item, 'percent_remove', response.data.jobTotalPercent);
            } else if (index2 !== -1) {
              this.$set(item.split_replace[index2], 'percent', this.splitPercentForMobileContext);
              this.$set(item, 'percent_replace', response.data.jobTotalPercent);
            }

          } else {
            let item = _.find(this.jobCard[data.type], (i) => {
              return i.id == data.item.id;
            })
            let index = _.findIndex(item.split, (i) => {
              return i.id == data.selectedSplitId;
            });
            if (index !== -1) {
              this.$set(item.split[index], 'percent', this.splitPercentForMobileContext);
              this.$set(item, 'percent', response.data.jobTotalPercent);
            }
          }
          this.splitPercentForMobileContext = null;
          this.selectedEstimateItemData.selectedSplitId = null;

        } else {
          toastr.error(response.data.msg)
        }
      })
    },
    removeSplitForMobileContext(splitItem, data) {
      console.log(splitItem, data, 'removing')
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/card/job/remove-split`,
        validateStatus: function (status) {
          return status < 500
        },
        data: {
          id: splitItem.id,
        },
      }).then(response => {
        if (response.status === 200 && response.data._status) {
          if (data.type === 'RR') {
            let item = _.find(this.jobCard.RR, (i) => {
              return i.id == data.item.id;
            })
            let index = _.findIndex(item.split_remove, (i) => {
              return i.id == splitItem.id;
            });
            let index2 = _.findIndex(item.split_replace, (i) => {
              return i.id == splitItem.id;
            });

            if (index !== -1) {
              item.split_remove.splice(index, 1);
              this.$set(item, 'percent_remove', response.data.jobTotalPercent);
              if (response.data.jobTotalPercent == 0) {
                this.$set(item, 'is_checked_remove', false);
                this.$set(item, 'job_user_fullname_remove', "");
              }
            } else if (index2 !== -1) {
              item.split_replace.splice(index2, 1);
              this.$set(item, 'percent_replace', response.data.jobTotalPercent);
              if (response.data.jobTotalPercent == 0) {
                this.$set(item, 'is_checked_replace', false);
                this.$set(item, 'job_user_fullname_replace', "");
              }
            }
          } else {
            let item = _.find(this.jobCard[data.type], (i) => {
              return i.id == data.item.id;
            })
            let index = _.findIndex(item.split, (i) => {
              return i.id == splitItem.id;
            });
            if (index !== -1) {
              item.split.splice(index, 1);
              this.$set(item, 'percent', response.data.jobTotalPercent);
              if (response.data.jobTotalPercent == 0) {
                this.$set(item, 'is_checked', false);
                this.$set(item, 'job_user_fullname', "");
              }
            }
          }
          toastr.error('Removed!')
        } else {
          toastr.error(response.data.msg)
        }
      })
    },
    addNewSplit(data) {
      this.$refs.contextForEstimateItem.close();
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/card/${this.card.card_id}/job/add-split`,
        validateStatus: function (status) {
          return status < 500
        },
        data: {
          "type": data.splitType === 'Refit' ? 'Replace' : data.splitType,
          "id": Number(data.item.id),
          "percent": Number(data.splitPercent),
        },
      }).then(response => {
        if (response.status === 200 && response.data._status) {
          if (data.type === 'RR') {
            if (data.splitType === 'Remove') {
              data.item.split_remove.push(response.data.splitRecord);
              data.item.percent_remove = response.data.jobTotalPercent;
              data.item.is_checked_remove = true;
              data.item.job_user_fullname_remove = this.userInfo.full_name;
            } else {
              data.item.split_replace.push(response.data.splitRecord);
              data.item.percent_replace = response.data.jobTotalPercent;
              data.item.is_checked_replace = true;
              data.item.job_user_fullname_replace = this.userInfo.full_name;
            }

          } else {
            data.item.split.push(response.data.splitRecord);
            data.item.percent = response.data.jobTotalPercent;
            data.item.is_checked = true;
            data.item.job_user_fullname = this.userInfo.full_name;
          }
        } else {
          toastr.error(response.data.msg)
        }
      })
    },
    updateSplit(data) {
      if (!data.selectedSplitId) {
        toastr.error('Please select a split');
        return;
      }
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/card/job/change-split`,
        validateStatus: function (status) {
          return status < 500
        },
        data: {
          id: data.selectedSplitId,
          percent: data.splitPercent,
        },
      }).then(response => {
        if (response.status === 200 && response.data._status) {
          toastr.success('Changed successfully');
          if (data.type === 'RR') {
            let index = _.findIndex(data.item.split_remove, (item) => {
              return item.id == data.selectedSplitId;
            });
            let index2 = _.findIndex(data.item.split_replace, (item) => {
              return item.id == data.selectedSplitId;
            });

            if (index !== -1) {
              data.item.split_remove[index].percent = data.splitPercent;
              data.item.percent_remove = response.data.jobTotalPercent;
            } else if (index2 !== -1) {
              data.item.split_replace[index2].percent = data.splitPercent;
              data.item.percent_replace = response.data.jobTotalPercent;
            }

          } else {
            let index = _.findIndex(data.item.split, (item) => {
              return item.id == data.selectedSplitId;
            });
            if (index !== -1) {
              data.item.split[index].percent = data.splitPercent;
              data.item.percent = response.data.jobTotalPercent;
            }
          }

        } else {
          toastr.error(response.data.msg)
        }
      })
    },
    removeSplit(splitItem, data) {
      console.log(splitItem, data, 'removing')
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/card/job/remove-split`,
        validateStatus: function (status) {
          return status < 500
        },
        data: {
          id: splitItem.id,
        },
      }).then(response => {
        if (response.status === 200 && response.data._status) {
          if (data.type === 'RR') {
            let index = _.findIndex(data.item.split_remove, (item) => {
              return item.id == splitItem.id;
            });
            let index2 = _.findIndex(data.item.split_replace, (item) => {
              return item.id == splitItem.id;
            });

            if (index !== -1) {
              data.item.split_remove.splice(index, 1);
              data.item.percent_remove = response.data.jobTotalPercent;
              if (data.item.percent_remove == 0) {
                data.item.is_checked_remove = false;
                data.item.job_user_fullname_remove = "";
              }
            } else if (index2 !== -1) {
              data.item.split_replace.splice(index2, 1);
              data.item.percent_replace = response.data.jobTotalPercent;
              if (data.item.percent_replace == 0) {
                data.item.is_checked_replace = false;
                data.item.job_user_fullname_replace = "";
              }
            }
          } else {
            let index = _.findIndex(data.item.split, (item) => {
              return item.id == splitItem.id;
            });
            if (index !== -1) {
              data.item.split.splice(index, 1);
              data.item.percent = response.data.jobTotalPercent;
              if (data.item.percent == 0) {
                data.item.is_checked = false;
                data.item.job_user_fullname = "";
              }
            }
          }
          toastr.error('Removed!')
        } else {
          toastr.error(response.data.msg)
        }
      })
    },
    removeSplit2(splitItem, item, type) {
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/card/job/remove-split`,
        validateStatus: function (status) {
          return status < 500
        },
        data: {
          id: splitItem.id,
        },
      }).then(response => {
        if (response.status === 200 && response.data._status) {
          if (type === 'RR') {
            let index = _.findIndex(item.split_remove, (item) => {
              return item.id == splitItem.id;
            });
            let index2 = _.findIndex(item.split_replace, (item) => {
              return item.id == splitItem.id;
            });

            if (index !== -1) {
              item.split_remove.splice(index, 1);
              item.percent_remove = response.data.jobTotalPercent;
              if (item.percent_remove == 0) {
                item.is_checked_remove = true;
                item.job_user_fullname_remove = this.userInfo.full_name;
                this.onChangeJobCardCheckedItemOfRR(item, 'Remove');
              }
            } else if (index2 !== -1) {
              item.split_replace.splice(index2, 1);
              item.percent_replace = response.data.jobTotalPercent;
              if (item.percent_replace == 0) {
                item.is_checked_replace = true;
                item.job_user_fullname_replace = this.userInfo.full_name;
                this.onChangeJobCardCheckedItemOfRR(item, 'Replace');
              }
            }
          } else {
            let index = _.findIndex(item.split, (item) => {
              return item.id == splitItem.id;
            });
            if (index !== -1) {
              item.split.splice(index, 1);
              item.percent = response.data.jobTotalPercent;
              if (item.percent == 0) {
                item.is_checked = true;
                item.job_user_fullname = this.userInfo.full_name;
                this.onChangeJobCardCheckedItem(item, type);
              }
            }
          }
        } else {
          toastr.error(response.data.msg)
        }
      })
    },
    deleteSplitRemoveChecked: function (item, type) {
      _.forEach(item.split_remove, (subItem) => {
        this.removeSplit2(subItem, item, type);
      })
    },
    deleteSplitReplaceChecked: function (item, type) {
      console.log(item, 'item')
      _.forEach(item.split_replace, (subItem) => {
        this.removeSplit2(subItem,  item, type);
      })
    },
    deleteSplitChecked: function (item, type) {
      console.log(item, 'item')
      _.forEach(item.split, (subItem) => {
        this.removeSplit2(subItem, item, type);
      })
    },
    addCardToBoardFromMultiselect (opt) {
      if (opt === 'Vehicle In Board') {
        this.addedToVehicleInBoard()
      } else if (opt === 'Departure Board') {
        this.addToDepartureBoard()
      } else if (opt === "Bookings Board") {
        this.addToBookingBoard();
      }
    },
    removeCardToBoardFromMultiselect (opt) {
      if (opt === 'Vehicle In Board') {
        this.removeVehicle(this.card.boardIn.id)
      } else if (opt === 'Departure Board') {
        this.removeVehicle(this.card.boardDeparture.id)
      } else if (opt === "Bookings Board") {
        this.removeFromBookingBoard(this.card.plannerBoard?.vehicle?.id);
      }
    },
    removeVehicle (id) {
      NProgress.start()
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/board/remove`,
        validateStatus: function (status) {
          return status < 500
        },
        data: {
          boardId: id,
        },
      }).then(response => {
        if (response.status === 200 && response.data._status) {
          toastr.success('Removed successfully')
          this.loadCard(this.$route.params.card_id, true)
        } else {
          toastr.error(response.data.msg)
        }
      })
          .catch(error => {
            //toastr.error(error);
          })
          .finally(() => {
            NProgress.done()
          })
    },
    changedEmailAddress () {
      this.checkEmail2()
      this.autoUpdateContacts()
    },
    checkEmail () {
      if (this.card.contactEmail.includes('@')) {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        this.isCheckEmail = re.test(this.card.contactEmail)
      } else {
        this.isCheckEmail = true
      }
    },
    checkEmail2 () {
      if (this.card.contactEmail === '' || !(this.card.contactEmail && this.card.contactEmail.trim())) {
        return
      }
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.isCheckEmail = re.test(this.card.contactEmail)
    },
    closeContextForEstimateItem () {
      if (this.selectedEstimateItemData && !_.isEmpty(this.selectedEstimateItemData.item)) {
        let estimateItem = this.$refs['estimateItem' + this.selectedEstimateItemData.item.id]
        estimateItem[0].style.cssText = 'background-color: transparent'
        this.selectedEstimateItemData.item = {}
      }
      this.isOpenContextMenuForJobCard = false
    },
    isHasMineComment (comments) {
      let b = false
      let vm = this
      _.forEach(comments, function (item) {
        if (item.userId == vm.userInfo.user_id) {
          b = true
        }
      })
      return b
    },
    addedToVehicleInBoard () {
      NProgress.start()
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/board/in/add-card`,
        validateStatus: function (status) {
          return status < 500
        },
        data: {
          cardId: this.card.card_id,
        },
      }).then(response => {
        if (response.status === 200 && response.data._status) {
          toastr.success('Card ' + this.card.card_number + ' added successfully to the Vehicle In Board')
          this.showAddedToVehicleInBoard = true
          this.loadCard(this.$route.params.card_id, true) // refresh data of card
        } else {
          toastr.error(response.data.msg)
        }
      })
          .catch(error => {
            toastr.error(error)
          })
          .finally(() => {
            NProgress.done()
          })
    },
    touchStartForJobCardItem (event, item, type, comment) {
      if (!this.isStatusActive) {
        return
      }
      this.$root.$emit('bv::hide::tooltip')
      if (!this.isShopManagerUser && !this.isProductionManagerUser) {
        return
      }
      this.isOpenContextMenuForJobCardOnMobile = true
      let self = this
      let c = _.find(item.job_comment, function (cm) {
        return cm.userId == comment.userId
      })
      let index = item.job_comment.indexOf(c)
      this.selectedEstimateItemData = {
        item: item,
        index: index,
        type: type,
      }
      this.selectedEstimateItemData.selectedSplitId = null;
      if (this.selectedEstimateItemData.type === 'RR') {
        this.splitTypeForMobileContext = 'Remove'
      } else {
        this.splitTypeForMobileContext = this.selectedEstimateItemData.type;
      }
      this.splitPercentForMobileContext = null;

      try {
        if (event.targetTouches[0].target.parentElement.parentElement.id !== 'context-menu-iphone') {
          this.isShowContext = false;
          this.isShowAddComment = false;
          this.isShowEditComment = false;
          this.isShowAddSplit = false;
          this.isShowViewEditSplit = false;
        }
      } catch (err) {
        this.isShowContext = false;
        this.isShowAddComment = false;
        this.isShowEditComment = false;
        this.isShowAddSplit = false;
        this.isShowViewEditSplit = false;
      }

      this.touchStartTime = new Date()
      this.topForLoader = event.touches[0].pageY
      if (event.touches[0].clientY + 50 > document.body.offsetHeight) {
        this.topForTouchMenu = event.touches[0].pageY - 160
      } else if (event.touches[0].clientY + 100 > document.body.offsetHeight) {
        this.topForTouchMenu = event.touches[0].pageY - 120
      } else if (event.touches[0].clientY + 150 > document.body.offsetHeight) {
        this.topForTouchMenu = event.touches[0].pageY - 70
      } else {
        this.topForTouchMenu = event.touches[0].pageY
      }
      this.leftForLoader = event.touches[0].pageX
      console.log(window.screen.width)
      if (event.touches[0].pageX + 300 > window.screen.width) {

        this.leftForTouchMenu = window.screen.width - 300
      } else {
        this.leftForTouchMenu = event.touches[0].pageX
      }
      console.log('start', event)
      this.isShowLoader = true
    },
    touchStart2 (event, data) {
      document.body.style.WebkitUserSelect = "none"
      if (!this.isStatusActive) {
        return
      }

      this.selectedSplitAllTypeOnMobile = data.type;

      try {
        if (event.targetTouches[0].target.parentElement.parentElement.id !== 'context-menu-iphone-2') {
          this.isShowContext2 = false
          this.isShowAddSplit2 = false
        }
      } catch (err) {
        this.isShowContext2 = false
        this.isShowAddSplit2 = false
      }

      this.touchStartTime = new Date()
      this.topForLoader = event.touches[0].pageY
      if (event.touches[0].clientY + 50 > document.body.offsetHeight) {
        this.topForTouchMenu = event.touches[0].pageY - 160
      } else if (event.touches[0].clientY + 100 > document.body.offsetHeight) {
        this.topForTouchMenu = event.touches[0].pageY - 120
      } else if (event.touches[0].clientY + 150 > document.body.offsetHeight) {
        this.topForTouchMenu = event.touches[0].pageY - 70
      } else {
        this.topForTouchMenu = event.touches[0].pageY
      }
      this.leftForLoader = event.touches[0].pageX

      if (event.touches[0].pageX + 385 > window.screen.width) {

        this.leftForTouchMenu = window.screen.width - 385
      } else {
        this.leftForTouchMenu = event.touches[0].pageX
      }
      console.log('start', event)
      this.isShowLoader2 = true
    },
    touchEnd2 (event) {
      event.preventDefault()
      console.log(event)
      this.isShowLoader2 = false
      let touchEndTime = new Date()
      let touchDelay = (touchEndTime - this.touchStartTime) / 700
      if (touchDelay >= 0.7) {
        this.isShowContext2 = true
      } else {
        this.isCheckedRemoveAllRRInJobCard()
      }
      document.body.style.WebkitUserSelect = ""
    },
    touchStart (event, data) {
      document.body.style.WebkitUserSelect = "none"
      if (!this.isStatusActive) {
        return
      }
      this.$root.$emit('bv::hide::tooltip')
      if (this.isOpenContextMenuForJobCardOnMobile) {
        return
      }
      let index = null
      if (this.isHasMineComment(data.item.job_comment)) {
        let self = this
        let comment = _.find(data.item.job_comment, function (c) {
          return c.userId == self.userInfo.user_id
        })
        index = data.item.job_comment.indexOf(comment)
      }

      if (this.selectedEstimateItemData && !_.isEmpty(this.selectedEstimateItemData.item)) {
        let estimateItem = this.$refs['estimateItem' + this.selectedEstimateItemData.item.id]
        estimateItem[0].style.cssText = 'background-color: transparent'
      }

      data.index = index
      this.selectedEstimateItemData = {
        item: data.item,
        type: data.type,
        index: index,
      }

      this.selectedEstimateItemData.selectedSplitId = null;
      if (this.selectedEstimateItemData.type === 'RR') {
        this.splitTypeForMobileContext = 'Remove';
      } else {
        this.splitTypeForMobileContext = this.selectedEstimateItemData.type;
      }
      this.splitPercentForMobileContext = null;

      try {
        if (event.targetTouches[0].target.parentElement.parentElement.id !== 'context-menu-iphone') {
          this.isShowContext = false;
          this.isShowAddComment = false;
          this.isShowEditComment = false;
          this.isShowHighlightRow = false;
          this.isShowAddSplit = false;
          this.isShowViewEditSplit = false;
        }
      } catch (err) {
        this.isShowContext = false;
        this.isShowAddComment = false;
        this.isShowEditComment = false;
        this.isShowHighlightRow = false;
        this.isShowAddSplit = false;
        this.isShowViewEditSplit = false;
      }

      this.touchStartTime = new Date()
      this.topForLoader = event.touches[0].pageY
      if (event.touches[0].clientY + 50 > document.body.offsetHeight) {
        this.topForTouchMenu = event.touches[0].pageY - 160
      } else if (event.touches[0].clientY + 100 > document.body.offsetHeight) {
        this.topForTouchMenu = event.touches[0].pageY - 120
      } else if (event.touches[0].clientY + 150 > document.body.offsetHeight) {
        this.topForTouchMenu = event.touches[0].pageY - 70
      } else {
        this.topForTouchMenu = event.touches[0].pageY
      }
      this.leftForLoader = event.touches[0].pageX

      if (event.touches[0].pageX + 385 > window.screen.width) {

        this.leftForTouchMenu = window.screen.width - 385
      } else {
        this.leftForTouchMenu = event.touches[0].pageX
      }
      console.log('start', event)
      this.isShowLoader = true
    },
    touchEnd (event) {
      this.$root.$emit('bv::hide::tooltip')
      console.log(event)
      this.isShowLoader = false
      let touchEndTime = new Date()
      let touchDelay = (touchEndTime - this.touchStartTime) / 700
      if (touchDelay >= 0.7) {
        this.isShowContext = true
        let estimateItem = this.$refs['estimateItem' + this.selectedEstimateItemData.item.id]
        estimateItem[0].style.cssText = 'background-color: #F9F9F9'
      }
      setTimeout(() => {
        this.isOpenContextMenuForJobCardOnMobile = false
      }, 1000)
      document.body.style.WebkitUserSelect = ""
    },
    touchStartAddComment (event) {
      this.isShowEditComment = false;
      this.isShowAddComment = true;
      this.isShowHighlightRow = false;
      this.isShowViewEditSplit = false;
      this.isShowAddSplit = false;
    },
    touchStartEditComment (event) {
      this.isShowAddComment = false;
      this.isShowEditComment = true;
      this.isShowHighlightRow = false;
      this.isShowViewEditSplit = false;
      this.isShowAddSplit = false;
    },
    touchStartLevelHighlight (event) {
      this.isShowHighlightRow = true;
      this.isShowAddComment = false;
      this.isShowEditComment = false;
      this.isShowViewEditSplit = false;
      this.isShowAddSplit = false;
    },
    touchStartAddSplit () {
      this.splitPercentForMobileContext = null;
      this.isShowHighlightRow = false;
      this.isShowAddComment = false;
      this.isShowEditComment = false;
      this.isShowViewEditSplit = false;
      this.isShowAddSplit = true;
      setTimeout(() => {
        this.$refs['split-' +  this.selectedEstimateItemData.item.id].focus();
      }, 10);
    },
    toushStartViewEditSplit () {
      this.splitPercentForMobileContext = null;
      this.isShowHighlightRow = false;
      this.isShowAddComment = false;
      this.isShowEditComment = false;
      this.isShowViewEditSplit = true;
      this.isShowAddSplit = false;
    },
    touchClose (event) {
      if (event.targetTouches[0].target.parentElement.parentElement.id !== 'context-menu-iphone' || event.targetTouches[0].target.parentElement.parentElement.id !== 'context-menu-iphone-2') {
        this.isShowContext = false;
        this.isShowContext2 = false;
        this.isShowAddComment = false;
        this.isShowEditComment = false;
        this.isShowHighlightRow = false;
        this.isShowAddSplit = false;
        this.isShowViewEditSplit = false;
        if (this.selectedEstimateItemData && !_.isEmpty(this.selectedEstimateItemData.item)) {
          let estimateItem = this.$refs['estimateItem' + this.selectedEstimateItemData.item.id]
          estimateItem[0].style.cssText = 'background-color: transparent'
        }
      }
    },
    addedImageToDepartureBoard (boardId, imageId) {
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/board/add-image`,
        validateStatus: function (status) {
          return status < 500
        },
        data: {
          boardId: boardId,
          imageId: imageId,
        },
      }).then(response => {
        if (response.status === 200 && response.data._status) {
          //toastr.success('Image added successfully to the Departure Board');
        }
      })
          .catch(error => {
            //toastr.error(error);
          })
          .finally(() => {
            NProgress.done()
          })
    },
    addToDepartureBoard () {
      NProgress.start()
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/board/departure/add-card`,
        validateStatus: function (status) {
          return status < 500
        },
        data: {
          cardId: this.card.card_id,
        },
      }).then(async response => {
        if (response.status === 200 && response.data._status) {
          toastr.success('Card ' + this.card.card_number + ' added successfully to the Departure Board')
          this.isNeedAddImgsToDeparture = true
          this.loadCard(this.$route.params.card_id, true) // refresh data of card
        } else {
          toastr.error(response.data.msg)
        }
      })
          .catch(error => {
            toastr.error(error)
          })
          .finally(() => {
            NProgress.done()
          })
    },
    openContext () {
      let estimateItem = this.$refs['estimateItem' + this.selectedEstimateItemData.item.id]
      estimateItem[0].style.cssText = 'background-color: #F9F9F9'
    },
    changeHighlightRow: function (id, type, color) {
      let update = {}
      update.jobCardHighlight = {}
      update.jobCardHighlight[type] = {}
      update.jobCardHighlight[type][id] = color
      this.setUpdateData(update)
      this.beforeDebouncedAutoSave()
    },
    addEstimateItemComment: function (id, type, comment) {
      let update = {}
      update.jobCardComment = {}
      update.jobCardComment[type] = {}
      update.jobCardComment[type][id] = comment
      this.setUpdateData(update)
      this.beforeDebouncedAutoSave()
    },
    clickOptionOfContextForEstimateItem (data, comment, type) {
      let id = data.item.id
      let item = data.item
      let estimateType = data.type
      if (type === 1) {
        this.addEstimateItemComment(id, estimateType, this.currentEstimateItemComment)

        if (item.job_comment === null) {
          item.job_comment = [{
            author: this.userInfo.full_name,
            text: this.currentEstimateItemComment,
            userId: this.userInfo.user_id,
          }]
        } else {
          item.job_comment.push({
            author: this.userInfo.full_name,
            text: this.currentEstimateItemComment,
            userId: this.userInfo.user_id,
          })
        }

        this.currentEstimateItemComment = ''
        this.$refs.contextForEstimateItem.close()
      } else if (type === 2) {
        if (this.isShopManagerUser || this.isProductionManagerUser) {
          if (comment.userId == this.userInfo.user_id) {
            this.addEstimateItemComment(id, estimateType, comment.text)
          } else {
            //comment.author = this.userInfo.full_name;
            this.addEstimateItemComment(id, estimateType, {
              text: comment.text,
              userId: comment.userId,
            })
          }
        } else {
          this.addEstimateItemComment(id, estimateType, comment.text)
        }
        this.$refs.contextForEstimateItem.close()
      } else if (type === 3) {
        if (this.isShopManagerUser || this.isProductionManagerUser) {
          if (comment.userId == this.userInfo.user_id) {
            this.addEstimateItemComment(id, estimateType, null)
          } else {
            comment.author = this.userInfo.full_name
            this.addEstimateItemComment(id, estimateType, {
              text: null,
              userId: comment.userId,
            })
          }
        } else {
          this.addEstimateItemComment(id, estimateType, null)
        }
        let commentForDelete = _.find(item.job_comment, function (item) {
          return item.userId === comment.userId
        })
        let index = item.job_comment.indexOf(commentForDelete)
        item.job_comment.splice(index, 1)
        comment.text = null
        comment.author = ''
        this.$refs.contextForEstimateItem.close()
      } else if (type === 4) {
        this.changeHighlightRow(id, estimateType, 'yellow')
        item.job_highlight = 'yellow'
        this.$refs.contextForEstimateItem.close()
      } else if (type === 5) {
        this.changeHighlightRow(id, estimateType, 'green')
        item.job_highlight = 'green'
        this.$refs.contextForEstimateItem.close()
      } else if (type === 6) {
        this.changeHighlightRow(id, estimateType, 'purple')
        item.job_highlight = 'purple'
        this.$refs.contextForEstimateItem.close()
      } else if (type === 7) {
        this.changeHighlightRow(id, estimateType, 'none')
        item.job_highlight = 'none'
        this.$refs.contextForEstimateItem.close()
      }
      if (this.isShowContext) {
        if (this.selectedEstimateItemData && !_.isEmpty(this.selectedEstimateItemData.item)) {
          let estimateItem = this.$refs['estimateItem' + this.selectedEstimateItemData.item.id]
          estimateItem[0].style.cssText = 'background-color: transparent'
        }
        this.selectedEstimateItemData.item = {};
        this.selectedEstimateItemData.index = null;
        this.selectedEstimateItemData.type = '';
        this.isShowContext = false;
        this.isOpenContextMenuForJobCardOnMobile = false;
        this.isShowAddComment = false;
        this.isShowEditComment = false;
        this.isShowHighlightRow = false;
        this.isShowAddSplit = false;
        this.isShowViewEditSplit = false;
      }
    },
    longtapHandler (params) {
      let vm = this
      return function (direction, event) {
        console.log('longtapHandler', direction, event, params)
        vm.openMenuForEstimateItem(direction, params)
      }
    },
    openMenuForEstimateItem: function (event, data) {
      if (this.isMobile || !this.isStatusActive) {
        return
      }
      console.log('data', data)
      if (this.selectedEstimateItemData && !_.isEmpty(this.selectedEstimateItemData.item)) {
        let estimateItem = this.$refs['estimateItem' + this.selectedEstimateItemData.item.id]
        estimateItem[0].style.cssText = 'background-color: transparent'
      }
      this.selectedEstimateItemData.item = data.item
      let index = null
      if (this.isHasMineComment(data.item.job_comment)) {
        let self = this
        let comment = _.find(data.item.job_comment, function (c) {
          return c.userId == self.userInfo.user_id
        })
        index = data.item.job_comment.indexOf(comment)
      }
      data.index = index;
      data.selectedSplitId = null;
      if (data.type === 'RR') {
        data.splitType = 'Remove';
      } else {
        data.splitType = data.type;
      }
      data.splitPercent = null;
      if (!this.isOpenContextMenuForJobCard || !(this.isShopManagerUser || this.isProductionManagerUser)) {
        this.$refs.contextForEstimateItem.open(event, data)
      }
    },
    openMenuForJobCardItem (event, item, type, comment) {
      if (this.isMobile || this.isStatusActive) {
        return
      }
      let self = this;
      let c = _.find(item.job_comment, function (cm) {
        return cm.userId == comment.userId
      })
      let index = item.job_comment.indexOf(c)
      let data = {
        item: item,
        index: index,
        type: type,
      }

      data.index = index;
      data.selectedSplitId = null;
      if (data.type === 'RR') {
        data.splitType = 'Remove';
      } else {
        data.splitType = data.type;
      }
      data.splitPercent = null;

      if (this.isShopManagerUser || this.isProductionManagerUser) {
        this.isOpenContextMenuForJobCard = true
        this.selectedEstimateItemData.item = data.item
        this.$refs.contextForEstimateItem.open(event, data)
      }
    },
    onCustomerAddressSelect: function (address) {
      console.log('address', address)
      if (address && address.status === true) {
        if (address.country) {
          this.card.customer.country = address.country
        } else {
          this.card.customer.country = ''
        }
        this.card.customer.address = address.fullAddress
        if (address.unit) {
          this.card.customer.level_unit_lot = address.unit
        } else if (address.subpremise) {
          this.card.customer.level_unit_lot = address.subpremise
        } else {
          this.card.customer.level_unit_lot = ''
        }

        if (address.postal_code) {
          this.card.customer.postcode = address.postal_code
        } else {
          this.card.customer.postcode = ''
        }
        if (address.state) {
          this.card.customer.state = address.state
        } else {
          this.card.customer.state = ''
        }
        if (address.locality) {
          this.card.customer.suburb = address.locality
        } else {
          this.card.customer.suburb = ''
        }
        if (address.street_number && address.street) {
          this.card.customer.street_name = address.street_number + ' ' + address.street
        } else if (address.street_number) {
          this.card.customer.street_name = address.street_number
        } else if (address.street) {
          this.card.customer.street_name = address.street
        } else {
          this.card.customer.street_name = ''
        }

        this.autoUpdateCardCustomer()
      }
    },
    addNote: function () {
      this.$refs['notes-tab-link'].click()
      this.$refs['cardNotes'].addNote()
      this.isOpenNewTaskForm = true
    },
    vehicleOwnerTabChange () {
      this.currentTab = 'vehicleOwner'
      this.cardSaveElement = 'All'
      this.isHiddenOptions = false
      this.isHiddenNewNote = false
      this.classTabContent = 'tab-content'
      this.isActivityChangeInDetailTab = false
      this.updateCardProgressBar()
      this.$storage.set('views.cardsEdit.' + this.$route.params.card_id + '.defaultView', 'vehicleOwner')
    },
    notesTabChange () {
      this.currentTab = 'notes'
      this.cardSaveElement = 'All'
      this.isHiddenOptions = false
      this.isHiddenNewNote = false
      this.classTabContent = 'tab-content-table'
      this.$storage.set('views.cardsEdit.' + this.$route.params.card_id + '.defaultView', 'notes')
    },
    imagesTabChange () {
      this.currentTab = 'images'
      this.cardSaveElement = 'Images'
      this.isHiddenOptions = false
      this.isHiddenNewNote = false
      this.classTabContent = 'tab-content'
      this.$storage.set('views.cardsEdit.' + this.$route.params.card_id + '.defaultView', 'images')
    },
    filesTabChange () {
      this.currentTab = 'files'
      this.cardSaveElement = 'Files'
      this.isHiddenOptions = false
      this.isHiddenNewNote = false
      this.classTabContent = 'tab-content'
      this.$storage.set('views.cardsEdit.' + this.$route.params.card_id + '.defaultView', 'files')
    },
    activityTabChange () {
      this.currentTab = 'activity'
      this.cardSaveElement = 'All'
      this.isHiddenOptions = false
      this.isHiddenNewNote = true
      this.classTabContent = 'tab-content'
      this.$storage.set('views.cardsEdit.' + this.$route.params.card_id + '.defaultView', 'activity')
      setTimeout(function () {
        let circlesNum = document.querySelectorAll('.wizard-nav-pills-activity li').length
        document.getElementsByClassName('wizard-navigation-activity')[0].style.width = 89.3 * circlesNum + 'px'
      }, 10)
    },
    jobCardTabChange () {
      this.currentTab = 'jobCard'
      this.cardSaveElement = 'All'
      this.isHiddenOptions = false
      this.isHiddenNewNote = false
      this.classTabContent = 'tab-content'
      this.$storage.set('views.cardsEdit.' + this.$route.params.card_id + '.defaultView', 'jobCard')
    },
    saveNewTask: function (newTask) {
      let update = {}
      update.newTask = newTask
      this.setUpdateData(update, true)
      //this.autoSave();
      toastr.success('The New Task created')
      this.isOpenNewTaskForm = false
    },
    closeNewTask: function () {
      //this.isOpenNewTask = false;
      this.newTask = {}
      this.isOpenNewTaskForm = false
    },
    closeTask: function () {
    },
    deleteTask: function (task) {
      let update = {}
      update.task = task
      this.setUpdateData(update, true)
      this.$store.commit('dashboard/deleteTask', task.id)
      this.$store.commit('dashboard/deleteComment', task.id)
      //this.autoSave();
      toastr.success('The Task deleted')
    },
    deleteActivity: function (id, isEmail = false) {
      Axios.post('/fe/activity/delete',
          {
            'activityId': id
          }
      )
          .then(response => {
            if (response.data._status) {
              toastr.success('The Activity list entry deleted')
              let removedItem = _.filter(this.activity, function (item) {
                return item.activity_id == id
              })
              let index = this.activity.indexOf(removedItem[0])
              this.activity.splice(index, 1)
              if (isEmail) {
                this.$store.commit('dashboard/deleteEmail', id)
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
    },
    saveTask: function (task) {
      let update = {}
      update.task = task
      this.setUpdateData(update, true)
      toastr.success('The Task updated')
    },
    openNewTask: function () {
      const today = new Date()
      let month = ('0' + (today.getMonth() + 1)).slice(-2)
      let date = ('0' + today.getDate()).slice(-2)
      let year = today.getFullYear()

      this.newTask = {
        name: '',
        date: null,
        time: null,
        notes: '',
        type: { key: 'to-do', value: 'ToDo' },
        assignedTo: { id: '-1', name: 'Estimators' },
        status: { key: 'none', value: 'None' },
      }
      this.$refs['activity-tab-link'].click()
      this.isOpenNewTaskForm = true
    },
    loadPaintCodes: function () {
      if (this.isEstimateMethodologyCustom) {
        return false
      }
      let make = this.card.vehicle.make
      if (make != '') {
        Axios({
          method: 'get',
          responseType: 'json',
          url: `/fe/vehicle/paint-code/${make}`,
          validateStatus: function (status) {
            return status < 500
          },
        })
            .then(response => {
              if (response.status === 200) {
                this.vehiclePaintCodes = response.data
              }
            })
            .catch(error => {
              console.log(error)
            })
      }
    },
    //setChecked
    isEmpty: function (value) {
      return _.isEmpty(value)
    },
    onChangeJobCardCheckedItem: function (item, type) {
      if (item.is_checked) {
        item.job_user_fullname = this.userInfo.full_name
      } else {
        item.job_user_fullname = ''
      }
      let update = {}
      update.jobCard = {}
      update.jobCard[type] = {}
      update.jobCard[type][item.id] = item.is_checked
      this.setUpdateData(update)
      this.beforeDebouncedAutoSave()
    },
    onChangeJobCardCheckedItemOfRR: function (item, type) {
      let update = {}
      update.jobCard = {}
      update.jobCard[type] = {}
      if (type === 'Remove') {
        update.jobCard[type][item.id] = item.is_checked_remove
        if (item.is_checked_remove) {
          item.job_user_fullname_remove = this.userInfo.full_name
        } else if (!item.is_checked_remove && !item.is_checked_replace) {
          item.job_user_fullname_remove = ''
        }
      } else if (type === 'Replace') {
        update.jobCard[type][item.id] = item.is_checked_replace
        if (item.is_checked_replace) {
          item.job_user_fullname_replace = this.userInfo.full_name
        } else if (!item.is_checked_replace && !item.is_checked_remove) {
          item.job_user_fullname_replace = ''
        }
      }
      this.setUpdateData(update)
      this.beforeDebouncedAutoSave()
    },
    setUpdateData: function (v, isImmediately = false) {
      let uid = Math.random().toString(36).substr(2, 9) + Date.now()
      this.updateDataInCardEdit({ uid, v })
      if (isImmediately) {
        this.autoSave()
      } else {
        this.beforeDebouncedAutoSave()
      }
    },
    assignedToOpen () {
      if (!this.isStatusActive) {
        return
      }
      setTimeout(() => {
        document.addEventListener('click', (e) => {
          if (!e.target.closest('.filterAssigned') && !e.target.closest('.openfilterTarget') && this.$refs.AssignedfilterRef) {
            this.$refs.AssignedfilterRef.classList.remove('openFilterAssigned')
          }
        })
      }, 0)
      this.$refs.AssignedfilterRef.classList.add('openFilterAssigned')
    },
    changeAssignedName (elem) {
      let update = {}
      this.loaderAssignTo = true
      update.assignedTo = elem
      this.assignedToText = elem.name
      this.$refs.AssignedfilterRef.classList.remove('openFilterAssigned')
      this.setUpdateData(update)
      this.card.assignedTo = elem
      this.beforeDebouncedAutoSave()
    },
    changedNameOfAssignedTo () {
      this.loaderAssignTo = false
      this.assignedToUser = this.assignedToText
    },
    markAsReadActivity: function (item) {
      this.$store.dispatch('updateActivityViewedStatus', {
        activity_id: item.activity_id,
        card_id: this.card.card_id,
        viewed: 1
      })
      item.viewed = 1
    },
    markAsUnReadActivity: function (item) {
      this.$store.dispatch('updateActivityViewedStatus', {
        activity_id: item.activity_id,
        card_id: this.card.card_id,
        viewed: 0
      })
      item.viewed = 0
    },
    handleScroll: function (evt, el) {
      const activityTabLink = this.$refs['activity-tab-link']
      if (activityTabLink && activityTabLink.classList.contains('active') && !activityTabLink.classList.contains('viewed')) {

        let activityItems = document.querySelectorAll('.tl-activity-item')
        let viewedAllActivities = true
        activityItems.forEach((item) => {
          if (item && !item.classList.contains('viewed')) {
            const activityId = item.getAttribute('data-activity-id')
            const isElementInView = Utils.isElementInView(item, false)
            if (isElementInView) {
              item.classList.add('viewed')
              // send request to change viewed = 1 for current activity
              this.$store.dispatch('updateActivityViewedStatus', {
                activity_id: activityId,
                card_id: this.card.card_id,
                viewed: '1'
              })
                  .then(response => {
                  }).catch(error => {
              })
            } else {
              viewedAllActivities = false
            }
          }
        })
        if (viewedAllActivities) {
          activityTabLink.classList.add('viewed')
        }
      }
    },
    handleItemRead: function (el) {
      const activityTabLink = this.$refs['activity-tab-link']
      if (activityTabLink && activityTabLink.classList.contains('active')) {
        const isChecked = el.target.checked ? 1 : 0
        this.$store.dispatch('updateActivityViewedStatus', {
          activity_id: el.target.id,
          card_id: this.card.card_id,
          viewed: isChecked
        })
      }
    },
    isDone: function (q) {
    },
    transformFile: function (file, q, w, e) {
      return false
    },
    replaceCharAtCategories: function (elem) {
      return (elem.split(' ').length > 1) ?
          elem.split(' ').map(_ => _.replace(_.charAt(0), _.charAt(0))).join(' ').replace(/ /g, '_') : elem
      //elem.split(' ').map(_ => _.replace(_.charAt(0), _.charAt(0).toLowerCase())).join(' ').replace(/ /g, '_') : elem.toLowerCase()
    },
    updateImageCategory: function (info) {
      if (!info) {
        return
      }
      this.beforeDebouncedUnsavedChanges()
      let update = {}
      if (info.imgInfo) {
        let key = _.findIndex(this.image_categories, { file_id: info.imgInfo.id })
        if (key >= 0) {
          this.image_categories[key] = {
            file_id: info.imgInfo.id,
            category: info.categoryName,
          }
        } else {
          this.image_categories.push({
            file_id: info.imgInfo.id,
            category: info.categoryName,
          })
        }
        update.imageCategories = this.image_categories
      }
      if (info.imagesSort) {
        update.imagesSort = info.imagesSort
      }
      this.setUpdateData(update)
      this.beforeDebouncedAutoSave()
    },
    onChangeCardProgress: function (id) {
      if (!this.isStatusActive) {
        return
      }
      this.card.card_status = id
      this.autoUpdateCardStatus()
    },
    onChangeCardDetailProgress: function (id, index) {
      this.card.card_status = id
      this.clickedIndexInDetailTab = index
      this.isActivityChangeInDetailTab = true
      this.autoUpdateCardStatus()
    },
    changedCardCustomerAddress: function (addr) {
      this.card.customer.address = addr['address']
      this.card.customer.level_unit_lot = addr['levelUnitLot']
      this.card.customer.street_name = addr['street_name']
      this.card.customer.suburb = addr['suburb']
      this.card.customer.state = addr['state']
      this.card.customer.postcode = addr['postcode']
      this.card.customer.country = addr['country']
      this.autoUpdateCardCustomer()
    },
    onInsurerSelect: function (i) {
      if (i === null) {
        this.card.insurance.insurer_id = null
        this.card.insurance.insurer_location = ''
        this.selectedInsurerModel = []
      } else {
        this.card.insurance.insurer_name = i.insurer_name
        this.card.insurance.insurer_id = i.insurer_id
        this.card.insurance.insurer_location = i.insurer_location
        this.card.insurance.rates = i.rates
        this.selectedInsurerModel = i
      }
      this.card.insurance.insurer_assessor_id = null
      this.card.insurance.assessor_name = ''
      this.card.insurance.assessor_email = ''
      this.card.insurance.assessor_phone = ''
    },
    onAssessorSelect: function (a) {
      if (a === null) {
        this.card.insurance.insurer_assessor_id = null
        this.card.insurance.assessor_email = ''
        this.card.insurance.assessor_phone = ''
      } else {
        this.card.insurance.insurer_assessor_id = a.insurer_assessor_id
        this.card.insurance.assessor_name = a.assessor_name
        this.card.insurance.assessor_email = a.assessor_email
        this.card.insurance.assessor_phone = a.assessor_phone
      }
    },
    completeDeleteCard: function () {
      this.$refs.deleteCardModal.hide()
      let update = {}
      update.delete = this.$route.params.card_id
      this.setUpdateData(update)
      this.$store.dispatch('card/deleteCardById', this.$route.params.card_id)
      this.$store.dispatch('estimate/deleteEstimateByCardId', this.$route.params.card_id)

      if (!this.isTechnicalUser && (this.card.invoice && this.card.invoice.id > 0)) {
        this.$store.dispatch('invoice/deleteInvoiceById', this.card.invoice.id)
      }

      if (!this.isTechnicalUser && (this.card.excess_invoice && this.card.excess_invoice.id > 0) && this.card.insurance.excess) {
        this.$store.dispatch('invoice/deleteInvoiceById', this.card.excess_invoice.id)
      }

      this.autoSave()
      let self = this
      setTimeout(function () {
        if (!_.isEmpty(self.$route.params.trackerFilter)) {
          self.$store.commit('setCardFilter', self.$route.params.trackerFilter);
        }
        self.$router.push({ name: 'Cards', params: {filters: self.$route.params.filters, trackerFilter: self.$route.params.trackerFilter} })
      }, 500)
    },
    completeCopyCard: function () {
      this.$refs.copyCardModal.hide()
      NProgress.start()
      if (this.copyCard.copyToCard && this.copyCard.copyToCard.card_id && this.copyCard.copyToCard.card_id > 0) {
        this.copyCard.copyToCardId = this.copyCard.copyToCard.card_id
      }
      delete this.copyCard.copyToCard
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/card/${this.card.card_id}/copy-card`,
        validateStatus: function (status) {
          return status < 500
        },
        data: this.copyCard,
      }).then(response => {
        if (response.status === 200 && response.data._status) {
          if (!this.copyCard.isNew) {
            this.$store.dispatch('card/deleteCardById', this.copyCard.copyToCardId)
          }
          toastr.success('Duplicate Card ' + response.data.number + ' created successfully')
          this.$router.push({ name: 'CardsEdit', params: { card_id: response.data.id } })
          return
        } else {
          toastr.error(response.data.msg)
        }
        this.loadActivity()
      })
          .catch(error => {
            toastr.error(error)
          })
          .finally(() => {
            NProgress.done()
          })
    },
    deleteCard: function () {
      if (this.card.estimate_number != '') {
        this.$refs.deleteCardModal.show()
      } else {
        this.completeDeleteCard()
      }
    },
    copyCardModal: function () {
      this.copyCard.isNew = false
      this.copyCard.copyToCardId = 0
      this.copyCard.copyToCard = {}
      this.copyCard.isCopyImages = false
      this.copyCard.isCopyFiles = false
      this.$refs.copyCardModal.show()
    },
    completeMarkAsActive: function () {
      this.$refs.markAsActiveModal.hide()
      let update = {}
      update.state = 1
      this.setUpdateData(update)
      this.beforeDebouncedAutoSave()
      var self = this
      setTimeout(function () {
        self.card.state = 1
      }, 500)
    },
    completeMarkAsDraft: function () {
      this.$refs.markAsDraftModal.hide()
      let update = {}
      update.state = 0
      this.setUpdateData(update)
      this.beforeDebouncedAutoSave()
      var self = this
      setTimeout(function () {
        self.card.state = 0
      }, 500)
    },
    completeMarkAsClosed: function () {
      this.$refs.markAsClosedModal.hide()
      let update = {}
      update.state = 3
      this.setUpdateData(update)
      this.beforeDebouncedAutoSave()
      var self = this
      setTimeout(function () {
        self.card.state = 3
      }, 500)
    },
    markAsActive: function () {
      if (this.card.estimate_number != '') {
        this.$refs.markAsActiveModal.show()
      } else {
        this.completeMarkAsActive()
      }
    },
    markAsDraft: function () {
      if (this.card.estimate_number != '') {
        this.$refs.markAsDraftModal.show()
      } else {
        this.completeMarkAsDraft()
      }
    },
    markAsClosed: function () {
      if (this.card.estimate_number != '') {
        this.$refs.markAsClosedModal.show()
      } else {
        this.completeMarkAsClosed()
      }
    },
    markAsRead: function () {
      this.$store.dispatch('updateActivityViewedStatus', {
        card_id: this.card.card_id,
        viewed: 1
      })
      _.forEach(_.filter(this.activity, { 'viewed': '0' }), function (item) {
        item.viewed = '1'
      })
    },
    validateForm: function () {
      if (this.card.customer.customer_id === null || this.card.vehicle.customer_vehicle_id === null) {
        toastr.error('Please choose customer and their vehicle')
        return false
      }

      return true
    },
    showUnsavedChanges: function () {
      if (!this.isAutoSave) {
        return
      }
      this.autoSaveMessage = 'Unsaved Changes'
    },
    checkIsEstimatorSelected () {
      if (this.isEstimatorUser && _.isEmpty(this.estimator)) {
        //    toastr.error('Please choose Estimator');
      }
    },
    autoSave: function () {
      if (!this.isAutoSave) {
        return
      }
      if (JSON.stringify(this.updateData) === '{}') {
        return
      }
      if (!this.validateForm()) {
      }

      this.checkIsEstimatorSelected()

      let card_id = this.$route.params.card_id
      this.autoSaveMessage = 'Saving...'
      let saveData = JSON.stringify({
        assignedTo: this.card.assignedTo,
        customer: this.card.customer,
        customer_contact_2: this.card.customer_contact_2,
        vehicle: this.card.vehicle,
        booking_date: this.card.booking_date,
        completion_date: this.card.completion_date,
        pickUpTime: this.card.pickUpTime,
        boardIn: this.card.boardIn,
        boardDeparture: this.card.boardDeparture,
        vehicleInBoard: this.card.vehicleInBoard,
        card_number: this.card.card_number,
        file_ids: this.file_ids,
        image_ids: this.image_ids,
        card_status: this.card.card_status
      })
      Axios({
        method: 'post',
        responseType: 'json',
        headers: { 'Autosave': true },
        url: `/fe/card/${card_id}`,
        validateStatus: function (status) {
          return status < 500
        },
        data: this.updateData
      })
          .then(response => {
            if (response.status === 200) {
              this.autoSaveMessage = 'Saved'
              this.changedNameOfAssignedTo()
              this.originalData = saveData
              // delete this.updateData.selected_customer;
              // delete this.updateData.existing_vehicle;
              this.deleteUpdateDataByField('selected_customer')
              this.deleteUpdateDataByField('existing_vehicle')

              if (response.data.response.update.images) {
                this.images = response.data.response.update.images
              }
              if (response.data.response.update.files) {
                this.updateFiles(response.data.response.update.files)
              }
              if (response.data.response.update.activity) {
                this.loadActivity()
              }
              if (response.data.response.update.vehicle_for_custom && response.data.response.update.vehicle_for_custom == true) {
                this.$store.dispatch('loadVehicleMakeModels')
              }
              let vm = this
              for (var k in response.data.response.result) {
                // delete vm.updateData[k];
                vm.deleteUpdateDataByField(k)
              }
              return true
            } else {
              this.autoSaveMessage = 'Unsaved Changes'
            }
          })
          .catch(error => {
            this.autoSaveMessage = 'Unsaved Changes'
          })

    },
    autoUpdateNote: function (event) {
      // this.updateData.image_ids = this.image_ids;
      this.beforeDebouncedAutoSave()
    },
    autoUpdateImage: function (event) {
      let update = {}
      update.image_ids = this.image_ids
      this.setUpdateData(update)
      this.beforeDebouncedAutoSave()
    },
    autoUpdateContacts: function () {
      let update = {}
      update.vehicleDriverName = this.card.vehicleDriverName
      update.contactNumber1 = this.card.contactNumber1
      update.contactNumber2 = this.card.contactNumber2
      update.contactEmail = this.card.contactEmail
      this.setUpdateData(update)
      this.beforeDebouncedAutoSave()
    },
    autoUpdateBusinessABN: function (event) {
      let update = {}
      update.abn = this.card.customer.abn
      this.setUpdateData(update)
      this.beforeDebouncedAutoSave()
    },
    autoUpdateBookingDate: function (event) {
      let update = {}
      update.booking_date = this.card.booking_date
      this.setUpdateData(update)
      this.beforeDebouncedAutoSave()
    },
    autoUpdateCompletionDate: function (event) {
      let update = {}
      update.completion_date = this.card.completion_date
      this.setUpdateData(update)
      this.beforeDebouncedAutoSave()
    },
    autoUpdatePickUpTime: function (event) {
      console.log('time', this.card.pickUpTime)
      let update = {}
      update.pickUpTime = this.card.pickUpTime
      this.setUpdateData(update)
      this.beforeDebouncedAutoSave()
    },
    autoUpdateDepartureBoardDate: function (event) {
      let update = {}
      update.boardDeparture = this.card.boardDeparture
      this.setUpdateData(update)
      this.beforeDebouncedAutoSave()
    },
    autoUpdateVehicleInBoard: function (event) {
      let update = {}
      update.boardIn = this.card.boardIn
      this.setUpdateData(update)
      this.beforeDebouncedAutoSave()
    },
    autoUpdateCardStatus: function (event) {
      this.beforeDebouncedUnsavedChanges()
      let update = {}
      update.card_status = this.card.card_status
      this.setUpdateData(update)
      this.beforeDebouncedAutoSave()
      this.updateCardProgressBar()
    },
    autoUpdateVehicleTotalLoss: function (event) {
      if (this.card.isTotalLoss == 'true') {
        this.card.isTotalLoss = true
      }
      if (this.card.isTotalLoss == 'false') {
        this.card.isTotalLoss = false
      }
      let update = {}
      update.isTotalLoss = this.card.isTotalLoss
      this.setUpdateData(update)
      this.beforeDebouncedAutoSave()
    },
    autoUpdateNewScheduleNumber: function () {
      this.beforeDebouncedUnsavedChanges()
      let update = {}
      update.new_schedule_number = {
        schedule_number: this.card.vehicle.series_shedule_number,
        model: this.card.vehicle.model,
        make: this.card.vehicle.make,
        series: this.card.vehicle.series,
      }
      this.setUpdateData(update)
      this.beforeDebouncedAutoSave()
    },
    autoUpdateCardVehicle: function (event) {
      if (!this.isAutoSave) {
        return
      }
      if (this.isNewVehicleInfo) {
        if (this.newVehicleId) {
          this.card.vehicle.customer_vehicle_id = this.newVehicleId
        }
        if (this.card.vehicle.customer_vehicle_id == null || this.card.vehicle.customer_vehicle_id == 0) {
          this.isAutoSave = false
          let card_id = this.$route.params.card_id
          Axios({
            method: 'post',
            responseType: 'json',
            headers: { 'addNewVehicleInfo': true },
            url: `/fe/card/${card_id}`,
            validateStatus: function (status) {
              return status < 500
            },
            data: {
              vehicle: this.card.vehicle,
              customer_id: this.card.customer.customer_id,
              is_rego: this.isNoNeedVehicleRego
            }
          })
              .then(response => {
                if (response.status === 200) {
                  if (response.data.result.id > 0) {
                    this.autoSaveMessage = 'Saved'
                    this.card.vehicle.customer_vehicle_id = response.data.result.id
                    this.card.customer.customer_id = response.data.result.customer_id
                    this.newVehicleId = response.data.result.id
                    this.newCustomerId = response.data.result.customer_id
                    this.newVehicleInfo = this.card.vehicle
                    this.selectedCustomerVehicles = []
                    // this.updateData.vehicle = null;
                    this.clearUpdateDataByField('vehicle')
                  } else {
                    this.autoSaveMessage = 'Unsaved Changes'
                  }
                } else {
                  this.autoSaveMessage = 'Unsaved Changes'
                }
                this.isAutoSave = true
                // this.beforeDebouncedAutoSave();
              })
              .catch(error => {
                this.autoSaveMessage = 'Unsaved Changes'
                this.isAutoSave = true
                //this.beforeDebouncedAutoSave();
              })
        } else {
          let update = {}
          if (this.isNewVehicleInfo && this.newVehicleId > 0) {
            update.existing_vehicle = true
          }
          this.newVehicleInfo = this.card.vehicle
          update.vehicle = this.card.vehicle
          update.vehicle.is_rego = this.isNoNeedVehicleRego
          update.customer_id = this.card.customer.customer_id
          this.setUpdateData(update)
          this.beforeDebouncedAutoSave()
        }
      } else {
        let update = {}
        update.vehicle = this.card.vehicle
        update.customer_id = this.card.customer.customer_id
        this.setUpdateData(update)
        this.beforeDebouncedAutoSave()
      }
    },
    autoUpdateCardCustomerEmail: function (value) {
      this.card.customer.email = value
      this.$v.card.customer.email.$touch()
      this.autoUpdateCardCustomer()
    },
    autoUpdateCardCustomer: function (event) {
      if (!this.isAutoSave) {
        return
      }
      if (this.$v.card.customer.$anyError) {
        this.showUnsavedChanges()
        return
      }
      if (this.isNewCustomerInfo) {
        if (this.newCustomerId) {
          this.card.customer.customer_id = this.newCustomerId
        }
        if (this.card.customer.customer_id == null || this.card.customer.customer_id == 0) {
          //создаём новый объект customer для сохранения данных
          this.isAutoSave = false
          let card_id = this.$route.params.card_id
          Axios({
            method: 'post',
            responseType: 'json',
            headers: { 'addNewCustomerInfo': true },
            url: `/fe/card/${card_id}`,
            validateStatus: function (status) {
              return status < 500
            },
            data: {
              customer: this.card.customer,
              assignedTo: this.card.assignedTo,
              vehicle: this.card.vehicle
            }
          })
              .then(response => {
                if (response.status === 200) {
                  if (response.data.result.id > 0) {
                    this.autoSaveMessage = 'Saved'
                    this.card.customer.customer_id = response.data.result.id
                    this.newCustomerId = response.data.result.id
                    this.newCustomerInfo = this.card.customer
                    this.selectedCustomerVehicles = []
                    // this.updateData.customer = null;
                    // this.updateData.vehicle = null;
                    this.clearUpdateDataByField('customer')
                    this.clearUpdateDataByField('vehicle')
                  } else {
                    this.autoSaveMessage = 'Unsaved Changes'
                  }
                } else {
                  this.autoSaveMessage = 'Unsaved Changes'
                }
                this.isAutoSave = true
                // this.beforeDebouncedAutoSave();
              })
              .catch(error => {
                this.autoSaveMessage = 'Unsaved Changes'
                this.isAutoSave = true
                //this.beforeDebouncedAutoSave();
              })
        } else {
          let update = {}
          if (!this.isNewVehicleInfo) {
            update.selected_customer = true
          }
          this.newCustomerInfo = this.card.customer
          update.customer = this.card.customer
          update.vehicle = this.card.vehicle
          update.customer_id = this.card.customer.customer_id
          this.setUpdateData(update)
          this.beforeDebouncedAutoSave()
          return
        }
      } else {
        let update = {}
        update.assignedTo = this.card.assignedTo
        update.customer = this.card.customer
        update.vehicle = this.card.vehicle
        update.customer_id = this.card.customer.customer_id
        if (this.isSelectedExistingCustomer) {
          update.selected_customer = true
          this.isSelectedExistingCustomer = false
        }
        this.setUpdateData(update)
        this.beforeDebouncedAutoSave()
      }
    },
    autoUpdateInsurance: function (value) {
      if (this.card.insurance_repair == '2' || this.card.insurance_repair == 'Other') {
        // this.addNewVehicleWithoutRego();
      }
      this.setUpdateData(value)
      this.beforeDebouncedAutoSave()
    },
    autoUpdate: function (obj) {
    },
    disCopy: function () {
      return !!this.estimates.length
    },
    saveCard: function (card_id) {
      if (this.cardSaveElement === 'Images' && this.$refs.imgUpDrop.dropzone.files.length) {
        let vm = this.$refs.imgUpDrop
      }

      if (!this.validateForm()) {
        //return;
      }
      this.files.forEach((file, i) => {
        if (file.uuid) {
          delete file.uuid
        }
      })

      var saveData = JSON.stringify({
        customer: this.card.customer,
        vehicle: this.card.vehicle,
        booking_date: this.card.booking_date,
        completion_date: this.card.completion_date,
        pickUpTime: this.card.pickUpTime,
        boardIn: this.card.boardIn,
        boardDeparture: this.card.boardDeparture,
        card_number: this.card.card_number,
        file_ids: this.file_ids,
        image_ids: this.image_ids,
        card_status: this.card.card_status
      })
      NProgress.start()
      Axios({
        method: 'post',
        responseType: 'json',
        url: `/fe/card/${card_id}`,
        validateStatus: function (status) {
          return status < 500
        },
        data: saveData
      }).then(response => {
        if (response.status === 200) {
          toastr.success('Card updated!')
          this.updateCardProgressBar()
          this.$refs.imgUpDrop.removeAllFiles(true)
          this.$refs.fileUpDrop.removeAllFiles(true)
          this.clearFileIdsInCardEdit()
          this.image_ids = []
          this.loadCard(card_id)
          this.originalData = saveData
        } else {
          toastr.error(response.data.msg)
        }
        this.loadActivity()
      })
          .catch(error => {
            toastr.error(error)
          })
          .finally(() => {
            NProgress.done();
          });
    },
    loadCard: function (card_id = 0, isNeedAutoUpdate = false, callbackToReturn = false, isShowNProgress = true, isFirstRequest = false) {
      if (!card_id) card_id = this.$route.params.card_id
      if (isShowNProgress) {
        NProgress.start()
      }
      Axios.get(`/fe/card/${card_id}`)
          .then(response => {
            var vm = this;
            if (callbackToReturn) {
              callbackToReturn(response.data);
              return;
            }
            setTimeout(function () {
              vm.isAutoSave = true
            }, 500)
            this.properties = response.data.properties
            this.card = response.data.card

            // console.log('loadCard', _.cloneDeep(this.card?.plannerBoard?.groupId))
            // this.plannerBoardGroupId = _.find(this.categoryOptions, (opt) => {
            //   return Number(opt.id) == Number(this.card?.plannerBoard?.groupId);
            // })
            // console.log('loadCard', _.cloneDeep(this.plannerBoardGroupId))
            console.log('response', response.data)
            this.jobCard = response.data.jobCard
            this.assignedToUser = response.data.card.assignedTo.name
            this.assignedToText = response.data.card.assignedTo.name
            this.assignedToArr = response.data.users
            this.assignedToUsers = response.data.users

            this.checkEmail2()

            this.card.statuses = []
            this.updateFiles(response.data.files)
            this.images = response.data.images
            this.card.booking_date = response.data.booking_date
            this.card.completion_date = response.data.completion_date
            if (response.data.pickUpTime !== null) {
              this.card.pickUpTime = response.data.pickUpTime
            } else {
              this.card.pickUpTime = ''
            }
            this.card.boardIn = response.data.boardIn
            this.card.boardDeparture = response.data.boardDeparture
            this.card.plannerBoard = response.data.plannerBoard;

            if (this.card.plannerBoard.onSite) {
              this.onSiteReason = this.card.plannerBoard.onSite.reason
              this.onSiteAttempt = this.card.plannerBoard.onSite.attempt
              if (this.card.plannerBoard.onSite.isAttendedBooking) {
                this.checkIsAttendYes(true)
              } else if (this.card.plannerBoard.onSite.isAttendedBooking === false) {
                this.checkIsAttendNo(true)
              }
            }

            this.currentBoards.splice(0, this.currentBoards.length)

            if (response.data.boardIn && response.data.boardIn.createdDate && response.data.boardIn.id) {
              this.currentBoards.push('Vehicle In Board')
            }

            if (response.data.boardDeparture && response.data.boardDeparture.createdDate && response.data.boardDeparture.id) {
              this.currentBoards.push('Departure Board')
            }

            if (response.data.plannerBoard && response.data.plannerBoard?.vehicle?.id) {
              this.currentBoards.push('Bookings Board')
            }

            if (this.isNeedAddImgsToDeparture && response.data.boardIn && response.data.boardIn.id) {
              let boardInId = response.data.boardIn.id
              let vm = this
              let itemIn = _.filter(this.getterBoardIn, function (item) {
                return item.id == boardInId
              })
              if (itemIn[0]) {
                _.forEach(itemIn[0].images, function (img) {
                  vm.addedImageToDepartureBoard(response.data.boardDeparture.id, img.id)
                })
              }
              this.isNeedAddImgsToDeparture = false
            }
            this.settings = response.data.settings
            if (!_.isEmpty(response.data.estimates)) {
              this.estimates = response.data.estimates
              let estimate = this.estimates[0]
              this.estimator = estimate.estimator
            }
            this.insurers = response.data.insurers
            this.assessors = response.data.assessors
            if (response.data.card.customer.customer_id == 0) {
              this.isNewCustomerInfo = true
            }
            if (response.data.card.vehicle.customer_vehicle_id == 0) {
              this.isNewVehicleInfo = true
            }
            this.selectedVehicleMake = this.card.vehicle.make
            this.selectedVehicleModel = this.card.vehicle.model

            this.loadCEStatuses() // for activity status steps
            this.loadPaintCodes()
            this.originalData = JSON.stringify({
              customer: this.card.customer,
              vehicle: this.card.vehicle,
              booking_date: this.card.booking_date,
              completion_date: this.card.completion_date,
              pickUpTime: this.card.pickUpTime,
              boardIn: this.card.boardIn,
              boardDeparture: this.card.boardDeparture,
              card_number: this.card.card_number,
              file_ids: this.file_ids,
              image_ids: this.image_ids,
              card_status: this.card.card_status
            })

            if (isNeedAutoUpdate) {
              this.autoUpdateCardVehicle()
            }
            if (isFirstRequest) {
              this.isLoadedCard = true
            }
            this.setTitle()

          })
          .catch(error => {
            toastr.error('Card not found')
            console.log(error)
            //   this.$router.push('/cards');
          })
          .finally(() => {
            if (isShowNProgress) {
              NProgress.done()
            }
          });
    },
    loadNotes: function () {
      if (this.isTechnicalUser) {
        return false
      }
      let card_id = this.$route.params.card_id
      Axios.get(`/fe/note/${card_id}`)
          .then(response => {
            this.notes = response.data
          })
          .catch(error => {
            NProgress.done()
          })
    },
    loadActivity: function (sort_type) {
      const card_id = this.$route.params.card_id
      let payload = { card_id: card_id }
      if (sort_type) {
        payload.sort_type = sort_type
      }
      this.$store.dispatch('loadCardActivity', payload).then(response => {
        if (response.status === 200) {
          this.activity = response.data
        } else if (response.status === 400) {
          toastr.error(response.data.msg)
        } else {
          toastr.warning(response.data.msg)
        }
      }).catch(error => {
        toastr.error('Error occurred while loading card activity')
        NProgress.done()
      })
    },
    clickBrowse: function (id) {
      $(id).trigger('click')
    },
    clickBrowseDropzoneImages: function (event) {
      if ( this.$refs?.imgUpDrop?.dropzone?.hiddenFileInput) {
        this.$refs.imgUpDrop.dropzone.hiddenFileInput.removeAttribute("capture")
      }
    },
    openCapture() {
      if (this.$refs?.imgUpDrop?.dropzone?.hiddenFileInput) {
        this.$refs.imgUpDrop.dropzone.hiddenFileInput.setAttribute("capture", 'camera')
      }
    },
    clickCancelDropzoneImages: function (event) {
      event.preventDefault()
      this.$refs.imgUpDrop.removeAllFiles(true)
      this.image_ids = []
      this.image_categories = []
    },
    sendImgUpload ($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = 'none'
    },
    sendFileRemove: function (file) {
      NProgress.start()
      let url = '/fe/card/sendFileRemove'
      Axios({
        method: 'post',
        responseType: 'json',
        url: url,
        validateStatus: function (status) {
          return status < 500
        },
        data: { key: file.key, id: file.id, card_id: this.$route.params.card_id }
      })
          .then(response => {
            if (response.status == 200) {
              //toastr.success(response.data.msg);

            } else {
              //  toastr.error(response.data.msg);
            }
            NProgress.done()

          })
          .catch(error => {
            NProgress.done()
            toastr.error(error)
          })
    },
    completeImgUpload ($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].removeAttribute('style')
    },
    successImgUpload (file, response) {
      if (response.status) {
        this.image_ids.push(response.file_id)
        //this.imagesAfterUpload.push({id: response.file_id, file: file});
        this.autoUpdateImage()
        var vm = this
        setTimeout(function () {
          vm.$refs.imgUpDrop.removeFile(file)
        }, 1000)
      }
    },
    addImgUpload ($event) {
      $event.previewElement.children[($event.previewElement.children.length - 1)].style.display = 'none'
    },
    deleteImage: function (data) {
      let correctItem = this.resultSortingImages[data.key].filter(_ => {
        return _.id === data.id
      })
      var filename = correctItem[0].name
      if (correctItem[0].id != null) {
        this.sendFileRemove(correctItem[0])
      }
      let findIndex = this.images.indexOf(correctItem[0])
      this.images.splice(findIndex, 1)
      toastr.success(filename + ' has been deleted')

      //var filename = this.images[index].name;
      //if (this.images[index].id != null) {
      //    this.sendFileRemove(this.images[index]);
      //}
      //this.images.splice(index, 1);
      //toastr.success(filename + ' has been deleted');
    },
    addImage: function (img) {
      if (img.src == '' || img.src === undefined || img.src === null) {
        return
      } else {
        this.images.unshift(img)
        this.newImageAdded = true
      }
    },
    // addFile: function (file) {
    //     if (file.src == '' || file.src === undefined || file.src === null) {
    //         return;
    //     } else {
    //         this.unshiftFileInCardEdit(file);
    //         this.newFileAdded = true;
    //     }
    // },
    printJobCard: function () {
      let card_id = this.$route.params.card_id
      let token = localStorage.getItem('token')
      window.open(appConfig.baseAPIURL + `/fe/pdf/jobcard/${card_id}?at=${token}`)
      return
    },
    copyToEstimate: function (type) {
      if (this.currentTab === 'vehicleOwner' && !this.$refs['vehicle-block'].checkErrorForCopyToEstimate()) {
        return false
      }
      let card_id = this.$route.params.card_id

      NProgress.start()
      Axios.post(`/fe/create-or-go-to-estimate/${card_id}`)
          .then(response => {
            if (response.data.status && response.data.id > 0) {
              this.$router.push('/estimates/view/' + response.data.id)
            } else {
              toastr.error('Estimate does not create')
            }
            NProgress.done()
          })
          .catch(error => {
            toastr.error('Estimate does not create')
            NProgress.done()
          })
    },
    onEstimatorSelect: function (c) {
      this.estimator = c
      let update = {}
      update.estimator = c
      this.setUpdateData(update)
      this.beforeDebouncedAutoSave()
    },
    onCustomerSelect: function (c) {
      if (Number(c?.customer_id) && Number(this.card?.customer?.customer_id) && Number(c?.customer_id) == Number(this.card?.customer?.customer_id)) {
        return
      }
      if (c === null) {
        this.isNewCustomerInfo = true
        if (this.isNewVehicleInfo) {
          //this.isNewVehicleInfo = true;
          // this.card.vehicle = this.newVehicleInfo
        } else {
          // this.card.vehicle.customer_vehicle_id = null
          // this.card.vehicle.rego_number = ''
          // this.card.vehicle.dom = ''
          // this.card.vehicle.complianceDate = ''
          // this.card.vehicle.make = ''
          // this.card.vehicle.model = ''
          // this.card.vehicle.series = ''
          // this.card.vehicle.series_shedule_number = ''
          // this.card.vehicle.colour = ''
          // this.card.vehicle.colour_variant = ''
          // this.card.vehicle.badge = ''
          // this.card.vehicle.boby = ''
          // this.card.vehicle.vin = ''
          // this.card.vehicle.odometer = 0
          // this.card.vehicle.transmission = ''
        }

        let name = this.card.customer.name
        if (name && name != '') {
          this.newCustomerInfo.name = name
        }
        this.card.customer = this.newCustomerInfo
        this.autoUpdateCardCustomer()

      } else {
        let customer = _.filter(this.getterAllCustomers, function (customer) {
          return customer.customer_id == c.customer_id
        })[0]
        this.isSelectedExistingCustomer = true
        this.isNewCustomerInfo = false
        this.isNewVehicleInfo = false
        this.card.customer.customer_id = customer.customer_id
        this.card.customer.name = customer.customer_name
        this.card.customer.phone = customer.phone
        this.card.customer.fax = customer.fax
        this.card.customer.address = customer.address
        this.card.customer.level_unit_lot = customer.level_unit_lot
        this.card.customer.street_name = customer.street_name
        this.card.customer.suburb = customer.suburb
        this.card.customer.state = customer.state
        this.card.customer.postcode = customer.postcode
        this.card.customer.country = customer.country
        this.card.customer.mobile = customer.mobile
        this.card.customer.email = customer.email
        this.card.customer.customer_type = customer.customer_type
        this.card.customer.abn = customer.abn
        this.selectedCustomerVehicles = customer.vehicles
        // this.card.vehicle.customer_vehicle_id = null
        // this.card.vehicle.rego_number = ''
        // this.card.vehicle.dom = ''
        // this.card.vehicle.complianceDate = ''
        // this.card.vehicle.make = ''
        // this.card.vehicle.model = ''
        // this.card.vehicle.series = ''
        // this.card.vehicle.series_shedule_number = ''
        // this.card.vehicle.colour = ''
        // this.card.vehicle.colour_variant = ''
        // this.card.vehicle.body = ''
        // this.card.vehicle.badge = ''
        // this.card.vehicle.vin = ''
        // this.card.vehicle.odometer = 0
        // this.card.vehicle.transmission = ''
        this.autoUpdateCardCustomer()

      }
      this.checkEmail()
    },
    onCustomerUpdateName: function (name) {
      this.isSelectedExistingCustomer = true
      this.isNewCustomerInfo = false
      this.isNewVehicleInfo = false
      this.card.customer.updateCustomerName = name
      this.card.customer.name = name
      this.autoUpdateCardCustomer()
    },
    replyActivity (index) {
      const refIndex = 'activityReplyMessageRef' + index
      if (this.$refs[refIndex]) {
        this.$refs[refIndex][0].showReplyMessage(false)
      }

    },
    replyToAllActivity (index) {
      const refIndex = 'activityReplyMessageRef' + index
      if (this.$refs[refIndex]) {
        this.$refs[refIndex][0].showReplyMessage(true)
      }
    },
    loadCEStatuses () {
      this.$store.dispatch('loadCEStatuses').then((res) => {
        if (res.status === 200) {
          this.ceStatuses = res.data
          this.updateCardProgressBar()
        }
      }).catch((err) => {
      })
    },
    updateCardProgressBar () {

      this.card.statuses = []
      let currentStatusIndex = 0
      this.ceStatuses.forEach((ce_status, i) => {
        if (ce_status.ce_status_id.toString() === this.card.card_status) {
          currentStatusIndex = i
        }
      })
      this.cardProgress.startIndex = currentStatusIndex

      this.ceStatuses.forEach((ce_status, i) => {
        let status = {
          id: ce_status.ce_status_id,
          title: ce_status.name,
          active: false,
        }
        if (currentStatusIndex >= i) {
          status.active = true
          status.icon = 'ti-check'
        } else {
          status.active = false
          status.icon = ' '
        }
        this.card.statuses.push(status)
      })
      if (!this.isActivityChangeInDetailTab) {
        let activeIndex = this.card.statuses.findIndex(value => !value.active)
        if (activeIndex == 13 || activeIndex == -1) {
          this.progressStatus = [this.card.statuses.slice(-4)]
        }
        if (activeIndex === 1) {
          this.progressStatus = this.card.statuses.slice(0, 4)
        } else {
          this.progressStatus = this.card.statuses.slice(activeIndex - 2, activeIndex + 2)
        }
      } else {

        this.progressStatus.map((pStatus, Index) => {
              pStatus.current = false
              if (Index <= this.clickedIndexInDetailTab) {
                pStatus.active = true
                pStatus.icon = 'ti-check'

                if (Index === this.clickedIndexInDetailTab) {
                  pStatus.current = true
                }
              } else {
                pStatus.active = false
                pStatus.icon = ''
              }
            }
        )
      }
      let count = this.ceStatuses.length
      if (count > 0) {
        this.cardProgress.width = 'width: ' + (count * 89.3) + 'px'
        this.cardProgress.widthActivePercent = 'width: ' + (89.3 * (currentStatusIndex + 1) - 25) + 'px'
      } else {
        this.cardProgress.width = 'width: 0px'
        this.cardProgress.widthActivePercent = 'width: 0px'
      }

      this.updateCardProgressBarSmall()
    },
    updateCardProgressBarSmall() {
      let currentStatusIndexSmall = 0
      this.filteredStatusList.forEach((ce_status, i) => {
        if (ce_status.id.toString() === this.card.card_status) {
          currentStatusIndexSmall = i
        }
      })
      let countSmall = this.filteredStatusList.length

      let el = document.querySelector("#card-details > div:nth-child(1) > div:nth-child(3) > div.card.ex-card.vue-form-wizard.wizard-bottom > div > div.card.ex-card.wizard-bottom > div > div.wizard-navigation > ul")

      let width = 134
      if (el && el.clientWidth) {
        width = el.clientWidth / 4
        if (el.clientWidth > 365 && el.clientWidth <= 460) {
          width += 5
        }
        if (el.clientWidth > 460) {
          width += 15
        }
      }
      if (countSmall > 0) {
        this.cardProgressSmall.width = 'width: ' + (countSmall * 89.3) + 'px'
        if (currentStatusIndexSmall == 0) {
          this.$set(this.cardProgressSmall, 'widthActivePercent', 'width: 25px')
        } else {
          this.$set(this.cardProgressSmall, 'widthActivePercent', 'width: ' + (width * (currentStatusIndexSmall)) + 'px')
        }
      } else {
        this.cardProgressSmall.width = 'width: 0px'
        this.$set(this.cardProgressSmall, 'widthActivePercent', 'width: 0px')
      }
    },
    changeActivitySortBy (sort_by) {
      this.activitySortTypeText = sort_by
      this.card.sort_type = sort_by
      this.loadActivity(sort_by)
    },
    beforeDebouncedAutoSave: function () {
      if (!this.isAutoSave) {
        return
      }
      this.debouncedAutoSave()
    },
    beforeDebouncedUnsavedChanges: function () {
      if (!this.isAutoSave) {
        return
      }
      this.debouncedUnsavedChanges()
    },
    calculateTopSticky () {
      this.updateCardProgressBarSmall()
      const tabsComponent = this.$refs.tabsComponent
      const pageHeader = document.querySelector('.page-header')
      const pageHeaderHeight = pageHeader ? pageHeader.offsetHeight : 75
      if (tabsComponent) {
        // site-header + page-header + tabs-component
        this.topSticky = 60 + pageHeaderHeight + this.$refs.tabsComponent.offsetHeight
      } else {
        // default
        this.topSticky = 0
      }
    },
    updateHeightOptionsMenu (event) {
      if (document.body.offsetHeight < 600) {
        this.heightOptions = document.body.offsetHeight - event.clientY - 30
      }
    },
    getUpdatedJobCardsAndAssignedName(timestamp) {
      Axios.get('/fe/card/' + this.card.card_id + '/get-updated-data/' + timestamp)
          .then(response => {
              if (response.data.jobCard) {
                this.jobCard = response.data.jobCard;
              }
              if (response.data.card) {
                this.card.assignedTo = response.data.card.assignedTo;
                this.card.assignedTo = response.data.card.assignedTo;
                this.assignedToUser = response.data.card.assignedTo.name;
                this.assignedToText = response.data.card.assignedTo.name;
              }
          })
          .catch(error => {
          })
    },
  },
  mounted: function () {
    this.checkConnectedPcsIntervalId = setInterval(() => {
      this.getConnectedPcs();
    }, this.checkConnectedPcsInterval);
    if (!_.isEmpty(this.$storage.get('views.cardsEdit.' + this.$route.params.card_id + '.defaultView'))) {
      this.currentTab = this.$storage.get('views.cardsEdit.' + this.$route.params.card_id + '.defaultView')
      if(this.currentTab === 'activity') {
        this.isHiddenNewNote = true
      }
    }
    if (this.$route.name == "CardsEditImage") {
      this.currentTab = 'images'
      let body = document.querySelector('body');
      if (!_.includes(body.classList, 'compact-sidebar')) {
        body.classList.add('compact-sidebar');
      }
    }

    let params = this.$route.params
    this.$nextTick(() => {
      if (params && params.action && (params.action == 'goToTask' || params.action == 'goToComment' || params.action == 'goToEmail' || params.action == 'goToSms')) {
        this.activityTabChange()
      }
    })

    this.loadCard(this.$route.params.card_id, false, false, true, true)
    this.loadNotes()
    this.$store.dispatch('loadCustomerVehicles')
    this.$store.dispatch('loadInsurances')
    this.$store.dispatch('loadVehiclesForCustom')
    this.loadActivity()
    Axios.get('/fe/users')
        .then(response => {
          context.commit('loadUsers', response.data.users)
        })
        .catch(error => {
        })
    this.calculateTopSticky()
    window.addEventListener('resize', this.calculateTopSticky);
    this.intervalUpdateImages = setInterval(() => {
      this.checkUpdate();
    }, 5000)

    this.setTitle()
  },
  beforeDestroy() {
    if (this.intervalUpdateImages !== null) {
      clearInterval(this.intervalUpdateImages);
    }
    if (this.intervalUpdateScannerFiles !== null) {
      clearInterval(this.intervalUpdateScannerFiles)
    }
    if (this.checkConnectedPcsIntervalId !== null) {
      clearInterval(this.checkConnectedPcsIntervalId)
    }
    this.setTitle(true)
  },
  beforeRouteLeave (to, from, next) {
    this.$storage.remove('views.cardsEdit.' + this.$route.params.card_id + '.defaultView')
    next()
  },
  unmounted () {
    window.removeEventListener('resize', this.calculateTopSticky)
  },
  created: function () {
    this.loadCategories()
    this.debouncedAutoSave = _.debounce(this.autoSave, 1000)
    this.debouncedUnsavedChanges = _.debounce(this.showUnsavedChanges, 50)
  },
  watch: {
    image_categories: function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.customer.name': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.customer.mobile': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.customer.email': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.customer.customer_type': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.customer.abn': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.customer.address': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.customer.level_unit_lot': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.customer.street_name': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.customer.suburb': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.customer.state': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.customer.postcode': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.customer.country': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.customer_contact_2': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.vehicle': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.booking_date': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.completion_date': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.vehicleInBoard': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.pickUpTime': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.boardIn': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.boardDeparture': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.card_number': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    file_ids: function () {
      this.beforeDebouncedUnsavedChanges()
    },
    image_ids: function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.card_status': function () {
      this.beforeDebouncedUnsavedChanges()
    },

    'card.vehicle.dom': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.vehicle.complianceDate': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.vehicle.rego_number': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.vehicle.make': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.vehicle.model': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.vehicle.series': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.vehicle.colour': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.vehicle.badge': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.vehicle.body': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.vehicle.vin': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.vehicle.odometer': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.vehicle.transmission': function () {
      this.beforeDebouncedUnsavedChanges()
    },

    'card.insurance_repair': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.insurance.id': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.insurance.insurance_claim_type': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.insurance.insurer_id': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.insurance.insurer_name': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.insurance.insurer_location': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.insurance.insurer_assessor_id': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.insurance.assessor_name': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.insurance.assessor_email': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.insurance.assessor_phone': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.insurance.policy_number': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.insurance.claim_number': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.insurance.assessment_date': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.insurance.excess': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.insurance.contribution': function () {
      this.beforeDebouncedUnsavedChanges()
    },
    'card.insurance.discount': function () {
      this.beforeDebouncedUnsavedChanges()
    },
  },
  components: {
    DatePicker,
    FileUploader,
    ImageLightbox,
    AdvancedSearch,
    AmazonAds,
    FormWizard,
    TabContent,
    WizardStep,
    vueDropzone: vue2Dropzone,
    NumberFormatter,
    GoogleLocation,
    Multiselect,
    ActivityList,
    VueTimepicker,
    CardNotes,
    CardFiles,
    InsuranceBlock,
    VehicleBlock,
    advancedSearchWithNew,
    AddressSuggestions,
    VueContext,
    AdvertBanner,
    SmallFormWizard,
    Vue2TouchEvents,
  }
}
</script>

<template>
  <div class="ex-cards-edit cards-edit-page" :class="{'is-mobile-only': isMobileOnly}">
    <div class="page-header">
      <h4>Card - {{ card.card_number }} <span class="text-muted">|</span> {{ card.customer.name }} <span
          class="text-muted">|</span> {{ showUpperRegoNumber }}
        <span class="autosave">{{ autoSaveMessage }}</span></h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item">
          <router-link to="/">Home</router-link>
        </li>
        <li class="breadcrumb-item">
          <a @click.prevent="goToCards" class="clickable">View Cards</a>
        </li>
        <li class="breadcrumb-item active">Edit Card</li>
      </ol>
      <h4 v-if="isShowBusinessName" class="header-display-name">{{ getterBusinessName }}</h4>
      <div class="page-header-nav-pin-images">
        <label v-if="isStatusActive" class="form-check-inline checkbox"
               v-b-tooltip.hover="{customClass: 'ps-tooltip', title:'Click to view images', placement:'left'}">
          <input class="form-check-input" name="pinimage" v-model="pinImage" type="checkbox">
          <span class="icon"><i class='bx bx-check'></i></span><span class="text">Pin Images</span>
        </label>
      </div>
      <div v-if="!isHiddenOptions" class="page-header-nav-options">
        <div class="navbar-nav nav dropdown dropdown-options  show inline">
          <button type="button"
                  class="btn waves-effect waves-light ex-options-button"
                  id="dropdownMenuLink1" data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                  @click="updateHeightOptionsMenu($event)">Options
            <span class="dropdown-arrow  pull-right" style="
                                                  box-sizing: border-box;

                                                  width: 32px;
                                                  border-left: 1px solid rgba(27,30,56,0.25);
                                                  ">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                     </span>
          </button>
          <div class="dropdown-menu dropdown-menu-right animated fadeInUp"
               aria-labelledby="dropdownMenuLink1" :style="{'max-height': heightOptions + 'px'}"
               :class="{'options-menu-height': heightOptions != ''}">
            <a v-if="!isTechnicalUser && !(estimates.length > 0) && isStatusActive && !(properties.isFromIag && !properties.isAcceptedOrmJob)" @click="copyToEstimate"
               class="dropdown-item text-primary p-1 clickable">Create Estimate</a>
            <a v-if="!isTechnicalUser && estimates.length > 0" @click="copyToEstimate"
               class="dropdown-item text-primary p-1 clickable">Go to Estimate</a>
            <a v-if="card.plannerBoard && card.plannerBoard.onSite && card.plannerBoard.onSite.id" @click="goToEstimateBooking"
               class="dropdown-item text-primary p-1 clickable"> Go to Estimate Booking</a>
            <a v-if="!isTechnicalUser && !isCustomerUser && isStatusActive"
               class="dropdown-item text-primary p-1 clickable has-child">
              <span>Add To</span>
              <div class="dropdown-menu-child" style="padding: 0px">
                <a v-if="vendorInfo && vendorInfo.webtrimAuth && vendorInfo.webtrimAuth.active" @click="addToWebtrim" style="padding: 12px 12px 11px" class="dropdown-item text-primary p-1 clickable">WEBTRIM</a>
                <a @click="addedToVehicleInBoard" style="padding: 12px 12px 11px"
                   class="dropdown-item text-primary p-1 clickable">Vehicle In Board</a>
                <a @click="addToDepartureBoard" style="padding: 10px 10px 6px"
                   class="dropdown-item text-primary p-1 clickable">Departure Board</a>
              </div>
            </a>
            <a v-if="estimates.length > 0" @click="printJobCard"
               class="dropdown-item text-primary p-1 clickable">Print Job Card</a>
            <a v-if="!isHiddenNewNote && isStatusActive" @click="addNote" data-toggle="modal"
               data-target="#noteBoxModal"
               class="dropdown-item text-primary p-1 clickable">
              Add Note
            </a>
            <a v-if="isStatusActive" @click="openNewTask" class="dropdown-item text-primary p-1 clickable">Add New Task</a>
            <a v-if="!isTechnicalUser && card.state != 0 && isStatusActive" @click="markAsDraft"
               class="dropdown-item text-primary p-1 clickable">Mark As Draft</a>
            <a v-if="!isTechnicalUser && card.state != 1 && isStatusActive" @click="markAsActive"
               class="dropdown-item text-primary p-1 clickable">Mark As Active</a>
            <a v-if="!isTechnicalUser && card.state != 3 && isStatusActive" @click="markAsClosed"
               class="dropdown-item text-primary p-1 clickable">Mark As Closed</a>
            <a v-if="isShowMarkAsRead && isStatusActive" @click="markAsRead"
               class="dropdown-item text-primary p-1 clickable">Mark As Read</a>
            <a v-if="properties && properties.isFromIag && !properties.isAcceptedOrmJob && !properties.isDeclinedOrmJob  && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.orm" @click="acceptORMJob"
               class="dropdown-item text-primary p-1 clickable">Accept ORM Job</a>
            <a v-if="properties && properties.isFromIag && !properties.isAcceptedOrmJob && !properties.isDeclinedOrmJob  && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.orm" @click="$refs.declineORMJob.show()"
               class="dropdown-item text-primary p-1 clickable">Decline ORM Job</a>
            <a v-if="!isDisabledFieldsByUserRole && isStatusActive" @click="copyCardModal"
               class="dropdown-item text-primary p-1 clickable">Copy Card</a>
            <a v-if="!isDisabledFieldsByUserRole && isStatusActive" @click="deleteCard"
               class="dropdown-item text-primary p-1 clickable">Delete Card</a>
          </div>
        </div>
      </div>
    </div>
    <div :class="{'hide-card-block': !isLoadedCard && isShowBigImage}" class="box-block bg-white ex-main-box">
      <div class="ex-main-card">
        <!--<div class="card ex-main-card">-->
        <div ref="tabsComponent" class="tabs-component">
          <ul class="nav nav-tabs nav-tabs-2 float-sm-left">
            <li class="tabs-component-tab nav-item">
              <a href="#card-details" @click="vehicleOwnerTabChange()" data-toggle="tab"
                 :class="{'active': currentTab === 'vehicleOwner'}" class="tabs-component-tab-a nav-link">Vehicle Details</a>
            </li>
            <li v-if="!isTechnicalUser" class="nav-item">
              <a href="#card-notes" @click="notesTabChange()" data-toggle="tab"
                 :class="{'active': currentTab === 'notes'}" class="tabs-component-tab-a nav-link "
                 ref="notes-tab-link">
                Notes <span v-if="totalNotes" class="tag tag-info">{{ totalNotes }}</span>
              </a>
            </li>
            <li class="nav-item">
              <a href="#card-photos" @click="imagesTabChange" data-toggle="tab"
                 :class="{'active': currentTab === 'images'}" class="nav-link">Images
                <span v-if="totalImages"
                      class="tag tag-info">{{ totalImages }}</span></a>
            </li>
            <li class="nav-item">
              <a href="#card-files" @click="filesTabChange" data-toggle="tab"
                 :class="{'active': currentTab === 'files'}" class="nav-link">Files <span
                  v-if="totalFiles" class="tag tag-info">{{ totalFiles }}</span></a>
            </li>
            <li class="nav-item">
              <a href="#activity" data-toggle="tab" id="activity-tab" :class="{'active': currentTab === 'activity'}"
                 class="nav-link activity-tab"
                 @click="activityTabChange"
                 ref="activity-tab-link">Activity</a>
            </li>
            <li class="nav-item" v-if="estimates.length > 0">
              <a href="#job-card" @click="jobCardTabChange" data-toggle="tab"
                 :class="{'active': currentTab === 'jobCard'}" class="nav-link">Job
                Card</a>
            </li>
            <li class="tabs-component-tab nav-item">
              <a class="tabs-component-tab-a nav-link openfilterTarget" style="cursor:pointer"
                 @click="assignedToOpen()">Assigned To: {{
                    computedAssignedUserName
                }}
                <img v-if="loaderAssignTo" class="loaderAssignTo" src="../../assets/img/wait.gif" alt="loading"></a>
              <div class="filterAssigned" ref="AssignedfilterRef">
                <input type="text" placeholder="Search" v-model="filterAssignTo">
                <ul type="none">
                  <li @click="changeAssignedName(elem)" v-for="elem in computedActiveUsers">
                    {{ elem.name }}
                  </li>
                </ul>
              </div>
            </li>
            <li v-if="!isMobile && isStatusActive" class="mobile-pin-images">
              <label class="form-check-inline checkbox">
                <input class="form-check-input" name="pinimage" v-model="pinImage" type="checkbox">
                <span class="icon"><i class='bx bx-check'></i></span><span
                  class="text">Pin Images</span>
              </label>
            </li>

          </ul>
          <div class="rs-btn-group tab-header float-sm-right mt-1-sm additional-padding-btn">
            <div class="tabs-nav-right-pin-images">
              <label v-if="isStatusActive" class="form-check-inline checkbox"
                     v-b-tooltip.hover="{customClass: 'ps-tooltip', title:'Click to view images', placement:'left'}">
                <input class="form-check-input" name="pinimage" v-model="pinImage" type="checkbox">
                <span class="icon"><i class='bx bx-check'></i></span><span
                  class="text">Pin Images</span>
              </label>
            </div>
            <!--<div v-if="!isHiddenNewNote" class="tabs-nav-right-btn new-note-button">
                            <button @click="addNote" data-toggle="modal" data-target="#noteBoxModal" type="button"
                                    class="btn btn-primary w-min-sm waves-effect waves-light mr-2">
                                <i class="ti-pHellolus"></i>New Note
                            </button>
                        </div>-->
            <div class="tabs-nav-right-options" v-if="!isHiddenOptions">
              <div class="navbar-nav nav dropdown dropdown-options  show inline">
                <button type="button"
                        class="btn waves-effect waves-light ex-options-button"
                        id="dropdownMenuLink" data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true"
                        @click="updateHeightOptionsMenu($event)">Options
                  <span class="dropdown-arrow  pull-right" style="
                                                  box-sizing: border-box;

                                                  width: 32px;
                                                  border-left: 1px solid rgba(27,30,56,0.25);
                                                  ">
                                    <i class="fa fa-chevron-down pull-right"></i>
                                     </span>
                </button>
                <div class="dropdown-menu dropdown-menu-right animated fadeInUp"
                     aria-labelledby="dropdownMenuLink" :style="{'max-height': heightOptions + 'px'}"
                     :class="{'options-menu-height': heightOptions != ''}">
                  <a v-if="!isTechnicalUser && !(estimates.length > 0) && isStatusActive && !(properties.isFromIag && !properties.isAcceptedOrmJob)" @click="copyToEstimate"
                     class="dropdown-item text-primary p-1 clickable"> Create Estimate</a>
                  <a v-if="!isDisabledFieldsByUserRole && estimates.length > 0" @click="copyToEstimate"
                     class="dropdown-item text-primary p-1 clickable"> Go to Estimate</a>

                  <a v-if="card.plannerBoard && card.plannerBoard.onSite && card.plannerBoard.onSite.id" @click="goToEstimateBooking"
                     class="dropdown-item text-primary p-1 clickable"> Go to Estimate Booking</a>

                  <a v-if="!isHiddenNewNote && isStatusActive" @click="addNote" data-toggle="modal"
                     data-target="#noteBoxModal"
                     class="dropdown-item text-primary p-1 clickable">
                    Add Note
                  </a>

                  <a v-if="!isDisabledFieldsByUserRole  && !isCustomerUser && isStatusActive"
                     class="dropdown-item text-primary p-1 clickable has-child">
                    <span>Add To</span>
                    <div class="dropdown-menu-child" style="padding: 0px">
                      <a v-if="vendorInfo && vendorInfo.webtrimAuth && vendorInfo.webtrimAuth.active" @click="addToWebtrim" style="padding: 12px 12px 11px" class="dropdown-item text-primary p-1 clickable">WEBTRIM</a>
                      <a @click="addedToVehicleInBoard" style="padding: 12px 12px 11px"
                         class="dropdown-item text-primary p-1 clickable">Vehicle In Board</a>
                      <a @click="addToDepartureBoard" style="padding: 10px 10px 6px"
                         class="dropdown-item text-primary p-1 clickable">Departure Board</a>
                    </div>
                  </a>

                  <a v-if="estimates.length > 0" @click="printJobCard"
                     class="dropdown-item text-primary p-1 clickable"> Print Job Card</a>
                  <a v-if="isStatusActive" @click="openNewTask" class="dropdown-item text-primary p-1 clickable"> Add New Task</a>
                  <a v-if="!isTechnicalUser && card.state != 0 && isStatusActive" @click="markAsDraft"
                     class="dropdown-item text-primary p-1 clickable"> Mark As Draft</a>
                  <a v-if="!isTechnicalUser && card.state != 1 && isStatusActive" @click="markAsActive"
                     class="dropdown-item text-primary p-1 clickable"> Mark As Active</a>
                  <a v-if="!isTechnicalUser && card.state != 3 && isStatusActive" @click="markAsClosed"
                     class="dropdown-item text-primary p-1 clickable"> Mark As Closed</a>
                  <a v-if="isShowMarkAsRead && isStatusActive" @click="markAsRead"
                     class="dropdown-item text-primary p-1 clickable"> Mark As Read</a>
                  <a v-if="properties && properties.isFromIag && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.orm" @click="acceptORMJob"
                     class="dropdown-item text-primary p-1 clickable"> Accept ORM Job </a>
                  <a v-if="properties && properties.isFromIag && vendorInfo.features  && vendorInfo.features.integrations && vendorInfo.features.integrations.orm" @click="$refs.declineORMJob.show()"
                     class="dropdown-item text-primary p-1 clickable"> Decline ORM Job </a>
                  <a v-if="!isDisabledFieldsByUserRole && isStatusActive" @click="copyCardModal"
                     class="dropdown-item text-primary p-1 clickable"> Copy Card</a>
                  <a v-if="!isDisabledFieldsByUserRole && isStatusActive" @click="deleteCard"
                     class="dropdown-item text-primary p-1 clickable"> Delete Card</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div :class="classTabContent">
          <div v-if="currentTab === 'vehicleOwner'" id="card-details" class="add-padd-mobile">
            <div class="row" style="padding-top: 15px;">
              <div class="col-md-4 reset-padd">
                <div class="card ex-card ex-card-details">
                  <div class="card-header bg-navyblue">
                    <strong>Vehicle Owner</strong>
                  </div>
                  <div class="card-block bg-white">
                    <div class="form-group row">
                      <label for="customer_name" class="col-sm-4 col-4 col-form-label">Vehicle
                        Owner</label>
                      <div class="col-sm-8 col-8">
                        <advanced-search-with-new ref="customer.name"
                                                  :searchLabel="'Customer'"
                                                  v-model="card.customer.name"
                                                  :searchData="customerVehicles"
                                                  :searchKey="'customer_name'"
                                                  @onSelect="onCustomerSelect"
                                                  @onUpdate="onCustomerUpdateName"
                                                  :placeholderText="'Customer Name'"
                                                  :is-disabled="!isStatusActive || isDisabledFieldsByUserRole"
                                                  :class="{'disabled-cards-edit-is-user': isDisabledFieldsByUserRole}"
                        >
                        </advanced-search-with-new>
                      </div>
                    </div>

                    <div class="form-group row owner-type-checkboxes">
                      <label for="mobile" class="col-sm-4 col-4 col-form-label">Owner Type</label>
                      <div class="col-sm-8 col-8 ex-customer-type-container">
                        <div class="checkbox-group">
                          <div class="checkbox-p">
                            <label class="form-check-inline radio checkbox-v" style="margin-top: 5px">
                              <input v-model="card.customer.customer_type"
                                     class="form-check-input" type="radio" name="customer_type"
                                     id="individual_customer_type" value="I"
                                     @change="autoUpdateCardCustomer" :disabled="!isStatusActive || isDisabledFieldsByUserRole">
                              <span class="icon"><i class='bx bx-check'></i></span><span
                                class="text">Individual</span>
                            </label>
                          </div>
                          <div class="checkbox-p">
                            <label class="form-check-inline radio" style="margin-top: 5px">
                              <input v-model="card.customer.customer_type"
                                     class="form-check-input" type="radio" name="customer_type"
                                     id="business_customer_type" value="B"
                                     @change="autoUpdateCardCustomer" :disabled="!isStatusActive || isDisabledFieldsByUserRole">
                              <span class="icon"><i class='bx bx-check'></i></span><span
                                class="text">Business</span>
                            </label>
                          </div>
                          <div>
                            <label class="form-check-inline radio" style="margin-top: 5px">
                              <input v-model="card.customer.customer_type"
                                     class="form-check-input" type="radio" name="customer_type"
                                     id="business_fleet_customer_type" value="F"
                                     @change="autoUpdateCardCustomer" :disabled="!isStatusActive || isDisabledFieldsByUserRole">
                              <span class="icon"><i class='bx bx-check'></i></span><span style="min-width: 121px"
                                                                                         class="text">Business Fleet</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="card.customer.customer_type === 'B' && !isTechnicalUser"
                         class="form-group row">
                      <label for="abn" class="col-sm-4 col-4 col-form-label">Business ABN</label>
                      <div class="col-sm-8 col-8">
                        <input type="text" :readonly="!isStatusActive" :disabled="isDisabledFieldsByUserRole"
                               v-model="card.customer.abn" class="form-control" id="abn"
                               placeholder="Business ABN"
                               @change="autoUpdateCardCustomer">
                      </div>
                    </div>

                    <div v-if="card.customer.customer_type === 'F' && !isTechnicalUser"
                         class="form-group row">
                      <label class="col-sm-4 col-4 col-form-label">Business ABN</label>
                      <div class="col-sm-8 col-8">
                        <input type="text" :disabled="isDisabledFieldsByUserRole"
                               :readonly="!isStatusActive"
                               v-model="card.customer.abn" class="form-control"
                               placeholder="Business ABN"
                               @change="autoUpdateBusinessABN">
                      </div>
                    </div>

                    <div v-if="card.customer.customer_type === 'F' && !isTechnicalUser"
                         class="form-group row">
                      <label class="col-sm-4 col-4 col-form-label">Vehicle Driver</label>
                      <div class="col-sm-8 col-8">
                        <input type="text" :disabled="isDisabledFieldsByUserRole"
                               :readonly="!isStatusActive"
                               v-model="card.vehicleDriverName" class="form-control"
                               placeholder="Vehicle Driver"
                               @change="autoUpdateContacts">
                      </div>
                    </div>

                    <div class="form-group row" v-if="!isTechnicalUser">
                      <label for="mobile" class="col-sm-4 col-4 col-form-label">Contact Number 1</label>
                      <div class="col-sm-8 col-8">
                        <input type="text" v-model="card.contactNumber1" class="form-control"
                               placeholder="Contact Number 1"
                               @change="autoUpdateContacts"
                               :readonly="!isStatusActive"
                               :disabled="isDisabledFieldsByUserRole">
                      </div>
                    </div>
                    <div class="form-group row" v-if="!isTechnicalUser">
                      <label for="mobile" class="col-sm-4 col-4 col-form-label">Contact Number 2</label>
                      <div class="col-sm-8 col-8">
                        <input type="text" v-model="card.contactNumber2"
                               class="form-control" placeholder="Contact Number 2"
                               @change="autoUpdateContacts"
                               :readonly="!isStatusActive"
                               :disabled="isDisabledFieldsByUserRole">
                      </div>
                    </div>
                    <div class="form-group row" v-if="!isTechnicalUser">
                      <label for="fax" class="col-sm-4 col-4 col-form-label">Fax</label>
                      <div class="col-sm-8 col-8">
                        <input type="text" v-model="card.customer.fax" class="form-control"
                               id="fax" placeholder="Fax"
                               :readonly="!isStatusActive"
                               :disabled="!card.customer.customer_id || isDisabledFieldsByUserRole"
                               @change="autoUpdateCardCustomer">
                      </div>
                    </div>
                    <div class="form-group row" v-if="!isTechnicalUser">
                      <label class="col-sm-4 col-4 col-form-label">Email Address</label>
                      <div class="col-sm-8 col-8">
                        <input type="text" v-model="card.contactEmail" class="form-control"
                               placeholder="Email Address"
                               @change="changedEmailAddress"
                               @blur="checkEmail2"
                               @input="checkEmail"
                               :readonly="!isStatusActive"
                               :disabled="isDisabledFieldsByUserRole">
                        <small class="form-text show-help-for-body"
                               v-if="!isCheckEmail">
                          Please enter a valid email address
                        </small>
                      </div>
                    </div>
                    <div class="form-group row" v-if="!isTechnicalUser">
                      <label for="address" class="col-sm-4 col-4 col-form-label">Search Address</label>
                      <div class="col-sm-8 col-8">
                        <AddressSuggestions type="text"
                                            :refsAddress="'modalContactForm'"
                                            :model.sync="card.customer.address"
                                            id="label_address_loc"
                                            placeholder="Search for an Address"
                                            :options="addressOptions"
                                            @onSelect="onCustomerAddressSelect"
                                            :is-disabled="!isStatusActive || isDisabledFieldsByUserRole"
                        >
                        </AddressSuggestions>
                      </div>
                    </div>
                    <div class="form-group row" v-if="!isTechnicalUser">
                      <label for="level_unit_lot" class="col-sm-4 col-4 col-form-label">Level/Unit/Lot
                        Nbr</label>
                      <div class="col-sm-8 col-8">
                        <input type="text" v-model="card.customer.level_unit_lot"
                               class="form-control" id="level_unit_lot"
                               placeholder="Lot, Unit, or Level number"
                               :readonly="!isStatusActive"
                               @change="autoUpdateCardCustomer" :disabled="isDisabledFieldsByUserRole">
                      </div>
                    </div>
                    <div class="form-group row" v-if="!isTechnicalUser">
                      <label for="street_name" class="col-sm-4 col-4 col-form-label">Street
                        Nbr/Name</label>
                      <div class="col-sm-8 col-8">
                        <input type="text" v-model="card.customer.street_name"
                               class="form-control" id="street_name"
                               placeholder="Street Number or Name"
                               :readonly="!isStatusActive"
                               @change="autoUpdateCardCustomer" :disabled="isDisabledFieldsByUserRole">
                      </div>
                    </div>
                    <div class="form-group row" v-if="!isTechnicalUser">
                      <label for="suburb" class="col-sm-4 col-4 col-form-label">Suburb</label>
                      <div class="col-sm-8 col-8">
                        <input type="text" v-model="card.customer.suburb" class="form-control"
                               :readonly="!isStatusActive"
                               id="suburb" :disabled="isDisabledFieldsByUserRole" placeholder="Suburb"
                               @change="autoUpdateCardCustomer">
                      </div>
                    </div>
                    <div class="form-group row" v-if="!isTechnicalUser">
                      <label for="state" class="col-sm-4 col-4 col-form-label">State</label>
                      <div class="col-sm-8 col-8">
                        <!--<input type="text" class="form-control" id="state" placeholder="State">-->
                        <multiselect
                            ref="vehicleOwnerAddressState"
                            v-model="computedCustomerState"
                            :options="addressStates"
                            :showLabels="false"
                            :option-height="29"
                            :max-height="203"
                            :close-on-select="true"
                            placeholder="Select State"
                            track-by="key"
                            label="value"
                            style="opacity: 1"
                            :disabled="!card.customer.customer_id || !isStatusActive || isDisabledFieldsByUserRole"
                            @select="autoUpdateCardCustomer"
                        >
                        </multiselect>
                      </div>
                    </div>
                    <div class="form-group row" v-if="!isTechnicalUser">
                      <label for="postcode" class="col-sm-4 col-4 col-form-label">Postcode</label>
                      <div class="col-sm-8 col-8">
                        <input type="text" v-model="card.customer.postcode" class="form-control"
                               id="postcode" placeholder="Postcode"
                               :readonly="!isStatusActive"
                               :disabled="!card.customer.customer_id || isDisabledFieldsByUserRole"
                               @change="autoUpdateCardCustomer">
                      </div>
                    </div>
                    <div class="form-group row" v-if="!isTechnicalUser">
                      <label for="country" class="col-sm-4 col-4 col-form-label">Country</label>
                      <div class="col-sm-8 col-8">
                        <multiselect
                            id="country"
                            ref="vehicleOwnerAddressCountry"
                            v-model="computedCustomerCountry"
                            :options="addressCountries"
                            :showLabels="false"
                            :option-height="29"
                            :max-height="203"
                            :close-on-select="true"
                            placeholder="Select Country"
                            track-by="key"
                            label="value"
                            :disabled="!card.customer.customer_id || !isStatusActive || isDisabledFieldsByUserRole"
                            @select="autoUpdateCardCustomer"
                            style="opacity: 1"
                        >
                        </multiselect>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 p-0 reset-padd wrapper-vehicle-block">
                <!-- Vehicle block -->
                <vehicle-block
                    ref="vehicle-block"
                    :card="card"
                    :settings="settings"
                    :vehiclePaintCodes="vehiclePaintCodes"
                    :propsIsAutoSave="isAutoSave"
                    :properties="properties"
                    :isCardEdit="true"
                    @loadPaintCodes="loadPaintCodes"
                    @setUpdateData="setUpdateData"
                    :propsEstimateMethodology="card.estimate_methodology"
                ></vehicle-block>
              </div>
              <div class="col-md-4 reset-padd">
                <div class="card ex-card ex-quote-information" v-if="!isTechnicalUser">
                  <div class="card-header bg-navyblue">
                    <strong>Estimate Information</strong>
                  </div>
                  <div class="card-block bg-white">
                    <div class="form-group row">
                      <label for="customer_name" class="col-sm-4 col-4 col-form-label">Estimate
                        Number</label>
                      <div class="col-sm-8 col-8">
                        <div class="form-control" style="min-height: 2.4rem;">
                          <ul class="nav nav-pills nav-stacked nav-8">
                            <li class="nav-item mr-1"
                                :class="{'pt-0-5': index > 0 && e.supplement_number == 0}"
                                v-for="(e, index) in estimates">
                              <router-link v-if="!isTechnicalUser && e.estimate_id > 0"
                                           :class="{'disabled-cards-edit-is-user': isDisabledFieldsByUserRole}"
                                           :to="'/estimates/view/' + e.estimate_id">
                                <span v-if="e.supplement_number > 0">+&nbsp;</span>
                                <span
                                    v-b-tooltip.hover="{customClass: 'ps-tooltip', title:'Estimate', placement: 'left'}">{{
                                    e.estimate_number
                                  }} </span>
                                <span v-if="e.supplement_number > 0">-
                                                                    <span
                                                                        v-b-tooltip.hover="{customClass: 'ps-tooltip', title:'Supplement', placement: 'left'}"> {{
                                                                        e.supplement_number
                                                                      }} </span>
                                                                </span>
                              </router-link>
                              <span v-else><span
                                  v-if="e.supplement_number > 0">+&nbsp;</span>{{
                                                                     <span v-b-tooltip="{customClass: 'ps-tooltip'}"
                                                                           title="Estimate"> {{
                                                                         e.estimate_number
                                                                       }} </span>
                                                                }}<span
                                    v-if="e.supplement_number > 0">-{{
                                                                        <span v-b-tooltip="{customClass: 'ps-tooltip'}"
                                                                              placement='left'
                                                                              title="Supplement"> {{
                                                                            e.supplement_number
                                                                          }} </span>
                                                                    }}</span></span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="estimator" class="col-sm-4 col-4 col-form-label">Estimator</label>
                      <div class="col-sm-8 col-8">
                        <!--<span class="form-control">{{ assignedToUser || '' }}</span>-->

                        <multiselect
                            ref="card.estimator"
                            v-model="estimator"
                            :options="computedEstimators"
                            :showLabels="false"
                            :option-height="29"
                            :max-height="203"
                            :close-on-select="true"
                            :disabled="!isStatusActive || isDisabledFieldsByUserRole"
                            style="opacity: 1"
                            @select="onEstimatorSelect"
                            placeholder="Select Estimator"
                            track-by="id"
                            label="name"
                        >
                        </multiselect>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card ex-card ex-invice-information" v-if="!isTechnicalUser">
                  <div class="card-header bg-navyblue">
                    <strong>Invoice Information</strong>
                  </div>
                  <div class="card-block bg-white">
                    <div class="form-group row">
                      <label for="invoice_number" class="col-sm-4 col-4 col-form-label">Invoice
                        Number</label>
                      <div class="col-sm-8 col-8">
                        <div class="form-control" style="min-height: 2.4rem;">
                          <ul class="nav nav-pills nav-stacked nav-8">
                            <li class="nav-item mr-1 pt-0-5"
                                v-if="!isTechnicalUser && (card.invoice && card.invoice.id>0)">
                              <router-link v-b-tooltip title="Complete Invoice"
                                           :to="{name:'InvoiceView',  params: { invoice_id: card.invoice.id }}"
                                           :class="{'disabled-cards-edit-is-user': isDisabledFieldsByUserRole}"
                                           v-b-tooltip.hover="{customClass: 'ps-tooltip', title:'Complete Invoice', placement: 'left'}">
                                {{ card.invoice.number }}
                              </router-link>
                            </li>
                            <li class="nav-item mr-1 pt-0-5"
                                v-if="!isTechnicalUser && (card.excess_invoice && card.excess_invoice.id>0) && card.insurance.excess">
                              <router-link v-b-tooltip title="Excess Invoice"
                                           :to="{name:'InvoiceExcessView',  params: { invoice_id: card.excess_invoice.id }}"
                                           :class="{'disabled-cards-edit-is-user': isDisabledFieldsByUserRole}"
                                           v-b-tooltip.hover="{customClass: 'ps-tooltip', title:'Excess Invoice', placement: 'left'}">
                                {{ card.excess_invoice.number }}
                              </router-link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="payment_status" class="col-sm-4 col-4 col-form-label">Payment
                        Status</label>
                      <div class="col-sm-8 col-8">
                                                <span class="form-control">
                                                    <div v-if="card.invoice && card.invoice.id>0">Complete {{
                                                        card.invoice.status
                                                      }}</div>
                                                    <div v-if="card.excess_invoice && card.excess_invoice.id>0">Excess {{
                                                        card.excess_invoice.status
                                                      }}</div>
                                                </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card ex-card vue-form-wizard wizard-bottom">
                  <div class="vue-form-wizard xs">
                    <div class="card ex-card wizard-bottom">
                      <small-form-wizard
                          title="Progress"
                          :current-status-id="card.card_status"
                          :statuses="card.statuses"
                          :progress="cardProgressSmall.widthActivePercent"
                          @change="onChangeCardProgress"
                          @full-clicked="activityTabChange"
                      />
                    </div>
                    <div class="wizard-card-footer clearfix">
                      <span></span>
                    </div>
                  </div>
                </div>

                <div class="medium-rectangle-b-promo-block">
                  <advert-banner
                      v-if="!isMobile"
                      :bannerType="2"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-8 pr-0 reset-padd">
                <!-- Insurance block -->
                <insurance-block
                    :card="card"
                    :assessors="assessors"
                    :insurers="insurers"
                    @updateInsurance="autoUpdateInsurance"
                    @updateTotalLoss="autoUpdateVehicleTotalLoss"
                    :hiddenElements="{market_value:true, agreed_value:true}"
                    :isDisabledIsurerName="properties.isFromIag"
                ></insurance-block>
              </div>
              <div class="col-md-4 reset-padd">
                <div class="card ex-card ex-invice-information" v-if="!isTechnicalUser">
                  <div class="card-header bg-navyblue">
                    <strong>Scheduling</strong>
                  </div>
                  <div class="card-block bg-white">
                    <div class="form-group row">
                      <label class="col-sm-4 col-4 col-form-label">Date
                        In</label>
                      <div class="col-sm-8 col-8">
                        <date-picker v-model="card.booking_date"
                                     id="date_in"
                                     :is-disabled="!isStatusActive || isDisabledFieldsByUserRole"
                                     @onChange="autoUpdateBookingDate"
                                     :placeHolderText="'Date In'">
                        </date-picker>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-sm-4 col-4 col-form-label">Date
                        Out</label>
                      <div class="col-sm-8 col-8">
                        <date-picker v-model="card.completion_date"
                                     :is-disabled="!isStatusActive || isDisabledFieldsByUserRole"
                                     id="date_out"
                                     @onChange="autoUpdateCompletionDate"
                                     :placeHolderText="'Date Out'">
                        </date-picker>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-4 col-form-label">Pick Up Time</label>
                      <div class="col-sm-8 col-8">
                        <vue-timepicker
                            v-model="card.pickUpTime"
                            :disabled="!isStatusActive || isDisabledFieldsByUserRole"
                            :placeholder="'HH:MM'"
                            format="hh:mm A"
                            @change="autoUpdatePickUpTime"
                            class="cards-edit-timepicker"
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-sm-4 col-4" style="padding-left: 0px">
                        <span style="display: block" class="col-form-label">Boards</span>
                        <span style="color: rgba(0, 0, 0, 0.5); font-size: 12px;">Add & remove the card to different boards</span>
                      </div>
                      <div class="col-sm-8 col-8">
                        <multiselect
                            v-model="currentBoards"
                            :options="['Vehicle In Board', 'Departure Board', 'Bookings Board']"
                            :showLabels="false"
                            placeholder=""
                            :option-height="29"
                            :max-height="203"
                            @select="addCardToBoardFromMultiselect"
                            @remove="removeCardToBoardFromMultiselect"
                            :close-on-select="true"
                            :disabled="!isStatusActive || isDisabledFieldsByUserRole"
                            :multiple="true"
                            class="board-multiselect-width"
                        />
                      </div>
                    </div>

                    <div class="form-group row" v-if="currentBoards && currentBoards.includes('Bookings Board')">
                      <div class="col-sm-4 col-4" style="padding-left: 0px">
                        <span style="display: block" class="col-form-label">Booking Board Category</span>
                      </div>
                      <div class="col-sm-8 col-8">
                        <multiselect
                            :options="categoryOptions"
                            v-model="computedBookingBoardCategory"
                            :showLabels="false"
                            placeholder=""
                            :option-height="29"
                            :max-height="203"
                            track-by="id"
                            label="name"
                            @select="changedBookingCategory"
                            :disabled="!isStatusActive || isDisabledFieldsByUserRole"
                            class="board-multiselect-width"
                        >
                          <template slot="singleLabel" slot-scope="props">
                            <span style="transform: translateY(3px);" class="categories-menu-color-icon" :style="{backgroundColor: props.option.color}"></span> <span>{{props.option.name}}</span>
                          </template>
                          <template slot="option" slot-scope="props">
                            <span style="transform: translateY(3px);" class="categories-menu-color-icon" :style="{backgroundColor: props.option.color}"></span> <span>{{props.option.name}}</span>
                          </template>
                        </multiselect>
                      </div>
                    </div>

                    <div class="form-group row"
                         v-if="card.boardDeparture && card.boardDeparture.createdDate && card.boardDeparture.createdDate !== null">
                      <label class="col-sm-4 col-4 col-form-label">Added to Departure Board</label>
                      <div class="col-sm-8 col-8">
                        <date-picker
                            v-model="card.boardDeparture.createdDate"
                            :isDisabled="true"
                            :placeHolderText="'Added to Departure Board'">
                        </date-picker>
                      </div>
                    </div>
                    <div class="form-group row"
                         v-if="card.boardIn && card.boardIn.createdDate && card.boardIn.createdDate !== null">
                      <label class="col-sm-4 col-4 col-form-label">Added to Vehicle in Board</label>
                      <div class="col-sm-8 col-8">
                        <date-picker style="opacity: 1; border-radius: 3px;"
                                     v-model="card.boardIn.createdDate"
                                     :isDisabled="true"
                                     :placeHolderText="'Added to Vehicle in Board'">
                        </date-picker>
                      </div>
                    </div>

                    <template v-if="card.plannerBoard && card.plannerBoard.onSite && card.plannerBoard.onSite.id">

                      <div class="estimage-line"></div>

                      <div style="margin-top: 40px">
                        <div class="form-group row" >
                          <label class="col-sm-4 col-4 col-form-label">On-site Estimate Booking Date</label>
                          <div class="col-sm-8 col-8">
                            <date-picker v-model="card.plannerBoard.onSite.onsiteEstimateBookingDate" @onChange="saveOnsiteData" :is-disabled="!isStatusActive || isDisabledFieldsByUserRole" :placeHolderText="'Date'">
                            </date-picker>
                            <p v-if="card.plannerBoard.onSite.isRebooked" style="font-weight: bold; font-size: 12px; line-height: 16px; margin-bottom: 0px; margin-top: 7px;">Re-booked</p>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4 col-4 col-form-label">
                            Attended Estimate Booking
                          </label>
                          <div class="col-sm-8 col-8 d-flex insurer-block-mobile">
                            <div class="checkbox-p">
                              <label class="form-check-inline checkbox d-flex">
                                <input class="form-check-input" type="checkbox" name="nonAttReason"
                                       v-model="isAttendedBookingYes"
                                       @change="checkIsAttendYes(isAttendedBookingYes)"
                                       :disabled="!isStatusActive || isDisabledFieldsByUserRole"
                                >
                                <span class="icon"><i class='bx bx-check'></i></span><span class="text">Yes</span>
                              </label>
                            </div>
                            <div class="checkbox-p">
                              <label class="form-check-inline checkbox d-flex">
                                <input class="form-check-input" type="checkbox" name="nonAttReason"
                                       hidden
                                       v-model="isAttendedBookingNo"
                                       @change="checkIsAttendNo(isAttendedBookingNo)"
                                       :disabled="!isStatusActive || isDisabledFieldsByUserRole">
                                <span class="icon"><i class='bx bx-check'></i></span><span class="text">No</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="col-sm-4 col-4" style="padding-left: 0px">
                            <span style="display: block" class="col-form-label">Non-attendance reason</span>
                          </div>
                          <div class="col-sm-8 col-8">
                            <multiselect
                                :options="properties.estimateBooking && properties.estimateBooking.reasons ? properties.estimateBooking.reasons : []"
                                v-model="onSiteReason"
                                :showLabels="false"
                                placeholder=""
                                @input="saveOnsiteData(false)"
                                :option-height="29"
                                :max-height="203"
                                :close-on-select="true"
                                :disabled="isDisabledOnSiteSelects"
                                class="board-multiselect-width"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <div class="col-sm-4 col-4" style="padding-left: 0px">
                            <span style="display: block" class="col-form-label">Call back Attempts </span>
                          </div>
                          <div class="col-sm-8 col-8">
                            <multiselect
                                :options="properties.estimateBooking && properties.estimateBooking.attempts ? properties.estimateBooking.attempts : []"
                                v-model="onSiteAttempt"
                                :showLabels="false"
                                placeholder=""
                                :option-height="29"
                                @input="saveOnsiteData(false)"
                                :max-height="203"
                                :close-on-select="true"
                                :disabled="isDisabledOnSiteSelects"
                                class="board-multiselect-width"
                            />
                          </div>
                        </div>
                      </div>
                    </template>





                    <div class="medium-rectangle-b-promo-block">

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="currentTab === 'notes' && !isTechnicalUser" id="card-notes">
            <card-notes
                ref="cardNotes"
                :notes="notes"
                :card_id="$route.params.card_id"
                @loadNotes="loadNotes">
            </card-notes>
          </div>
          <div v-if="currentTab === 'files'" id="card-files">
            <card-files @beforeDebouncedAutoSave="beforeDebouncedAutoSave">
              <button type="button" style="width: 100%; margin: 10px 0px 0px 15px;" @click="isShowScanner = !isShowScanner"
                      class="btn btn-primary w-min-sm waves-effect waves-light">
                Select Scanner
              </button>
              <div v-if="isShowScanner" class="scanner-items" style="width: 100%;">
                <template v-if="getActivePcs(pcs) && getActivePcs(pcs).length">
                  <div
                      v-for="pc in getActivePcs(pcs)"
                      :key="pc.id"
                      @click="selectPc(pc)"
                      class="scanner-item"
                      :class="{'selected-scanner-item': pc.id == selectedPcId}"
                  >
                    {{pc.pc_name}}
                  </div>
                </template>
                <template v-else>
                  <div class="scanner-item">No Active Scanners</div>
                </template>
              </div>
              <div class="current-scanner" v-else-if="selectedPcId">
                Current Scanner: {{selectedPcName}}
              </div>
            </card-files>
          </div>
          <div v-if="currentTab === 'images'" id="card-photos">
            <button v-if="(!isTechnicalUser || !isStatusActive) && isShowBigImage"
                    style="position: absolute; z-index: 9999999999; top: 80px; transform: translateX(10px);"
                    class="btn btn-primary mx-0 waves-effect waves-light" @click="pinImageOnBigImageView">
              Pin Images
            </button>
            <div>
              <vue-dropzone v-on:vdropzone-file-added="addImgUpload"
                            v-on:vdropzone-success="successImgUpload"
                            v-on:vdropzone-complete="completeImgUpload"
                            v-on:vdropzone-sending="sendImgUpload"
                            ref="imgUpDrop"
                            id="imgUploadDrop"
                            class="dropify-wrapper dropzoneContainer"
                            style="height: auto;"
                            v-if="isStatusActive"
                            :options="imageUploadDropzoneOptions"
                            :useCustomSlot="true">
                <div class="dropify-message " style="transform: translateY(0%);">
                  <template v-if="isMobile">
                    <i @click="openCapture" class='bx bx-camera drop-clickable file-icon'></i>
                    <p @click="openCapture" class="drop-down-text drop-clickable">Take a Photo</p>
                  </template>
                  <template v-else>
                    <i class='bx bx-cloud-upload'></i>
                    <p class="drop-down-text">Drag and Drop Images Here</p>
                  </template>
                  <p class="text-or mb-0-5">Or</p>
                  <div class="text-sm-center mb-1 ex-image-upload-buttons">
                    <button @click.prevent="clickBrowseDropzoneImages($event)" type="button" class="drop-clickable btn btn-primary waves-effect waves-light browse-btn browse-btn-images">Browse Images</button>
                    <button @click.prevent="clickCancelDropzoneImages($event)" type="button"
                            class="btn btn-secondary waves-effect waves-light cancel-btn">Cancel
                    </button>
                  </div>
                </div>
              </vue-dropzone>
            </div>

            <div class="row">
              <image-lightbox
                  ref="imageLightbox"
                  :isPreviewImage="'true'"
                  :fromComponent="'card'"
                  :position="'below_drag_area'"
                  :imageList="imageSource"
                  :isShowBigImage="isShowBigImage"
                  @showOrHideGallery="showOrHideGallery"
                  @updateCategoryFunc="updateImageCategory"
                  @removeImage="deleteImage"
                  :is-delete="true"
                  :path="''"
                  :showContextMenu="true"
                  :boardInId="boardInId"
                  :boardDepartureId="boardDepartureId"
              ></image-lightbox>
            </div>
          </div>
          <div v-if="currentTab === 'activity'" id="activity" ref="CardActivity">
            <div class="row sticky" v-if="!isTechnicalUser" :style="`top: ${topSticky}px`">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 additional-card-progress-scroll">
                <vue-scroll>
                  <div class="vue-form-wizard xs">
                    <div class="wizard-header">
                      <h4 class="text-left mb-0">Card Progress</h4>
                    </div>
                    <div class="wizard-navigation wizard-navigation-activity mt-0">
                      <div class="wizard-progress-with-circle">
                        <div class="wizard-progress-bar"
                             style="background-color: rgb(94, 121, 255); color: rgb(20, 39, 66);"
                             :style="cardProgress.widthActivePercent"></div>
                      </div>
                      <ul role="tablist"
                          class="wizard-nav wizard-nav-pills wizard-nav-pills-activity">
                        <li :class="{'active': cardstatus.active}"
                            v-for="(cardstatus, index)  in card.statuses">
                          <a @click="onChangeCardProgress(cardstatus.id)"
                             v-if="card.card_status===cardstatus.id">
                            <div role="tab" tabindex="0" aria-controls="Card Open"
                                 class="wizard-icon-circle md checked" aria-disabled="true"
                                 aria-selected="true"
                                 style="border-color: rgb(20, 39, 66);">
                              <div class="wizard-icon-container">
                                <i class="wizard-icon ti-check"></i>
                              </div>
                            </div>
                            <span class="stepTitle active"
                                  style="color: rgb(20, 39, 66);">{{ cardstatus.title }}</span>
                          </a>
                          <a @click="onChangeCardProgress(cardstatus.id)" v-else>
                            <div role="tab" tabindex="" class="wizard-icon-circle md">

                            </div>
                            <span class="stepTitle">{{ cardstatus.title }}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="wizard-card-footer clearfix pb-1 pr-1">
                      <span></span>
                    </div>
                  </div>
                </vue-scroll>
              </div>

              <div class="col-md-12">
                <div class="border-top"></div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-10 col-xl-8">
                <div class="row activity-list-header px-0">
                  <div class="col-xs-6">
                    <h4>Activity List</h4>
                  </div>

                  <div class="col-md-6">
                    <div class="navbar-nav nav dropdown dropdown-options show inline float-right mr-0">
                                            <span
                                                class="btn waves-effect waves-light ex-options-button"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="true">
                                                {{ activitySortTypeText }}
                                                <span class="dropdown-arrow  pull-right"
                                                      style="box-sizing: border-box; width: 30px; border-left: 1px solid rgba(27, 30, 56, 0.25);">
                                                    <i class="fa fa-chevron-down pull-right"></i>
                                                </span>
                                            </span>
                      <div class="dropdown-menu dropdown-menu-right animated fadeInUp">
                        <a @click="changeActivitySortBy(activitySortTypes.email)"
                           class="dropdown-item text-primary p-1 clickable">
                          <i class="fa fa-envelope-o" aria-hidden="true"></i> {{ activitySortTypes.email }}
                        </a>
                        <a v-if="isSmsAvailable" @click="changeActivitySortBy(activitySortTypes.sms)"
                           class="dropdown-item text-primary p-1 clickable">
                          <i class="fa fa-envelope-o" aria-hidden="true"></i> {{ activitySortTypes.sms }}
                        </a>
                        <a @click="changeActivitySortBy(activitySortTypes.external)"
                           class="dropdown-item text-primary p-1 clickable">
                          <i class="fa fa-external-link" aria-hidden="true"></i> {{ activitySortTypes.external }}
                        </a>
                        <a @click="changeActivitySortBy(activitySortTypes.assignment)"
                           class="dropdown-item text-primary p-1 clickable">
                          <i class="fa fa-user" aria-hidden="true"></i> {{ activitySortTypes.assignment }}
                        </a>
                        <a @click="changeActivitySortBy(activitySortTypes.status_change)"
                           class="dropdown-item text-primary p-1 clickable">
                          <i class="fa fa-pencil-square-o" aria-hidden="true"></i> {{ activitySortTypes.status_change }}</a>
                        <a v-if="this.activitySortTypeText != 'Sort By' && this.activitySortTypeText != 'Show All'"
                           @click="changeActivitySortBy(activitySortTypes.show_all)"
                           class="dropdown-item text-primary p-1 clickable"
                           style="padding-left: 28px !important">{{ activitySortTypes.show_all }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-12 col-lg-10 col-xl-8">
                <activity-list
                    :activity="computedActivity"
                    :cardId="card.card_id"
                    :newTask="newTask"
                    :isOpenNewTaskForm="isOpenNewTaskForm"
                    :filteredListAssignedTo="getterVisibleUsers"
                    @loadActivity="loadActivity"
                    @loadFiles=""
                    @loadImages=""
                    @saveTask="saveTask"
                    @saveNewTask="saveNewTask"
                    @closeTask="closeTask"
                    @deleteTask="deleteTask"
                    @deleteActivity="deleteActivity"
                    @closeNewTask="closeNewTask"
                >
                </activity-list>
              </div>
              <div class="col-xl-4">
                <div class="desktop-b-promo-block cards-activity-list-b-promo">
                  <advert-banner
                      v-if="!isMobile"
                      :bannerType="4"
                  />
                </div>
              </div>
              <div class="col-md-6 col-xs-12">
                <amazon-ads></amazon-ads>
              </div>
            </div>
          </div>
          <div v-if="currentTab === 'jobCard'" id="job-card" class="nav-title touch-block">
            <h4 class="estimate-header">Estimate Item Checklist</h4>
            <div class="row" @touchstart="touchClose">
              <div class="col-md-7" style="margin-bottom: 100px">
                <div class="card" v-if="!isEmpty(jobCardRR)"
                     :class="{'last-estimate-item': isEmpty(jobCardPDR) && isEmpty(jobCardOther) && isEmpty(jobCardSublet) && isEmpty(jobCardPart) && isEmpty(jobCardPaint) && isEmpty(jobCardRepair)}">
                  <div class="card-header bg-navyblue row px-0 m-0" style="height:60px !important;">
                    <div class="col-lg-9 col-sm-9 col-xs-9" style="padding-left:12px; padding-top:7px;">R&R</div>
                    <div class="col-lg-3 col-sm-3 col-xs-3 text-right d-flex additional-flex-end"
                         style="padding-right:12px;">
                      <div class="areaTouch" @touchstart="touchStart2($event,{type: 'Remove'})" @touchend="touchEnd2" style="margin-right: 20px;">
                        <label for="allRemoveRrInJobCard" class="input-label pin-job">
                          <input id="allRemoveRrInJobCard" class="input-checkbox" :disabled="!isStatusActive"
                                 type="checkbox" name="removeCheckbox" v-model="isCheckedRemoveAllRRInJobCard"/>

                          <span
                              @contextmenu.prevent="openAddSplitAllContext($event, {type: 'Remove'})"
                              :class="{
                                'pin_content': !getIsAllPartsSplitIsReady('Remove') && isCheckedRemoveAllRRInJobCard,
                                'gradient-checkbox-RR-header': !getIsAllPartsSplitIsReady('Remove') && isCheckedRemoveAllRRInJobCard,
                                'checkbox-in-job-card-header':  !getIsAllPartsSplitIsReady('Remove') && isCheckedRemoveAllRRInJobCard,
                              }"
                              class="pin_header pin-checkmark checkbox-title-RR">
                            <i class='bx bx-check'></i>
                          </span>
                        </label>
                        <p class="allRrInJobCard-text" style="right: -10px;">Remove</p>
                      </div>

                      <div class="areaTouch" @touchstart="touchStart2($event,{type: 'Replace'})" @touchend="touchEnd2">
                        <label for="allRrInJobCard" class="input-label pin-job">
                          <input class="input-checkbox" :disabled="!isStatusActive" type="checkbox" name="checkbox"
                                 id="allRrInJobCard" v-model="isCheckedAllRRInJobCard">
                          <span
                              @contextmenu.prevent="openAddSplitAllContext($event, {type: 'Replace'})"
                              :class="{
                                'pin_content': !getIsAllPartsSplitIsReady('Replace') && isCheckedAllRRInJobCard,
                                'gradient-checkbox-RR-header': !getIsAllPartsSplitIsReady('Replace') && isCheckedAllRRInJobCard,
                                'checkbox-in-job-card-header': !getIsAllPartsSplitIsReady('Replace') && isCheckedAllRRInJobCard,
                              }"
                              class="pin_header pin-checkmark checkbox-title-RR">
                            <i class='bx bx-check'></i>
                          </span>
                        </label>
                        <p class="allRrInJobCard-text">Refit</p>
                      </div>
                    </div>
                  </div>
                  <div class="card-block">
                    <div class="row"
                         :ref="'estimateItem' + item.id"
                         :class="{'background-yellow': item.job_highlight === 'yellow', 'background-green': item.job_highlight === 'green', 'background-purple': item.job_highlight === 'purple'}"
                         @contextmenu.prevent="openMenuForEstimateItem($event, {item: item, type: 'RR'})"
                         v-for="item in jobCardRR">
                      <div class="col-lg-9 col-sm-9 col-xs-9 left-element" style="padding:1rem 0">
                        <div class="col-lg-10 col-sm-8 col-xs-8 pl-0 areaTouch"
                             @touchstart="touchStart($event,{item: item, type: 'RR'})" @touchend="touchEnd">
                          {{ item.name }}
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-3 col-xs-3 d-flex additional-flex-end">
                        <div class="card-block-icons">
                          <div @touchstart="touchStartForJobCardItem($event, item, 'RR', comment)" @touchend="touchEnd"
                               @contextmenu.prevent="openMenuForJobCardItem($event, item, 'RR', comment)"
                               v-for="comment in item.job_comment" :key="comment.userId" class="icon-comment-active"
                               v-b-tooltip.hover="{customClass: 'ps-tooltip', title: comment.text + ' - ' + comment.author, placement:'left'}">
                            <i class='bx bx-comment-detail job-card-comment'></i>
                          </div>
                          <div v-if="item.job_user_fullname_remove && item.is_checked_remove"
                               class="icon-comment-active"
                               v-b-tooltip.hover="{customClass: 'ps-tooltip', placement:'left', html: true, title: getAllNamesOfItem(item, 'Remove')}">
                            <template v-if="item && item.split_remove.length > 1 && item.percent_remove && item.percent_remove == 100">
                              <i  class='bx bxs-group ml-1 icon-block-margin bxs-group-remove'></i>
                            </template>
                            <i v-else class='bx bxs-user ml-1 icon-block-margin bxs-user-remove' :class="{'gradient-icon': item.percent_remove > 0 && item.percent_remove < 100}"></i>
                          </div>
                          <div v-else class="icon-comment-active">
                            <i class='bx bx-user ml-1 icon-block-margin bx-user-remove'></i>
                          </div>
                          <div v-if="item.job_user_fullname_replace && item.is_checked_replace"
                               class="icon-comment-active"
                               v-b-tooltip.hover="{customClass: 'ps-tooltip', placement:'left', html: true, title: getAllNamesOfItem(item, 'Replace')}">
                            <template v-if="item && item.split_replace.length > 1 && item.percent_replace && item.percent_replace == 100">
                              <i  class='bx bxs-group ml-1 icon-block-margin bxs-group-refit'></i>
                            </template>
                            <i v-else class='bx bxs-user ml-1 icon-block-margin bxs-user-refit' :class="{'gradient-icon': item.percent_replace > 0 && item.percent_replace < 100}"></i>
                          </div>
                          <div v-else class="icon-comment-active">
                            <i class='bx bx-user ml-1 icon-block-margin bx-user-refit'></i>
                          </div>
                        </div>
                        <div class="text-right d-flex additional-flex-end" style="padding:0.75rem">
                          <div style="margin-right: 50px; position: relative">
                            <label class="pin-job">
                              <input type="checkbox"
                                     :disabled="!isStatusActive"
                                     @change="onChangeJobCardCheckedItemOfRR(item, 'Remove')"
                                     name="removeCheckbox"
                                     v-model="item.is_checked_remove"/>
                              <span class="pin_content pin-checkmark"><i class='bx bx-check'></i></span>
                            </label>
                            <span :ref="'removeRemoveSplitCheckbox-' + item.id" v-if="item.is_checked_remove && item.percent_remove > 0 && item.percent_remove < 100" @click="deleteSplitRemoveChecked(item, 'RR')" class="pin_content pin-checkmark gradient-checkbox"><i class='bx bx-check d-flex'></i></span>
                          </div>
                          <div style="position: relative">
                            <label class="pin-job right-element">
                              <input type="checkbox"
                                     :disabled="!isStatusActive"
                                     @change="onChangeJobCardCheckedItemOfRR(item, 'Replace')"
                                     name="replaceCheckbox"
                                     v-model="item.is_checked_replace"/>
                              <span class="pin_content pin-checkmark"><i
                                  class='bx bx-check'></i></span>
                            </label>
                            <span :ref="'removeReplaceSplitCheckbox-' + item.id" v-if="item.is_checked_replace && item.percent_replace > 0 && item.percent_replace < 100" @click="deleteSplitReplaceChecked(item, 'RR')" class="pin_content pin-checkmark gradient-checkbox"><i class='bx bx-check d-flex'></i></span>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div class="card" v-if="!isEmpty(jobCardRepair)"
                     :class="{'last-estimate-item': isEmpty(jobCardPDR) && isEmpty(jobCardOther) && isEmpty(jobCardSublet) && isEmpty(jobCardPart) && isEmpty(jobCardPaint)}">
                  <div class="card-header bg-navyblue row px-0 m-0">
                    <div class="col-lg-9  col-sm-9 col-xs-9" style="padding-left:12px;">Repair
                    </div>
                    <div @touchstart="touchStart2($event,{type: 'Repair'})" @touchend="touchEnd2" class="col-lg-3 col-sm-3 col-xs-3 text-right areaTouch" style="padding-right:12px;">
<!--                      All-->
                      <label class="pin-job">
                        <input type="checkbox" :disabled="!isStatusActive" v-model="isCheckedAllRepairInJobCard">
                        <span
                            style="top: -10px !important"
                            @contextmenu.prevent="openAddSplitAllContext($event, {type: 'Repair'})"
                            :class="{
                                'pin_content': !getIsAllPartsSplitIsReady('Repair') && isCheckedAllRepairInJobCard,
                                'gradient-checkbox': !getIsAllPartsSplitIsReady('Repair') && isCheckedAllRepairInJobCard,
                                'checkbox-in-job-card-header': !getIsAllPartsSplitIsReady('Repair') && isCheckedAllRepairInJobCard,
                              }"
                            class="pin_header pin-checkmark checkbox-title-RR">
                            <i class='bx bx-check'></i>
                          </span>
                      </label>
                    </div>
                  </div>
                  <div class="card-block">
                    <div class="row"
                         :ref="'estimateItem' + item.id"
                         :class="{'background-yellow': item.job_highlight === 'yellow', 'background-green': item.job_highlight === 'green', 'background-purple': item.job_highlight === 'purple'}"
                         @contextmenu.prevent="openMenuForEstimateItem($event, {item: item, type: 'Repair'})"
                         v-for="item in jobCardRepair">
                      <div class="col-lg-9  col-sm-9 col-xs-9 left-element " style="padding:0.75rem 0">
                        <div class="col-lg-10 col-sm-8 col-xs-7 pl-0 areaTouch"
                             @touchstart="touchStart($event,{item: item, type: 'Repair'})" @touchend="touchEnd">
                          {{ item.name }}
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-3 col-xs-3 d-flex additional-flex-end">
                        <div class="card-block-icons">
                          <div @touchstart="touchStartForJobCardItem($event, item, 'Repair', comment)"
                               @touchend="touchEnd"
                               @contextmenu.prevent="openMenuForJobCardItem($event, item, 'Repair', comment)"
                               v-for="comment in item.job_comment" :key="comment.userId" class="icon-comment-active"
                               v-b-tooltip.hover="{customClass: 'ps-tooltip', title: comment.text + ' - ' + comment.author, placement:'left'}">
                            <i class='bx bx-comment-detail job-card-comment'></i>
                          </div>

                          <div v-if="item.job_user_fullname" class="icon-comment-active"
                               v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltip-split-users', html: true, placement:'left', title: getAllNamesOfItem(item, 'Repair')}">
                            <template v-if="item && item.split.length > 1 && item.percent && item.percent == 100">
                              <i  class='bx bxs-group ml-1 icon-block-margin bxs-group-remove'></i>
                            </template>
                            <i v-else class='bx bxs-user bxs-user-split ml-1 icon-block-margin' :class="{'gradient-icon': item.percent > 0 && item.percent < 100}"></i>
                          </div>
                          <div v-else class="icon-comment-active">
                            <i class='bx bx-user ml-1 icon-block-margin bx-user-clear'></i>
                          </div>
                        </div>
                        <div class="text-right d-flex additional-flex-end" style="padding:0.75rem; position: relative;">
                          <label class="pin-job left-element ">
                            <input type="checkbox" :disabled="!isStatusActive"
                                   @change="onChangeJobCardCheckedItem(item, 'Repair')"
                                   v-model="item.is_checked"/>
                            <span class="pin_content pin-checkmark repair-fixed"><i class='bx bx-check'></i></span>
                          </label>
                          <span :ref="'removeSplitCheckbox-' + item.id" v-if="item.is_checked && item.percent > 0 && item.percent < 100"  @click="deleteSplitChecked(item, 'Repair')" class="pin_content pin-checkmark   gradient-checkbox additional-split-checkbox"><i class='bx bx-check d-flex'></i></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card" v-if="!isEmpty(jobCardPaint)"
                     :class="{'last-estimate-item': isEmpty(jobCardPDR) && isEmpty(jobCardOther) && isEmpty(jobCardSublet) && isEmpty(jobCardPart)}">
                  <div class="card-header bg-navyblue row px-0 m-0">
                    <div class="col-lg-8 col-sm-7 col-xs-7" style="padding-left:12px;">PAINT</div>
                    <div class="col-lg-1 col-sm-1 col-xs-1" style="text-align: center">Paint Mtrl</div>
                    <div class="col-lg-3 col-sm-4 col-xs-4 text-right areaTouch" @touchstart="touchStart2($event,{type: 'Paint'})" @touchend="touchEnd2"
                         style="padding-left: 0px; padding-right:12px;">
                      <label class="pin-job">
                        <input type="checkbox" :disabled="!isStatusActive" v-model="isCheckedAllPaintInJobCard">
                        <span
                            style="top: -10px !important"
                            @contextmenu.prevent="openAddSplitAllContext($event, {type: 'Paint'})"
                            :class="{
                                'pin_content': !getIsAllPartsSplitIsReady('Paint') && isCheckedAllPaintInJobCard,
                                'gradient-checkbox': !getIsAllPartsSplitIsReady('Paint') && isCheckedAllPaintInJobCard,
                                'checkbox-in-job-card-header': !getIsAllPartsSplitIsReady('Paint') && isCheckedAllPaintInJobCard,
                              }"
                            class="pin_header pin-checkmark checkbox-title-RR">
                            <i class='bx bx-check'></i>
                          </span>
                      </label>
                    </div>
                  </div>
                  <div class="card-block">
                    <div class="row"
                         :ref="'estimateItem' + item.id"
                         :class="{'background-yellow': item.job_highlight === 'yellow', 'background-green': item.job_highlight === 'green', 'background-purple': item.job_highlight === 'purple'}"
                         @contextmenu.prevent="openMenuForEstimateItem($event, {item: item, type: 'Paint'})"
                         v-for="item in jobCardPaint">
                      <div class="col-lg-8 col-sm-7 col-xs-7 left-element areaTouch" style="padding:1rem 0"
                           @touchstart="touchStart($event,{item: item, type: 'Paint'})" @touchend="touchEnd">
                        {{ item.name }}
                      </div>
                      <div class="col-lg-1 col-sm-1 col-xs-1" style="padding:0.75rem; text-align: center"
                           @touchstart="touchStart($event,{item: item, type: 'RR'})" @touchend="touchEnd">
                        {{ item.paint_material }}
                      </div>
                      <div class="col-lg-3 col-sm-4 col-xs-4 d-flex additional-flex-end">
                        <div class="card-block-icons">
                          <div @touchstart="touchStartForJobCardItem($event, item, 'Paint', comment)"
                               @touchend="touchEnd"
                               @contextmenu.prevent="openMenuForJobCardItem($event, item, 'Paint', comment)"
                               v-for="comment in item.job_comment" :key="comment.userId" class="icon-comment-active"
                               v-b-tooltip.hover="{customClass: 'ps-tooltip', title: comment.text + ' - ' + comment.author, placement:'left'}">
                            <i class='bx bx-comment-detail job-card-comment'></i>
                          </div>

                          <div v-if="item.job_user_fullname" class="icon-comment-active"
                               v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltip-split-users', placement:'left', html: true, title: getAllNamesOfItem(item, 'Paint')}">
                            <template v-if="item && item.split.length > 1 && item.percent && item.percent == 100">
                              <i  class='bx bxs-group ml-1 icon-block-margin bxs-group-remove'></i>
                            </template>
                            <i v-else class='bx bxs-user bxs-user-split ml-1 icon-block-margin' :class="{'gradient-icon': item.percent > 0 && item.percent < 100}"></i>
                          </div>
                          <div v-else class="icon-comment-active">
                            <i class='bx bx-user ml-1 icon-block-margin bx-user-clear'></i>
                          </div>
                        </div>
                        <div class="text-right" style="padding:0.75rem; position: relative;">
                          <label class="pin-job right-element">
                            <input type="checkbox" :disabled="!isStatusActive"
                                   @change="onChangeJobCardCheckedItem(item, 'Paint')"
                                   v-model="item.is_checked"/>
                            <span class="pin_content pin-checkmark"><i
                                class='bx bx-check'></i></span>
                          </label>
                          <span :ref="'removeSplitCheckbox-' + item.id" v-if="item.is_checked && item.percent > 0 && item.percent < 100"  @click="deleteSplitChecked(item, 'Paint')" class="pin_content pin-checkmark gradient-checkbox additional-split-checkbox" style="top: 8px !important;"><i class='bx bx-check d-flex'></i></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card" v-if="!isEmpty(jobCardPart)"
                     :class="{'last-estimate-item': isEmpty(jobCardPDR) && isEmpty(jobCardOther) && isEmpty(jobCardSublet)}">
                  <div class="card-header bg-navyblue row px-0 m-0">
                    <div class="col-lg-5 col-sm-4 col-xs-4" style="padding-left:12px;">Parts</div>
                    <div class="col-lg-2 col-sm-2 col-xs-2" style="padding-left: 0.75rem; text-align: center">Part
                      Nbr
                    </div>
                    <div class="col-lg-1 col-sm-1 col-xs-1" style="text-align: center">Odr Status</div>
                    <div class="col-lg-1 col-sm-1 col-xs-1"
                         style="padding-left: 0.75rem; text-align: center">Loc
                    </div>
                    <div class="col-lg-3 col-sm-4 col-xs-4 text-right areaTouch" @touchstart="touchStart2($event,{type: 'Part'})" @touchend="touchEnd2"
                         style="padding-left: 0px; padding-right:12px;">
                      <label class="pin-job">
                        <input type="checkbox" :disabled="!isStatusActive" v-model="isCheckedAllPartInJobCard">
                        <span
                            style="top: -10px !important"
                            @contextmenu.prevent="openAddSplitAllContext($event, {type: 'Part'})"
                            :class="{
                                'pin_content': !getIsAllPartsSplitIsReady('Part') && isCheckedAllPartInJobCard,
                                'gradient-checkbox': !getIsAllPartsSplitIsReady('Part') && isCheckedAllPartInJobCard,
                                'checkbox-in-job-card-header': !getIsAllPartsSplitIsReady('Part') && isCheckedAllPartInJobCard,
                              }"
                            class="pin_header pin-checkmark checkbox-title-RR">
                            <i class='bx bx-check'></i>
                          </span>
                      </label>
                    </div>
                  </div>
                  <div class="card-block">
                    <div class="row"
                         :ref="'estimateItem' + item.id"
                         :class="{'background-yellow': item.job_highlight === 'yellow', 'background-green': item.job_highlight === 'green', 'background-purple': item.job_highlight === 'purple'}"
                         @contextmenu.prevent="openMenuForEstimateItem($event, {item: item, type: 'Part'})"
                         v-for="item in jobCardPart">
                      <div class="col-lg-5 col-sm-4 col-xs-4 left-element areaTouch" style="padding:1rem 0"
                           @touchstart="touchStart($event,{item: item, type: 'Part'})" @touchend="touchEnd">
                        {{ item.name }}
                      </div>
                      <div class="col-lg-2 col-sm-2 col-xs-2"
                           style="padding:0.75rem; text-align: center; word-break: break-all;"
                           @touchstart="touchStart($event,{item: item, type: 'Part'})" @touchend="touchEnd">
                        {{ item.nbr }}
                      </div>
                      <div class="col-lg-1 col-sm-1 col-xs-1"
                           style="padding:0.75rem;font-family: 'Nunito Sans';font-size: 12px;font-weight: bold; text-align: center"
                           @touchstart="touchStart($event,{item: item, type: 'Part'})" @touchend="touchEnd">
                        Odr
                      </div>
                      <div class="col-lg-1 col-sm-1 col-xs-1"
                           style="padding:0.75rem" @touchstart="touchStart($event,{item: item, type: 'Part'})"
                           @touchend="touchEnd">
                      </div>
                      <div class="col-lg-3 col-sm-4 col-xs-4 d-flex additional-flex-end">
                        <div class="card-block-icons">
                          <div @touchstart="touchStartForJobCardItem($event, item, 'Part', comment)"
                               @touchend="touchEnd"
                               @contextmenu.prevent="openMenuForJobCardItem($event, item, 'Part', comment)"
                               v-for="comment in item.job_comment" :key="comment.userId" class="icon-comment-active"
                               v-b-tooltip.hover="{customClass: 'ps-tooltip', title: comment.text + ' - ' + comment.author, placement:'left'}">
                            <i class='bx bx-comment-detail job-card-comment'></i>
                          </div>

                          <div v-if="item.job_user_fullname" class="icon-comment-active"
                               v-b-tooltip.hover="{customClass: 'ps-tooltip', placement:'left', html: true, title: getAllNamesOfItem(item, 'Part')}">
                            <i class='bx bxs-user bxs-user-split ml-1 icon-block-margin' :class="{'gradient-icon': item.percent > 0 && item.percent < 100}"></i>
                          </div>
                          <div v-else class="icon-comment-active">
                            <i class='bx bx-user ml-1 icon-block-margin bx-user-clear'></i>
                          </div>
                        </div>
                        <div class="text-right" style="padding:0.75rem; position: relative;">
                          <label class="pin-job right-element">
                            <input type="checkbox" :disabled="!isStatusActive"
                                   @change="onChangeJobCardCheckedItem(item, 'Part')"
                                   v-model="item.is_checked"/>
                            <span class="pin_content pin-checkmark"><i
                                class='bx bx-check'></i></span>
                          </label>
                          <span :ref="'removeSplitCheckbox-' + item.id" v-if="item.is_checked && item.percent > 0 && item.percent < 100"  @click="deleteSplitChecked(item, 'Part')" class="pin_content pin-checkmark gradient-checkbox   additional-split-checkbox" style="top: 8px !important;"><i class='bx bx-check d-flex'></i></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card" v-if="!isEmpty(jobCardSublet)"
                     :class="{'last-estimate-item': isEmpty(jobCardPDR) && isEmpty(jobCardOther)}">
                  <div class="card-header bg-navyblue row px-0 m-0">
                    <div class="col-lg-10  col-sm-10 col-xs-10" style="padding-left:12px;">Sublet
                    </div>
                    <div class="col-lg-2 col-sm-2 col-xs-2 text-right areaTouch" @touchstart="touchStart2($event,{type: 'Sublet'})" @touchend="touchEnd2" style="padding-right:12px;">
<!--                      All-->
                      <label class="pin-job">
                        <input type="checkbox" :disabled="!isStatusActive" v-model="isCheckedAllSubletInJobCard">
                        <span
                            style="top: -10px !important"
                            @contextmenu.prevent="openAddSplitAllContext($event, {type: 'Sublet'})"
                            :class="{
                                'pin_content': !getIsAllPartsSplitIsReady('Sublet') && isCheckedAllSubletInJobCard,
                                'gradient-checkbox': !getIsAllPartsSplitIsReady('Sublet') && isCheckedAllSubletInJobCard,
                                'checkbox-in-job-card-header': !getIsAllPartsSplitIsReady('Sublet') && isCheckedAllSubletInJobCard,
                              }"
                            class="pin_header pin-checkmark checkbox-title-RR">
                            <i class='bx bx-check'></i>
                          </span>
                      </label>
                    </div>
                  </div>
                  <div class="card-block">
                    <div class="row"
                         :ref="'estimateItem' + item.id"
                         :class="{'background-yellow': item.job_highlight === 'yellow', 'background-green': item.job_highlight === 'green', 'background-purple': item.job_highlight === 'purple'}"
                         @contextmenu.prevent="openMenuForEstimateItem($event, {item: item, type: 'Sublet'})"
                         v-for="item in jobCardSublet">
                      <div class="col-lg-10  col-sm-10 col-xs-10 left-element areaTouch" style="padding:0.75rem 0"
                           @touchstart="touchStart($event,{item: item, type: 'Sublet'})" @touchend="touchEnd">
                        {{ item.name }}
                      </div>
                      <div class="col-lg-2 col-sm-2 col-xs-2 d-flex additional-flex-end">
                        <div class="card-block-icons">
                          <div @touchstart="touchStartForJobCardItem($event, item, 'Sublet', comment)"
                               @touchend="touchEnd"
                               @contextmenu.prevent="openMenuForJobCardItem($event, item, 'Sublet', comment)"
                               v-for="comment in item.job_comment" :key="comment.userId" class="icon-comment-active"
                               v-b-tooltip.hover="{customClass: 'ps-tooltip', title: comment.text + ' - ' + comment.author, placement:'left'}">
                            <i class='bx bx-comment-detail job-card-comment'></i>
                          </div>

                          <div v-if="item.job_user_fullname" class="icon-comment-active"
                               v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltip-split-users', placement:'left', html: true, title: getAllNamesOfItem(item, 'Sublet')}">
                            <i class='bx bxs-user bxs-user-split ml-1 icon-block-margin' :class="{'gradient-icon': item.percent > 0 && item.percent < 100}"></i>
                          </div>
                          <div v-else class="icon-comment-active">
                            <i class='bx bx-user ml-1 icon-block-margin bx-user-clear'></i>
                          </div>
                        </div>
                        <div class="text-right" style="padding:0.75rem; position: relative;">
                          <label class="pin-job right-element ">
                            <input type="checkbox" :disabled="!isStatusActive"
                                   @change="onChangeJobCardCheckedItem(item, 'Sublet')"
                                   v-model="item.is_checked"/>
                            <span class="pin_content pin-checkmark"><i
                                class='bx bx-check'></i></span>
                          </label>
                          <span :ref="'removeSplitCheckbox-' + item.id" v-if="item.is_checked && item.percent > 0 && item.percent < 100"  @click="deleteSplitChecked(item, 'Sublet')" class="pin_content pin-checkmark gradient-checkbox additional-split-checkbox" style="top: 8px !important;"><i class='bx bx-check d-flex'></i></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card" v-if="!isEmpty(jobCardOther)" :class="{'last-estimate-item': isEmpty(jobCardPDR)}">
                  <div class="card-header bg-navyblue row px-0 m-0">
                    <div class="col-lg-10  col-sm-10 col-xs-10" style="padding-left:12px;">Other
                    </div>
                    <div @touchstart="touchStart2($event,{type: 'Other'})" @touchend="touchEnd2" class="col-lg-2 col-sm-2 col-xs-2 text-right areaTouch" style="padding-right:12px;">
<!--                      All-->
                      <label class="pin-job">
                        <input type="checkbox" :disabled="!isStatusActive" v-model="isCheckedAllOtherInJobCard">
                        <span
                            style="top: -10px !important"
                            @contextmenu.prevent="openAddSplitAllContext($event, {type: 'Other'})"
                            :class="{
                                'pin_content': !getIsAllPartsSplitIsReady('Other') && isCheckedAllOtherInJobCard,
                                'gradient-checkbox': !getIsAllPartsSplitIsReady('Other') && isCheckedAllOtherInJobCard,
                                'checkbox-in-job-card-header': !getIsAllPartsSplitIsReady('Other') && isCheckedAllOtherInJobCard,
                              }"
                            class="pin_header pin-checkmark checkbox-title-RR">
                            <i class='bx bx-check'></i>
                          </span>
                      </label>
                    </div>
                  </div>
                  <div class="card-block">
                    <div class="row"
                         :ref="'estimateItem' + item.id"
                         :class="{'background-yellow': item.job_highlight === 'yellow', 'background-green': item.job_highlight === 'green', 'background-purple': item.job_highlight === 'purple'}"
                         @contextmenu.prevent="openMenuForEstimateItem($event, {item: item, type: 'Other'})"
                         v-for="item in jobCardOther">
                      <div class="col-lg-10  col-sm-10 col-xs-10 left-element areaTouch" style="padding:0.75rem 0"
                           @touchstart="touchStart($event,{item: item, type: 'Other'})" @touchend="touchEnd">
                        {{ item.name }}
                      </div>
                      <div class="col-lg-2 col-sm-2 col-xs-2 d-flex additional-flex-end">
                        <div class="card-block-icons">
                          <div @touchstart="touchStartForJobCardItem($event, item, 'Other', comment)"
                               @touchend="touchEnd"
                               @contextmenu.prevent="openMenuForJobCardItem($event, item, 'Other', comment)"
                               v-for="comment in item.job_comment" :key="comment.userId" class="icon-comment-active"
                               v-b-tooltip.hover="{customClass: 'ps-tooltip', title: comment.text + ' - ' + comment.author, placement:'left'}">
                            <i class='bx bx-comment-detail job-card-comment'></i>
                          </div>

                          <div v-if="item.job_user_fullname" class="icon-comment-active"
                               v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltip-split-users', placement:'left', html: true, title: getAllNamesOfItem(item, 'Other')}">
                            <i class='bx bxs-user bxs-user-split ml-1 icon-block-margin' :class="{'gradient-icon': item.percent > 0 && item.percent < 100}"></i>
                          </div>
                          <div v-else class="icon-comment-active">
                            <i class='bx bx-user ml-1 icon-block-margin bx-user-clear'></i>
                          </div>
                        </div>
                        <div class="text-right" style="padding:0.75rem; position: relative">
                          <label class="pin-job right-element ">
                            <input type="checkbox" :disabled="!isStatusActive"
                                   @change="onChangeJobCardCheckedItem(item, 'Other')"
                                   v-model="item.is_checked"/>
                            <span class="pin_content pin-checkmark"><i
                                class='bx bx-check'></i></span>
                          </label>
                          <span :ref="'removeSplitCheckbox-' + item.id" v-if="item.is_checked && item.percent > 0 && item.percent < 100"  @click="deleteSplitChecked(item, 'Other')" class="pin_content pin-checkmark gradient-checkbox additional-split-checkbox" style="top: 8px !important;"><i class='bx bx-check d-flex'></i></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card last-estimate-item" v-if="!isEmpty(jobCardPDR)">
                  <div class="card-header bg-navyblue row px-0 m-0">
                    <div class="col-lg-10  col-sm-10 col-xs-10" style="padding-left:12px;">PDR
                    </div>
                    <div @touchstart="touchStart2($event,{type: 'PDR'})" @touchend="touchEnd2" class="col-lg-2 col-sm-2 col-xs-2 text-right areaTouch" style="padding-right:12px;">
<!--                      All-->
                      <label class="pin-job">
                        <input type="checkbox" :disabled="!isStatusActive" v-model="isCheckedAllPDRInJobCard">
                        <span
                            style="top: -10px !important"
                            @contextmenu.prevent="openAddSplitAllContext($event, {type: 'PDR'})"
                            :class="{
                                'pin_content': !getIsAllPartsSplitIsReady('PDR') && isCheckedAllPDRInJobCard,
                                'gradient-checkbox': !getIsAllPartsSplitIsReady('PDR') && isCheckedAllPDRInJobCard,
                                'checkbox-in-job-card-header': !getIsAllPartsSplitIsReady('PDR') && isCheckedAllPDRInJobCard,
                              }"
                            class="pin_header pin-checkmark checkbox-title-RR">
                            <i class='bx bx-check'></i>
                          </span>
                      </label>
                    </div>
                  </div>
                  <div class="card-block">
                    <div class="row"
                         :ref="'estimateItem' + item.id"
                         :class="{'background-yellow': item.job_highlight === 'yellow', 'background-green': item.job_highlight === 'green', 'background-purple': item.job_highlight === 'purple'}"
                         @contextmenu.prevent="openMenuForEstimateItem($event, {item: item, type: 'PDR'})"
                         v-for="item in jobCardPDR">
                      <div class="col-lg-10  col-sm-10 col-xs-10 left-element areaTouch" style="padding:0.75rem 0"
                           @touchstart="touchStart($event,{item: item, type: 'PDR'})" @touchend="touchEnd">
                        {{ item.name }}
                      </div>
                      <div
                          class="col-lg-2 col-sm-2 col-xs-2 d-flex additional-flex-end additional-cards-tooltip-wrapper">
                        <div class="card-block-icons">
                          <div @touchstart="touchStartForJobCardItem($event, item, 'PDR', comment)" @touchend="touchEnd"
                               @contextmenu.prevent="openMenuForJobCardItem($event, item, 'PDR', comment)"
                               v-for="comment in item.job_comment" :key="comment.userId" class="icon-comment-active"
                               v-b-tooltip.hover="{customClass: 'ps-tooltip', title: comment.text + ' - ' + comment.author, placement:'left'}">
                            <i class='bx bx-comment-detail job-card-comment'></i>
                          </div>

                          <div v-if="item.job_user_fullname" class="icon-comment-active"
                               v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltip-split-users', placement:'topleft', html: true, title: getAllNamesOfItem(item, 'PDR')}">
                            <i class='bx bxs-user bxs-user-split ml-1 icon-block-margin' :class="{'gradient-icon': item.percent > 0 && item.percent < 100}"></i>
                          </div>
                          <div v-else class="icon-comment-active">
                            <i class='bx bx-user ml-1 icon-block-margin bx-user-clear'></i>
                          </div>
                        </div>
                        <div class="text-right" style="padding:0.75rem; position: relative;">
                          <label class="pin-job right-element ">
                            <input type="checkbox" :disabled="!isStatusActive"
                                   @change="onChangeJobCardCheckedItem(item, 'PDR')"
                                   v-model="item.is_checked"/>
                            <span class="pin_content pin-checkmark"><i
                                class='bx bx-check'></i></span>
                          </label>
                          <span :ref="'removeSplitCheckbox-' + item.id" v-if="item.is_checked && item.percent > 0 && item.percent < 100"  @click="deleteSplitChecked(item, 'PDR')" class="pin_content pin-checkmark gradient-checkbox additional-split-checkbox" style="top: 8px !important;"><i class='bx bx-check d-flex'></i></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mobile-b-promo-block">
                  <advert-banner
                      v-if="isMobile"
                      :bannerType="1"
                  />
                </div>
              </div>

              <div class="col-md-3">
              </div>
            </div>
            <vue-context style="height: auto !important;" :close-on-click="false" @open="openContext"
                         @close="closeContextForEstimateItem" ref="contextForEstimateItem">
              <ul slot-scope="item">
                <li v-if="(item.data && item.data.item) && (item.data.item.job_comment === null || ((item.data.item.job_comment.length === 0 || !isHasMineComment(item.data.item.job_comment)) && item.data.item.job_comment.length < 3))"
                    class="hasSubContext">Add Comment <i
                    class="icon-right bx bx-chevron-right"></i>
                  <div class="context-level subContext estimate-item-comment">
                                      <textarea v-model="currentEstimateItemComment"
                                                ref="commentTextarea"
                                                placeholder="Add comment">
                                      </textarea>
                    <i @click="clickOptionOfContextForEstimateItem(item.data, '', 1)"
                       class='bx bx-save save-comment-icon'></i>
                  </div>
                </li>

                <li v-if="item && item.data && item.data.item && item.data.index !== null && item.data.item.job_comment && item.data.item.job_comment[item.data.index] && (item.data.item.job_comment[item.data.index].userId && item.data.item.job_comment[item.data.index].author)"
                    class="hasSubContext" style="height: auto">
                  <span>Edit Comment</span>
                  <i class="icon-right bx bx-chevron-right"></i>
                  <div class="context-level subContext estimate-item-comment">
                                        <textarea v-model="item.data.item.job_comment[item.data.index].text"
                                                  ref="commentEditTextarea"
                                                  placeholder="Edit comment">
                                        </textarea>
                    <i @click="clickOptionOfContextForEstimateItem(item.data, item.data.item.job_comment[item.data.index], 2)"
                       class='bx bx-save save-comment-icon'></i>
                  </div>
                </li>
                <li v-if="item && item.data && item.data.item && item.data.index !== null && item.data.item.job_comment && item.data.item.job_comment[item.data.index]  && item.data.item.job_comment[item.data.index].userId && item.data.item.job_comment[item.data.index].author"
                    @click="clickOptionOfContextForEstimateItem(item.data, item.data.item.job_comment[item.data.index], 3)"
                    style="height: auto">
                  <span>Delete Comment</span>
                </li>

                <li v-if="item && item.data &&  item.data.type && item.data.type !== 'Part'" class="hasSubContext">
                  <div @mouseover="$refs['split-' +  item.data.item.id].focus()" style="width: 103px; height: 30px" class="">
                    <span>Add Split</span> <i class="icon-right bx bx-chevron-right"></i>
                  </div>
                  <div class="context-level subContext estimate-item-split" v-if="item.data && item.data.item" style="height: auto !important">
                    <div class="estimate-item-split-menu">
                      <span class="pl-1" style="white-space: nowrap;">Add % Split</span>
                      <input :ref="'split-' + item.data.item.id" v-model="item.data.splitPercent" type="number" class="form-control estimate-item-split-input">
                      <i v-if="item.data.splitPercent" @click="addNewSplit(item.data)" class='bx bx-add-to-queue add-split-icon'></i>
                      <i v-else class='bx bx-add-to-queue add-split-icon-clear'></i>
                    </div>
                    <div class="estimate-item-split-menu" v-if="item.data">
                      <span class="pl-1" style="white-space: nowrap;">Section</span>
                      <multiselect
                        v-model="item.data.splitType"
                        :options="(item.data.type === 'RR') ?  ['Remove', 'Refit']: [item.data.type]"
                        :showLabels="false"
                        placeholder="Remove"
                        :option-height="29"
                        :max-height="203"
                        :close-on-select="true"
                        class="estimate-item-split-input"
                        style="margin-left: 34px;"
                      />
                    </div>

                    <div v-if="item && item.data &&  item.data.type &&  item.data.type === 'RR'" class="">
                      <template v-if="item && item.data && item.data.item && item.data.type && (item.data.item.split_remove.length || item.data.item.split_replace.length)">
                        <div v-for="splitItem in item.data.item.split_remove" :key="splitItem.id" class="estimate-item-split-menu justify-content-between">
                          <div class="d-flex">
                            <span class="pl-1" style="white-space: nowrap;">Rmv {{ splitItem.percent }}% - </span><span style="padding-left: 3px" :class="{ 'current-split-user': splitItem.createdBy.id === userInfo.user_id}">{{ splitItem.createdBy.fullName }}</span>
                          </div>
                          <div>
                            <i v-if="splitItem.createdBy.id === userInfo.user_id" @click="removeSplit(splitItem, item.data)" class='bx bx-trash split-delete-btn'></i>
                          </div>
                        </div>
                        <div v-for="splitItem in item.data.item.split_replace" :key="splitItem.id" class="estimate-item-split-menu justify-content-between">
                          <div class="d-flex">
                            <span class="pl-1" style="white-space: nowrap;">Rft {{ splitItem.percent }}% - </span><span style="padding-left: 3px" :class="{ 'current-split-user': splitItem.createdBy.id === userInfo.user_id}">{{ splitItem.createdBy.fullName }}</span>
                          </div>
                          <div>
                            <i v-if="splitItem.createdBy.id === userInfo.user_id" @click="removeSplit(splitItem, item.data)" class='bx bx-trash split-delete-btn'></i>
                          </div>
                        </div>
                      </template>
                      <div class="estimate-item-split-menu" style="margin-top: 20px; padding-top: 10px; border-top: 1px solid rgba(27, 30, 56, 0.25)">
                        <div v-if="item.data && item.data.item && item.data.item.percent_remove < 100" class="">
                          <span class="pl-1" style="color: red">{{100 - item.data.item.percent_remove}}% Rmv</span><span class="pl-1">Remaining Un-ticked</span>
                        </div>
                        <div v-else class="">
                          <span class="pl-1" style="color: green">100%</span><span class="pl-1">Remove Completed</span>
                        </div>
                      </div>

                      <div class="estimate-item-split-menu" style="margin-bottom: 15px;">
                        <div v-if="item.data && item.data.item && item.data.item.percent_replace < 100" class="">
                          <span class="pl-1" style="color: red">{{100 - item.data.item.percent_replace}}% Rft</span><span class="pl-1">Remaining Un-ticked</span>
                        </div>
                        <div v-else class="">
                          <span class="pl-1" style="color: green">100%</span><span class="pl-1">Refit Completed</span>
                        </div>
                      </div>

                    </div>

                    <div v-else class="">
                      <template v-if="item && item.data && item.data.item && item.data.type && item.data.item.split && item.data.item.split.length">
                        <div v-for="splitItem in item.data.item.split" :key="splitItem.id" class="estimate-item-split-menu justify-content-between">
                          <div class="d-flex">
                            <div class="d-flex">
                              <span class="pl-1" style="white-space: nowrap;">
                                <span v-if="item.data.type == 'Repair'">Rep </span>
                                <span v-else-if="item.data.type == 'Paint'">Pnt </span>
                                <span v-else>{{item.data.type}}</span>
                                {{ splitItem.percent }}% - </span><span style="padding-left: 3px" :class="{ 'current-split-user': splitItem.createdBy.id === userInfo.user_id}">{{ splitItem.createdBy.fullName }}</span>
                            </div>
                          </div>
                          <div>
                            <i v-if="splitItem.createdBy.id === userInfo.user_id" @click="removeSplit(splitItem, item.data)" class='bx bx-trash split-delete-btn'></i>
                          </div>
                        </div>
                      </template>
                      <div class="estimate-item-split-menu" style="margin-top: 20px; margin-bottom: 15px; padding-top: 10px; border-top: 1px solid rgba(27, 30, 56, 0.25)">
                        <div v-if="item.data && item.data.item && item.data.item.percent < 100" class="">
                          <span class="pl-1" style="color: red">{{100 - item.data.item.percent}}% {{item.data.type}}</span><span class="pl-1">Remaining Un-ticked</span>
                        </div>
                        <div v-else class="">
                          <span class="pl-1" style="color: green">100%</span><span class="pl-1">{{item.data.type}} Completed</span>
                        </div>
                      </div>
                    </div>

                  </div>
                </li>

                <li class="hasSubContext" v-if="item.data && item.data.item && ((item.data.type === 'RR' && (item.data.item.is_checked_remove || item.data.item.is_checked_replace)) || (item.data.item.is_checked))">
                  <span>View / Edit Split</span>
                  <i class="icon-right bx bx-chevron-right"></i>

                  <div class="context-level subContext estimate-item-split" v-if="item.data && item.data.item" style="height: auto !important">
                    <div class="estimate-item-split-menu">
                      <span class="pl-1" style="white-space: nowrap;">Edit % Split</span>
                      <input :ref="'split-edit-' + item.data.item.id" v-model="item.data.splitPercent" :disabled="!item.data.selectedSplitId" type="number" class="form-control estimate-item-split-input">
                      <i v-if="item.data.splitPercent" @click="updateSplit(item.data)" class='bx bx-add-to-queue add-split-icon'></i>
                      <i v-else class='bx bx-add-to-queue add-split-icon-clear'></i>
                    </div>
                    <div class="estimate-item-split-menu" v-if="item.data">
                      <span class="pl-1" style="white-space: nowrap;">Section</span>
                      <multiselect
                          v-model="item.data.splitType"
                          :options="(item.data.type === 'RR') ?  ['Remove', 'Refit']: [item.data.type]"
                          :showLabels="false"
                          placeholder="Remove"
                          :option-height="29"
                          :max-height="203"
                          :disabled="true"
                          :close-on-select="true"
                          class="estimate-item-split-input"
                          style="margin-left: 34px;"
                      />
                    </div>

                    <div v-if="item.data.type === 'RR'" class="">
                      <template v-if="item && item.data && item.data.item && item.data.type && (item.data.item.split_remove.length || item.data.item.split_replace.length)">
                        <div v-for="splitItem in item.data.item.split_remove" :key="splitItem.id" class="estimate-item-split-menu justify-content-between">
                          <div class="d-flex">
                            <span class="pl-1" style="white-space: nowrap;">Rmv {{ splitItem.percent }}% - </span><span style="padding-left: 3px" :class="{ 'current-split-user': splitItem.createdBy.id === userInfo.user_id}">{{ splitItem.createdBy.fullName }}</span>
                          </div>
                          <div>
                            <i v-if="splitItem.createdBy.id === userInfo.user_id" @click="goToUpdateSplit(item.data, 'Remove', splitItem)" class='bx bx-edit-alt split-edit-btn'></i>
                            <i v-if="splitItem.createdBy.id === userInfo.user_id" @click="removeSplit(splitItem, item.data)" class='bx bx-trash split-delete-btn'></i>
                          </div>
                        </div>
                        <div v-for="splitItem in item.data.item.split_replace" :key="splitItem.id" class="estimate-item-split-menu justify-content-between">
                          <div class="d-flex">
                            <span class="pl-1" style="white-space: nowrap;">Rft {{ splitItem.percent }}% - </span><span style="padding-left: 3px" :class="{ 'current-split-user': splitItem.createdBy.id === userInfo.user_id}">{{ splitItem.createdBy.fullName }}</span>
                          </div>
                          <div>
                            <i v-if="splitItem.createdBy.id === userInfo.user_id" @click="goToUpdateSplit(item.data, 'Refit', splitItem)" class='bx bx-edit-alt split-edit-btn'></i>
                            <i v-if="splitItem.createdBy.id === userInfo.user_id" @click="removeSplit(splitItem, item.data)" class='bx bx-trash split-delete-btn'></i>
                          </div>
                        </div>
                      </template>
                      <div class="estimate-item-split-menu" style="margin-top: 20px; padding-top: 10px; border-top: 1px solid rgba(27, 30, 56, 0.25)">
                        <div v-if="item.data && item.data.item && item.data.item.percent_remove < 100" class="">
                          <span class="pl-1" style="color: red">{{100 - item.data.item.percent_remove}}% Rmv</span><span class="pl-1">Remaining Un-ticked</span>
                        </div>
                        <div v-else class="">
                          <span class="pl-1" style="color: green">100%</span><span class="pl-1">Remove Completed</span>
                        </div>
                      </div>

                      <div class="estimate-item-split-menu" style="margin-bottom: 15px;">
                        <div v-if="item.data && item.data.item && item.data.item.percent_replace < 100" class="">
                          <span class="pl-1" style="color: red">{{100 - item.data.item.percent_replace}}% Rft</span><span class="pl-1">Remaining Un-ticked</span>
                        </div>
                        <div v-else class="">
                          <span class="pl-1" style="color: green">100%</span><span class="pl-1">Refit Completed</span>
                        </div>
                      </div>

                    </div>

                    <div v-else class="">
                      <template v-if="item && item.data && item.data.item && item.data.type && item.data.item.split && item.data.item.split.length">
                        <div v-for="splitItem in item.data.item.split" :key="splitItem.id" class="estimate-item-split-menu justify-content-between">
                          <div class="d-flex">
                            <span class="pl-1" style="white-space: nowrap;">
                              <span v-if="item.data.type == 'Repair'">Rep </span>
                              <span v-else-if="item.data.type == 'Paint'">Pnt </span>
                              <span v-else>{{item.data.type}} </span>
                              {{ splitItem.percent }}% - </span><span style="padding-left: 3px" :class="{ 'current-split-user': splitItem.createdBy.id === userInfo.user_id}">{{ splitItem.createdBy.fullName }}</span>
                          </div>
                          <div>
                            <i v-if="splitItem.createdBy.id === userInfo.user_id" @click="goToUpdateSplit(item.data, item.data.type, splitItem)" class='bx bx-edit-alt split-edit-btn'></i>
                            <i v-if="splitItem.createdBy.id === userInfo.user_id" @click="removeSplit(splitItem, item.data)" class='bx bx-trash split-delete-btn'></i>
                          </div>
                        </div>
                      </template>
                      <div class="estimate-item-split-menu" style="margin-top: 20px; margin-bottom: 15px; padding-top: 10px; border-top: 1px solid rgba(27, 30, 56, 0.25)">
                        <div v-if="item.data && item.data.item && item.data.item.percent < 100" class="">
                          <span class="pl-1" style="color: red">{{100 - item.data.item.percent}}% {{item.data.type}}</span><span class="pl-1">Remaining Un-ticked</span>
                        </div>
                        <div v-else class="">
                          <span class="pl-1" style="color: green">100%</span><span class="pl-1">{{item.data.type}} Completed</span>
                        </div>
                      </div>
                    </div>

                  </div>
                </li>

                <li class="hasSubContext">Highlight Row <i class="icon-right bx bx-chevron-right"></i>
                  <ul class="context-level subContext" style="width: 124px; left: 139px;">
                    <li @click="clickOptionOfContextForEstimateItem(item.data, '', 4)">Highlight Yellow</li>
                    <li @click="clickOptionOfContextForEstimateItem(item.data, '', 5)">Highlight Green</li>
                    <li @click="clickOptionOfContextForEstimateItem(item.data, '', 6)">Highlight Purple</li>
                  </ul>
                </li>
                <li @click="clickOptionOfContextForEstimateItem(item.data, '', 7)"
                    v-if="item && item.data && item.data.item && item.data.item.job_highlight !== 'none'">Remove
                  Highlight
                </li>
              </ul>
            </vue-context>

            <vue-context style="height: auto !important;" :close-on-click="false" ref="contextForEstimateAllItems">
              <ul slot-scope="item">
                <li class="hasSubContext">
                  <div @mouseover="$refs['splitAll'].focus()" style="width: 103px; height: 30px" class="">
                    <span>Add All Split</span> <i class="icon-right bx bx-chevron-right"></i>
                  </div>
                  <div class="context-level subContext estimate-item-split" style="height: auto !important">
                    <div class="estimate-item-split-menu" style="padding-bottom: 10px;">
                      <span class="pl-1" style="white-space: nowrap;">Add % Split</span>
                      <input :ref="'splitAll'" v-model="splitAllPercent" type="number" class="form-control estimate-item-split-input">
                      <i v-if="splitAllPercent" @click="addAllSplit(item.data.type, splitAllPercent)" class='bx bx-add-to-queue add-split-icon'></i>
                      <i v-else class='bx bx-add-to-queue add-split-icon-clear'></i>
                    </div>

                  </div>
                </li>

                <li class="hasSubContext" @click="undoAddSplitAll(item.data.type)">
                  <div style="width: 103px; height: 30px" class="">
                    <span>Undo Add Split All</span>
                  </div>
                </li>

              </ul>
            </vue-context>
          </div>
        </div>
      </div>
    </div>

    <modal-wizard
        id="declineORMJob"
        ref="declineORMJob"
        cancelButtonText="Cancel"
        finishButtonText="Ok"
        :finishButtonTooltip="isDisabledModalFinishButton ? 'Please fill in all fields' : ''"
        :isDisabledFinishButton="isDisabledModalFinishButton"
        @on-close="locationForDecline = ''; reasonForDeclineORM = '';"
        @on-complete="declineORMJob">
      <modal-wizard-tab title="Decline ORM Job" description="" title-icon-class="ti-align-right" class="pr-2">
        <template slot-scope="props">
          <div class="pt-2">Are you sure you want Decline this Job?</div>
          <div class="form-group row">
            <label class="col-form-label"></label>
            <div class="col-12">
              <textarea v-model="reasonForDeclineORM" style="height: 67px; resize: none" class="form-control" placeholder="Please add a reason for declining the repair"/>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label">Please confirm the current location of the vehicle</label>
            <div class="col-12">
              <multiselect
                  :options="['With me the Repairer', 'Returned to the Customer', 'RMC']"
                  :showLabels="false"
                  v-model="locationForDecline"
                  :option-height="29"
                  :max-height="203"
                  :close-on-select="true">
              </multiselect>
            </div>
          </div>
        </template>
      </modal-wizard-tab>
    </modal-wizard>

    <modal-wizard ref="markAsActiveModal" cancelButtonText="Close" finishButtonText="Ok"
                  @on-complete="completeMarkAsActive">
      <modal-wizard-tab title="Card - Mark As Active" description="" title-icon-class="ti-align-right"
                        :tabData="card">
        <template slot-scope="props">
          <div style="padding-top: 10px;">We have found the following Estimates linked to this card. These will be
            marked as active.
          </div>
          <div class="mark-as">
            <div class="row">
              <div class="col-md-4 estimate-number">Estimate Number:</div>
              <div class="col-md-5">{{ props.estimate_number }}</div>
            </div>
            <div class="row" v-if="props.supplement_numbers != ''">
              <div class="col-md-4 estimate-number">Supplement Number(s):</div>
              <div class="col-md-5">{{ props.supplement_numbers }}</div>
            </div>
          </div>
        </template>
      </modal-wizard-tab>
    </modal-wizard>
    <modal-wizard ref="markAsDraftModal" cancelButtonText="Close" finishButtonText="Ok"
                  @on-complete="completeMarkAsDraft">
      <modal-wizard-tab title="Card - Mark as Draft" description="" title-icon-class="ti-align-right"
                        :tabData="card">
        <template slot-scope="props">
          <div style="padding-top: 10px;">We have found the following Estimates linked to this card. These will be
            marked as Draft.
          </div>
          <div class="mark-as">
            <div class="row">
              <div class="col-md-4 estimate-number">Estimate Number:</div>
              <div class="col-md-5">{{ props.estimate_number }}</div>
            </div>
            <div class="row" v-if="props.supplement_numbers != ''">
              <div class="col-md-4 estimate-number">Supplement Number(s):</div>
              <div class="col-md-5">{{ props.supplement_numbers }}</div>
            </div>
          </div>
        </template>
      </modal-wizard-tab>
    </modal-wizard>
    <modal-wizard ref="markAsClosedModal" cancelButtonText="Close" finishButtonText="Ok"
                  @on-complete="completeMarkAsClosed">
      <modal-wizard-tab title="Card - Mark As Closed" description="" title-icon-class="ti-align-right"
                        :tabData="card">
        <template slot-scope="props">
          <div style="padding-top: 10px;">We have found the following Estimates linked to this card. These will be
            marked as closed.
          </div>
          <div class="mark-as">
            <div class="row">
              <div class="col-md-4 estimate-number">Estimate Number:</div>
              <div class="col-md-5">{{ props.estimate_number }}</div>
            </div>
            <div class="row" v-if="props.supplement_numbers != ''">
              <div class="col-md-4 estimate-number">Supplement Number(s):</div>
              <div class="col-md-5">{{ props.supplement_numbers }}</div>
            </div>
          </div>
        </template>
      </modal-wizard-tab>
    </modal-wizard>
    <modal-wizard ref="deleteCardModal" cancelButtonText="Close" finishButtonText="Ok"
                  @on-complete="completeDeleteCard">
      <modal-wizard-tab title="Delete Card" description="" title-icon-class="ti-align-right" :tabData="card">
        <template slot-scope="props">
          <div v-if="((card.invoice && card.invoice.id > 0) || (card.excess_invoice && card.excess_invoice.id > 0))"
               style="padding-top: 10px;">We have found the following Estimates and Invoices linked to this card. These
            will be deleted also.
          </div>
          <div v-else style="padding-top: 10px;">We have found the following Estimates linked to this card. These will
            be deleted also.
          </div>
          <div class="mark-as">
            <div class="row">
              <div class="col-md-4 estimate-number">Estimate Number:</div>
              <div class="col-md-5">{{ props.estimate_number }}</div>
            </div>
            <div class="row" v-if="props.supplement_numbers != ''">
              <div class="col-md-4 estimate-number">Supplement Number(s):</div>
              <div class="col-md-5">{{ props.supplement_numbers }}</div>
            </div>
            <div class="row" v-if="!isTechnicalUser && (card.invoice && card.invoice.id > 0)">
              <div class="col-md-4 estimate-number">Complete Invoice Number:</div>
              <div class="col-md-5">{{ card.invoice.number }}</div>
            </div>
            <div class="row"
                 v-if="!isTechnicalUser && (card.excess_invoice && card.excess_invoice.id > 0) && card.insurance.excess">
              <div class="col-md-4 estimate-number">Excess Invoice Number:</div>
              <div class="col-md-5">{{ card.excess_invoice.number }}</div>
            </div>
          </div>
        </template>
      </modal-wizard-tab>
    </modal-wizard>
    <modal-wizard ref="copyCardModal" cancelButtonText="Cancel" finishButtonText="Create"
                  @on-complete="completeCopyCard" class="copy-card-modal">
      <modal-wizard-tab title="Copy Card" description="" title-icon-class="ti-align-right">
        <div class="copy-card-modal-text">Do you want to Copy this Card {{ card.card_number }} and all of its details?
        </div>

        <div class="copy-card-modal-block">
          <div class="copy-card-modal-block-top">
            <div class="col-md-6">
              <span class="">Create a New Card & Estimate</span>
            </div>
            <div class="col-md-4">
              <label class="pin-job">
                <input type="checkbox" v-model="copyCard.isNew"/>
                <span class="pin_content pin-checkmark pin-new-card"><i class='bx bx-check'></i></span>
              </label>
            </div>
          </div>
          <span class="copy-card-modal-text-middle">or</span>
          <div class="copy-card-modal-block-top">
            <div class="col-md-6">
              <span class="">Select an existing Card & Estimate to Copy {{ card.card_number }} into</span>
            </div>
            <div class="col-md-5">
              <multiselect
                  v-model="copyCard.copyToCard"
                  :options="computedCardsForCopyCard"
                  :showLabels="false"
                  :option-height="29"
                  :max-height="203"
                  :close-on-select="true"
                  placeholder="Select Card Number"
                  track-by="card_id"
                  label="card_number"
                  :disabled="copyCard.isNew"
              />
            </div>
          </div>
          <span class="copy-card-modal-text-middle">Optional</span>
          <div class="copy-card-modal-block-top">
            <div class="col-md-6 copy-card-modal-block copy-card-text-align">
              <span class="">Copy Images</span>
              <span class="">Copy Files</span>
            </div>
            <div class="col-md-4 copy-card-modal-block">
              <label class="pin-job modal-checkbox-position">
                <input type="checkbox" v-model="copyCard.isCopyImages"/>
                <span class="pin_content pin-checkmark modal-pin"><i
                    class='bx bx-check'></i></span>
              </label>
              <label class="pin-job">
                <input type="checkbox" v-model="copyCard.isCopyFiles"/>
                <span class="pin_content pin-checkmark modal-pin"><i
                    class='bx bx-check'></i></span>
              </label>
            </div>
          </div>
        </div>
      </modal-wizard-tab>
    </modal-wizard>
    <transition name="slide-fade">
      <div v-if="pinImage" class="image-pin-area rs-scroll rs-scroll--transparent">
        <div>
          <div class="vissible-sm">
            <label class="pin-image" v-if="isStatusActive">Pin Images
              <input type="checkbox" name="pinimage" v-model="pinImage">
              <span class="pin-checkmark"><i class="fa fa-check"></i></span>
            </label>
          </div>
          <vue-dropzone
              v-on:vdropzone-file-added="addImgUpload"
              v-on:vdropzone-complete="completeImgUpload"
              v-on:vdropzone-sending="sendImgUpload"
              v-on:vdropzone-success="successImgUpload"
              ref="imgUpDrop"
              id="imgUploadDrop2"
              class="dropify-wrapper dropzoneContainer"
              :options="itemsListImageUploadDropzoneOptions"
              :useCustomSlot="true">
            <div class="dropify-message" style="transform: translateY(0%);">
              <img src="../../assets/img/layouts/bx-cloud-upload.svg"/>
              <p>Drag or Drop File</p>
            </div>
          </vue-dropzone>
        </div>
        <!-- image lightbox on sidebar -->
        <image-lightbox
            :isPreviewImage="'true'"
            :fromComponent="'card'"
            :position="'pin_images'"
            :showContextMenu="true"
            :isShowBigImage="isShowBigImage"
            @updateCategoryFunc="updateImageCategory"
            :imageList="imageSource"
            @selectImageGallery="selectImageGallery"
            :isAlreadyShowGallery="isShowGallery"
            @removeImage="deleteImage"
            :path="''"
            class="pin-image-lightbox"
        ></image-lightbox>
      </div>
    </transition>

    <div>
      <div v-if="isShowLoader || isShowLoader2" :style="{left: leftForLoader + 'px', top: topForLoader + 'px'}" id="context-loader">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -1 34 34">

          <circle cx="16" cy="16" r="15.9155" class="context-loader-background"/>
          <circle cx="16" cy="16" r="15.9155" :class="{'active': isShowLoader || isShowLoader2}" class="context-loader-progress"/>
        </svg>
      </div>
      <ul v-if="isShowContext"
          :style="{left: leftForTouchMenu + 'px', top: topForTouchMenu + 'px', position: 'absolute'}"
          id="context-menu-iphone">
        <li class="areaTouch"
            v-if="selectedEstimateItemData.item && (selectedEstimateItemData.item.job_comment === null || ((selectedEstimateItemData.item.job_comment.length === 0 || !isHasMineComment(selectedEstimateItemData.item.job_comment)) && selectedEstimateItemData.item.job_comment.length < 3))"
            id="context-comment-btn" @touchstart="touchStartAddComment">Add Comment<i
            class="icon-right-touch bx bx-chevron-right"></i></li>
        <div v-if="isShowAddComment" class="context-level-add-comment estimate-item-comment">
          <textarea v-model="currentEstimateItemComment" placeholder="Add comment"></textarea>
          <i @touchend="clickOptionOfContextForEstimateItem(selectedEstimateItemData, '', 1)"
             class='bx bx-save save-comment-icon'></i>
        </div>
        <li class="areaTouch"
            v-if="selectedEstimateItemData.item && selectedEstimateItemData.index !== null && selectedEstimateItemData.item.job_comment && selectedEstimateItemData.item.job_comment[selectedEstimateItemData.index] && (selectedEstimateItemData.item.job_comment[selectedEstimateItemData.index].userId && selectedEstimateItemData.item.job_comment[selectedEstimateItemData.index].author)"
            @touchstart="touchStartEditComment">Edit Comment<i class="icon-right-touch bx bx-chevron-right"></i></li>
        <div v-if="isShowEditComment" class="context-level-add-comment estimate-item-comment">
          <textarea v-model="selectedEstimateItemData.item.job_comment[selectedEstimateItemData.index].text"
                    placeholder="Edit comment"></textarea>
          <i @touchend="clickOptionOfContextForEstimateItem(selectedEstimateItemData, selectedEstimateItemData.item.job_comment[selectedEstimateItemData.index], 2)"
             class='bx bx-save save-comment-icon'></i>
        </div>
        <li class="areaTouch"
            v-if="selectedEstimateItemData.item && selectedEstimateItemData.index !== null && selectedEstimateItemData.item.job_comment && selectedEstimateItemData.item.job_comment[selectedEstimateItemData.index]  && selectedEstimateItemData.item.job_comment[selectedEstimateItemData.index].userId && selectedEstimateItemData.item.job_comment[selectedEstimateItemData.index].author"
            @touchstart="clickOptionOfContextForEstimateItem(selectedEstimateItemData,selectedEstimateItemData.item.job_comment[selectedEstimateItemData.index], 3)">
          Delete Comment
        </li>

        <li @touchend="touchStartAddSplit" class="areaTouch" v-if="selectedEstimateItemData && selectedEstimateItemData.item && selectedEstimateItemData.type !== 'Part'">Add Split
          <i class="icon-right-touch bx bx-chevron-right"></i>
        </li>

        <div class="context-level subContext estimate-item-split" v-if="selectedEstimateItemData && selectedEstimateItemData.item && isShowAddSplit" style="height: auto !important">
          <div class="estimate-item-split-menu">
            <span class="pl-1" style="white-space: nowrap;">Add % Split</span>
            <input :ref="'split-' + selectedEstimateItemData.item.id" v-model="splitPercentForMobileContext" type="number" class="form-control estimate-item-split-input">
            <i v-if="splitPercentForMobileContext" @touchend="addNewSplitForMobileContext(selectedEstimateItemData)" class='bx bx-add-to-queue add-split-icon'></i>
            <i v-else class='bx bx-add-to-queue add-split-icon-clear'></i>
          </div>
          <div class="estimate-item-split-menu" v-if="selectedEstimateItemData">
            <span class="pl-1" style="white-space: nowrap;">Section</span>
            <multiselect
                v-model="splitTypeForMobileContext"
                :options="(selectedEstimateItemData.type === 'RR') ?  ['Remove', 'Refit']: [selectedEstimateItemData.type]"
                :showLabels="false"
                placeholder="Remove"
                :option-height="29"
                :max-height="203"
                :close-on-select="true"
                class="estimate-item-split-input"
                style="margin-left: 34px;"
            />
          </div>

          <div v-if="selectedEstimateItemData.type === 'RR'" class="">
            <template v-if="selectedEstimateItemData && selectedEstimateItemData.item && selectedEstimateItemData.type && (selectedEstimateItemData.item.split_remove.length || selectedEstimateItemData.item.split_replace.length)">
              <div v-for="splitItem in selectedEstimateItemData.item.split_remove" :key="splitItem.id" class="estimate-item-split-menu justify-content-between">
                <div class="d-flex">
                  <span class="pl-1" style="white-space: nowrap;">Rmv {{ splitItem.percent }}% - </span><span style="padding-left: 3px" :class="{ 'current-split-user': splitItem.createdBy.id === userInfo.user_id}">{{ splitItem.createdBy.fullName }}</span>
                </div>
                <div>
                  <i v-if="splitItem.createdBy.id === userInfo.user_id" @touchend="removeSplitForMobileContext(splitItem, selectedEstimateItemData)" class='bx bx-trash split-delete-btn'></i>
                </div>
              </div>
              <div v-for="splitItem in selectedEstimateItemData.item.split_replace" :key="splitItem.id" class="estimate-item-split-menu justify-content-between">
                <div class="d-flex">
                  <span class="pl-1" style="white-space: nowrap;">Rft {{ splitItem.percent }}% - </span><span style="padding-left: 3px" :class="{ 'current-split-user': splitItem.createdBy.id === userInfo.user_id}">{{ splitItem.createdBy.fullName }}</span>
                </div>
                <div>
                  <i v-if="splitItem.createdBy.id === userInfo.user_id" @touchend="removeSplitForMobileContext(splitItem, selectedEstimateItemData)" class='bx bx-trash split-delete-btn'></i>
                </div>
              </div>
            </template>
            <div class="estimate-item-split-menu" style="margin-top: 20px; padding-top: 10px; border-top: 1px solid rgba(27, 30, 56, 0.25)">
              <div v-if="selectedEstimateItemData.item && selectedEstimateItemData.item.percent_remove < 100" class="">
                <span class="pl-1" style="color: red">{{100 - selectedEstimateItemData.item.percent_remove}}% Rmv</span><span class="pl-1">Remaining Un-ticked</span>
              </div>
              <div v-else class="">
                <span class="pl-1" style="color: green">100%</span><span class="pl-1">Remove Completed</span>
              </div>
            </div>

            <div class="estimate-item-split-menu" style="margin-bottom: 15px;">
              <div v-if="selectedEstimateItemData.item && selectedEstimateItemData.item.percent_replace < 100" class="">
                <span class="pl-1" style="color: red">{{100 - selectedEstimateItemData.item.percent_replace}}% Rft</span><span class="pl-1">Remaining Un-ticked</span>
              </div>
              <div v-else class="">
                <span class="pl-1" style="color: green">100%</span><span class="pl-1">Refit Completed</span>
              </div>
            </div>

          </div>

          <div v-else class="">
            <template v-if="selectedEstimateItemData && selectedEstimateItemData.item && selectedEstimateItemData.type && selectedEstimateItemData.item.split && selectedEstimateItemData.item.split.length">
              <div v-for="splitItem in selectedEstimateItemData.item.split" :key="splitItem.id" class="estimate-item-split-menu" style="flex-direction: column">
                <div class="d-flex" style="width: 100%; align-items: center; justify-content: space-between;">
                  <span class="pl-1" style="white-space: nowrap;">
                    <span v-if="selectedEstimateItemData.type == 'Repair'">Rep</span>
                    <span v-else-if="selectedEstimateItemData.type == 'Paint'">Pnt</span>
                    <span v-else>{{selectedEstimateItemData.type}}</span>
                    {{ splitItem.percent }}% - </span>
                    <span style="padding-left: 3px" :class="{ 'current-split-user': splitItem.createdBy.id === userInfo.user_id}">{{ splitItem.createdBy.fullName }}</span>
                  <div>
                    <i v-if="splitItem.createdBy.id === userInfo.user_id" @touchend="removeSplit(splitItem, selectedEstimateItemData)" class='bx bx-trash split-delete-btn'></i>
                  </div>
                </div>
              </div>
            </template>
            <div class="estimate-item-split-menu" style="margin-top: 20px; margin-bottom: 15px; padding-top: 10px; border-top: 1px solid rgba(27, 30, 56, 0.25)">
              <div v-if="selectedEstimateItemData.item && selectedEstimateItemData.item.percent < 100" class="">
                <span class="pl-1" style="color: red">{{100 - selectedEstimateItemData.item.percent}}% {{selectedEstimateItemData.type}}</span><span class="pl-1">Remaining Un-ticked</span>
              </div>
              <div v-else class="">
                <span class="pl-1" style="color: green">100%</span><span class="pl-1">{{selectedEstimateItemData.type}} Completed</span>
              </div>
            </div>
          </div>

        </div>

        <li class="areaTouch" v-if="selectedEstimateItemData && selectedEstimateItemData.item && selectedEstimateItemData.type !== 'Part' && ((selectedEstimateItemData.type === 'RR' && (selectedEstimateItemData.item.is_checked_remove || selectedEstimateItemData.item.is_checked_replace)) || (selectedEstimateItemData.item.is_checked))" @touchstart="toushStartViewEditSplit">View / Edit Split
          <i class="icon-right-touch bx bx-chevron-right"></i>
        </li>

        <div class="context-level subContext estimate-item-split" v-if="selectedEstimateItemData && selectedEstimateItemData.item && isShowViewEditSplit" style="height: auto !important">
          <div class="estimate-item-split-menu">
            <span class="pl-1" style="white-space: nowrap;">Edit % Split</span>
            <input :ref="'split-edit-' + selectedEstimateItemData.item.id" v-model="splitPercentForMobileContext" :disabled="!selectedEstimateItemData.selectedSplitId" type="number" class="form-control estimate-item-split-input">
            <i v-if="splitPercentForMobileContext" @touchend="updateSplitForMobileContext(selectedEstimateItemData)" class='bx bx-add-to-queue add-split-icon'></i>
            <i v-else class='bx bx-add-to-queue add-split-icon-clear'></i>
          </div>
          <div class="estimate-item-split-menu" v-if="selectedEstimateItemData">
            <span class="pl-1" style="white-space: nowrap;">Section</span>
            <multiselect
                v-model="splitTypeForMobileContext"
                :options="(selectedEstimateItemData.type === 'RR') ?  ['Remove', 'Refit']: [selectedEstimateItemData.type]"
                :showLabels="false"
                placeholder="Remove"
                :option-height="29"
                :max-height="203"
                :disabled="true"
                :close-on-select="true"
                class="estimate-item-split-input"
                style="margin-left: 34px;"
            />
          </div>

          <div v-if="selectedEstimateItemData.type === 'RR'" class="">
            <template v-if="selectedEstimateItemData && selectedEstimateItemData.item && selectedEstimateItemData.type && (selectedEstimateItemData.item.split_remove.length || selectedEstimateItemData.item.split_replace.length)">
              <div v-for="splitItem in selectedEstimateItemData.item.split_remove" :key="splitItem.id" class="estimate-item-split-menu justify-content-between">
                <div class="d-flex">
                  <span class="pl-1" style="white-space: nowrap;">Rmv {{ splitItem.percent }}% - </span><span style="padding-left: 3px" :class="{ 'current-split-user': splitItem.createdBy.id === userInfo.user_id}">{{ splitItem.createdBy.fullName }}</span>
                </div>
                <div>
                  <i v-if="splitItem.createdBy.id === userInfo.user_id" @touchend="goToUpdateSplitForMobile(selectedEstimateItemData, 'Remove', splitItem)" class='bx bx-edit-alt split-edit-btn'></i>
                  <i v-if="splitItem.createdBy.id === userInfo.user_id" @touchend="removeSplitForMobileContext(splitItem, selectedEstimateItemData)" class='bx bx-trash split-delete-btn'></i>
                </div>
              </div>
              <div v-for="splitItem in selectedEstimateItemData.item.split_replace" :key="splitItem.id" class="estimate-item-split-menu justify-content-between">
                <div class="d-flex">
                  <span class="pl-1" style="white-space: nowrap;">Rft {{ splitItem.percent }}% - </span><span style="padding-left: 3px" :class="{ 'current-split-user': splitItem.createdBy.id === userInfo.user_id}">{{ splitItem.createdBy.fullName }}</span>
                </div>
                <div>
                  <i v-if="splitItem.createdBy.id === userInfo.user_id" @touchend="goToUpdateSplitForMobile(selectedEstimateItemData, 'Refit', splitItem)" class='bx bx-edit-alt split-edit-btn'></i>
                  <i v-if="splitItem.createdBy.id === userInfo.user_id" @touchend="removeSplitForMobileContext(splitItem, selectedEstimateItemData)" class='bx bx-trash split-delete-btn'></i>
                </div>
              </div>
            </template>
            <div class="estimate-item-split-menu" style="margin-top: 20px; padding-top: 10px; border-top: 1px solid rgba(27, 30, 56, 0.25)">
              <div v-if="selectedEstimateItemData && selectedEstimateItemData.item && selectedEstimateItemData.item.percent_remove < 100" class="">
                <span class="pl-1" style="color: red">{{100 - selectedEstimateItemData.item.percent_remove}}% Rmv</span><span class="pl-1">Remaining Un-ticked</span>
              </div>
              <div v-else class="">
                <span class="pl-1" style="color: green">100%</span><span class="pl-1">Remove Completed</span>
              </div>
            </div>

            <div class="estimate-item-split-menu" style="margin-bottom: 15px;">
              <div v-if="selectedEstimateItemData && selectedEstimateItemData.item && selectedEstimateItemData.item.percent_replace < 100" class="">
                <span class="pl-1" style="color: red">{{100 - selectedEstimateItemData.item.percent_replace}}% Rft</span><span class="pl-1">Remaining Un-ticked</span>
              </div>
              <div v-else class="">
                <span class="pl-1" style="color: green">100%</span><span class="pl-1">Refit Completed</span>
              </div>
            </div>

          </div>

          <div v-else class="">
            <template v-if="selectedEstimateItemData && selectedEstimateItemData.item && selectedEstimateItemData.type && selectedEstimateItemData.item.split && selectedEstimateItemData.item.split.length">
              <div v-for="splitItem in selectedEstimateItemData.item.split" :key="splitItem.id" class="estimate-item-split-menu" style="flex-direction: column">
                <div class="d-flex" style="width: 100%; align-items: center; justify-content: space-between;">
                  <span class="pl-1" style="white-space: nowrap;">
                    <span v-if="selectedEstimateItemData.type == 'Repair'">Rep </span>
                    <span v-else-if="selectedEstimateItemData.type == 'Paint'">Pnt </span>
                    <span v-else>{{selectedEstimateItemData.type}} </span>
                    {{ splitItem.percent }}% - </span>
                    <span style="padding-left: 3px" :class="{ 'current-split-user': splitItem.createdBy.id === userInfo.user_id}">{{ splitItem.createdBy.fullName }}</span>
                  <div>
                    <i v-if="splitItem.createdBy.id === userInfo.user_id" @touchend="goToUpdateSplitForMobile(selectedEstimateItemData, selectedEstimateItemData.type, splitItem)" class='bx bx-edit-alt split-edit-btn'></i>
                    <i v-if="splitItem.createdBy.id === userInfo.user_id" @touchend="removeSplitForMobileContext(splitItem, selectedEstimateItemData)" class='bx bx-trash split-delete-btn'></i>
                  </div>
                </div>
              </div>
            </template>
            <div class="estimate-item-split-menu" style="margin-top: 20px; margin-bottom: 15px; padding-top: 10px; border-top: 1px solid rgba(27, 30, 56, 0.25)">
              <div v-if="selectedEstimateItemData && selectedEstimateItemData.item && selectedEstimateItemData.item.percent < 100" class="">
                <span class="pl-1" style="color: red">{{100 - selectedEstimateItemData.item.percent}}% {{selectedEstimateItemData.type}}</span><span class="pl-1">Remaining Un-ticked</span>
              </div>
              <div v-else class="">
                <span class="pl-1" style="color: green">100%</span><span class="pl-1">{{selectedEstimateItemData.type}} Completed</span>
              </div>
            </div>
          </div>

        </div>

        <li class="areaTouch" @touchstart="touchStartLevelHighlight" id="context-highlight-btn">Highlight Row<i
            class="icon-right-touch bx bx-chevron-right"></i></li>
        <div v-if="isShowHighlightRow" id="context-highlight" class="context-level-highlight">
          <ul>
            <li @touchend="clickOptionOfContextForEstimateItem(selectedEstimateItemData, '', 4)">Highlight Yellow</li>
            <li @touchend="clickOptionOfContextForEstimateItem(selectedEstimateItemData, '', 5)">Highlight Green</li>
            <li @touchend="clickOptionOfContextForEstimateItem(selectedEstimateItemData, '', 6)">Highlight Purple</li>
          </ul>
        </div>
        <li class="areaTouch" @touchend="clickOptionOfContextForEstimateItem(selectedEstimateItemData, '', 7)"
            v-if="selectedEstimateItemData.item.job_highlight !== 'none'">Remove Highlight
        </li>
      </ul>
      <ul v-else-if="isShowContext2"
          :style="{left: leftForTouchMenu + 'px', top: topForTouchMenu + 'px', position: 'absolute'}"
          id="context-menu-iphone-2">
        <li class="areaTouch"
            v-if="selectedSplitAllTypeOnMobile != 'Part'"
            id="context-comment-btn-2" @touchstart="showAddAllSplit">Add All Split<i
            class="icon-right-touch bx bx-chevron-right"></i></li>
        <li class="areaTouch"
            v-if="selectedSplitAllTypeOnMobile != 'Part'"
            id="context-comment-btn-2" @touchstart="undoAddSplitAll(selectedSplitAllTypeOnMobile)">Undo Add Split All</li>

        <li v-if="isCheckedAll[selectedSplitAllTypeOnMobile]" @touchend="checkAllEstItms(selectedSplitAllTypeOnMobile, false)" class="areaTouch" id="context-comment-btn-2">Cancel All</li>
        <li v-else @touchend="checkAllEstItms(selectedSplitAllTypeOnMobile, true)" class="areaTouch" id="context-comment-btn-2">Check All</li>

        <div v-if="isShowAddSplit2" class="context-level estimate-item-split" style="height: auto !important">
          <div class="estimate-item-split-menu" style="padding-bottom: 10px;">
            <span class="pl-1" style="white-space: nowrap;">Add % Split</span>
            <input :ref="'splitAll'" v-model="splitAllPercent" type="number" class="form-control estimate-item-split-input">
            <i v-if="splitAllPercent" @touchend="addAllSplit(selectedSplitAllTypeOnMobile, splitAllPercent)" class='bx bx-add-to-queue add-split-icon'></i>
            <i v-else class='bx bx-add-to-queue add-split-icon-clear'></i>
          </div>
        </div>
      </ul>
    </div>
  </div>
</template>
<style scoped>
.estimage-line {
  width: 100%;
  height: 10px;
  right: 0;
  display: block;
  position: absolute;
  background: #e3e4e7;
}
.categories-menu-color-icon {
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 3px;
  margin-right: 10px !important;
}

.V3 .current-split-user {
  color: #5E79FF;
  width: 100px;
  display: block;
  white-space: break-spaces;
  padding-left: 3px;
}

.job-card-comment {
  margin-left: 13px;
}

.last-estimate-item {
  margin-bottom: 120px !important;
}

.background-yellow {
  background-color: #FFFF00 !important;
}

.background-green {
  background-color: #00B050 !important;
}

.background-purple {
  background-color: #F069ED !important;
}

.estimate-item-comment {
  position: absolute;
  border-radius: 5px;
  overflow: hidden;
  width: 148px !important;
  height: 121px !important;
  left: 139px !important;
  background-color: #FFFFFF;
  border: 1px solid #BDBDBD;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}



.estimate-item-comment textarea {
  height: 119px;
  width: 146px;
  border: none;
  padding: 0.75rem;
  resize: none;
}

.save-comment-icon {
  position: absolute;
  bottom: 7px;
  left: 114px;
  font-size: 27px;
  color: #73716C;
}

.estimate-item-split {
  position: absolute;
  border-radius: 5px;
  overflow: hidden;
  min-width: 240px !important;
  height: 250px !important;
  left: 139px !important;
  top: -2px;
  background-color: #FFFFFF;
  border: 1px solid #BDBDBD;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  cursor: default;
  color: black;
}

.estimate-item-split:hover {
  color: black;
}

.estimate-item-split-menu {
  display: flex;
  align-items: center;
  padding-left: 5px;
  margin-top: 10px;
}

.estimate-item-all-split-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.add-split-icon,
.add-split-icon-clear {
  font-size: 27px;
  color: #5E79FF;
  padding-bottom: 12px;
  cursor: pointer;
}

.add-split-icon-clear {
  color: rgba(27, 30, 56, 0.25);
}

.add-all-split-icon,
.add-all-split-icon-clear {
  font-size: 27px;
  color: #5E79FF;
  cursor: pointer;
}

.add-all-split-icon-clear {
  color: rgba(27, 30, 56, 0.175);
}

.estimate-item-split-input {
  width: 100px;
  margin-right: 10px;
  margin-left: 10px;
}

.estimate-item-all-split-input {
  width: 100px;
  margin-right: 10px;
  margin-left: 10px;
}

.estimate-item-split-input::-webkit-outer-spin-button,
.estimate-item-split-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.estimate-item-split-input[type=number] {
    -moz-appearance:textfield;
}

.V3 .estimate-item-split .split-delete-btn,
.V3 .estimate-item-split .split-edit-btn {
  font-size: 18px;
  color: #E39000;
  cursor: pointer;
  float: none;
  transform: none;
  padding-right: 5px;
}

.V3 .cards-edit-page .tab-content {
  padding-bottom: 0px !important;
}

.V3 .cards-edit-page > .box {
  margin-bottom: 0px;
}

.wizard-bottom .progress-title span {
  font-size: 18px;
}

.wizard-bottom .progress-title a {
  margin-top: 6px;
}

.wizard-bottom .wizard-nav-pills li {
  cursor: pointer;
  max-width: initial !important;
}

.wizard-bottom .wizard-progress-bar {
  background-color: #5E79FF !important;
  color: #5E79FF !important;
  border-radius: 10px 0 0 10px;
  left: 40px;
  display: block;
  position: relative;
}

.wizard-bottom .wizard-progress-bar-not-active {
  width: 100%;
  background-color: rgba(27, 30, 56, 0.12);
  height: 10px;
  position: absolute;
  left: 50px;
  top: 9px;
}

.wizard-bottom .wizard-navigation {
  overflow: hidden !important;
  min-width: 320px !important;
  max-width: 481px !important;
}

.cards-edit-page .card-block {
  padding-right: 0;
}

.V3 .col-form-label {
  padding: 8px 0;
}

#job-card .card {
  margin-bottom: 2rem;
}

#job-card .card .card-header {
  font-weight: 700;
}

#job-card .card .card-block .row:hover {
  /*background-color: #F3F3F3;*/
}

#job-card .card input {
  cursor: pointer;
}

#job-card .card .card-header input {
  margin-left: 8px;
}

@media screen and (min-width: 768px) {
  #job-card .card .card-header input {
    margin-left: 8px;
  }
}

@media screen and (max-width: 768px) {
  #job-card .xs-hidden {
    display: none;
  }

  .V3 .cards-edit-page .tab-content {
    padding-right: 0px;
  }
}

.show-help-for-body {
  font-size: 95% !important;
  color: red;
  margin-top: .75rem !important;
}

fieldset[disabled] .multiselect {
  pointer-events: none
}

/*.multiselect__spinner {*/
/*    position: absolute;*/
/*    right: 1px;*/
/*    top: 1px;*/
/*    width: 48px;*/
/*    height: 31px;*/
/*    background: #FFFFFF;*/
/*    display: block*/
/*}*/

/*.multiselect__spinner:after, .multiselect__spinner:before {*/
/*    position: absolute;*/
/*    content: "";*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    margin: -8px 0 0 -8px;*/
/*    width: 16px;*/
/*    height: 16px;*/
/*    border-radius: 100%;*/
/*    border-color: #41B883 transparent transparent;*/
/*    border-style: solid;*/
/*    border-width: 2px;*/
/*    box-shadow: 0 0 0 1px transparent*/
/*}*/

/*.multiselect__spinner:before {*/
/*    animation: a 2.4s cubic-bezier(.41, .26, .2, .62);*/
/*    animation-iteration-count: infinite*/
/*}*/

/*.multiselect__spinner:after {*/
/*    animation: a 2.4s cubic-bezier(.51, .09, .21, .8);*/
/*    animation-iteration-count: infinite*/
/*}*/

/*.multiselect__loading-enter-active, .multiselect__loading-leave-active {*/
/*    transition: opacity .4s ease-in-out;*/
/*    opacity: 1*/
/*}*/

/*.multiselect__loading-enter, .multiselect__loading-leave-active {*/
/*    opacity: 0*/
/*}*/

/*.multiselect, .multiselect__input, .multiselect__single {*/
/*    font-family: inherit;*/
/*    -ms-touch-action: manipulation;*/
/*    touch-action: manipulation*/
/*}*/

/*.multiselect {*/
/*    box-sizing: content-box;*/
/*    display: block;*/
/*    position: relative;*/
/*    width: 100%;*/
/*    text-align: left;*/
/*    color: #35495E*/
/*}*/

/*.multiselect * {*/
/*    box-sizing: border-box*/
/*}*/

/*.multiselect:focus {*/
/*    outline: none*/
/*}*/

/*.multiselect--disabled {*/
/*    opacity: .6*/
/*}*/

/*.multiselect--active {*/
/*    z-index: 1*/
/*}*/

/*.multiselect--active:not(.multiselect--above) .multiselect__current, .multiselect--active:not(.multiselect--above) .multiselect__input, .multiselect--active:not(.multiselect--above) .multiselect__tags {*/
/*    border-bottom-left-radius: 0;*/
/*    border-bottom-right-radius: 0*/
/*}*/

/*.multiselect--active .multiselect__select {*/
/*    transform: rotate(180deg)*/
/*}*/

/*.multiselect--above.multiselect--active .multiselect__current, .multiselect--above.multiselect--active .multiselect__input, .multiselect--above.multiselect--active .multiselect__tags {*/
/*    border-top-left-radius: 0;*/
/*    border-top-right-radius: 0*/
/*}*/

/*.multiselect__input, .multiselect__single {*/
/*    position: relative;*/
/*    display: inline-block;*/
/*    min-height: 20px;*/
/*    line-height: 20px;*/
/*    border: none;*/
/*    border-radius: 5px;*/
/*    background: #FFFFFF;*/
/*    !*padding: 0 0 0 5px;*!*/
/*    width: 100%;*/
/*    transition: border .1s ease;*/
/*    box-sizing: border-box;*/
/*    !*  margin-bottom: 8px;*!*/
/*    vertical-align: top*/
/*}*/

/*.multiselect__input::-webkit-input-placeholder {*/
/*    color: #35495E*/
/*}*/

/*.multiselect__input:-ms-input-placeholder {*/
/*    color: #35495E*/
/*}*/

/*.multiselect__input::placeholder {*/
/*    color: #35495E*/
/*}*/

/*.multiselect__tag ~ .multiselect__input, .multiselect__tag ~ .multiselect__single {*/
/*    width: auto*/
/*}*/

/*.multiselect__input:hover, .multiselect__single:hover {*/
/*    border-color: #CFCFCF*/
/*}*/

/*.multiselect__input:focus, .multiselect__single:focus {*/
/*    border-color: #A8A8A8;*/
/*    outline: none*/
/*}*/

/*.multiselect__single {*/
/*    !*padding-left: 5px;*!*/
/*    !*margin-bottom: 8px*!*/
/*}*/

/*.multiselect__tags-wrap {*/
/*    display: inline*/
/*}*/

/*.multiselect__tags {*/
/*    min-height: 20px;*/
/*    display: block;*/
/*    padding: .5rem 40px .5rem .75rem;*/
/*    border-radius: 5px;*/
/*    border: 1px solid rgba(0, 0, 0, .25);*/
/*    background: #FFFFFF;*/
/*}*/

/*.multiselect__tag {*/
/*    position: relative;*/
/*    display: inline-block;*/
/*    padding: 4px 26px 4px 10px;*/
/*    border-radius: 5px;*/
/*    margin-right: 10px;*/
/*    color: #FFFFFF;*/
/*    line-height: 1;*/
/*    background: #41B883;*/
/*    margin-bottom: 5px;*/
/*    white-space: nowrap;*/
/*    overflow: hidden;*/
/*    max-width: 100%;*/
/*    text-overflow: ellipsis*/
/*}*/

/*.multiselect__tag-icon {*/
/*    cursor: pointer;*/
/*    margin-left: 7px;*/
/*    position: absolute;*/
/*    right: 0;*/
/*    top: 0;*/
/*    bottom: 0;*/
/*    width: 22px;*/
/*    text-align: center;*/
/*    line-height: 22px;*/
/*    transition: all .2s ease;*/
/*    border-radius: 5px*/
/*}*/

/*.multiselect__tag-icon:after {*/
/*    content: "\D7";*/
/*    color: #266D4D;*/
/*}*/

/*.multiselect__tag-icon:focus, .multiselect__tag-icon:hover {*/
/*    background: #369A6E*/
/*}*/

/*.multiselect__tag-icon:focus:after, .multiselect__tag-icon:hover:after {*/
/*    color: #FFFFFF*/
/*}*/

/*.multiselect__current {*/
/*    min-height: 20px;*/
/*    overflow: hidden;*/
/*    padding: 8px 12px 0;*/
/*    padding-right: 30px;*/
/*    white-space: nowrap;*/
/*    border-radius: 5px;*/
/*    border: 1px solid #E8E8E8*/
/*}*/

/*.multiselect__current, .multiselect__select {*/
/*    line-height: 16px;*/
/*    box-sizing: border-box;*/
/*    display: block;*/
/*    margin: 0;*/
/*    text-decoration: none;*/
/*    cursor: pointer*/
/*}*/

/*.multiselect__select {*/
/*    position: absolute;*/
/*    right: 1px;*/
/*    width: 40px;*/
/*    height: 22px;*/
/*    top: 8px;*/
/*    padding: 4px 8px;*/
/*    text-align: center;*/
/*    transition: transform .2s ease;*/
/*    display: inline-block;*/
/*    font: normal normal normal 14px/1 FontAwesome;*/
/*    font-size: inherit;*/
/*    text-rendering: auto;*/
/*    -webkit-font-smoothing: antialiased;*/
/*}*/

/*.multiselect__select:before {*/
/*    position: relative;*/
/*    right: 0;*/
/*    margin-top: 4px;*/
/*    content: "\f107";*/
/*}*/

/*.multiselect__placeholder {*/
/*    color: #ADADAD;*/
/*    display: inline-block;*/
/*    !* margin-bottom: 10px;*!*/
/*    !*padding-top: 2px*!*/
/*}*/

/*.multiselect--active .multiselect__placeholder {*/
/*    display: none*/
/*}*/

/*.multiselect__content-wrapper {*/
/*    position: absolute;*/
/*    display: block;*/
/*    background: #FFFFFF;*/
/*    width: 100%;*/
/*    max-height: 240px;*/
/*    overflow: auto;*/
/*    border: 1px solid #E8E8E8;*/
/*    border-top: none;*/
/*    border-bottom-left-radius: 5px;*/
/*    border-bottom-right-radius: 5px;*/
/*    z-index: 1;*/
/*    -webkit-overflow-scrolling: touch*/
/*}*/

/*.multiselect__content {*/
/*    list-style: none;*/
/*    display: inline-block;*/
/*    padding: 0;*/
/*    margin: 0;*/
/*    min-width: 100%;*/
/*    vertical-align: top*/
/*}*/

/*.multiselect--above .multiselect__content-wrapper {*/
/*    bottom: 100%;*/
/*    border-bottom-left-radius: 0;*/
/*    border-bottom-right-radius: 0;*/
/*    border-top-left-radius: 5px;*/
/*    border-top-right-radius: 5px;*/
/*    border-bottom: none;*/
/*    border-top: 1px solid #E8E8E8*/
/*}*/

/*.multiselect__content::webkit-scrollbar {*/
/*    display: none*/
/*}*/

/*.multiselect__element {*/
/*    display: block*/
/*}*/

/*.multiselect__option {*/
/*    display: block;*/
/*    padding: .5rem .75rem;;*/
/*    min-height: 20px;*/
/*    line-height: 16px;*/
/*    text-decoration: none;*/
/*    text-transform: none;*/
/*    vertical-align: middle;*/
/*    position: relative;*/
/*    cursor: pointer;*/
/*    white-space: nowrap*/
/*}*/

/*.multiselect__option:after {*/
/*    top: 0;*/
/*    right: 0;*/
/*    position: absolute;*/
/*    line-height: 40px;*/
/*    padding-right: 12px;*/
/*    padding-left: 20px;*/
/*}*/

/*.multiselect__option--highlight {*/
/*    !*background: #41B883;*!*/
/*    outline: none;*/
/*    !*color: #FFFFFF*!*/
/*    background-color: #F5F5F5 !important;*/
/*    color: #5E6571;*/
/*}*/

/*.multiselect__option--highlight:after {*/
/*    content: attr(data-select);*/
/*    !*background: #41B883;*!*/
/*    !*color: #FFFFFF*!*/
/*    background-color: #F5F5F5 !important;*/
/*    color: #5E6571;*/
/*}*/

/*.multiselect__option--selected {*/
/*    background: #F3F3F3;*/
/*    color: #35495E;*/
/*    font-weight: 700*/
/*}*/

/*.multiselect__option--selected:after {*/
/*    content: attr(data-selected);*/
/*    color: silver*/
/*}*/

/*.multiselect__option--selected.multiselect__option--highlight {*/
/*    background: #F3F3F3;*/
/*    color: #35495E;*/
/*    !*  color: #FFFFFF*!*/
/*}*/

/*.multiselect__option--selected.multiselect__option--highlight:after {*/
/*    background: #F3F3F3;*/
/*    color: #35495E;*/
/*    content: attr(data-deselect);*/
/*    !* color: #FFFFFF*!*/
/*}*/

/*.multiselect--disabled {*/
/*    background: #EDEDED;*/
/*    pointer-events: none*/
/*}*/

/*.multiselect--disabled .multiselect__current, .multiselect--disabled .multiselect__select, .multiselect__option--disabled {*/
/*    background: #EDEDED;*/
/*    color: #A6A6A6*/
/*}*/

/*.multiselect__option--disabled {*/
/*    cursor: text;*/
/*    pointer-events: none*/
/*}*/

/*.multiselect__option--group {*/
/*    background: #EDEDED;*/
/*    color: #35495E*/
/*}*/

/*.multiselect__option--group.multiselect__option--highlight {*/
/*    background: #35495E;*/
/*    color: #FFFFFF*/
/*}*/

/*.multiselect__option--group.multiselect__option--highlight:after {*/
/*    background: #35495E*/
/*}*/

/*.multiselect__option--disabled.multiselect__option--highlight {*/
/*    background: #DEDEDE*/
/*}*/

/*.multiselect__option--group-selected.multiselect__option--highlight {*/
/*    background: #FF6A6A;*/
/*    color: #FFFFFF*/
/*}*/

/*.multiselect__option--group-selected.multiselect__option--highlight:after {*/
/*    background: #FF6A6A;*/
/*    content: attr(data-deselect);*/
/*    color: #FFFFFF*/
/*}*/

/*.multiselect-enter-active, .multiselect-leave-active {*/
/*    transition: all .15s ease*/
/*}*/

/*.multiselect-enter, .multiselect-leave-active {*/
/*    opacity: 0*/
/*}*/

/*.multiselect__strong {*/
/*    margin-bottom: 8px;*/
/*    line-height: 20px;*/
/*    display: inline-block;*/
/*    vertical-align: top*/
/*}*/

@keyframes a {
  0% {
    transform: rotate(0)
  }
  to {
    transform: rotate(2turn)
  }
}
</style>

<style scoped>
.multiselect__select {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.multiselect__select:before {
  content: "\f107";
  border: none;
}
</style>

<style scoped>
.slide-fade-enter-active {
  transition: all .2s ease;
}

.slide-fade-leave-active {
  transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(-10px);
  opacity: 0;
}


</style>
<style scoped>
.nav.nav-pills.nav-stacked.nav-8 .nav-item {
  display: inline;
  margin-right: 40px;
}

.modal .mark-as {
  margin-left: 25%;
  margin-top: 25px;
}

.modal .mark-as .row {
  margin-bottom: 25px;
  font-size: 1.1rem;
}

.modal .mark-as .row .estimate-number {
  font-weight: bold;
}

.wizard-bottom .vue-form-wizard.xs {
  overflow: hidden !important;
}

.vue-form-wizard.xs {
  padding-bottom: 0px !important;
  overflow: auto;
}

.vue-fwizard.rs-scroll {
  max-height: auto;
  height: 176px;
  overflow: auto;
}

.wizard-nav {
  overflow: hidden;
}

.vue-form-wizard .wizard-nav-pills a, .vue-form-wizard .wizard-nav-pills li {
  max-width: 89px;
}

.vue-form-wizard .wizard-nav-pills {
  flex-wrap: nowrap;
}

.vue-form-wizard.xs .wizard-icon-circle {
  width: 30px !important;
  height: 30px !important;
  background: transparent !important;
  border: none !important;
}

.wizard-icon.ti-check {
  font-weight: bold;
  color: rgb(94, 121, 255);
}

.vue-form-wizard.xs .wizard-navigation .wizard-progress-with-circle {
  height: 10px !important;
  top: 19px !important;
}

.vue-form-wizard.xs .wizard-nav-pills > li.active > a .wizard-icon {
  font-size: 12px !important;
  color: rgb(94, 121, 255);
}

.wizard-navigation {
  overflow-x: auto;
}

#activity .wizard-navigation {
  float: left;
  margin-top: 18px;
  margin-left: 30px;
}

.vue-form-wizard .wizard-icon-circle .wizard-icon-container {
  border-radius: 50%;
  background-color: white;
  border: 1px solid rgb(94, 121, 255);
  flex: unset;
  width: 24px;
  height: 24px;
}

#activity .tl-wrap.b-a-primary {
  padding-left: 23px;
}

#activity .tl-wrap.b-a-primary:before {
  width: 25px;
  height: 25px;
  top: 10px;
  left: -14.5px;
  background: #142742;
  border-color: #142742;
}

#activity .tl-wrap.b-a-primary i.left {
  position: absolute;
  top: 1.15rem;
  left: -8px;
  margin-top: 18px;
  width: auto;
  height: auto;
  font-size: 13px;
  color: #FFFFFF;
}

#activity .tl-wrap.b-a-primary .fa-pencil-square-o {
  left: -7.5px;
  top: 1.3rem;
}

#activity .tl-wrap.b-a-primary .fa-external-link {
  top: 1.25rem;
  left: -8px;
}

#activity .timeline .tl-content {
  padding-left: 1.25rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

#activity .tl-wrap.b-a-primary {
  border-left-width: 1px;
  border-color: #e3e4e7 !important;
}

#activity .btn.btn-outline-primary.b-dd {
  font-size: 13px;
  font-weight: 600;
  border: none;
}

#activity .btn.btn-outline-primary.b-dd i.left {
  position: relative;
  top: 0rem;
  color: #7986A5;
  font-size: 12px;
  margin-left: 7px;
}

#activity .btn.btn-outline-primary.b-act {
  text-align: right;
  padding-right: 5px;
  padding-left: 5px;
  margin-top: -21px;
  height: auto;
  margin-right: -7px;
}

#activity .btn.btn-outline-primary.b-act i.left {
  position: relative;
  top: 0rem;
  color: #7986A5;
  font-size: 12px;
  margin-left: 14px;
}

#activity .btn.btn-outline-primary.b-dd:hover {
  color: #4F5D71;
  background: transparent !important;
}

#activity .open .btn.btn-outline-primary.b-dd {
  color: #4F5D71;
}

#activity .vue-form-wizard .wizard-header {
  padding: 15px 30px;
  padding-bottom: 0px;
}

#activity .wizard-icon-circle ~ .stepTitle {
  font-size: 12px;
  font-weight: 600;
  padding: 5px 38px;
}

#activity .act-dd i.left {
  width: 14px !important;
  height: 18px !important;
  position: relative !important;
  top: 1px !important;
  margin-top: 0px !important;
  color: #142742 !important;
  display: inline-block;
  left: 0 !important;
  height: auto !important;
  font-size: 11px !important;
}

.act-dd.act-dd-right {
  right: -9px !important;
}

.srt-dd-right {
  right: 25px !important;
  max-width: 130px;
  width: 130px;
}

.activity-list-header {
  padding: 15px;
}

.srt-dd-right.dropdown-menu i {
  position: relative;
  top: 0px;
  display: inline-block;
  padding-right: 5px;
}


.btn-outline-primary-light {
  color: #5883CC !important;
  border-color: #5883CC !important;
}

.btn-outline-primary-light:hover {
  color: #FFFFFF !important;
  background: #5883CC !important;
  border-color: #5883CC !important;
}

.border-top {
  border: 0.5px solid #DCDCDC;
}

.V3 .dropzoneContainer {
  margin: 5px 15px;
  width: auto;
  border: 1px dashed rgba(27, 30, 56, 0.25);
  border-radius: 3px;
  background: white !important;
}

.V3 .dropify-wrapper .dropify-message span.file-icon:before {
  content: '\E800';
  font-weight: 800;
  font-size: 37px;
  color: #5E79FF;
}

.V3 .dropify-wrapper .dropify-message .drop-down-text {
  color: #1C1F39;
  font-size: 13px;
  font-weight: bold;
}

.V3 .browse-btn {
  height: 38px;
  width: 126px;
  border-radius: 3px;
  background-color: #5E79FF;
  color: white;
  font-size: 13px;
  font-weight: bold;
}

.V3 .cancel-btn {
  box-sizing: border-box;
  height: 39px;
  width: 127px;
  border: 1px solid rgba(28, 31, 57, 0.25);
  border-radius: 3px;
  font-size: 13px;
  font-weight: bold;
}

.btnNotBorder {
  border-color: #FAFAFA !important;
}

.btnNotBorder:hover {
  background-color: #FAFAFA !important;
  color: #142742 !important;
}

.btnNotBorder .fa-sort-down {
  left: unset !important;
  margin: 0 !important;
  top: 5px !important;
  font-size: 14px !important;
  width: 20px !important;
  height: auto !important;
}

.dropdownLinks-container {
  flex-direction: column !important;
  position: absolute !important;
  border: none !important;
  background-color: #FAFAFA !important;
  right: 20px !important;
  top: 28px !important;
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
}

.dropdownLinks-links {
  position: relative;
  background: white;
  border: 1px solid rgba(0, 0, 0, .25) !important;
}

.dropdownLinks-links:after,
.dropdownLinks-links:before {
  bottom: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.dropdownLinks-links:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #FFFFFF;
  border-width: 5px;
  right: 12px;
  background-clip: padding-box;
}

.dropdownLinks-links:before {
  border-color: rgba(184, 184, 184, 0);
  border-bottom-color: #B8B8B8;
  border-width: 6px;
  right: 11px;
  background-clip: padding-box;
}

.activtyPopupIcon {
  position: unset !important;
  margin: 0 !important;
  font-size: 10px !important;
}

.autosave {
  color: #142742;
  font-size: 13px;
  margin-left: 100px;
}

.tabs-absolute-right {
  display: inline-flex;
  margin-right: 30px;
}

.tabs-absolute-right-notes-btn {
  margin-right: 160px;
}

.tab-content-wrap > li {
  position: relative;
}

.filterAssigned:before {
  top: -19px;
  border-color: transparent transparent transparent #BDBDBD;
  border-width: 9px;
  left: 30px;
}

.filterAssigned:after {
  top: -18px;
  border-color: transparent transparent transparent #FFFFFF;
  border-width: 9px;
  left: 30px;
}

.filterAssigned:after, .filterAssigned:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  transform: rotate(-90deg);
}


.tabs-component ul > li .loaderAssignTo {
  position: absolute;
  max-width: 30px;
  top: 5px;
  right: -16px;
}

.tabs-component ul > li {
  position: relative;
}

.filterAssigned > input {
  border: 1px solid rgba(27,30,56,.25);
  padding: 2px 7px;
  margin: 0px 7px;
  width: 90%;
  border-radius: 5px;
}

.filterAssigned > ul > li {
  font-weight: bold !important;
  padding: 5px 10px;
  cursor: pointer;
}

.filterAssigned > ul > li:hover {
  background-color: rgba(94, 121, 255, 0.1) !important;
  color: #5E79FF !important;
  border-bottom: 1px solid transparent;
  font-weight: bold !important;
}

.filterAssigned > ul {
  padding: 0px;
  height: auto;
  max-height: 300px;
  overflow: auto;
  margin-top: 4px;
}

.filterAssigned {
  position: absolute;
  left: 0;
  z-index: 22;
  background-color: #FFFFFF;
  border: 1px solid #BDBDBD;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding: 5px 0px;
  transition: 0.4s;
  top: 100%;
  transform: translateY(10px);
  opacity: 0;
  visibility: hidden;
  width: auto;
  min-width: 175px;
  max-width: 300px;
}

.openFilterAssigned {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

.top_tabs_right .assignedToWrap > a {
  padding: 0.5em 1.25em;
}

.top_tabs_right .assignedToWrap {
  margin-right: 10px;
  position: relative;
}

.top_tabs_right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media screen and (max-width: 991px) {
  .tabs-absolute-right {
    position: relative;
    top: 0px;
    right: 0;
    z-index: 99;
    float: left;
  }
}

.image-pin-area {
  position: fixed;
  width: 220px;
  height: calc(100% - 60px);
  overflow-y: auto;
  display: block;
  background: linear-gradient(180deg, #1C1F3A 0%, #161931 53.69%, #282C48 100%);
  left: 0;
  z-index: 999999;
  top: 60px;
  padding: 15px;
}

.image-pin-area .dropzone {
  padding-top: 0;
  margin-bottom: 15px;
}

.image-pin-area .dropzoneContainer {
  margin: 0;
  padding-top: 10px;
}

.image-pin-area .dropzone .dz-preview {
}

.image-pin-area .dropzone .dz-message {
  margin-top: 45px;
}

.image-pin-area .dropzone .dz-message p {
  color: #FFFFFF;
  font-size: 14px;
  font-weight: normal;
  line-height: 19px;
  margin-top: 11px;
}

.image-pin-area .dropify-wrapper {
  width: auto;
  height: auto;
  border-radius: 5px;
  border-width: 0;
  background-color: #31344B !important;
  margin-bottom: 25px;
}

.image-pin-area .col-sm-3 {
  width: 100%;
  padding: 0;
}

.image-pin-area .col-sm-3 .box.box-block.bg-grey {
  padding: 0;
}

.image-pin-area .col-sm-3 .box.box-block.bg-grey .wrap.img-fluid {
  width: 100%;
  height: auto;
}

.pin-image {
  display: flex;
  align-items: center;
  position: relative;
  color: #34495E;
  height: 27px;
  font-weight: 600;
  font-size: 13px;
  padding-top: 8px;
  padding-left: 35px;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

@media screen and (max-width: 991px) {
  .pin-image {
    margin-bottom: 15px;
  }
}

/* Hide the browser's default checkbox */
.pin-image input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.pin-checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid rgba(27, 30, 56, 0.25);
  border-radius: 3px;
  background-color: white;
}

/* On mouse-over, add a grey background color */
.pin-image:hover input ~ .pin-checkmark {
  background-color: #CCCCCC;
  border-color: #CCCCCC;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* When the checkbox is checked, add a blue background */
.pin-image input:checked ~ .pin-checkmark {
  background-color: #5E79FF;
  border-color: #5E79FF;
}

/* Create the checkmark/indicator (hidden when not checked) */
.V3 .pin-checkmark i {
  margin: auto;
  width: 16px;
  margin-top: 3px;
  display: none;
}

.V3 #job-card .pin-checkmark i {
  font-size: 26px;
  color: white;
  margin-left: -2px;
  margin-top: -2px;
}

/* Show the checkmark when checked */
.pin-image input:checked ~ .pin-checkmark i {
  display: block;
}

/* Style the checkmark/indicator */
.pin-image .pin-checkmark:after {
}

.vissible-sm {
  display: none;
}

@media screen and (max-width: 991px) {
  .vissible-sm {
    display: block;
  }
}

.V3 .tab-content {
  padding-left: 0px;
  padding-right: 0px;
}

.V3 .text-or {
  color: #1C1F39;
  font-family: "Nunito Sans";
  font-weight: 700;
  opacity: 0.25;
  font-size: 13px;
}

.V3 #card-photos .bx {
  font-size: 37px;
  color: #5E79FF;
}


.V3 #job-card .estimate-header {
  margin: 0 15px 15px 15px;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: bold;
}

.V3 #job-card .card-header {
  font-family: "Nunito Sans";
  font-size: 13px;
  font-weight: bold;
}

.V3 #job-card .row > div {
  padding: 0;
}

.V3 #job-card .card-block {
  font-family: "Nunito Sans";
  font-size: 12px;
  font-weight: 600;
  padding: 0px !important;
}

.V3 #job-card .card-block .left-element {
  padding: 0.75rem 0px 0.75rem 13px !important;
}

.V3 #job-card .card-block .right-element {
  padding-left: 13px;
}

.V3 #job-card .card-header .pin_header {
  top: -11px;
  left: -25px;
}

.V3 #job-card .card-block .pin_content {
  left: -14px;
  top: -8px;
}

.V3 #job-card .card-block .row {
  border-bottom: 1px solid rgba(27, 30, 56, 0.25);
}

.V3 #job-card .card-block .row:last-child {
  border: none;
}

/* On mouse-over, add a grey background color */
.V3 #job-card .pin-job:hover input ~ .pin-checkmark {
  background-color: white;
  border-color: #CCCCCC;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* When the checkbox is checked, add a blue background */
.V3 #job-card .pin-job input:checked ~ .pin-checkmark {
  background-color: #02C39A;
  border: 1px solid #DFDFDF;
}


.pin-job {
  position: relative;
  color: #34495E;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* Hide the browser's default checkbox */
.pin-job input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* On mouse-over, add a grey background color */
.pin-job:hover input ~ .pin-checkmark {
  background-color: #CCCCCC;
  border-color: #CCCCCC;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* When the checkbox is checked, add a blue background */
.pin-job input:checked ~ .pin-checkmark {
  background-color: #5E79FF;
  border-color: #5E79FF;
}

/* Show the checkmark when checked */
.pin-job input:checked ~ .pin-checkmark i {
  display: block;
}

/* on mobile device */
/* .V3 .is-mobile-only .page-header, */
.V3 .is-mobile-only .tabs-component {
  position: relative;
  top: 0px;
}
</style>
<style>
.hide-card-block {
  opacity: 0 !important;
}

.V3 .cards-edit-page label.radio > input[type="radio"]:checked:disabled + span.icon i {
  color: #000000;
}

.V3 .cards-edit-page label.radio > input[type="radio"]:checked:disabled + span.icon {
  background-color: rgba(27, 30, 56, 0.03);
}

.V3 .cards-edit-page .owner-type-checkboxes label.radio > input[type="radio"]:checked:disabled + span.icon i {
  color: white;
}

.V3 .cards-edit-page .owner-type-checkboxes label.radio > input[type="radio"]:checked:disabled + span.icon {
  background-color: #5E79FF;
}

.V3 .cards-edit-page label.radio > input[type="radio"]:disabled + span.icon:hover {
  cursor: not-allowed;
}


.cards-edit-page #declineORMJob .modal-footer {
  margin-top: 25px;
}

.cards-edit-page #declineORMJob .modal-content {
  margin-left: 20%;
  width: 60%;
}

.V3 .cards-edit-page .checkbox-in-job-card-header {
  top: 9px !important;
  left: -25px !important;
  width: 25px;
}
.sticky {
  background-color: white;
  z-index: 111;
  top: 203px;
  position: sticky;
  position: -webkit-sticky;
}

/* max-width 1522px, 1126px, 1026px, 991px, 975px handle using js */
@media screen and (max-width: 767px) {
  .sticky {
    top: unset !important; /* override js calculation */
    position: relative;
  }
}

.nav-title {
  padding-left: 15px;
}

.V3 .cards-edit-page .estimate-item-split-input .multiselect__input,
.V3 .cards-edit-page .estimate-item-split-input .multiselect__single,
.V3 .cards-edit-page .estimate-item-split-input .multiselect__tags {
  min-width: auto !important;
}

.V3 #job-card .multiselect__content-wrapper {
  max-width: 100px !important;
  width: 100px;
  min-width: 100px;
}

.V3 #job-card .multiselect__option--highlight:hover,
.V3 #job-card .multiselect__option--highlight,
.V3 #job-card .multiselect__option--highlight::after {
  background-color: transparent !important;
}

.V3 #job-card .multiselect__option span:hover,
.V3 #job-card .multiselect__element span:hover {
  color: #5E79FF !important;
}

.multiselect__option--selected {
  background-color: transparent !important;
}

.V3 .cards-edit-page .form-control:disabled,
.V3 .cards-edit-page .multiselect--disabled {
    opacity: 1 !important;
}

.V3 .cards-edit-page .multiselect--disabled .multiselect__select:before {
    content: none !important ;
}

.V3 .cards-edit-page .disabled-cards-edit-is-user .fa-angle-down {
    display: none !important;
  }

.cards-edit-page .vue__time-picker input.display-time:disabled,
.cards-edit-page .vue__time-picker input.display-time.disabled {
  color: #1C1F39;
}
</style>

<style scoped>
@media screen and (max-width: 767px) {
  .reset-padd {
    padding-right: 0px;
    padding-left: 0px;
  }

  .add-padd-mobile {
    padding-right: 15px;
    padding-left: 15px;
  }

  .checkbox-group {
    flex-direction: column;
  }
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-p {
  padding-bottom: 10px;
  padding-right: 10px;
}

.checkbox-title-RR {
  top: 0 !important;
}

.allRrInJobCard-text {
  right: -2px;
  top: 14px;
  position: relative;
}

.card-block-icons {
  visibility: hidden;
  display: flex;
  justify-content: flex-end;
  padding-top: 0.75rem !important;
  padding-right: 20px !important;
}

.card-block-icons div i {
  font-size: 22px;
  color: #02C39A;
}

.icon-user-active,
.icon-comment-active {
  visibility: visible;
  cursor: pointer;
}

.repair-fixed {
  top: 0 !important;
}

.V3 .card-header {
  align-content: center;
  align-items: center;
}

.additional-flex-end {
  justify-content: flex-end;
}

#activity .timeline .tl-content table td tr {
  word-break: break-all;
}

#activity .timeline .tl-content table td {
  word-break: break-all;
}

.tooltip {
  top: 32px !important;
  right: 20px !important;
}

@media screen and (max-width: 1670px) {
  .additional-padding-btn {
    padding-bottom: 10px;
  }
}

#activity .wizard-progress-with-circle {
  background-color: rgba(27, 30, 56, 0.12);
  border-radius: 5px;
  /* to keep border radius */
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .header-display-name {
    display: none;
  }
}
</style>

<style>
.V3 .cards-edit-page .board-multiselect-width .multiselect__input {
  min-width: 0;
}

.V3 .cards-edit-page span.vue__time-picker input.display-time {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  font-family: 'Nunito Sans', sans-serif;
}

@media screen and (max-width: 1024px) {
  .V3 .cards-edit-page .page-header .page-header-nav-pin-images {
    bottom: 22px;
  }
}
</style>

<style>
.areaTouch {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#context-loader {
  position: absolute;
  width: 84px;
  height: 84px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

#context-loader svg {
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
}

#context-loader svg .context-loader-background {
  fill: none;
  stroke: #e2eff0;
  stroke-width: 1.8;
}

#context-loader .context-loader-progress {
  fill: none;
  stroke: #1b1e3812;
  stroke: #5e79ff;
  stroke-dasharray: 100 100;
  stroke-dashoffset: 100;
  stroke-linecap: round;
  stroke-width: 1.8;
  transition: all 5s ease-in-out;
}

.context-loader-progress.active {
  animation: dashOffset 1s forwards;
}

.pin-job {
  position: relative;
  color: #34495E;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: 0.3s;
}

.pin-checkmark {
  position: absolute;
  top: 12px;
  height: 25px;
  width: 25px;
  border-radius: 3px;
  background-color: white;
  border: 1px solid #DFDFDF;
  left: 3px;
  color: #34495E;
  cursor: pointer;
}

.allRrInJobCard-text {
  right: -2px;
  top: 14px;
  position: relative;
  font-family: "Nunito Sans";
  font-size: 13px;
  font-weight: bold;
}

.input-checkbox {
  visibility: hidden;
}


@keyframes dashOffset {
  from {
    stroke-dashoffset: 100;
  }

  to {
    stroke-dashoffset: 0;
  }
}

#context-menu-iphone, #context-menu-iphone-2 {
  position: absolute;
  background: #fff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  white-space: nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: 'Nunito Sans', sans-serif;
  color: #373A3C;
  font-size: 11px !important;
  line-height: 15px !important;
  font-weight: bold !important;
  border: 1px solid #bdbdbd;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  width: 140px;
  border-radius: 3px;
  z-index: 100;
}

#context-menu-iphone li, #context-menu-iphone-2 li {
  display: block;
  text-decoration: none;
  color: #373A3C;
  padding: 15px 10px 15px 10px !important;
  height: 31px;
  border-radius: 3px 3px 0 0;
  text-align: left;
}

#context-menu-iphone li, #context-menu-iphone-2 li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}

#context-menu-iphone li:hover {
  background-color: rgba(94, 121, 255, 0.1) !important;
  color: #5E79FF !important;
  font-weight: bold;
}

#context-menu-iphone li:hover {
  background-color: rgba(94, 121, 255, 0.1) !important;
  color: #5E79FF !important;
  font-weight: bold;
}

.icon-right-touch {
  transform: translate(100%, 0) !important;
  font-size: 21px !important;
  position: absolute;
  right: 25px;
}

.context-level-add-comment {
  position: absolute;
  left: 140px;
  width: 148px !important;
  height: 121px !important;
  border: 1px solid #BDBDBD;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  transition: .4s;
  transform: translateY(15px);
  background: #FFFFFF;
  top: -15px;
  border-radius: 3px;
  z-index: 10;
}

.context-level-add-comment textarea {
  height: 85px;
  width: 105px;
  border: none;
  padding: 0.75rem;
  resize: none;
  font-family: 'Nunito Sans', sans-serif;
  color: #73716C;
  font-size: 12px;
}

.context-level-add-comment textarea:focus {
  outline: none;
}

.context-level-add-all-split {
  position: absolute;
  left: 140px;
  width: 240px !important;
  border: 1px solid #BDBDBD;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  transition: .4s;
  transform: translateY(15px);
  background: #FFFFFF;
  top: -15px;
  border-radius: 3px;
  z-index: 10;
}

.save-comment-icon {
  position: absolute;
  bottom: 7px;
  left: 114px;
  font-size: 27px;
  color: #73716C;
}

.context-level-highlight {
  list-style: none;
  position: absolute;
  left: 139px !important;
  width: 120px !important;
  border: 1px solid #BDBDBD;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  transition: .4s;
  transform: translateY(15px);
  background: #FFFFFF;
  border-radius: 3px;
  top: 20px;
}

.context-level-highlight ul {
  display: block;
  text-decoration: none;
  border-radius: 3px 3px 0 0;
  text-align: left;
}

.context-level-highlight ul li {
  padding: 8px 10px 5px 10px !important;
  height: 31px;
  border-radius: 3px 3px 0 0;
}

.touch-block {
  margin: 15px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.V3 .cards-edit-page span.vue__time-picker.cards-edit-timepicker input.display-time {
  padding-left: 10px;
}

.V3 .cards-edit-page span.vue__time-picker.cards-edit-timepicker .controls{
  right:15px;
}

.V3 .cards-edit-page .estimate-item-split-menu .multiselect__option--selected.multiselect__option--highlight:after {
  height: auto;
}

.cards-edit-page .estimate-item-split-menu .multiselect__element {
  padding: 0px !important;
}

.V3 .cards-edit-page .gradient-icon {
  background: linear-gradient(to right, #02C39A 50%, #FE914A 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.V3 .cards-edit-page .gradient-checkbox {
  background: linear-gradient(to right, #02C39A 50%, #FE914A 50%);
  height: 26px;
  top: -2px !important;
}

.V3 .cards-edit-page .gradient-checkbox-RR-header {
  background: linear-gradient(to right, #02C39A 50%, #FE914A 50%);
  height: 26px;
  top: 0px !important;
}


.V3 .cards-edit-page .additional-split-checkbox {
  top: 9px !important;
  left: -5px !important;
  width: 26px;
}

.V3 .cards-edit-page .card-header .bx {
  vertical-align: baseline;
}

.V3 .cards-edit-page .bxs-group {
  text-transform: uppercase;
  background: radial-gradient(circle farthest-side at 110% 32%, #5E79FF 50%, #02C39A 30%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #02C39A;
}

.bx-user-remove,
.bx-user-refit,
.bx-user-clear {
  color: #e3e4e7 !important;
}

@media screen and (max-width: 488px) {
  .card-block-icons {
    padding-right: 20px !important;
  }

  .card-block-icons i {
    margin-right: 0 !important;
  }

  .icon-block-margin {
    margin-left: 0 !important;
  }
}

.ps-tooltip-split-users .tooltip-inner {
  text-align: start !important;
}
</style>

<style scoped>
  .ban-user-select {
    -webkit-user-select: none !important;
  }

.modal-select-decline-orm {
  display: flex;
  align-items: center !important;
}
</style>
